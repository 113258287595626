export const yo = {
    "How Others Can Find You": "Ṣe ẹ̀dá mọ̀ láti wọ̀n",
    "Schedules": "Awọn iṣẹlẹ",
    "Login": "Wọle",
    "Start Your InstaPay Journey Today!": "Bẹrẹ Irin-ajo InstaPay Rẹ Loni!",
    "Phone Number or Email": "Nọmba Foonu tabi Imeeli",
    "Login Using Social Media": "Wọle Nipa Lilo Awujo Media",
    "Continue": "Tẹsiwaju",
    "Don't Have an Account? Sign Up": "Ko ni Akọọlẹ? Forukọsilẹ",
    "Sign Up": "Forukọsilẹ",
    "Edit": "Ṣàtúnṣe",
    "Enter Your Password": "Tẹ Ọrọigbaniwọle Rẹ",
    "Forgot Password?": "Gbagbe Ọrọigbaniwọle?",
    "Account Type": "Iru Akọọlẹ",
    "Individual": "Ẹni kọọkan",
    "Business": "Iṣowo",
    "Already Have an Account? Sign In": "Tẹlẹ ni Akọọlẹ? Wọle",
    "Sing In": "Wọle",
    "Enter Your Personal Details": "Tẹ Awọn Alaye Ti ara ẹni Rẹ",
    "Add Photo or Logo": "Fi Fọto tabi Aami kun",
    "Enter Your Name": "Tẹ Orukọ Rẹ",
    "Back": "Pada",
    "Phone Number": "Nọmba Foonu",
    "Email Address": "Àdírẹ́sì ìméèlì",
    "Create a Strong Password": "Ṣẹda Ọrọigbaniwọle Lagbara",
    "Password Requirements": "Àwọn Ìbéèrè Fún Ọ̀rọ̀ Ìgbànímọ̀ọ́rọ̀",
    "Must be between 9 and 64 characters": "Gbọdọ wa laarin awọn ohun kikọ 9 si 64",
    "Include at least two of the following:": "Pẹlu o kere ju meji ninu atẹle:",
    "Uppercase character": "Ohun kikọ Nla",
    "Lowercase character": "Ohun kikọ Kekere",
    "Number": "Nọmba",
    "Special character": "Ohun kikọ Pataki",
    "Confirm Password": "Jẹrisi Ọrọigbaniwọle",
    "Finalize Your Process": "Parí Ilana Rẹ",
    "Referral Code (Optional)": "Koodu Tọka (Iyan)",
    "It will autofill if you're signing up with an invitation link.": "Yoo kun laifọwọyi ti o ba n forukọsilẹ pẹlu ọna asopọ ìpè.",
    "I agree with": "Mo gba pẹlu",
    "Terms & Conditions": "Awọn ofin & Ipo",
    "Privacy Policy": "Eto Afihan Asiri",
    "Create Your Account": "Ṣẹda Akọọlẹ Rẹ",
    "Enter Code": "Tẹ Koodu",
    "A one-time verification code has been sent to your email address  and phone number.": "A ti fi koodu ijẹrisi lẹẹkan ranṣẹ si adirẹsi imeeli rẹ ati nọmba foonu.",
    "Resend available in ": "Atunṣe wa ni",
    "seconds": "aaya",
    "Verify": "Ṣayẹwo",
    "Verification Code Verified": "Koodu Ijẹrisi Ti Ṣayẹwo",
    "Please continue to the next step.": "Jọwọ tẹsiwaju si igbesẹ ti n tẹle.",
    "Finish": "Parí",
    "Finishing": "Npari",
    "Company Name": "Orukọ Ile-iṣẹ",
    "Enter Your Business Details": "Tẹ Awọn Alaye Iṣowo Rẹ",
    "Country ": "Orilẹ-ede",
    "You cannot change the country once your account is verified.": "O ko le yi orilẹ-ede pada lẹhin ti a ti ṣayẹwo akọọlẹ rẹ.",
    "Learn more": "Kọ ẹkọ diẹ sii",
    "Optional": "Iyan",
    "Continuing": "Tẹsiwaju",
    "Didn't receive the verification code?": "Ko gba koodu ijẹrisi naa?",
    "Resend Now": "Fi pada ranṣẹ Bayi",
    "Search Here": "Wa Nibi",
    "Search for users, services, and more on InstaPay": "Wa fun awọn olumulo, iṣẹ, ati siwaju sii lori InstaPay",
    "Search users here": "Wa awọn olumulo nibi",
    "Earn money with our affiliate program!": "Jo'gun owo pẹlu eto alafaramo wa!",
    "Generate passive income by sharing your InstaPay affiliate code with your followers. For each transaction they make, \nyou earn a commission, thereby maximizing the profits from your online influence.": "\"Ṣe inawo owo-wiwọle afẹyinti nipa pinpin koodu alafaramo InstaPay rẹ pẹlu awọn ọmọlẹyin rẹ. Fun iṣowo kọọkan ti wọn ṣe, iwọ yoo jo'gun \nigbimọ kan, nitorinaa o pọ si ere lati ipa ayelujara rẹ.\"",
    "Learn More": "Kọ ẹkọ diẹ sii",
    "Balance Amount": "Iye Iwontunwonsi",
    "You spent less compared to last month.": "O lo din owo ju osù to kọja lọ.",
    "Referral Rewards": "Awọn Ẹbun Itọkasi",
    "Bitcoin": "Bitcoin",
    "USDT": "USDT",
    "Ethereum": "Ethereum",
    "Quick Transfer to Your Most Recent Contacts:": "Yiyara Gbigbe si Awọn Olubasọrọ Rẹ Tó Láti ṣẹṣẹ:",
    "Number of Transactions": "Nọmba Awọn Iṣowo",
    "Total Transaction Amount": "Apapọ Iye Iṣowo",
    "Total Sent": "Apapọ Ti a Ránṣẹ",
    "Total Received": "Apapọ Ti a Gba",
    "Add Funds": "Fi Owo Kun",
    "Send Money": "Fi Owo Ránṣẹ\n",
    "Request Money": "Beere Fun Owo",
    "Send a Quote": "Fi Iye Ránṣẹ",
    "Pay With Crypto": "San pẹlu Crypto",
    "Payment Insights": "Awọn Oye Isanwo",
    "Recent Transactions ": "Awọn Iṣowo Láìpẹ",
    "Today": "Lónìí",
    "My Wallets": "Awọn Wallet mi",
    "Balance": "Iwontunwonsi",
    "Wallet ID": "ID Wallet",
    "Monthly Limit": "Iye Iṣẹ oṣooṣu",
    "Top Up": "Kun Oke",
    "Summary": "Akopọ",
    "Settings": "Eto",
    "More": "Diẹ sii",
    "Upgrade to Business Account": "Yípadà si Àkọọlẹ Iṣẹ",
    "Conversion": "Yíyípadà",
    "Enter Amount": "Tẹ Iye sii",
    "Exchanged Amount": "Iye Ti a Yípadà",
    "Convert": "Yípadà",
    "You Are Converting": "O n Yípadà",
    "From": "Láti ọdọ",
    "To": "Si",
    "Exchange Rate": "Oṣuwọn Paṣipaarọ",
    "Fees": "Owó Ìdíyelé",
    "You Will Receive": "Iye Ti O Ma Gba",
    "Slide To Confirm": "Fà Lati Jẹri",
    "Verification Code Confirmed - You'll be redirected to the next step": "Ijeri Koodu Ìjeri - O ma tò ọ lọ si igbesẹ tó tẹle",
    "Moving to next step in": "Lọ si igbesẹ tó tẹle nínú",
    "Thanks for using InstaPay": "O ṣeun fun lilo InstaPay",
    "Your funds have been credited to your wallet.": "A ti kọ owo rẹ sinu Wallet rẹ.",
    "Dashboard": "Dashboard",
    "Accounts": "Awọn Iroyin",
    "Transactions": "Awọn Iṣowo",
    "Other Transactions": "Awọn Iṣowo Míràn",
    "Payments": "Awọn Isanwo",
    "Beneficiaries": "Awọn Anfani",
    "Referrals": "Awọn Itọkasi",
    "Quotations": "Awọn Ìdárayá",
    "Pending": "Nduro",
    "My QR Code Sticker": "Alemo QR Code mi",
    "My Portfolio": "Awọn Ifihan mi",
    "My Payment Address": "Adirẹsi Isanwo mi",
    "Analytics": "Awọn Itupalẹ",
    "Profile": "Profaili",
    "Dark Mode": "Ipo Dudu",
    "Support": "Atilẹyin",
    "Logout": "Jade",
    "Powered By": "Ti pese nipasẹ",
    "Kemit Kingdom SA": "Kemit Kingdom SA",
    "A Swiss Company": "Ile-iṣẹ Fintech Swiss",
    "MAIN MENU": "MENU AKỌKỌ",
    "OTHERS": "AWỌN MIRAN",
    "Wallet": "Wallet",
    "Default": "Aiyipada",
    "Breakdown": "Oniruuru",
    "Credit": "Gbigba",
    "Debit": "Fifunni",
    "Wallet Management": "Isakoso Wallet",
    "Top-up Your Wallet": "Kun Oke Wallet Rẹ",
    "Download Statement": "Gba Alaye",
    "Block the Wallet": "Dina Wallet",
    "Wallet Status": "Ipo Wallet",
    "-Select-File-Type-": "-Yan-Iru-Faili-",
    "Download Now": "Gba Bayi",
    "Downloading": "Gbigba",
    "All": "Gbogbo",
    "Sent": "Ti Ránṣẹ",
    "Received": "Ti Gba",
    "Requested": "Ti Beere",
    "Quoted": "Ti Sọ",
    "Search Transactions": "Wa Awọn Iṣowo",
    "Date & Time": "Ọjọ & Akoko",
    "Type": "Iru",
    "Transaction ID": "ID Iṣowo",
    "Recipient": "Olugba",
    "Amount": "Iye",
    "Status": "Ìpo",
    "Payment Type": "Iru Isanwo",
    "Sent Payments": "Awọn Isanwo Ti a Ránṣẹ",
    "Received Payments": "Awọn Isanwo Ti a Gba",
    "Date": "Ọjọ",
    "Cycles/Time": "Awọn Àkókò/Igba",
    "Payment As": "Bi Isanwo",
    "Name": "Oruko",
    "Cancel": "Fagilee",
    "Subscriptions & Scheduled": "Awọn Alabapin & Ti a Ṣeto",
    "Select the option that matches your needs from the list below:": "Yan aṣayan ti o baamu awọn aini rẹ lati atokọ ti o wa ni isalẹ:",
    "Easily send money to friends, family, or businesses locally or internationally. \nChoose from multiple transfer options, including bank deposits, mobile wallets, \ncash pick-up, payment cards, and wallet-to-wallet transfers. Enjoy the convenience \nand security of InstaPay for seamless and instant money transfers.": "Fi owo ranṣẹ si ọrẹ, ẹbi, tabi awọn iṣowo ni agbegbe tabi kariaye pẹlu irọrun. Yan lati ọpọlọpọ awọn aṣayan gbigbe, pẹlu idogo banki, mobile wallets,\nibi ti a le gba owo, awọn kaadi isanwo, ati awọn gbigbe lati wallet si wallet. Gbadun irọrun ati aabo ti InstaPay fun awọn gbigbe owo \nlaisiyonu ati lẹsẹkẹsẹ.",
    "International Transfer": "Gbigbe Kariaye\n",
    "Send money across borders with ease. Transfer funds internationally \nto friends, family, or businesses in just a few clicks. Choose from \na wide range of payment channels including bank account, \nmobile money/mobile wallet, cash pick-up location, or payment card \nfor convenient and secure transactions.": "Fi owo kọja awọn aala pẹlu irọrun. Gbe owo kariaye si ọrẹ, ẹbi, tabi awọn iṣowo ni diẹ ninu awọn tẹ ni kia kia. Yan lati inu ọpọlọpọ awọn ikanni \nisanwo pẹlu iroyin banki, owo alagbeka/mobile wallet, ibi ti a le gba owo, tabi kaadi isanwo fun awọn iṣowo ti o rọrun ati ailewu.",
    "Select the country where you want to send the money!": "Yan Orilẹ-ede Nibiti O Fẹ Fi Owo Ránṣẹ Si!",
    "Search Country": "Ṣawari Orilẹ-ede",
    "Trouble Sending Money?": "Iṣoro Nini Fi Owo Ránṣẹ?",
    "Confirm": "Jẹrisi",
    "Select Payout Channel": "Yan Ikanni Isanwo",
    "Select your most convenient option to send the money.": "Yan aṣayan ti o rọrun julọ lati fi owo ránṣẹ.",
    "Select The Bank": "Yan Banki",
    "Search Bank": "Ṣawari Banki",
    "Send money directly to a recipient's bank account. Enjoy the convenience and security of transferring funds seamlessly to any bank around the world.": "Fi owo taara si iroyin banki ti olugba. Gbadun irọrun ati aabo ti gbigbe owo laisiyonu si eyikeyi banki kakiri aye.",
    "Bank Transfer": "Gbigbe Banki",
    "Enter the amount": "Tẹ iye owo sii",
    "Tax": "Owo-ori",
    "Amount the recipient will receive": "Iye ti olugba yoo gba",
    "Select Remarks / Reason": "Yan Awọn Asọye / Idi",
    "Comments": "Awọn Asọye",
    "Attach Files": "Fi Awọn faili Kun",
    "Groups": "Awọn Ẹgbẹ",
    "Manage Groups": "Ṣakoso Awọn Ẹgbẹ",
    "Add New Group": "Fi Ẹgbẹ Tuntun Kun",
    "Invite": "Pè Eniyan Kan",
    "Manage Beneficiaries": "Ṣakoso Awọn Anfani",
    "Recipients List": "Atokọ Awọn Olugba",
    "Search Contacts": "Ṣawari Awọn Olubasọrọ",
    "Confirm Your Payment": "Jẹrisi Isanwo Rẹ",
    "Select Country": "Yan Orilẹ-ede",
    "Select the Recipient's Payment Channel": "Yan Ikanni Isanwo Olugba",
    "Change Selection": "Yipada Aṣayan",
    "You Are Transferring ": "O N Fi Owo Ránṣẹ",
    "Oops! Something Went Wrong.": "Oops! Nkan Ti Ṣẹlẹ.",
    "We're Sorry About That. ": "A Tọrọ Gafara Nipa Eyi.",
    "Go back to Payments Page.": "Pada si Oju-iwe Awọn Isanwo.",
    "Wallet to Wallet Transfer": "Gbigbe Lati Wallet Si Wallet",
    "Request a customized price quote from service providers or vendors.": "Beere fun iye owo aṣa lati ọdọ awọn olupese iṣẹ tabi awọn oniṣowo.",
    "Enter the Wallet ID": "Tẹ ID Wallet",
    "Enter the Wallet ID, Email, or Phone Number of the recipient.": "Tẹ ID Wallet, Imeeli, tabi Nọmba Foonu ti olugba.",
    "Recipient not on InstaPay?": "Ṣe Olugba Ko Wa Lori InstaPay? Pè Wọn.",
    "Invite Them": "Pè Wọn",
    "My Beneficiaries": "Awọn Anfani Mi",
    "Enter the Amount You Wish to Transfer.": "Tẹ Iye Ti O Fẹ Fi Ránṣẹ.",
    "Sending to ": "Fi Ránṣẹ Si",
    "You Have Selected Wallet to Wallet Transfer": "O Ti Yan Gbigbe Lati Wallet Si Wallet",
    "Authenticate Your Payment.": "Ṣe Ijerisi Isanwo Rẹ.",
    "You are transferring": "O N Fi Owo Ránṣẹ",
    "Total ": "Lapapọ",
    "Where Is My Payment?": "Ibo Ni Isanwo Mi Wa?",
    "Share": "Pin",
    "Your Payment Is Confirmed": "Isanwo Rẹ Ti Fidi Rẹ Mulẹ",
    "QR Code Payment": "Isanwo Koodu QR",
    "Instantly transfer money to other InstaPay users with our secure wallet-to-wallet \nservice. Send and receive funds hassle-free within seconds.": "Fi owo gbe lẹsẹkẹsẹ si awọn olumulo InstaPay miiran pẹlu iṣẹ wa ti o ni aabo lati wallet si wallet. Fi ati gba owo laisi wahala laarin iṣẹju-aaya.",
    "Scan QR with Your Camera or Upload from Your Device.": "Ṣayẹwo QR pẹlu Kamẹra Rẹ tabi Gbe lati Ẹrọ Rẹ.",
    "Upload from Your Device.": "Gbe lati Ẹrọ Rẹ.",
    "Alternatively, Use the InstaPay Unique Payment Address (UPA)": "Bi omiiran, Lo Adirẹsi Isanwo Alailẹgbẹ InstaPay (UPA)",
    "Enter InstaPay UPA": "Tẹ InstaPay UPA",
    "Recipient not on InstaPay? Invite Them": "Ṣe Olugba Ko Wa Lori InstaPay? Pè Wọn",
    "Verify QR Code": "Ṣe Ayẹwo Koodu QR",
    "Scan/Upload QR Code of the Recipient": "Ṣayẹwo/Gbe Koodu QR ti Olugba",
    "Payment Confirmed": "Isanwo Ti Fidi Rẹ Mulẹ",
    "Request customized price quotes from service providers or vendors to receive accurate cost estimates for the services or products you need.": "Beere fun iye owo adani lati ọdọ awọn olupese iṣẹ tabi awọn olutaja lati gba iṣiro idiyele to tọ fun awọn iṣẹ tabi awọn ọja ti o nilo.",
    "Choose The Beneficiary or Group": "Yan Ẹnìkan Tó Jẹ́ Ànfàní Tàbí Ẹgbẹ́",
    "Enter the Amount You Wish to Request.": "Tẹ iye owó tí o fẹ́ béèrè sí.",
    "You Are Requesting Money": "Ìwọ ń béèrè owó",
    "You are requesting": "Ìwọ ń béèrè",
    "As": "Bí",
    "Conversion of Your Cryptocurrencies": "Yípadà Òwò Owó Oníbíṣẹ́ rẹ",
    "Experience our user-centric, secure, and efficient Cash-Out Crypto to Fiat Conversion.": "Ṣe ìrírí ìyípadà owó oníbíṣẹ́ sí owó fíátì wa ti ó dára fún olùmúlò, aàbò, àti dídára.",
    "Choose the Cryptocurrency You Intend to Liquidate.": "Yan owó oníbíṣẹ́ tí o fẹ́ ta.",
    "Next": "Lẹ́yìn náà",
    "Enter the Crypto Wallet Address of the Recipient.": "Tẹ adírẹ́sì Wallet owó oníbíṣẹ́ olùgbà náà wọlé.",
    "Minimum Transactional Value: $100.": "Iye Owó Ti Ó Kéré Jùlọ Fún Gbígba Owó: $100.",
    "Note: Crypto prices frequently change. Refer to the final quotation on the confirm order page.": "Àkíyèsí: Iye owó oníbíṣẹ́ máa ń yípadà nígbà gbogbo. Tọ́ka sí ìsọtẹ́lẹ̀ ìkẹyìn ní ojú-ìwé jẹ́rìí ìbéèrè.",
    "Select Network": "Yan Nẹtiwọ́kì",
    "Send A Quote": "Rán Ìsọtẹ́lẹ̀ Kan",
    "Send a detailed price quote to your clients or customers. Present your services, products, or offers professionally with a personalized quotation.": "Rán ìsọtẹ́lẹ̀ owó aláyé sí àwọn alábàárà rẹ tàbí àwọn oníbàárà rẹ. Fi ìṣẹ́ rẹ, àwọn ọjà rẹ, tàbí àwọn ìfilọ́ rẹ hàn ní ọ̀nà ọjọgbọn pẹ̀lú ìsọtẹ́lẹ̀ owó tí \na ṣe pàtàkì fún wọn.",
    "Choose the Beneficiary": "Yan olùrànlọ́wọ́",
    "Allow Receiver to Bargain": "Gba olùgbéejáde láàyè láti pàṣípààrọ̀",
    "Enter the Title for Your Quotation.": "Tẹ àkọlé fún ìsọtẹ́lẹ̀ owó rẹ.",
    "Enter the Description for Your Quotation": "Tẹ àpèjúwe fún ìsọtẹ́lẹ̀ owó rẹ.",
    "You are Quoting": "O ń ṣe ìsọtẹ́lẹ̀ owó",
    "Title": "Àkọlé",
    "Description": "Àlàyé",
    "Attachments": "Àfikún",
    "Confirm Your Quotation": "Jẹ́rìí sí ìsọtẹ́lẹ̀ owó rẹ",
    "Verify The Quotation": "Ṣàyẹ̀wò ìsọtẹ́lẹ̀ owó",
    "Your quotation is confirmed": "Ìsọtẹ́lẹ̀ owó rẹ ti jẹ́rìí",
    "Request A Quote": "Beere Ìdáhùn",
    "Coming Soon": "Tí ń bọ̀ laipẹ",
    "Add Beneficiary": "Fi olùrànlọ́wọ́ kun",
    "Search for Beneficiaries": "Ṣàwárí àwọn olùrànlọ́wọ́",
    "Connected Accounts": "Àwọn àkọọ́lẹ́ tí a sopọ̀ mọ́ra",
    "Details": "Àlàyé",
    "First Name": "Orúkọ àkọ́kọ́",
    "Last Name": "Orúkọ ìdílé",
    "Country": "Orílẹ̀-èdè",
    "City": "Ìlú",
    "Address Line": "Àdírẹ́sì",
    "Mobile Number": "Nọ́mbà fóònù alágbèéká",
    "Relationship With Beneficiary": "Ìbáṣepọ̀ pẹ̀lú olùrànlọ́wọ́",
    "Bank Account": "Àkọọ́lẹ́ bánkì",
    "Mobile Money/Mobile Wallet": "Mobile Money/Mobile Wallet",
    "Payment Card": "Káàdì ìsanwó",
    "Crypto Wallet": "Crypto Wallet",
    "InstaPay Account": "Àkọọ́lẹ́ InstaPay",
    "Cash Pickup": "Gbígbà owó ní ààyè",
    "IBAN / SWIFT Code": "Kóòdù IBAN tàbí Swift/BIC",
    "Swift Code": "Kóòdù Swift",
    "Account Number": "Nọ́mbà àkọọ́lẹ́",
    "Bank Name": "Orúkọ bánkì",
    "Branch Name": "Orúkọ ẹ̀ka bánkì",
    "Branch Street": "Ìlà ẹ̀ka bánkì",
    "Province": "Ìpínlẹ̀",
    "Postal Code": "Kóòdù ìpòsíta",
    "Mobile Money Provider": "Olùpèsè Mobile Money",
    "Wallet Name": "Orúkọ Wallet",
    "Wallet Number": "Nọ́mbà Wallet",
    "Card Holder Name": "Orúkọ olóye káàdì",
    "Card Number": "Nọ́mbà káàdì",
    "Expiry Date": "Ọjọ́ ìparí",
    "Crypto Currency": "Owó Crypto",
    "Wallet Address": "Àdírẹ́sì Wallet",
    "Wallet Holder Name": "Orúkọ olóye Wallet",
    "Wallet Currency": "Òwò owó Wallet",
    "Select Document Type": "Yàn ìrú ìwé àmúlò",
    "Enter Document Number": "Tẹ nọ́mbà ìwé àmúlò",
    "Add Individual Account": "Fi àkọọ́lẹ́ kọọ̀kan kun",
    "Add Business Account": "Fi àkọọ́lẹ́ ìṣẹ́ kun",
    "Company Address": "Àdírẹ́sì ilé-iṣẹ́",
    "Company Email": "Ìméèlì ilé-iṣẹ́",
    "Company Phone No": "Nọ́mbà fóònù ilé-iṣẹ́",
    "Total Referrals": "Àpapọ̀ ìtọ́kasí",
    "My Earnings": "Owó Tí Mo Jẹ́",
    "Your Referral ID": "Nọ́mbà Ìtọ́kasí Rẹ",
    "Share Your Unique Referral Link": "Pín Àsopọ̀ Ìtọ́kasí Aláìlẹ́gbẹ́ Rẹ",
    "How do I Refer a Friend to InstaPay?": "Báwo ni mo ṣe lè ṣe ìtọ́kasí ọ̀rẹ́ kan sí InstaPay?",
    "Step 01": "Igbese 01",
    "Share your InstaPay unique referral link with your friends.": "Pín àsopọ̀ ìtọ́kasí aláìlẹ́gbẹ́ InstaPay rẹ pẹ̀lú àwọn ọ̀rẹ́ rẹ.",
    "Step 02": "Igbese 02",
    "Earn rewards when they transact $100 or more": "Jẹ́ èrè nígbà tí wọ́n bá ṣe ìgbòwó $100 tàbí ju bẹ́ẹ̀ lọ",
    "Step 03": "Igbese 03",
    "Earn up to $0.06 for every transaction your followers make.": "Jẹ́ èrè tó tó $0.06 fún ìgbòwó kọ̀ọ̀kan tí àwọn ọmọ-ẹgbẹ́ rẹ bá ṣe.",
    "My Referrals": "Àwọn Ìtọ́kasí Mi",
    "Referral Complete": "Ìtọ́kasí Tí Parí",
    "Received Quotations": "Àwọn Ìsọtẹ́lẹ̀ Tí A Gba",
    "Sent Quotations": "Àwọn Ìsọtẹ́lẹ̀ Tí A Rán",
    "Search Pending Items": "Ṣe Àwárí Ohun Tí ń Dúró Dè",
    "Actions": "Ìgbésẹ̀",
    "Received Requests": "Àwọn Ìbéèrè Tí A Gba",
    "Sent Requests": "Àwọn Ìbéèrè Tí A Rán",
    "No Actions": "Kò sí Ìgbésẹ̀",
    "You Requested": "Ìbéèrè Rẹ",
    "Via": "Nípasẹ̀",
    "How was your experience?": "Báwo ni ìrírí rẹ ṣe rí?",
    "Leave a Review": "Fi Àtúnyẹwò Sílẹ̀",
    "Overall Ratings": "Ìdíyelé Àpapọ̀",
    "Visit Profile": "Bẹ Wo Profaili",
    "Ratings": "Ìdíyelé",
    "Reviews": "Àwọn Àtúnyẹwò",
    "No reviews available": "Kò sí àwọn àtúnyẹwò",
    "Select Your Currency": "Yan Owó Rẹ",
    "Proceed": "Tẹ̀síwájú",
    "Commision": "Kọmíṣánì",
    "Unlock Seamless Transactions with InstaPay QR Code Sticker": "Ṣí Ìgbòwó Láìlórúkọ pẹ̀lú InstaPay QR Code Sticker",
    "Effortless and Swift:": "Láìṣòro àti Yáráyárá:",
    "Instantly download your unique InstaPay QR code sticker.": "Lọ̀ ọ́ gba àmì QR InstaPay aláìlẹ́gbẹ́ rẹ lẹ́sẹ̀kẹsẹ̀.",
    "Experience hassle-free, cashless payments without needing complex infrastructure like \npayment terminals.": "Ṣe àwọn ìgbòwó láìsí owó-ọjà láìsí nílò ètò ìsẹ̀dáwó gẹ́gẹ́ bíi àwọn ẹ̀rọ ìsanwó.",
    "Swiftly collect payments – no more tedious entry of credit card or phone numbers.": "Gba owó yáráyárá - kò sí nílò ìtẹ̀síwájú káàdì krẹ́dítì tàbí àwọn nọ́mbà fóònù mọ́.",
    "Each payment includes customer information for your convenience.": "Ọ̀kọ̀ọ̀kan ìgbòwó náà ni àlàyé alábára fún ìrọ̀rùn rẹ.",
    "Economically Smart:": "Ọ̀nà Òye Òǹjẹ́:",
    "Zero activation fees – start immediately without upfront costs.": "Kò sí owó ìbẹ̀rẹ̀ – bẹ̀rẹ̀ lẹ́sẹ̀kẹsẹ̀ láìsí owó ìkọ́kọ́.",
    "Forget about fixed monthly charges; pay as you transact.": "Gbagbe nípa owó ìdíyelé oṣù tó tọ́; sanwó bí o ṣe ń ṣe ìgbòwó.",
    "Ditch the hardware; no terminal costs mean more savings for you.": "Fi ohun èlò sílẹ̀; kò sí owó èlò ètò ìsanwó túmọ̀ sí ìfipamọ́ owó sí fún ọ.",
    "Enjoy low transaction fees at just 0.75% per QR code payment.": "Gbadùn ìdíyelé ìgbòwó kéré tó jẹ́ 0.75% fún ìgbòwó QR kọ̀ọ̀kan.",
    "We don't impose minimum commissions; you save more with each transaction.": "A kò gbé owó kọ́míṣánì tó kéré jùlọ; o máa fipamọ́ síwájú pẹ̀lú ìgbòwó kọ̀ọ̀kan.",
    "Experience the joy of instant payments, enhancing your cash flow.": "Ṣe ìrírí ayọ̀ ìgbòwó lẹ́sẹ̀kẹsẹ̀, tí yóò ṣe àtìlẹ́yìn fún ṣíṣàn owó rẹ.",
    "Make Every Transaction Count with InstaPay": "Ṣe Kí Ìgbòwó Kọ̀ọ̀kan Kà Nípa InstaPay",
    "Wallet QR": "Wallet QR",
    "Download QR": "Gba QR",
    "QR Code Status": "Ìpo QR Code",
    "Share your QR Code or payment address link to instantly receive payments in your InstaPay wallet.": "Pín QR Code rẹ tàbí àsopọ̀ àdírẹ́sì ìsanwó láti gba ìgbòwó lẹ́sẹ̀kẹsẹ̀ sí àpamọ́ rẹ ní InstaPay.",
    "QR Code Title": "Àkọlé QR Code",
    "View Transactions": "Wo Àwọn Ìgbòwó",
    "My QR Codes:": "Àwọn QR Codes Mi:",
    "Load More": "Fi Diẹ̀ Síi",
    "Benefits Of Portfolio": "Àwọn Anfàní Portfolio",
    "Benefits:": "Àwọn Anfàní:",
    "Enhanced Visibility:": "Híhún Àwòkọ̀ṣe:",
    "Elevate your public profile. Your portfolio acts as a dynamic showcase, accessible directly from your Payment Address.": "Gbé ìgbéraga rẹ sókè gẹ́gẹ́ bíi ẹni gbajúmọ̀. Portfolio rẹ jẹ́ àfihàn aláìléwu, tó wà lára taara láti àdírẹ́sì ìsanwó rẹ.\n",
    "Engage Your Audience:": "Ṣe ìfọwọ́sọwọ́pọ̀ pẹ̀lú Àwùjọ Rẹ:\n",
    "Connect with visitors through your creative or professional journey. Let your work speak for you, creating a deeper engagement with potential clients or supporters.": "Ṣe ìbáṣepọ̀ pẹ̀lú àwọn àlejò nípa ìrìn-àjò ọjọ́gbọ́n tàbí ẹ̀dá rẹ. Jẹ́ kí iṣẹ́ rẹ sọ̀rọ̀ fún ọ, èyí yóò fa ìfẹ́ sí i ní òdò àwọn alábára tàbí àwọn \nolùàtìlẹ́yìn ti o ṣeéṣe.",
    "Integrated Social Proof:": "Ìmúdájú Àwùjọ:",
    "Display your social media influence. Visitors can see your social media reach, adding credibility and context to your portfolio.": "Fi ìwúrí àwùjọ rẹ hàn. Àwọn aléjò lè rí ìtànran àwùjọ rẹ, tí ó ń ṣe àfikún ìgbàgbọ́ àti ìtumọ̀ sí iṣẹ́ rẹ.",
    "Seamless Transactions:": "Àwọn Ìṣòwò Aláìléwu:",
    "Enable visitors to request or initiate payments right from your public page. Your portfolio not only showcases your work but also facilitates easy financial interactions.": "Gba àwọn aléjò láàyè láti béèrè tàbí bẹ̀rẹ̀ ìṣòwò lórí ojú-òpó rẹ. Àpèjúwe rẹ kò ní fi iṣẹ́ rẹ hàn nìkan, ṣùgbọ́n ó tún rọrùn fún ìbáṣepọ̀ ìṣòwò.",
    "Personalized Storytelling:": "Ìtàn Tí A Ṣe Àdáni:\n",
    "Use the 'About Me' section to share your story, mission, or vision, creating a personal connection with your audience.": "Lò abala 'Nípa Èmi' láti pin ìtàn, ìṣẹ̀ṣe tàbí èrò rẹ, tí ó ń ṣe àmúlò ìbáṣepọ̀ pẹlú àwùjọ rẹ.\n",
    "My Portfolio' is more than just a gallery; it's a tool to build your brand, engage your audience, and streamline your financial interactions on InstaPay.": "Àpèjúwe Mi' kì í ṣe fọ́tò àgbéléwò nìkan; ó jẹ́ òhun èlò láti kọ́ àmì rẹ, láti jẹ́ kí àwùjọ rẹ dùn ún, àti láti ṣe àwọn ìbáṣepọ̀ ìṣòwò rẹ lórí InstaPay.",
    "Showcase Your Work, Amplify Your Reach. 'My Portfolio' is your digital canvas on \nInstaPay. Upload and display a diverse range of content – from captivating images \nand videos to informative PDFs. Whether you're an artist, freelancer, or \nentrepreneur, this feature lets you present your work or projects in a visually \nengaging way.": "Fi Iṣẹ́ Rẹ Hàn, Fa Ìtànran Rẹ Síi. 'Àpèjúwe Mi' ni àgbéléwò ìlọ́nà oní nọmba rẹ lórí InstaPay. Gbé àti fi àwọn àkóónú oríṣiríṣi hàn – láti àwòrán \nìfẹnukò tó dùn ún àti àwọn fídíò sí àwọn PDF aláyè. Bóyá o jẹ́ olórin, olóyè tí kò níṣẹ́ títọ́, tàbí oníṣòwò, ẹ̀yà ara yìí gba ọ láàyè láti fi iṣẹ́ tàbí \niṣẹ́ akanṣe rẹ hàn ní ọ̀nà tí yóò dùn ún fún ojú.",
    "Add/Manage Portfolio": "Fi/Kọ́ Àpèjúwe Rẹ Sílẹ̀",
    "Add New": "Fi Tuntun Kun",
    "Maximize Your Social Media Earnings with Your InstaPay Payment Address": "Fikun Òwò Rẹ Lórí Àwùjọ Àwùjọ Pẹ̀lú Àdírẹ́sì Ìṣòwò InstaPay Rẹ",
    "Make It Effortless for Your Audience to Support You:": "Ṣe Kí Ó Rọrùn Fún Àwùjọ Rẹ Láti Ṣe Àtìlẹ́yìn Fún Ọ:",
    "Easily integrate your InstaPay Payment Address into your social media posts.": "Laísí ìdààmú, dápọ̀ mọ́ àdírẹ́sì ìṣòwò InstaPay rẹ sínú àwọn ìfiranṣẹ́ àwùjọ àwùjọ rẹ.",
    "Easily copy and paste your payment address link into each of your posts, stories, or prominently on your profile.": "Laísí ìdààmú, dáàkọ àti lẹ mọ́ ònà àdírẹ́sì ìṣòwò rẹ sínú gbogbo ìfiranṣẹ́, ìtàn, tàbí ní ìpilẹ̀ṣẹ̀ lórí ojú-òpó rẹ.",
    "Ideal for influencers, artists, entrepreneurs, or anyone with a community eager to show \ntheir support.": "\"Yíyàra fún àwọn olóòtú àwùjọ àwùjọ, olórin, oníṣòwò, tàbí ẹnikẹ́ni tí ó ní àwùjọ tí wọ́n fẹ́ láti fi àtìlẹ́yìn wọn hàn.",
    "Instant Support, Endless Possibilities:": "Àtìlẹ́yìn Láìpẹ́, Àwọn Àǹfààní Tí Kò Lópin:",
    "Transform the way you monetize your social media content.": "Yí ọ̀nà tí o tètè jẹ́rìí sí àwọn àkóónú àwùjọ àwùjọ rẹ padà.",
    "Allow your followers to send you funds instantly with just a click, directly from their favorite social networks.": "Gba àwọn olùgbójú rẹ lààyè láti rán ọwó sí ọ láìpẹ́ pẹ̀lú kan nìkan, tààrà láti àwùjọ àwùjọ ayanfẹ́ wọn.",
    "Ideal for real-time support during live streams, as a tipping system for your creative content, or for funding your next big \nproject.": "Yíyàra fún àtìlẹ́yìn gígùn nígbàtí o bá ń sọ fídíò lórí ètò, gẹ́gẹ́ bí eto ìdásí fún àkóónú ẹ̀dá rẹ, tàbí fún ìfúnní fún iṣẹ́ ńlá rẹ tó ń bọ̀.",
    "Where to Place Your InstaPay Payment Address for Maximum Impact:": "Ibi Tí O Lè Fi Àdírẹ́sì Ìṣòwò InstaPay Rẹ Sí Fún Ìtànran Tó Pọ̀jù:",
    "Social Media Profiles: Pin it to your bio or about section for constant visibility.": "Àwòrán Àwùjọ Àwùjọ: Mọ ọ́ mọ́ ìtàn-àyé rẹ tàbí abala nípa rẹ fún ìhàn gígùn.",
    "Posts & Announcements: Incorporate it into your individual posts, particularly those highlighting your work or specific \ncalls for support.": "Ìfiranṣẹ́ àti Ìkéde: Ṣàfikún rẹ sí àwọn ìfiranṣẹ́ ara ẹni rẹ, pàápàá àwọn tí o jẹ́ afihàn iṣẹ́ rẹ tàbí ìpè kan pàtó fún àtìlẹ́yìn.",
    "Stories & Reels: Integrate it into your interactive stories or captivating reels to capture instant contributions.": "Ìtàn àti Fídíò Kukuru: Ṣàgbékalẹ̀ rẹ nínú àwọn ìtàn ìbáṣepọ̀ tàbí fídíò kukuru tí o mú àyà ká láti gba àwọn àfikún láìpẹ́.",
    "Live Streams: Share it during your LIVE sessions, making it easy for viewers to contribute in real-time.": "Fídíò Sísọ Gígùn: Pín rẹ nígbà àwọn ìgbà ìgbàlódé rẹ, kí o rọrùn fún àwọn olùwò láti ṣe àfikún ní àkókò gídí.",
    "Simple, Fast, and Secure:": "Rọrùn, Yára, àti Ààbò:",
    "No complex setup – get started in moments.": "Kò sí ìṣeto rírọrùn – bẹ̀rẹ̀ ní ìṣẹ́jú kan.",
    "Enjoy the security and speed of InstaPay's trusted payment processing.": "Gbadùn ààbò àti yára ìṣiṣẹ́ ìsanwó ìgbẹ́kẹ̀lé InstaPay.",
    "Receive payments from anyone, anywhere, without them needing an InstaPay account.": "Gbà ìsanwó láti ọdọ ènìyàn kankan, níbi kankan, láìsí wíwà ní àkántì InstaPay.",
    "Elevate Your Social Media Game with InstaPay Payment Address": "bé Ìwúrí Rẹ Lórí Àwùjọ Àwùjọ pẹ̀lú Àdírẹ́sì Ìṣòwò InstaPay",
    "InstaPay VPA (Virtual Payment Address)": "InstaPay VPA (Àdírẹ́sì Ìṣòwò Onírúurú)",
    "Payment Address Title": "Àkọlé Àdírẹ́sì Ìṣòwò",
    "Currency": "Owó",
    "Payment Address Description": "Àlàyé Àdírẹ́sì Ìṣòwò",
    "Update": "Ìmúdàgba",
    "Updating": "Nípa Mímúdàgba",
    "Total Transaction": "Ìgbàpadà Ìṣòwò",
    "Last 30 days": "Òṣù méjìlá sẹ́yìn",
    "Last 1 month": "Oṣù kan sẹ́yìn",
    "Last 1 year": "Ọdún kan sẹ́yìn",
    "Custom Date": "Ọjọ́ Àdáni",
    "Payment Methods": "Àwọn Ọ̀nà Ìṣòwò",
    "Payment Types": "Àwọn Ìrú Ìṣòwò",
    "Requested Amount": "Ìbéèrè Owó",
    "Quoted Amount": "Owó Ti a Sọ",
    "About Me": "Nípa Mi",
    "Followers": "Àwọn Olùgbójú",
    "InstaPay ID": "InstaPay ID",
    "Download QR Code": "Gba Kóòdù QR",
    "Initiate A Payment": "Bẹ̀rẹ̀ Ìṣòwò",
    "Report this person": "Èsùn ẹni yìí",
    "Add now": "Fi kun báyìí",
    "Recent Reviews": "Àwọn Àtúnyẹ̀wò Láìpẹ́",
    "Reviews As Seller": "Àtúnyẹ̀wò Bí Olùtàjà",
    "Reviews As Buyer": "Àtúnyẹ̀wò Bí Onírajà",
    "Select Language": "Yan Èdè",
    "Profile Completion": "Píparí Ìtàn-àyé",
    "Profile Completed": "Ìtàn-àyé Ti Pari",
    "Basic Info": "Aláyé Ìpilẹ̀ṣẹ̀",
    "Username": "Orúkọ olùmúlò",
    "Gender": "Abọ",
    "Male": "Ọkùnrin",
    "Female": "Obìnrin",
    "-Select-Gender-": "-Yan-Abọ-",
    "Date Of Birth": "Ọjọ́ Ìbí",
    "Address": "Orúkọ àti ọjọ́ ìbí",
    "Edit Profile": "Ṣàtúnṣe Ìtàn-àyé",
    "Update Profile": "Ìmúdàgba Ìtàn-àyé",
    "Updating Profile": "Nípa Ìmúdàgba Ìtàn-àyé",
    "Complete Now": "Parí Ní Báyìí",
    "Password": "Ọ̀rọ̀ Ìgbànímọ̀ọ́rọ̀",
    "Change Password": "Yí Ọ̀rọ̀ Ìgbànímọ̀ọ́rọ̀",
    "New Password": "Ọ̀rọ̀ Ìgb",
    "Re-enter New Password": "Tún Kọ Ọ̀rọ̀ Ìgbànímọ̀ọ́rọ̀ Tuntun",
    "Please follow this guide for a strong password": "Ẹ jọ̀wọ́ tẹ̀lé ìtọ́sọ́nà yìí fún ọ̀rọ̀ ìgbànímọ̀ọ́rọ̀ tó lágbára",
    "Include at least one special character.": "Pẹ̀lú ò kéré ju ọ̀kan lára àwọn àmì pàtàkì.",
    "Minimum of 8 characters.": "Ò kéré ju ẹ̀wọn lẹ́tà mẹ́jọ.",
    "Include at least one number": "Pẹ̀lú ò kéré ju nọ́mbà kan",
    "Change it often for enhanced security.": "Yí i padà nígbà gbogbo fún ààbò tó pọ̀ síi.",
    "Security Questions": "Àwọn Ìbéèrè Ààbò",
    "Security Question": "Ìbéèrè Ààbò",
    "Select your question": "Yan ìbéèrè rẹ",
    "Answer To The Question": "Ìdáhùn sí Ìbéèrè",
    "Nominee": "Olùdíje",
    "Relationship with Nominee": "Ìbáṣepọ̀ pẹ̀lú Olùdíje",
    "Nominee Contact Number": "Nọ́mbà Olùbáṣepọ̀ Olùdíje",
    "Nominee Address": "Àdírẹ́sì Olùdíje",
    "Delete Nominee": "Pa Olùdíje rẹ",
    "Update Nominee": "Ìmúdàgba Olùdíje",
    "Details Updated Successfully!": "Àwọn Aláyé Ti Ìmúdàgba Ní Aṣeyọrí!",
    "Success": "Aṣeyọrí",
    "You may elect a Nominee of your choice, provided they are not a minor, through the InstaPay platform itself. You acknowledge that no wet signature is required to \nappoint the nominee, and the online registration will be considered final and binding. In the event of the death of an InstaPay Wallet holder, the balance in the Wallet \nwill be transferred to the registered Nominee. KEMIT KINGDOM will be discharged from all its liabilities upon the handover of the amount to the Nominee. The Nominee \nwill provide any documents required by InstaPay, including those for identification and proof of death.": "O lè yan Olùdíje tí o fẹ́, tí ó bá jẹ́ pé kò jẹ́ ọmọdé, nípasẹ̀ pẹpẹ InstaPay fúnra rẹ. O mọ̀ pé kò sí nílò fún ìbúwọ́lù tó tutù láti yan olùdíje, àti pé \nìforúkọsílẹ̀ ayélujára yóò ka bíi èyí tí ó parí àti pé ó so mọ́. Nígbàtí oníhòòhò InstaPay Wallet bá kú, ìwọ̀ntúnwọ̀nsì nínú Wallet yóò jẹ́ gbe lọ sí \nOlùdíje tí a fọ́rúkọsílẹ̀. KEMIT KINGDOM yóò jẹ́ tú nínú gbogbo àwọn òfin rẹ̀ nígbà tí ó bá fi owó náà lé Olùdíje lọ́wọ́. Olùdíje yóò pèsè gbogbo \nàwọn ìwé tí InstaPay bèèrè, pẹ̀lú àwọn fún ìdánimọ̀ àti ẹ̀rí ikú.",
    "Add Nominee": "Fi Olùdíje Kun",
    "Two Factor Authentication": "Ìdánilójú Méjì",
    "SMS Number": "Nọ́mbà SMS",
    "Updated Successfully!": "Ti Ìmúdàgba Ní Aṣeyọrí!",
    "Social Network Accounts": "Àwọn Ìròyìn Àwùjọ Àwùjọ",
    "Here, you can set up and manage your integration settings.": "Níhìn-ín, o lè ṣeto àti ṣàkóso àwọn ètò ìdarapọ̀ mọ́ rẹ.",
    "Social Network Account": "Ìròyìn Àwùjọ Àwùjọ",
    "Activate Account": "Ṣíṣe Ìròyìn Náà Ṣíṣẹ",
    "Enter the code below in the InstaPay Chatbot.": "Tẹ kóòdù tó wà nísàlẹ̀ yìí sí ìnájàrá InstaPay.",
    "The code will expire in ": "Kóòdù yóò parí ní",
    "Notifications": "Àwọn Ìkìlọ̀",
    "Customize how you receive notifications. These settings apply to the activities you are monitoring.": "Ṣe àdáni bí o ṣe ń gbà àwọn ìkìlọ̀. Àwọn ètò yìí kan sí àwọn iṣẹ́ tí o ń ṣe àyẹ̀wò.",
    "Activity": "Iṣẹ́",
    "Email": "Àdírẹ́sì",
    "Push": "Títẹ síi",
    "SMS": "SMS",
    "Source": "Irinṣẹ",
    "Payment Requests": "Ibèèrè ìdá",
    "Bulk Payments": "Díè wọlé",
    "Identity Verification": "Ìdánimọ̀",
    "Verify Your Identity": "Ṣe Ìdánimọ̀ Rẹ",
    "Identity Verification Status:": "Ìpò Ìdánimọ̀:",
    "Briefly describe yourself and explain your reasons for using InstaPay.": "Kọ̀wé nípa ara rẹ kí o sì ṣàlàyé ìdí tí o fi ń lò InstaPay.",
    "Enter Your Message": "Kọ Ìfiranṣẹ́ Rẹ",
    "Select Occupation": "Yan Iṣẹ́",
    "It must be a government-issued identification document with a photo. All identification documents must be written in the Latin alphabet. If not, a certified \ntranslation is required.": "O gbọdọ̀ jẹ́ ìwé ìdánimọ̀ tí ìjọba fún ní àṣẹ, tí ó ní àwòrán. Gbogbo ìwé ìdánimọ̀ gbọdọ̀ kọ ní èdè Látìnì. Bí bẹ́ẹ̀ kọ́, ìtúmọ̀ tí a fọwọ́sí",
    "Accepted Documents": "jẹ́ dandan.",
    "A national identity card": "Àwọn Ìwé Tí A Gba",
    "A valid passport": "Káàdì Ìdánimọ̀ Orílẹ̀-èdè",
    "Valid residence permits (accepted only if issued by a European country or in Switzerland)": "Pásípóòtù Tó Wúlò",
    "Biometric Facial Recognition: Comparing video with passport photograph": "Àwọn Ìwé Àṣẹ Ìgbé Ilé (tí a gba nìkan bí o bá jẹ́ tí a fún ní àṣẹ ní orílẹ̀-èdè Yúróòpù tàbí ní Switzerland)",
    "Address Verification:": "Ìdánimọ̀",
    "Provide a bank statement or utility bill (less than 3 months old) as proof of address": "Pèsè ìwé ìdánwò báńkì tàbí ìwé ìdánwò iṣẹ́ ìgbàlódé (tí ó kéré jù lọ ọṣù mẹ́ta) gẹ́gẹ́ bí ẹ̀rí àdírẹ́sì.",
    "Additional Documents:": "Àwọn Ìwé Afikún:",
    "Kemit Kingdom reserves the right to request any additional documents (Enhanced Due Diligence - EDD) or further information necessary for conducting thorough \nverifications, in accordance with its legal obligations.": "Kemit Kingdom ni ẹ̀tọ́ láti béèrè fún àwọn ìwé afikún (Ìwádìí Tó Pọ̀ Jùlọ - EDD) tàbí àlàyé mìíràn tí ó nílò fún ìwádìí tó jinlẹ́,",
    "Start Verification": "ní ìbámu pẹ̀lú àwọn òfin rẹ.",
    "Sessions": "Bẹ̀rẹ̀ Ìwádìí",
    "This is a list of devices that have logged into your account. Remove any that you do not recognize.": "Àwọn Ìgbà Tí Ò Lò",
    "Your Withdrawal Options": "Èyí jẹ́ àtòjọ àwọn ẹ̀rọ tí wọ́n ti wọlé sí àkántì rẹ. Yọ àwọn tí o kò mọ̀ dàní.",
    "Choose your preferred method for withdrawing payments.": "Àwọn Àṣàyàn Yíyọ Kúrò",
    "No withdrawal options currently available in your country.": "Yan ọ̀nà tí o fẹ́ jùlọ fún yíyọ owó kúrò.",
    "Download Your Data": "Kò sí àṣàyàn yíyọ kúrò tí ó wà fún orílẹ̀-èdè rẹ lọ́wọ́lọ́wọ́.",
    "Request a Copy of Your Data": "Gba Dátà Rẹ",
    "Submit a request to download a copy of your data": "Bèèrè fún Dáàkọ Dátà Rẹ",
    "Which Data Should Be Included in the Download?": "Fí ìbéèrè sílẹ̀ láti gba dáàkọ dátà rẹ",
    "Select all applicable options.": "Irú Dátà Wo Ni O Yẹ Kí A Pẹ̀lú nínú Gbigba?",
    "Personal Information": "Yan gbogbo àṣàyàn tí ó yẹ.",
    "This information is used to secure your account, tailor your experience, and contact you as necessary.": "Aláyé Ti Ará ẹni",
    "Name and date of birth": "A lò àlàyé yìí láti dáàbò bò àkántì rẹ, láti ṣe àdáni ìrírí rẹ, àti láti kan sí ọ nígbà tí ó bá yẹ.",
    "Phone number": "Ìmélì",
    "Financial Information": "Nọ́mbà fóònù",
    "We use this information to enable faster checkout and to send or receive money in just a few clicks.": "Aláyé Òwò",
    "Bank accounts": "A lò àlàyé yìí láti jẹ́ kí ìsanwó yára àti láti rán tàbí gbà owó ní ìṣẹ́jú kan.",
    "Debit or credit cards": "Àwọn ìròyìn báńkì",
    "Credit Information": "Àwọn káàdì déb",
    "Includes your credit application information": "Pẹ̀lú àlàyé lórí ìbéèrè fún kírédítì rẹ",
    "Other Information (not included in file)": "Aláyé Mìíràn (tí kò sí nínú fáìlì)",
    "Includes device info, technical usage data, geolocation information, marketing preferences, consent history, and data used for other services such as credit, \nidentity verification, communications with PayPal, and third-party processors.": "Pẹ̀lú àlàyé lórí ẹ̀rọ rẹ, dátà lílo ìmọ̀ ẹ̀rọ, àlàyé lórí ìpò gẹ́ọ̀gráfíkì, àwọn ìfẹ́ sí títà, ìtàn ìfọwọ́sí, àti dátà tí a lò fún àwọn iṣẹ́ mìíràn bíi kírédítì,\nìdánimọ̀, ìbáṣepọ̀ pẹ̀lú PayPal, àti àwọn alágbàṣe ẹgbẹ́ kẹta.",
    "Choose file type:": "Yan ìrú fáìlì:",
    "Select file type": "Yan ìrú fáìlì",
    "PDF file can be easily opened on any computer": "Fáìlì PDF lè ṣí ní rọọrùn lórí kọ̀mpútà kankan",
    "Submit Request": "Fí ìbéèrè sílẹ̀",
    "Delete Your Account": "Pa Àkántì Rẹ",
    "Ask us to delete your data and close this account": "Béèrè fún wa láti pa dátà rẹ àti láti pa àkántì yìí",
    "We use your data to provide services, so if we delete your data, we also need to close your account.": "A lò dátà rẹ láti pèsè iṣẹ́, nítorí náà bí a bá pa dátà rẹ, a gbọdọ̀ pa àkántì rẹ.",
    "If your account is limited, go to the Resolution Center, select 'Go to Account Limitations', then click 'Resolve'.": "Bí àkántì rẹ bá ní ìdíwò, lọ sí Ìbéèrè Ìyọkúrò, yan 'Lọ sí Ìdíwò Àkántì', lẹ́yìn náà tẹ 'Yanjú'.",
    "Make sure any outstanding payments have cleared. We won't be able to close your account until they do.": "Ṣe ìdánilójú pé gbogbo ìsanwó tó ṣí kù ti jẹ́ sán. A kò ní lè pa àkántì rẹ títí tí wọn ò bá sán.",
    "If you have a negative balance, click the Resolve link next to your balance.": "Bí o bá ní gbèsè odi, tẹ ọ̀nà Yanjú tó wà ní ẹgbẹ́ gbèsè rẹ.",
    "Once your account is clear, and if you're eligible, we'll close your account and delete your data. We need \nto retain some of your data for legal and regulatory reasons. To learn more, see our ": "Lẹ́yìn tí àkántì rẹ di mímọ́, àti bí o bá yẹ, a ó pa àkántì rẹ àti pa dátà rẹ. A nílò láti tọ́jú díẹ̀ nínú dátà rẹ fún àwọn ìdí òfin àti ìlànà. Láti mọ̀ síwájú síi, wo ",
    "privacy statement": "ìpolongo ìpamọ́",
    "We'll email you to let you know the status, and if there are any other steps you need to take.": "A ó fi ìmélì ránṣẹ́ sí ọ láti jẹ́ kí o mọ ìpò rẹ, àti bí àwọn ìgbésẹ̀ mìíràn bá wà tí o ní láti gbé.",
    "Have more questions about data deletion?": "Ṣé o ní ìbéèrè síwájú síi nípa ìparẹ́ dátà?",
    "Contact us": "Kàn sí wa",
    "I understand that account deletion is permanent and can't be reversed.": "Mo mọ̀ pé ìparẹ́ àkántì jẹ́ pípẹ́ àti pé kò lè yípadà.",
    "Are you sure you want to close your account?": "Ṣé o dájú pé o fẹ́ pa àkántì rẹ?",
    "Yes, Continue": "Bẹ́ẹ̀, Tẹ̀síwájú",
    "Logging In": "Wíwọlé",
    "Complete your profile to streamline your transaction process.": "Pári ìtàn-àyé rẹ láti jẹ́ kí ìṣòwò rẹ rọrùn.",
    "Skip": "Fòyà",
    "Complete Your Profile": "Pári Ìtàn-àyé Rẹ",
    "API Key": "Bọ́tìnì API",
    "Get API Key": "Gba Bọ́tìnì API",
    "Generating New API Key": "Nípa Ṣíṣe Bọ́tìnì API Tuntun",
    "Generate API Key": "Ṣe Bọ́tìnì API",
    "Regenerate API Key": "Tún Ṣe Bọ́tìnì API",
    "To harness the full potential of InstaPay's seamless transactions, follow these quick steps to generate your API key. First, sign up for an account at \n[your registration URL] and access the Developer Dashboard. Navigate to the 'API Keys' section, generate a new key, and ensure its secure storage.": "Láti lò gbogbo àǹfààní ìṣòwò aláìléwu InstaPay, tẹ̀lé àwọn ìgbésẹ̀ yìí láti ṣe àgbékalẹ̀ bọ́tìnì API rẹ. Àkọ́kọ́, forúkọsílẹ̀ fún àkántì kan ní [URL ìforúkọsílẹ̀ rẹ] \nkí o sì wọlé sí Dáṣúbọ́ọ̀dù Olùṣèdá. Lọ sí apá 'Bọ́tìnì API', ṣe àgbékalẹ̀ bọ́tìnì tuntun, kí o sì ríi dájú pé o tọ́jú rẹ ní aàbò.",
    "Delete API Key": "Pa Bọ́tìnì API",
    "Webhook URL": "URL Webhook",
    "KYB Verification": "Ìwádìí KYB",
    "Additional Files": "Àwọn Fáìlì Afikún",
    "Business Verification": "Ìwádìí Òwò",
    "--Select-Your-Company-Type--": "--Yan-Iru-Ìlé-iṣẹ́-Rẹ--",
    "Documents Required:": "Àwọn Ìwé Tí A Nílò:",
    "Official Company Registry Extract (Entity's Registration/Incorporation Document)": "Ìwé Ìforúkọsílẹ̀ Ìlé-iṣẹ́ Osíṣẹ́ (Ìwé Ìforúkọsílẹ̀/Ìdásílẹ̀ Àjọ)",
    "This document serves as the company's official 'identity card' and should be obtained from the local business registry. It reflects the current status of the business \n(active or closed).": "Ìwé yìí ń ṣiṣẹ́ bí káàdì ìdánimọ̀ osíṣẹ́ fún ìlé-iṣẹ́ àti pé ó yẹ kí ó wá láti inú ìforúkọsílẹ̀ òwò agbègbè. Ó ń fi hàn ìpò lọ́wọ́lọ́wọ́ ti òwò\n(tí ó ń ṣiṣẹ́ tàbí tí ó ti pa).",
    "The document should be current, with an issuance date not exceeding 3 months.": "Ìwé yẹ kí ó jẹ́ tuntun, pẹ̀lú ọjọ́ tí wọ́n fún ní àṣẹ tí kò ju oṣù mẹ́ta lọ.",
    "The document must include the following details:": "Ìwé náà gbọdọ̀ pẹ̀lú àwọn aláyé wọ̀nyí:",
    "Legal name of the company": "Orúkọ osíṣẹ́ ti ìlé-iṣẹ́",
    "Full registered address": "Àdírẹ́ẹ̀sì ìforúkọsílẹ̀ kíkún",
    "Company registration number": "Nọ́mbà ìforúkọsílẹ̀ ìlé-iṣẹ́",
    "Date of incorporation": "Ọjọ́ ìdásílẹ̀",
    "Description of the company's activities/purpose": "Àlàyé lórí iṣẹ́/ìdí ti ìlé-iṣẹ́",
    "Legal representative's information: full name, date and place of birth, residential address (minimum requirement: country of residence), and nationality": "Aláyé àṣojú osíṣẹ́: orúkọ pátápátá, ọjọ́ àti ibi ìbí, àdírẹ́sì ibùgbé (ìbéèrè tó kéré jùlọ: orílẹ̀-èdè ibùgbé), àti orílẹ̀-èdè",
    "If applicable, the document should also include:": "Bí ó bá yẹ, ìwé náà lè tún ní:",
    "Brand or trading names": "Orúkọ brándì tàbí orúkọ ìṣòwò",
    "Value Added Tax (VAT) number": "Nọ́mbà Owo-ori Àfikún (VAT)",
    "List of Ultimate Beneficial Owners (UBOs) - this is mandatory in some countries, otherwise, it may be provided as a separate document.": "Àtòjọ Àwọn Olùjẹ Anfàní Ìkẹyìn (UBOs) - èyí jẹ́ dandan ní àwọn orílẹ̀-èdè kan, bíbẹẹ̀kọ́, a lè pèsè gẹ́gẹ́ bí ìwé tó yàtọ̀.",
    "Proof of Legal Representative's Authority": "Ìwé Ẹrí Ìṣòro Olóyè",
    "This document validates an individual's authority to represent the company": "Ìwé yìí fún ní ìdánilójú àṣẹ ènìyàn láti ṣojú ìlé-iṣẹ́",
    "Typically, the legal representative is named in the company's registry extract. However, if this is not the case, a document such as a Power of Attorney or Delegation \nof Powers must be secured.": "Nígbà wọ́pọ̀, a máa ń darúkọ àṣojú osíṣẹ́ nínú ìwé ìforúkọsílẹ̀ ìlé-iṣẹ́. Síbẹ̀síbẹ̀, bí èyí kò bá rí bẹ́ẹ̀, a gbọdọ̀ gba ìwé bíi Ìwé Àṣẹ Agbára tàbí Ìdánilójú Agbára.",
    "Power of Attorney/Delegation of Powers:": "Ìwé Àṣẹ Agbára/Ìdánilójú Agbára:",
    "The individual conferring the authority must be legitimately entitled to do so. This individual should be listed as a legal representative in the company's registry extract.": "Ẹni tí ó fún ní àṣẹ gbọdọ̀ ní ẹ̀tọ́ tí ó tọ́ síi láti ṣe bẹ́ẹ̀. Ẹni yìí yẹ kí o jẹ́ àkọsílẹ̀ gẹ́gẹ́ bí àṣojú osíṣẹ́ nínú ìwé ìforúkọsílẹ̀ ìlé-iṣẹ́.",
    "Ownership Structure and Ultimate Beneficial Owners (UBOs) Documentation": "Ìwé Àkọsílẹ̀ Ìṣẹ́jú àti Àwọn Olùjẹ Anfàní Ìkẹyìn (UBOs)",
    "Evidence of the company's listing on the relevant stock exchange should be provided. This could be a page from the stock exchange website displaying \nthe company's details.": "Ẹ̀rí wí pé a ti ṣe àkọsílẹ̀ ìlé-iṣẹ́ ní ọjà ìṣura yẹ kí o wà. Èyí lè jẹ́ ojú-ìwé kan láti ojú-òpó ayélujára ọjà ìṣura tí ó fihàn àlàyé ìlé-iṣẹ́.",
    "If less than 75% of the company's shares are publicly owned, all shareholders who own or control more than 25% of the shares will be subject to \nUltimate Beneficial Owner (UBO) verification requirements.": "Bí o bá kéré jù lọ 75% tí àwọn mọ́lẹ́bí ìlé-iṣẹ́ jẹ́ ti ìjọba gbangba, gbogbo àwọn olùmọ́lẹ́bí tí wọn ni tàbí ṣàkóso jù lọ 25% àwọn mọ́lẹ́bí yóò jẹ́ kókó \nfún ìwádìí Ònínúgbègbè Anfàní Ìkẹyìn (UBO).",
    "Collection of Identification Documents": "Gbigba ti Awọn Iwe Èrí Ìdánimọ̀",
    "The identification document must be government-issued, bearing a photograph of the individual. All identification documents must be written in the Latin alphabet. If not, \na certified translation is required.": "Ìwé ìdánimọ̀ gbọdọ̀ jẹ́ tí ìjọba fún ní àṣẹ, tí ó ní àwòrán ẹni náà. Gbogbo ìwé ìdánimọ̀ gbọdọ̀ kọ ní lẹ́tà Látìnì. Bí bẹ́ẹ̀ kọ́, a gbọdọ̀ ní ìtúmọ̀ tí a fọwọ́si.",
    "Accepted documents:": "Awọn ìwé tí a gbà:",
    "Valid National Identity Card": "Kaadi ìdánimọ̀ orílẹ̀-èdè tí ó wúlò",
    "Valid Passport": "Ìwé ìrìnnà tí ó wúlò",
    "Valid Resident Permits - These will be accepted only if issued by a European country.": "Àṣẹ ìgbé gẹ́gẹ́ bí olùgbé - A ó gbà wọ́n nìkan tí a bá ti fún wọn ní àṣẹ láti orílẹ̀-èdè Yúróòpù.",
    "Only legal representatives are subject to the identification document collection process.": "Àwọn aṣojú òfin nìkan ni wọ́n jẹ́ kókó fún ìkópa nínú ìlànà ìkópa ìwé ìdánimọ̀.",
    "Additional Requirement:": "Ìbéèrè Afikún:",
    "In cases involving a Power of Attorney or Delegation of Powers, both parties (the individual granting authority and the one receiving it) must provide a copy of their ID.": "Nínú àwọn ọ̀ràn tí ó ní ìṣe pẹ̀lú àṣẹ ìgbàgbọ́ (Power of Attorney) tàbí Fífún ní Agbára, àwọn méjèèjì (ẹni tí ó n fún ní àṣẹ àti ẹni tí ó n gbà á) \ngbọdọ̀ pèsè àdàkọ ìwé ìdánimọ̀ wọn.",
    "Additional Documents": "Àwọn ìwé Afikún",
    "Utility Bill (such as gas, electricity, telephone or mobile phone bill); or a document issued by a government department that shows the End-user’s address \nand name (e.g., Residential Certificate); or": "Ìwé ìsanwó ìrọ̀rùn bíi ìsanwó gáàsì, iná, tẹlifóònù tàbí bílì fóònù alágbèéká; tàbí ìwé kan tí ìjọba fún ní àṣẹ tí ó fihàn orúkọ àti adírẹ́sì olùmúlò \n(bíi Ìwé Èrí Ibi Gbé); tàbí",
    "Bank Statement; or": "Statement banki; tàbí",
    "a document issued by a government department that shows the End-user’s address and name (e.g., Residential Certificate).": "ìwé kan tí a fun ní àṣẹ láti ilé iṣẹ́ ìjọba tí ó fihàn adírẹ́sì àti orúkọ olùmúlò (bíi Ìwé Èrí Ibi Gbé).",
    "Kemit Kingdom retains the right to request any additional documents or further information necessary for conducting thorough verifications, in line with \nits legal obligations.": "Kemit Kingdom ní ẹ̀tọ́ láti béèrè fún àwọn ìwé àfikún tàbí àlàyé míì tí ó jẹ́ dandan fún ìṣètò ìwádìí tító, gẹ́gẹ́ bí òfin ti la kalẹ̀.",
    "Note:": "Àkíyèsí:",
    "Partners": "Àwọn Alábàáṣepọ́",
    "Add New Partner": "Ṣe Afikún Alábàáṣepọ́ Tuntun",
    "Designation": "Orúkọ Ìpò",
    "Partner Name": "Orúkọ Alábàáṣepọ́",
    "Partner Email": "Ìméèlì Alábàáṣepọ́",
    "Partner Designation": "Orúkọ Ìpò Alábàáṣepọ́",
    "Add Now": "Ṣe Afikún Báyìí",
    "Adding": "Nípa Ṣíṣe Afikún",
    "Delete Partner": "Pa Alábàáṣepọ́ rẹ̀",
    "Delete": "Pípá",
    "Deleting": "Nípa Pípá",
    "Are you sure, you want to remove sxz as partner?": "Ṣé o dájú pé o fẹ́ yọ sxz kúrò bíi alábàáṣepọ́?",
    "Entity's Registry Extract (Entity'sRegistration/Creation Document)": "Ìwé Àfihàn Ìforúkọsílẹ̀ Ajọṣepọ́ (Ìwé Ìforúkọsílẹ̀/Ìdásílẹ̀ Ajọṣepọ́)",
    "This document should be either:": "Ìwé yìí gbọdọ̀ jẹ́:",
    "A proof of creation of the entity, providing the entity's details, creation date, and purpose.": "Ẹ̀rí ìdásílẹ̀ ajọṣepọ́, tí ó n pèsè àlàyé nípa àwọn aláyé ajọṣepọ́, ọjọ́ ìdásílẹ̀ àti ìdí rẹ̀.",
    "A local registry extract:": "Àfihàn ìforúkọsílẹ̀ agbègbè:",
    "Required as per the country's regulations, serving as the current 'identity card' for the entity, indicating its status (active or closed).": "Ó jẹ́ dandan gẹ́gẹ́ bí òfin orílẹ̀-èdè náà ṣe sọ, tí ó jẹ́ káàdì ìdánimọ̀ lọ́wọ́lọ́wọ́ fún ajọṣepọ́, tí ó n fihàn ipò rẹ̀ (tí ó ṣíṣẹ́ tàbí tí ó ti parí).",
    "If a registry extract exists, it must not be older than 3 months.": "Tí àfihàn ìforúkọsílẹ̀ agbègbè bá wà, kò gbọdọ̀ ti pẹ́ ju oṣù mẹ́ta lọ.",
    "Entity name": "Orúkọ ajọṣepọ́",
    "Registration number (if applicable, based on the country's requirements)": "Nọ́mbà ìforúkọsílẹ̀ (tí ó bá yẹ, da lórí ìbéèrè orílẹ̀-èdè náà)",
    "Date of creation": "Ọjọ́ ìdásílẹ̀",
    "Description of the entity's activities or purpose": "Alaye àwọn iṣẹ́ tàbí ìdí pàtàkì ajọṣepọ́ náà",
    "Trading names": "Orúkọ ìṣòwò",
    "Articles of Association": "Àwọn Àtúnṣe Àpilẹ̀kọ",
    "The Articles of Association serve as the constitutional document of the entity, outlining its operating rules.": "Àwọn Àtúnṣe Àpilẹ̀kọ jẹ́ ìwé ìlànà ajọṣepọ́ náà, tí ó ṣàlàyé àwọn òfin ìṣiṣẹ́ rẹ̀.",
    "There is no time constraint on this document, meaning the most recent version is requested. Typically, these types of entities require at least one annual meeting \nthat may result in an updated version of the Articles of Association.": "Kò sí àkókò àkókò lórí ìwé yìí, èyí túmọ̀ sí pé àwọn ẹni tó jẹ́ títúnṣe jùlọ ni a ń béèrè fún. Lára àwọn ilé-iṣẹ́ báyìí, wọ́n máa ń ní ìpàdé ọdọọdún kan tó lè mú \nàtúnṣe tuntun wá sí àwọn Àtúnṣe Àpilẹ̀kọ.",
    "Examples of changes that could occur include updates to the entity's name, address, purpose, management board, roles, responsibilities, etc.": "Àwọn àpẹẹrẹ àwọn àyípadà tí ó lè wáyé ni àwọn ìmúdójúìwọn sí orúkọ ilé-iṣẹ́, àdírẹ̀ẹ̀sì, èrò, ìgbìmọ̀ ìṣàkóso, ipa àti òjúṣe, àti bẹ́ẹ̀ bẹ́ẹ̀ lọ.",
    "The document should contain information about:": "Ìwé náà gbọdọ̀ ní àlàyé nípa:",
    "The entity itself, including its legal name, registered address, and purpose": "Ilé-iṣẹ́ náà fúnra rẹ̀, pẹ̀lú orúkọ òfin rẹ̀, àdírẹ̀ẹ̀sì fọ́ọ̀mù àti èrò",
    "Its internal operating rules, such as the appointment process for management board members, their roles and powers, among other details. This list is not exhaustive.": "Àwọn òfin ìṣiṣẹ́ inú rẹ̀, gẹ́gẹ́ bí ìlànà ìyànṣẹ́lódì fún àwọn ọmọ ẹgbẹ́ ìgbìmọ̀ ìṣàkóso, ipa wọn àti agbára wọn, láàárín àwọn àlàyé mìíràn. Àkójọpọ̀ yìí kò tíì pé.",
    "How the entity is funded": "Bí wọ́n ṣe ń ṣe àtìlẹ́yìn owó fún ilé-iṣẹ́ náà",
    "Appointment/Removal/Changes on Board of Management Members:": "Ìyànṣẹ́lódì/Ìyọkúrò/Àyípadà lórí Ìgbìmọ̀ Ìṣàkóso:",
    "These updates usually occur based on the entity's needs or as stipulated in the Articles of Association. Changes are documented through board of management \nmeeting minutes, which are registered and certified to reflect the updated list of members, such as the president, vice-president, treasurer, and general secretary.": "Àwọn ìmúdójúìwọn wọ̀nyí máa ń wáyé nítorí àwọn àníyàn ilé-iṣẹ́ tàbí bí ó ti ṣe kọ sí nínú Àwọn Ìwé Ìdásílẹ̀. Àwọn àyípadà náà ni a máa ń ṣe àkọsílẹ̀ nínú \nìṣẹ́jú ìpàdé ìgbìmọ̀ ìṣàkóso, tí a sì ti forúkọ sílẹ̀ àti fọwọ́sí láti fi hàn àtúnyẹwò àwọn ọmọ ẹgbẹ́ tuntun, bíi ààrẹ, ìgbákejì ààrẹ, olùṣàkóso owó, àti àkówe \ngbogbogbò.",
    "The latest board management meeting minutes, including the current list of members, will be required in addition to the Articles of Association, if available.": "Ìṣẹ́jú ìpàdé ìgbìmọ̀ ìṣàkóso tó gbẹ̀yìn, pẹ̀lú àtòkọ àwọn ọmọ ẹgbẹ́ lọ́wọ́lọ́wọ́, yóò nílò láti fi kún Àwọn Ìwé Ìdásílẹ̀, tí ó bá wà.",
    "Note: If the current list of board management members is not included in the latest Articles of Association and there are no board meeting minutes available, \nthis list must be provided. It should be dated and signed by the most recently appointed legal representative, whose appointment can be verified through related \ndocumentation.": "Àkíyèsí: Tí àtòkọ àwọn ọmọ ẹgbẹ́ ìgbìmọ̀ ìṣàkóso lọ́wọ́lọ́wọ́ kò bá wà nínú Àwọn Ìwé Ìdásílẹ̀ tó gbẹ̀yìn àti pé kò sí ìṣẹ́jú ìpàdé ìgbìmọ̀, a gbọdọ̀ pèsè àtòkọ yìí. \nO gbọdọ̀ ní ọjọ́ àti ìbúwọ́lù ẹni tó jẹ́ aṣojú òfin tí a ṣẹ̀ṣẹ̀ yàn, èyí tí a lè jẹ́rìí rẹ̀ nípasẹ̀ àwọn ìwé àjẹmọ́.",
    "This document demonstrates an individual's authority to represent the entity. This document could be:": "Ìwé yìí fi hàn pé ẹni kan ní àṣẹ láti ṣòro fún àjọ náà. Ìwé yìí le jẹ:",
    "The latest Articles of Association": "Àwọn Ìwé Ìṣètò Ilé-iṣẹ́ tó ṣẹ̀ṣẹ̀ jùlọ",
    "The current list of board management members (which may be included in the board management meeting minutes)": "Àtòkọ àwọn ọmọ ẹgbẹ́ ìgbìmọ̀ ìṣàkóso lọ́wọ́lọ́wọ́ (eyí tí ó le wà nínú ìwé ìpàdé ìgbìmọ̀ ìṣàkóso)",
    "The document should specify:": "Ìwé yìí yẹ kí o sọ:",
    "Full name of the individual(s)": "Orúkọ kíkún ti ẹni náà",
    "Function of the individual(s). This information will be cross-verified with the Articles of Association to ensure that the individual's function permits them to bind the entity. \nThis is particularly important when the applicant is not the president, and other functions have limited representation authority as stated in the Articles of Association.": "Iṣẹ́ ti ẹni náà ń ṣe. A ó fi ìwé ìṣètò ilé-iṣẹ́ náà ṣe àyẹ̀wò àlàyé yìí láti ríi dájú pé iṣẹ́ ti ẹni náà ń ṣe lè fún un láṣẹ láti sojú fún àjọ náà.\nEyi pàtàkì gan-an nígbà tí ẹni náà kò jẹ́ olórí, àti pé àwọn iṣẹ́ míì ní àlàyé láti ṣojú fún àjọ náà pẹ̀lú àwọn ààlà tó ní bí ó ṣe wà nínú àwọn ìwé ìṣètò ilé-iṣẹ́ náà.",
    "Additional information about the legal representative that will be collected includes:": "Aláyé àfikún nípa aṣojú òfin tí a máa gba pẹ̀lú ni:",
    "Date and place of birth": "Ọjọ́ àti ibi ìbí",
    "Residential address (at least the country of residence should be stated)": "Adírẹ́ẹ̀sì ibùgbé (ó yẹ kí o tọ́ka sí orílẹ̀-èdè tí wọ́n ń gbé)",
    "Nationality": "Orílẹ̀-èdè",
    "Ownership Structure & Ultimate Beneficial Owners (UBOs)": "Ètò ìní àti Àwọn Olówó Ìpìlẹ̀ Ànfàní (UBOs)",
    "Typically, these types of entities, given their structure and purpose, do not have beneficial owners. However, board members will be identified and treated as such for \nscreening purposes, ID collection and verification, etc.": "Ni igbagbogbo, awọn iru awọn ajọ yii, ti a ba wo ilana ati idi wọn, kii ni awọn oniwun anfani. Sibẹsibẹ, a o ṣe idanimọ awọn ọmọ ẹgbẹ igbimọ ki a si tọju wọn \nbii ẹni pe wọn jẹ awọn oniwun anfani fun idi ti ayẹwo, gbigba ati ijẹrisi ID, ati bẹbẹ lọ.",
    "A small number of associations might have beneficial owners, particularly those generating profits. In such instances, this detail will be mentioned \nin the Articles of Association. These individuals will be easily identified and treated as such, alongside management board members.": "Diẹ ninu awọn ajọ le ni awọn oniwun anfani, paapaa awọn ti n ṣe èrè. Ni iru awọn ipo bẹẹ, a o sọ alaye yii ninu Awọn Ofin Ajọ. Awọn eniyan wọnyi ni a le \nrọrun idanimọ wọn ki a si tọju wọn bii bẹẹ, papọ pẹlu awọn ọmọ ẹgbẹ igbimọ isakoso.",
    "Additional Requirement: In cases involving a Power of Attorney or Delegation of Powers, both parties \n(the individual granting authority and the one receiving it) must provide a copy of their identification documents.": "Àfikún Ìbéèrè: Ní àwọn ọ̀ràn tí ó níi ṣe pẹ̀lú Ìfọwọ́sí Agbára tàbí Ìyànsípò Agbára, àwọn ẹgbẹ́ méjèèjì (ẹni tí ó ń fún ni agbára àti ẹni tí ó ń gba) gbọdọ̀ \ngbé dá ìwé ìdánimọ̀ wọn kalẹ̀.",
    "Sole Trader's Registry Extract (Entity's Registration/Creation Document)": "Ìwé Ìforúkọsílẹ̀ Olùṣòwò Àdánidá (Ìwé Ìforúkọsílẹ̀/Ìdásílẹ̀ Ajọ)",
    "This document serves as a local registry for sole traders, required in countries where such registration is mandatory. \nIt verifies that the individual is engaged in a professional activity as a sole trader. The name of this document varies from \ncountry to country.": "Ìwé yìí jẹ́ ìforúkọsílẹ̀ àgbáyé fún àwọn olùṣòwò àdánidá, tí a gbékalẹ̀ ní àwọn orílẹ̀-èdè tí irú ìforúkọsílẹ̀ yìí jẹ́ dandan. Ó jẹ́rìí sí pé ènìyàn náà jẹ́ olùṣòwò \nàdánidá ní ìṣẹ́-ọnà. Orúkọ ìwé yìí lè yàtọ̀ láti orílẹ̀-èdè kan sí èkejì.",
    "Where applicable, the document should not be older than 3 months.": "Níbi tí ó bá yẹ, ìwé náà kò gbọdọ̀ ju ọṣù mẹ́ta lọ.",
    "The document must contain:": "Ìwé náà gbọdọ̀ ní:",
    "Individual's name": "Orúkọ ènìyàn náà",
    "Residential address": "Àdírẹ́sì ibùgbé",
    "Registration number": "Nọ́mbà ìforúkọsílẹ̀",
    "The sole trader is the only individual subject to the identification document collection process.": "Oníṣòwò adánilọ́kọ ni ẹni kàn ṣoṣo tí a béèrè fún ìwé ìdánimọ̀.",
    "No Transactions": "Kò sí Ìgbòwó",
    "No Pending Items": "Kò sí Ohun Tí ń Dúró Dè",
    "No Beneficiaries": "Kò sí Àwọn Olùrànlọ́wọ́",
    "Send Money To": "Rán Owó Sí",
    "Confirmation": "Ìdánilójú",
    "You are setting": "O ń ṣètò",
    "as default.": "gẹ́gẹ́ bí ìlànà àkọ́kọ́.",
    "Previously": "Tẹ́lẹ̀",
    "was default QR.": "ni ìlànà àkọ́kọ́ QR.",
    "Category": "Ẹ̀ka",
    "-Select-From-The-List-": "-Yan-Láti-Inú-Atọ́ka-",
    "Add a note": "Fi Àkíyèsí kun",
    "Categories Added Successfully!": "Àwọn Ẹ̀ka Tí A Fi Kun Láṣeyọrí!",
    "Subscription starting date": "Ọjọ́ ìbẹ̀rẹ̀ ìforúkọsílẹ̀",
    "Until": "Títí",
    "Next Transaction": "Ìgbòwó Tókàn",
    "Transaction Method": "Ọ̀nà Ìgbòwó",
    "Accept the quotation": "Gba ìsọtẹ́lẹ̀ owó",
    "Choose Currency": "Yan Owó",
    "Accept": "Gba",
    "Bargain the quotation": "Jíròrò ìsọtẹ́lẹ̀ owó",
    "Enter Counter Amount:": "Tẹ Iye Kántá:",
    "Bargain": "Jíròrò",
    "Decline the quotation": "Fagilé ìsọtẹ́lẹ̀ owó",
    "Are you sure, you want to decline this quotation?": "Ṣé ó dá ọ lójú pé o fẹ́ kọ̀ ìsọtẹ́lẹ̀ owó yìí sílẹ̀?",
    "Decline": "Kọ̀",
    "Personal Account": "Àkọọ́lẹ́ Tìẹ̀",
    "Jan": "Jan",
    "Feb": "Feb",
    "Mar": "Mar",
    "Apr": "Eib",
    "May": "Mei",
    "Jun": "Jun",
    "Jul": "Jul",
    "Aug": "Ọ̀ṣù",
    "Sep": "Sep",
    "Oct": "Oct",
    "Nov": "Nov",
    "Dec": "Dec",
    "Today": "Ní òní",
    "Spent In": "Pẹ̀lú ni",
    "Revised Amount": "ẹ̀bá fẹẹ̀ rẹ",
    "You spent more comparing to last month": "Ẹ ti wọle lórí ẹ̀rọ̀ kẹtẹ",
    "Last": "Kọọkan",
    "days": "Ọjọ",
    "Year": "Odu",
    "Instant Mobile Top-up Across 150+ Countries": "Ifọwọkan Foonu Alagbeka Naa Ni Ori Ilẹ-aye Ju Ọgọrun Mẹtalelọgbọn Orilẹ-ede Lọ",
    "Delight your loved ones or recharge your own phone, no matter where you are! InstaPay’s Mobile Airtime service lets you instantly top up mobile phones in over 150 countries. Perfect for gifts or ensuring you're always connected, our service is designed for ease, speed, and security.": "Mu ayọ ba awọn ayanfẹ rẹ tabi tun foonu ara rẹ kun, laibikita ibi ti o wa! Iṣẹ Ifọwọkan Alagbeka InstaPay ngbanilaaye rẹ lati kun awọn foonu alagbeka lẹsẹkẹsẹ ni ju ọgọrun mẹtalelọgbọn orilẹ-ede lọ. O dara fun ẹbun tabi lati rii daju pe o nigbagbogbo ni asopọ, a ṣe apẹrẹ iṣẹ wa fun irọrun, iyara, ati aabo.",
    "How It Works - Step-by-Step Guide:": "Bii O ṣe n ṣiṣẹ - Itọnisọna Igbesẹ nipasẹ Igbesẹ:",
    "1. Choose 'Mobile Top-up': Access this feature directly within the InstaPay app on platforms like WhatsApp, Twitter, Telegram, or Instagram.": "1. Yan 'Ifọwọkan Alagbeka': Wọle si ẹya yii taara laarin ohun elo InstaPay lori awọn iru ẹrọ bii WhatsApp, Twitter, Telegram, tabi Instagram.",
    "2. Select Currency and Amount: Pick from multiple currencies (USD, GBP, EUR, etc.) and specify how much you want to top up.": "2. Yan Owo ati Iye: Yan lati ọpọlọpọ awọn owo nina (USD, GBP, EUR, bbl) ki o si pato iye ti o fẹ kun.",
    "3. Enter the Mobile Number: Type in the number you wish to recharge—yours or that of a loved one. Our system ensures a secure and swift process.": "3. Tẹ Nọmba Alagbeka sii: Kọ nọmba ti o fẹ ṣe ifọwọkan—ti ara rẹ tabi ti olufẹ kan. Eto wa ṣe onigbọwọ ilana ailewu ati yarayara.",
    "4. Confirm and Pay: Double-check the top-up details, confirm, and make the payment using your preferred method. The transaction processes instantly and securely.": "4. Jẹrisi ati Sanwo: Ṣayẹwo awọn alaye ifọwọkan lẹẹmeji, jẹrisi, ki o si sanwo nipa lilo ọna ti o fẹran. Ilana iṣowo naa waye lẹsẹkẹsẹ ati ni aabo.",
    "5. Receive Instant Confirmation: Both you and the recipient (if applicable) will receive a confirmation notification once the top-up is successful.": "5. Gba Ijerisi Lẹsẹkẹsẹ: Iwọ ati olugba naa (ti o ba yẹ) yoo gba ifitonileti ijerisi lẹhin ti ifọwọkan ba ṣaṣeyọri.",
    "Benefits:": "Awọn Anfani:",
    "Global Reach: Effortlessly send airtime to over 150 countries.": "Agbara Agbaye: Firanṣẹ akoko airi ni irọrun si ju ọgọrun mẹtalelọgbọn orilẹ-ede lọ.",
    "Instant Impact: See the benefits immediately—perfect for last-minute gifts or urgent communications.": "Ipa Lẹsẹkẹsẹ: Wo awọn anfani lẹsẹkẹsẹ—pipe fun awọn ẹbun iṣẹju to kẹhin tabi awọn ibaraẹnisọrọ pajawiri.",
    "Complete Security: Enjoy peace of mind with our secure payment system.": "Aabo Kikun: Gbadun alaafia ọkan pẹlu eto isanwo wa ti o ni aabo.",
    "Versatile Payment Options: Choose from credit cards, PayPal, or your InstaPay wallet for payments.": "Awọn Aṣayan Isanwo Oniruuru: Yan lati awọn kaadi kirẹditi, PayPal, tabi apamọwọ InstaPay rẹ fun awọn sisanwo.",
    "Ready to spread joy or stay connected? Top-up now and make someone’s day—or your own!": "Ṣetan lati tan ayọ tabi lati wa ni asopọ? Kun bayi ki o ṣe ayọ ọjọ ẹnikan—tabi tirẹ!",
    "Pick a language": "Yan ede kan",
    "French": "Faransé",
    "Spanish": "Espani",
    "Portuguese": "Pọtogí",
    "German": "Jámánì",
    "Ukrainian": "Ukranian",
    "Italian": "Itali",
    "Russian": "Rọ́ṣíà",
    "Arabic": "Arábìkì",
    "Polish": "Pólándì",
    "Dutch (Nederlands)": "Nídálándì",
    "Yoruba": "Yorùbá",
    "Indonesian": "Indonésíà",
    "Turkish": "Túrkì",
    "Swahili": "Swahili",
    "Hausa": "Hausa",
    "Hindi": "Hindi",
    "Urdu": "Urdu",
    "Chinese": "Chinese",
    "Select Your Preferred Language": "Yan Ẹ̀dá Àyẹ́yẹ Rẹ",
    "Please select the language you would \nlike to use for the eKYC process.": "Jọwọ yan èdè tí o fẹ́ lo fún ìlànà eKYC.",
    "Select Your Nationality": "Yan Ìdámọ̀ Orílẹ̀-èdè Rẹ",
    "Choose your nationality to see the \nsupported ID documents for  your country.": "Yan orílẹ̀-èdè rẹ láti rí àwọn ìwé ìdánimọ̀ tí a ṣe é gbé kalẹ̀ fún orílẹ̀-èdè rẹ.",
    "Grant permission to use the camera.": "Fun ìyàláàmú láti lo kámérà.",
    "Tap or click \"Allow\" when prompted to \ngrant permission for camera access to \ncapture the document.": "Tẹ̀tàbí tẹ \"Fúnàṣẹ̀\" nígbàtí ìbéèrè bá farahàn láti fúnni ní ìyàláàmú fún ìwọlé kámérà láti gbé ìwé náà.",
    "Upload Your Documents from \nGallery/Photos": "Ìkọ́lẹ̀ àwọn Ìwé Rẹ láti inú Ìkànsí/Àwọn Awòrán",
    "If you encounter issues with your camera \nor if your system doesn't have one, you can\n manually upload photos of the document \nfrom your local storage.": "Tí o bá ní ìṣòro pẹ̀lú kámérà rẹ tàbí tí ètò rẹ kò bá ní ọ̀kan, o lè ìkọ́lẹ̀ àwọn fọ́tò ìwé náà láti inú ìkọ́lé ìbòmíràn rẹ.",
    "Upload Your ID.": "Gbe ID rẹ sii.",
    "Make sure your ID is clear and not blurry to \nensure quick verification.": "Ṣe idaniloju pe ID rẹ ko ni aiṣedeede lati rii daju idaniloju yara.",
    "Valid Proof of Address": "Ẹri Adirẹsi To Wulo",
    "1. Ensure that your proof of address is \ncurrent and that the statement date is \nwithin the last three months from the \nstart date of your eKYC verification.\n\n2. Make sure your proof of address is clear \nand not blurry to ensure quick verification.": "1. Ṣe idaniloju pe ẹri adirẹsi rẹ wa lọwọlọwọ ati pe ọjọ ifihan ti o wa laarin oṣu mẹta to koja lati ọjọ ibẹrẹ ti idaniloju eKYC rẹ.\n\n2. Ṣe idaniloju pe ẹri adirẹsi rẹ ko ni aiṣedeede lati rii daju idaniloju yara.",
    "Important Notice\n\nPlease carefully review all the steps in this guideline before proceeding with your eKYC verification.\n\nAvoid Common Mistakes:\n\n* Ensure all your documents are clear, legible, and up-to-date.\n* Double-check that the information you provide matches the details on your official documents.\n* Follow the instructions precisely to avoid delays or rejections.\n\nNote: \nIf your eKYC verification fails and you need to resubmit, you will be charged again for the resubmission process.\n\nBy following these guidelines, you can help ensure a smooth and successful eKYC verification.\n\nFor any questions or assistance, please contact our support team at support@insta-pay.ch ": "Ifọrọranṣẹ Pataki\n\nJọwọ ka gbogbo igbesẹ ninu itọsọna yii ṣakiyesi ṣaaju ki o to tẹsiwaju pẹlu ayewo eKYC rẹ.\n\nYago fun aṣiṣe ti o wọpọ:\n* Rii daju pe gbogbo awọn iwe aṣẹ rẹ jẹ mimọ, ka, ati imudojuiwọn.\n* Ṣayẹwo pe alaye ti o pese baamu pẹlu awọn alaye ti o wa ninu awọn iwe aṣẹ osise rẹ.\n* Tẹle awọn ilana naa pẹkipẹki lati yago fun idaduro tabi kiko.\n\nAkiyesi: \nTi ayewo eKYC rẹ ko ba ṣaṣeyọri ati pe o nilo lati tun fi silẹ, iwọ yoo sanwo afikun fun ilana ifisilẹ lẹẹkansi.\n\nNipa tẹle awọn ilana wọnyi, o le rii daju ayewo eKYC ti ko ni wahala ati aṣeyọri.\n\nFun awọn ibeere tabi iranlọwọ, jọwọ kan si ẹgbẹ atilẹyin wa ni support@insta-pay.ch",
    "Personal Transactions": "Awọn idunadura ti ara ẹni",
    "Business Transactions": "Awọn idunadura Iṣowo",
    "Shopping & Purchases": "Iṣowo & Awọn rira",
    "Bills & Utilities": "Awọn owo-ori & Awọn iṣẹ",
    "Entertainment & Leisure": "Idaraya & Iṣere",
    "Investments & Savings": "Idoko-owo & Fipamọ",
    "Health & Wellness": "Ilera & Adayeba",
    "Transportation": "Gbigbe",
    "Education": "Ẹkọ",
    "Miscellaneous": "Aṣọtọ",
    "Family Support": "Atilẹyin Ẹbi",
    "WALLET": "WALLET",
    "Add a currency": "Fi owo ṣafikun",
    "When you add a currency, any payments you receive in that currency will be credited to your InstaPay balance. Your primary currency will be used for sending or requesting payments unless specified otherwise.": "Nigbati o ba ṣafikun owo, gbogbo awọn sisanwo ti o gba ni owo yẹn ni yoo gba kirediti si iwọntunwọnsi InstaPay rẹ. A yoo lo owo akọkọ rẹ fun fifiranṣẹ tabi beere awọn isanwo ayafi ti a ti sọ bibẹẹkọ.",

    "balance amount ": "iye iwọntunwọnsi",
    "referral ": "ìtọkasí",
    "crypto wallet balance": "iwontunwonsi apamọwọ crypto",
    "money in": "owo ti n wọle",
    "money out": "owo ti n jade",
    "analytics": "onínọmbà",
    "see more": "wo diẹ sii",
    "manage my Wallets and see the transaction details": "ṣakoso àwọn Wallets mi ki o wo àwọn alaye ti àwọn iṣowo mi",
    "set as default Wallet": "ṣeto bi Wallet aiyipada",
    "add currency": "fi owo kun",
    "convert funds ": "iyipada awọn owo",
    "withdraw balance": "yọ iṣiro kuro",
    "chart names": "orukọ awọn aworan",
    "withdraw balance from PKR wallet": "yọ ilé lati inu apo-iwe PKR",
    "select a payout channel": "yan ikanni isanwo kan",
    "you have entered an amount below the minimum payment range": "o ti tẹ iye kan ti o wa ni isalẹ ibiti isanwo ti o kere ju",
    "change": "iyipada",
    "edit": "satunkọ",
    "moving to next step in ...": "lọ si igbesẹ ti n bọ ni ...",
    "you are withdrawing ...": "o n yọ ...",
    "where is my payment": "ibo ni owo mi wa",
    "download": "gba lati ayelujara",
    "IBAN / SWIFT Code": "IBAN tabi SWIFT/BIC koodu",
    "swift code": "SWIFT koodu",
    "account number": "nọmba iroyin",
    "branch street": "itolẹ́wọ́ ìkẹta",
    "city": "ilu",
    "province": "agbegbe",
    "postal code": "koodu ifiweranṣẹ",
    "all payout channels": "gbogbo awọn ikanni isanwo",
    "bussiness beneficiary": "olugba iṣowo",
    "no quotation": "ko si isiro iye owo",
    "no pending items": "ko si awọn ohun ti o nduro",
    "QR status": "Ipo QR",
    "Portfolio": "Apo",
    "edit whole portfolio page": "ṣatunkọ gbogbo oju-iwe apo",
    "personal account": "akọọlẹ̀ ara ẹni",
    "Chart names": "Orukọ awọn aworan",
    "instagram": "instagram",
    "facebook": "facebook",
    "twitter": "twitter",
    "more": "sì í",
    "not connected": "kò ní sopọ̀ mọ́",
    "code": "koodu",
    "ratings": "ìwádìí",
    "current password": "ọrọigbaniwọle lọwọlọwọ",
    "include at least one uppercase and lowercase": "sọkalẹ o kere ju lẹẹkan ati lẹta kekere",
    "my social network accounts": "àkọọlẹ mi ti nẹ́tìwọ́ọ̀kì àwùjọ",
    "SMS ": "SMS",
    "view identity verfication guide": "wo itọsọna ìdánimọ̀",
    "boimetric face recognition": "fẹ́lẹ́fẹ́lẹ́ oju biometriki",
    "please select a payer (withdrawl options)": "jọwọ yan olufowo (awọn aṣayan yiyọ)",
    "IBAN number ": "nọ́mbà IBAN",
    "account holder name": "orukọ dimu àkọọlẹ",
    "download data": "gbé data",
    "bank transfer": "irinsin ifowopamo",
    "mobile wallet": "apamowo alagbeka",
    "all bank names": "gbogbo oruko banki",
    "pay via card": "sanwo nipasẹ kaadi",
    "you have entered an amount that is below the payer range": "o ti tẹ iye ti o wa ni isalẹ iwọn ti o sanwo",
    "trouble sending money": "ìṣoro fifiranṣẹ owó",
    "please follow these example": "jowo tẹle awọn apẹẹrẹ wọnyi",
    "wallet ID ": "ID Wallet",
    "username": "orukọ olumulo",
    "email": "imeeli",
    "phone number": "nọmba foonu",
    "no user found": "ko si olumulo ti a rii",
    "search": "awari",
    "scan/upload QR code of the recipient": "sẹẹnì/filọ koodu QR ti olugba",
    "invite them": "pe wọn",
    "trouble quoting money": "isoro ni didenunwo owo",
    "select a country": "yan orilẹ-ede kan",
    "airtime details": "ọ̀rọ̀ ilẹ̀kùn",
    "enter the phone number": "tẹ nọmba foonu sii",
    "service type": "iru iṣẹ",
    "select a service": "yan iṣẹ kan",
    "service list": "akọ́kọ́ iṣẹ",
    "amount entered is less than the ....": "iye ti a tẹ sii kere ju ....",
    "total": "àpapọ̀",
    "total credit the receiver gets": "Gbogbo kirẹditi ti olugba n gba",
    "take ": "mu",
    "OTP has been verified , You'll be redirected......": "OTP ti jẹrisi, iwọ yoo ṣe itọsọna......",
    "Link Your Instagram Account to InstaPay": "So àkọọlẹ Instagram rẹ pọ mọ InstaPay",
    "Step 01 – From Instagram App": "Igbesẹ 01 – Lati Ohun elo Instagram",
    "Open the Instagram app, search for the \"InstaPay\" profile page, select \"Message\" to open the chat box, type/say \"Hi\" and click send to initiate the conversation with the InstaPay chatbot.": "Ṣii ohun elo Instagram, wa oju-iwe profaili \"InstaPay\", yan \"Ifiranṣẹ\" lati ṣii apoti ifọrọranṣẹ, tẹ/so \"Pẹlẹ o\" ki o tẹ fi ranṣẹ lati bẹrẹ ibaraẹnisọrọ pẹlu bot ifọrọranṣẹ InstaPay.",
    "Step 02 – From InstaPay Instagram Profile Page ": "Igbesẹ 02 – Lati Oju-iwe Profaili Instagram InstaPay",
    "InstaPay chatbot will display two action buttons: \"Register\" and \"Connect.\" Select \"Connect,\" and the chatbot will prompt you to enter your InstaPay username.": "Bot ifọrọranṣẹ InstaPay yoo ṣafihan awọn bọtini iṣe meji: \"Forukọsilẹ\" ati \"So pọ\". Yan \"So pọ\" ati bot ifọrọranṣẹ yoo sọ fun ọ lati tẹ orukọ olumulo InstaPay rẹ sii.",
    "Step 03 – From InstaPay Instagram Profile Page": "Igbesẹ 03 – Lati Oju-iwe Profaili Instagram InstaPay",
    "The InstaPay chatbot will then ask for the InstaPay verification code. Enter the code you obtained from the InstaPay settings under \"My Social Network Accounts\" and click send.": "Bot ifọrọranṣẹ InstaPay yoo lẹhinna beere fun koodu ijẹrisi InstaPay. Tẹ koodu ti o gba lati inu awọn eto InstaPay ni isalẹ \"Awọn akọọlẹ Nẹtiwọọki Awujọ Mi\" ki o tẹ fi ranṣẹ.",
    "Step 04 – From InstaPay Instagram Profile Page": "Igbesẹ 04 – Lati Oju-iwe Profaili Instagram InstaPay",
    "You will receive a congratulatory message confirming that your Instagram account is now synced with InstaPay. Click on \"Main Menu\" to start using InstaPay services.": "Iwọ yoo gba ifiranṣẹ ikini ti o jẹrisi pe akọọlẹ Instagram rẹ ti ni bayi ni ibamu pẹlu InstaPay. Tẹ lori \"Akojọ Akọkọ\" lati bẹrẹ lilo awọn iṣẹ InstaPay.",
    "Additional Tips": "Awọn imọran afikun",
    "1.\tSecure Your Account: Ensure your Instagram account is secure by enabling two-factor authentication (2FA) to protect your linked InstaPay services.": "1. Daabobo akọọlẹ rẹ: Rii daju pe akọọlẹ Instagram rẹ wa ni aabo nipa mu ifọrọranṣẹ meji-oluyipada ṣiṣẹ (2FA) lati daabobo awọn iṣẹ InstaPay ti o ni asopọ.",
    "2.\tExplore More: Once linked, explore the wide range of services InstaPay offers, from seamless transactions to instant withdrawals.": "2. Ṣawari siwaju sii: Ni kete ti o ba sopọ, ṣawari ibiti awọn iṣẹ InstaPay nfunni, lati awọn iṣowo ailopin si awọn yiyọkuro lẹsẹkẹsẹ.",
    "3.\tStay Updated: Keep an eye on our updates, as we will soon support linking other social media accounts to make your experience even more integrated.": "3. Duro imudojuiwọn: Duro ṣayẹwo awọn imudojuiwọn wa, bi a yoo ṣe atilẹyin ni laipẹ lati so awọn akọọlẹ nẹtiwọki awujọ miiran pọ lati jẹ ki iriri rẹ pọ julọ.",
    "4.\tHelp & Support: If you face any issues during the linking process, contact our support team for assistance.": "4. Iranlọwọ & Atilẹyin: Ti o ba dojukọ eyikeyi awọn ọran lakoko ilana sisopọ, kan si ẹgbẹ atilẹyin wa fun iranlọwọ.",
    "Future Enhancements": "Awọn ilọsiwaju iwaju",
    "In the near future, InstaPay will allow you to link more social media accounts, providing you with even more flexibility and convenience. Stay tuned for exciting updates!": "Ni ọjọ iwaju, InstaPay yoo gba ọ laaye lati so ọpọlọpọ awọn akọọlẹ nẹtiwọki awujọ pọ, nfun ọ ni irọrun ati irọrun diẹ sii. Duro ṣayẹwo fun awọn imudojuiwọn igbadun!",
    "Personal Transactions": "Awọn idunadura ti ara ẹni",
    "Business Transactions": "Awọn idunadura Iṣowo",
    "Shopping & Purchases": "Iṣowo & Awọn rira",
    "Bills & Utilities": "Awọn owo-ori & Awọn iṣẹ",
    "Entertainment & Leisure": "Idaraya & Iṣere",
    "Investments & Savings": "Idoko-owo & Fipamọ",
    "Health & Wellness": "Ilera & Adayeba",
    "Transportation": "Gbigbe",
    "Education": "Ẹkọ",
    "Miscellaneous": "Aṣọtọ",
    "Family Support": "Atilẹyin Ẹbi",
    "Web - login with phone number": null,
    "Login": "Login",
    "Start Your InstaPay Journey Today!": "Bẹrẹ Irin-ajo InstaPay rẹ loni!",
    "Enter your phone number": "Tẹ nọmba foonu rẹ sii",
    "Login with email": "Wo sinu pẹlu imeeli",
    "Don't have an account?": "Ṣe o ni akọọlẹ kan?",
    "Sign Up": "Forukọsilẹ",
    "Continue": "Tesiwaju",
    "We recommend you download and install the Google Authenticator app": "A ṣe iṣeduro fun ọ lati ṣe igbasilẹ ati fi sori ẹrọ app Google Authenticator",
    "Two-factor authentication (2FA) is the best way to protect yourself online.": "Ijẹrisi meji-idi (2FA) jẹ ọna ti o dara julọ lati daabobo ara rẹ lori ayelujara.",
    "Strong security with Google Authenticator": "Aabo to lagbara pẹlu Google Authenticator",
    "Enter your password": "Tẹ ọrọ igbaniwọle rẹ sii",
    "Web Mobile": null,
    "We recommend you download and install the Google Authenticator app to ensure you receive your verification codes instantly, avoiding any SMS delays or issues.": "A ṣe iṣeduro fun ọ lati ṣe igbasilẹ ati fi sori ẹrọ app Google Authenticator lati rii daju pe o gba awọn koodu ijẹrisi rẹ ni kiakia, lati yago fun eyikeyi idaduro tabi awọn iṣoro pẹlu SMS.",
    "Web - Google Authenticator": null,
    "Google Authenticator": "Google Authenticator",
    "Two Factor Authentication Code": "Koodu Ijerisi-Meji",
    "Problem with the token?": "Isoro pẹlu itọnisọna?",
    "Verify using SMS": "Lo SMS lati ṣe ijẹrisi",
    "Verify": "Ṣayẹwo",
    "Web Mobile - Google Authenticator": null,
    "SMS - Web ": null,
    "SMS Verification": "Ijẹrisi SMS",
    "Enter the SMS code sent to +41********4053": "Tẹ koodu SMS ti a firanṣẹ si +41********4053",
    "Verify using Authenticator App": "Lo app Authenticator lati ṣe ijẹrisi",
    "Resend code in 120 seconds": "Tun koodu ranṣẹ ni iṣẹju-aaya 120",
    "Verify ": "Ṣayẹwo",
    "SMS - Web Mobile": null,
    "My own account": "Akọọlẹ temi",
    "Friends & Family": "Ọrẹ ati Ẹbi",
    "Aunt": "Auntie",
    "Brother-in-law": "Ẹgbẹ́ aya",
    "Cousin": "Arabinrin",
    "Daughter": "Ọmọbinrin",
    "Father": "Baba",
    "Father-in-law": "Baba oko",
    "Friend": "Ọrẹ",
    "Grandfather": "Baba ńlá",
    "Grandmother": "Mama ńlá",
    "Husband": "Ọkọ",
    "Mother": "Mama",
    "Mother-in-law": "Iya oko",
    "Nephew": "Ọmọkunrin arakunrin",
    "Niece": "Ọmọbinrin arakunrin",
    "Self (i.e. the sender, himself)": "Ararẹ (ẹni tí ń ránṣẹ́)",
    "Sister": "Arabinrin",
    "Sister-in-law": "Ẹgbẹ aya",
    "Son": "Ọmọkunrin",
    "Uncle": "Ẹ̀gbọ́n baba",
    "Wife": "Aya",
    "Others not listed": "Ẹlòmíràn tí kò sọ",
    "Merchant": "Onisowo",
    "No relationship": "Ko si ibasepọ",
    "Add Funds": "Fi Awọn owo kun",
    "Enter the Amount": "Tẹ iye naa sii:",
    "Fees": "Awọn owo iṣẹ:",
    "You Will Get": "Iwọ yoo gba:",
    "⚠️ The transactional value should be between[Currency ISO code & Amount] - [Currency ISO code & Amount] ": "⚠️ Iye iṣowo yẹ ki o wa laarin [Currency ISO code & Amount] - [Currency ISO code & Amount]",
    "⚠️ Invalid Amount: Your current  identity verification level limits the  maximum amount you can add. Please upgrade your identity verification to benefit from higher limits.           ": "⚠️ Iye ti ko tọ: Ipele ijẹrisi idanimọ rẹ lọwọlọwọ ni opin iye ti o pọ julọ ti o le ṣafikun. Jọwọ ṣe imudojuiwọn ijẹrisi idanimọ rẹ lati ni anfani lati awọn aala ti o ga julọ.",
    "ℹ️ The price of currency changes frequently based on market conditions. Please refer to the price on the confirm order page as your final quotation": "ℹ️ Iye owo nina yipada nigbagbogbo da lori awọn ipo ọja. Jọwọ tọka si idiyele lori oju-iwe aṣẹ jẹrisi bi asọye ikẹhin rẹ.",
    "Next": "Nigbamii",
    "Upgrade Identity Verification": "Mu Ijẹrisi Idanimọ Rẹ Ṣe",
    "Request money from anyone, anywhere, including across social networking platforms. With InstaPay, your payment requests are instantly accessible once accepted and funds are sent to your Receivable Accounts. Simplify your transactions and manage payments with ease and security.": "Beere owo lọwọ ẹnikẹni, nibikibi, pẹlu lori awọn iru ẹrọ nẹtiwọọki awujọ. Pẹlu InstaPay, awọn ibeere isanwo rẹ ni irọrun wiwọle lẹsẹkẹsẹ lẹhin ti o gba ati awọn owo ti wa ni firanṣẹ si Awọn akọọlẹ Gbigba rẹ. Ṣe ilọsiwaju awọn iṣowo rẹ ki o ṣakoso awọn sisanwo pẹlu irọrun ati aabo.",
    "You’ve been logged out.": "O ti jade.",
    "For your security, you’ve been logged out after 15 minutes of inactivity. Please log in again if you wish to use InstaPay.": "Fun aabo rẹ, a ti yọ ọ kuro lẹhin iṣẹju 15 ti aini iṣẹ. Jọwọ wọle lẹẹkansi ti o ba fẹ lo InstaPay.",
    "LOGIN AGAIN": "WÓLÉ TÚN",
    "Brother": "Arakunrin",
    "Current Password": "Ọ̀rọ̀ aṣínà ìsinsin yìí",
    "The new password cannot be the same as the old one": "Ọ̀rọ̀ aṣínà tuntun kò lè jẹ́ bẹ́ẹ̀ gẹ́gẹ́ bí èyí tí ẹ ti lo tán.",
    "Minimum password length: [X] characters ": "Ẹ̀gbá kan lọ́kàntùǹka ọmọ ìwé ìníkọ́: [X] àwọn alábàákọ.",
    "Include at least one uppercase and one lowercase letter.": "Rántí láti ṣàfikún lẹ́tà aláṣájú kan àti lẹ́tà àwọ̀nka kan.",
    "Nominee Information": "Alaye Ẹni Tó Gbẹ́kalẹ̀",
    "Verification Code Preferences": "Àṣàyàn Koodu ìmúdájú",
    "How Others Can Find You": "Báwọn Míràn Ṣe Lẹ̀ Rí Ọ",
    "My Social Network Accounts": "Àwọn Àkàǹlẹ̀ Nẹ́tíwọ́ọ̀kì Awùjọ Mi",
    "Login Activity": "Ìwààsù Ọ̀rọ̀ìkọ̀nà",
    "Receiving Accounts": "Àwọn Àkàǹlẹ̀ Gba",
    "Change Your Password To Continue": "Ṣàtúnṣe Ọ̀rọ̀ Àsìnà Rẹ Látí Tẹ̀síwájú",
    "Continue with": "Tẹ̀síwájú Pẹ̀lú",
    "Back to Login": "Padà Sílẹ̀ Àkọlé",
    "Send OTP": "Fíránṣẹ́ OTP",
    "A Verification code has been sent to your email and number.": "Àkọlé kan ti a fíránṣẹ́ sí ìmèlí rẹ̀ àti nọ́mbà rẹ.",
    "Resend available in": "Àfikún ìfíranṣẹ́ Tí Yé Aṣeyọrí Nínú",
    "Create a strong Password": "Ṣẹ̀dá Ọ̀rọ̀ Àsìnà Tí Ó Lágbára",
    "Password must:": "Ọ̀rọ̀ Àsìnà yẹ:",
    "Be between 9 and 64 characters": "Ṣe láàrín 9 sí 64 àwọn alábàákọ",
    "Include at least two of the following:": "Pé Fún Àkọlé Àtàtà Bí Àwọn Wọnyí:",
    "Lowercase Character": "Àkọlé Abẹ́lẹ̀",
    "Special Character": "Àkọlé Páàtìpáàtà",
    "Password Updated Successfully!": "Ọ̀rọ̀ Àsìnà Yẹyé Kúkúrú Láti Ṣe É!",
    "Click below to login": "Tẹ níhìn-ín láti wọlé",

    "Send Money": "Firanṣẹ Owo",
    "Enter the amount": null,
    "Recent Transfer": "Gbigbe Tuntun",
    "See all": "Wo gbogbo",
    "Send again": "Tun firanṣẹ",
    "You Send": "Iwọ Firanṣẹ",
    "They Receive": "Wọn Gba",
    "Receive Method": "Ọna gbigba",
    "Select method": "Yan ọna",
    "Next": "Itele",
    "Trouble sending money?": "Isoro ni fifiranṣẹ owo?",
    "Where do you want to send money ?": "Nibo ni o fẹ lati fi owo ranṣẹ si?",
    "Search ": "Ṣawari",
    "Confirm": "Jẹrisi",
    "Bank Deposit": "Idogo Banki",
    "Cash Pick-up": "Gbigba Owo",
    "Mobile Wallet": "Mobile Wallet",
    "Bank Card": "Kaadi Banki",
    "Crypto Wallet": "Wallet crypto",
    "What is the receiver’s Mobile Wallet?": "Kini Wallet alagbeka olugba naa?",
    "Transfer fees": "Owo gbigbe",
    "Total to pay": "Lapapọ lati san",
    "Choose the beneficiary!": "Yan olugba!",
    "Country": "Orilẹ-ede",
    "Receive method": "Ọna gbigba",
    "You send": "Iwọ Firanṣẹ",
    "change": "iyipada",
    "edit": "fi titun kun",
    "add new": "Awọn olugba",
    "Beneficiaries": "Ṣakoso Awọn olugba",
    "Manage Beneficiaries": "Ṣawari",
    "Search": "Itele",
    "CASH PICK-UP": "Gbigba Owo ni gbangba",
    "Where will your receiver collect the money": "Nibo ni olugba rẹ yoo gba owo naa",
    "Cash is available to collect within minutes from any branch of [name] ": "Owo wa lati gba laarin iṣẹju diẹ lati eyikeyi ẹka ti [orukọ]",
    "BANK ACCOUNT": "Ile-ifowopamọ",
    "Transfer to recipient’s bank account": "Gbigbe si akọọlẹ banki ti olugba",
    "BANK CARD": "KAADI ILE-IFOWOPAMỌ",
    "Fast transfer directly to recipient’s bank card": "Gbigbe yara taara si kaadi ile-ifowopamọ olugba",
    "CRYPTO WALLET": "Wallet crypto",
    "Fast transfer directly to a crypto wallet": "Gbigbe yara taara si Wallet crypto",
    "Review transfer": "Atunwo gbigbe",
    "Receiver's Details": "Alaye olugba",
    "Edit": "Ṣatunkọ",
    "Name": "Orukọ",
    "Reason for sending": "Idi fun fifiranṣẹ",
    "Sending": "Fifiranṣẹ",
    "Receiver gets": "Olugba gba",
    "Exchange rate": "Oṣuwọn paṣipaarọ",
    "Estimated time": "Aago asiko",
    "Select Payment Type": "Yan iru sisanwo",
    "Credit cards": "Kaadi kirẹditi",
    "Domestic cards": "Kaadi abele",
    "Mobile Wallets": "Awọn Wallet alagbeka",
    "InstaPay Wallet": "Wallet InstaPay",
    "Crypto (USDT, ETH, BTC)": "Crypto (USDT, ETH, BTC)",
    "PayPal": "PayPal",
    "CREDIT CARD": "KAADI KIRẸDITI",
    "Credit Card": "Kaadi kirẹditi",
    "Select card": "Yan kaadi",
    "Card Number": "Nọmba kaadi",
    "Enter the 16-digit card number on the card": "Tẹ nọmba kaadi 16-digit",
    "Expiry Date": "Ọjọ ipari",
    "Enter the expiration date of the card": "Tẹ ọjọ ipari ti kaadi naa",
    "CVV Number": "Nọmba CVV",
    "Enter the 3 or 4 digit number on the card": "Tẹ nọmba 3 tabi 4-digit lori kaadi naa",
    "INSTAPAY WALLET": "Wallet INSTAPAY",
    "Select Wallet": "Yan Wallet",
    "My Wallet": "Apo mi",
    "Conversion": "Iyipada",
    "Convert": "Iyipada",
    "Your payment is confirmed": "Ti ṣayẹwo isanwo rẹ",
    "Thanks for using InstaPay": "O ṣeun fun lilo InstaPay",
    "Status": "Ipo",
    "Share": "Pin",
    "Transfer created": "Gbigbe ṣẹda",
    "Payment received": "Sanwo gba",
    "Payment processed": "Sanwo ti ṣe ilana",
    "Transfer successful": "Gbigbe aṣeyọri",
    "It may take few hours for the funds to appear in [user name] account": "O le gba awọn wakati diẹ fun awọn owo lati han ninu akọọlẹ [orukọ olumulo]",
    "Transfer failed": "Gbigbe kuna",
    "Transaction Details": "Awọn alaye iṣowo",
    "Need help?": "Nilo iranlọwọ?",
    "Service Details": "Alaye Iṣẹ",
    "Funds Deposit Speed": "Wiwa Awọn Owo",
    "Cut-off Time": "Akoko Ige",
    "Bank holiday list": "Atokọ Awọn Isinmi Banki",
    "Threshold limits": "Awọn opin Iṣowo",
    "null": null,
    "Instant, Real Time": "Láìpé, nígbà gidi",
    "Same day, T+1": "Ní ọjọ́ kan náà, T+1",
    "Non-instant, T+1": "Kò láìpé, T+1",
    "Instant Payment, Real Time": "Ìsanwó láìpé, nígbà gidi",
    "Transaction processed before 3:00 PM Local time": "Ilana mu iṣowo ṣaaju 3:00 PM Aago agbegbe",
    "Delivered the same day": "Ti ṣe ifijiṣẹ ni ọjọ kanna",
    "Transactions processed post 3:00 PM": "Awọn iṣowo ti a ṣe ilana lẹhin 3:00 PM",
    "T+1 business days": "T+1 ọjọ iṣowo",
    "Payments made on weekdays before 3:00PM will be credited to the receiving bank account by midnight of the\nsame day.": "Awọn sisanwo ti a ṣe ni awọn ọjọ ọsẹ ṣaaju 3:00 PM yoo gba kirẹditi si akọọlẹ ile-ifowopamọ ti n gba nipasẹ idaji owurọ ti ọjọ kanna.",
    "Payments made on weekdays after 15:00 will be credited by midnight the following day.": "Awọn sisanwo ti a ṣe ni awọn ọjọ ọsẹ lẹhin 3:00 PM yoo gba kirẹditi nipasẹ idaji owurọ ọjọ keji.",
    "Payments made on a Saturday, Sunday or Public holiday will be credited to the account by midnight of the 1st following business day": "Awọn sisanwo ti a ṣe ni ọjọ Satidee, ọjọ Sunday tabi isinmi gbangba yoo gba kirẹditi si akọọlẹ naa nipasẹ idaji owurọ ọjọ iṣowo akọkọ ti n bọ.",
    "24*7": "24*7",
    "09hrs - 18 hrs Local Time": "09:00 - 18:00 Aago Agbegbe",
    "https://www.officeholidays.com/countries ": "https://www.officeholidays.com/countries",
    "Min per transaction limit:": "Iye to kere ju fun iṣowo kọọkan:",
    "Maximum per transaction limit:": "Iye to pọ julọ fun iṣowo kọọkan:",
    "Daily threshold limit:": "Iye oṣuwọn ojoojumọ:",
    "Weekly threshold limit:": "Iye oṣuwọn ọsẹ:",
    "Monthly threshold limit:": "Iye oṣuwọn oṣooṣu:",
    "Yearly threshold limit:": "Iye oṣuwọn ọdun:",
    "P2P": "P2P",
    "P2B": "P2B",
    "B2P": "B2P",
    "B2B": "B2B",
    "WALLET TO WALLET": "WALLET TO WALLET",
    "Basic Account (Unverified)": "Iwe iroyin ipilẹ (A ko fọwọsi)",
    "Identity Verified": "Idanimọ ti fọwọsi"

}