const BackBtn = () => {
    return (
        <li className="back-btn">
            <div className="mobile-back text-end">
                <span>Back</span>
                <i className="fa fa-angle-right ps-2" aria-hidden="true"></i>
            </div>
        </li>
    );
};

export default BackBtn;
