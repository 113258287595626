export const pl = {
    "How Others Can Find You": "Jak inni mogą cię znaleźć",
    "Schedules": "Rozkłady",
    "Login": "Logowanie",
    "Start Your InstaPay Journey Today!": "Rozpocznij swoją podróż z InstaPay już dziś!",
    "Phone Number or Email": "Numer telefonu lub email",
    "Login Using Social Media": "Logowanie przez media społecznościowe",
    "Continue": "Kontynuuj",
    "Don't Have an Account? Sign Up": "Nie masz konta? Zarejestruj się",
    "Sign Up": "Zarejestruj się",
    "Edit": "Szczegóły",
    "Enter Your Password": "Wprowadź swoje hasło",
    "Forgot Password?": "Zapomniałeś hasła?",
    "Account Type": "Typ konta",
    "Individual": "Indywidualne",
    "Business": "Biznesowe",
    "Already Have an Account? Sign In": "Masz już konto? Zaloguj się",
    "Sing In": "Zaloguj się",
    "Enter Your Personal Details": "Wprowadź swoje dane osobiste",
    "Add Photo or Logo": "Dodaj zdjęcie lub logo",
    "Enter Your Name": "Wprowadź swoje imię",
    "Back": "Powrót",
    "Phone Number": "Numer telefonu",
    "Email Address": "Adres Email",
    "Create a Strong Password": "Utwórz silne hasło",
    "Password Requirements": "Wymagania dotyczące hasła",
    "Must be between 9 and 64 characters": "Muszą mieć od 9 do 64 znaków",
    "Include at least two of the following:": "Zawierać przynajmniej dwa z poniższych:",
    "Uppercase character": "Wielka litera",
    "Lowercase character": "Mała litera",
    "Number": "Cyfra",
    "Special character": "Znak specjalny",
    "Confirm Password": "Potwierdź hasło",
    "Finalize Your Process": "Dokończ proces",
    "Referral Code (Optional)": "Kod polecający (opcjonalnie)",
    "It will autofill if you're signing up with an invitation link.": "Wypełni się automatycznie, jeśli rejestrujesz się przez link zaproszenia.",
    "I agree with": "Zgadzam się z",
    "Terms & Conditions": "Regulaminem",
    "Privacy Policy": "Polityką prywatności",
    "Create Your Account": "Utwórz swoje konto",
    "Enter Code": "Wprowadź kod",
    "A one-time verification code has been sent to your email address  and phone number.": "Jednorazowy kod weryfikacyjny został wysłany na twój adres email i numer telefonu.",
    "Resend available in ": "Ponowne wysłanie dostępne za",
    "seconds": "sekund",
    "Verify": "Zweryfikuj",
    "Verification Code Verified": "Kod weryfikacyjny zweryfikowany",
    "Please continue to the next step.": "Proszę kontynuować do następnego kroku.",
    "Finish": "Zakończ",
    "Finishing": "Kończenie",
    "Company Name": "Nazwa firmy",
    "Enter Your Business Details": "Wprowadź dane swojej firmy",
    "Country ": "Kraj",
    "You cannot change the country once your account is verified.": "Nie można zmienić kraju po zweryfikowaniu konta.",
    "Learn more": "Dowiedz się więcej",
    "Optional": "Opcjonalne",
    "Continuing": "Kontynuowanie",
    "Didn't receive the verification code?": "Nie otrzymałeś kodu weryfikacyjnego?",
    "Resend Now": "Wyślij ponownie teraz",
    "Search Here": "Szukaj tutaj",
    "Search for users, services, and more on InstaPay": "Szukaj użytkowników, usług i więcej na InstaPay",
    "Search users here": "Szukaj użytkowników tutaj",
    "Earn money with our affiliate program!": "Zarabiaj pieniądze z naszego programu partnerskiego!",
    "Generate passive income by sharing your InstaPay affiliate code with your followers. For each transaction they make, \nyou earn a commission, thereby maximizing the profits from your online influence.": "Generuj pasywny dochód, udostępniając swój kod partnerski InstaPay swoim obserwującym. Za każdą transakcję, którą wykonają,\notrzymujesz prowizję, maksymalizując zyski z Twojego wpływu online.",
    "Learn More": "Dowiedz się więcej",
    "Balance Amount": "Saldo",
    "You spent less compared to last month.": "Wydałeś mniej w porównaniu do zeszłego miesiąca.",
    "Referral Rewards": "Nagrody za polecenie",
    "Bitcoin": "Bitcoin",
    "USDT": "USDT",
    "Ethereum": "Ethereum",
    "Quick Transfer to Your Most Recent Contacts:": "Szybki transfer do Twoich najnowszych kontaktów:",
    "Number of Transactions": "Liczba transakcji",
    "Total Transaction Amount": "Całkowita kwota transakcji",
    "Total Sent": "Łącznie wysłane",
    "Total Received": "Łącznie otrzymane",
    "Add Funds": "Dodaj środki",
    "Send Money": "Wyślij Pieniądze",
    "Request Money": "Poproś o Pieniądze",
    "Send a Quote": "Wyślij ofertę",
    "Pay With Crypto": "Płać kryptowalutami",
    "Payment Insights": "Wgląd w płatności",
    "Recent Transactions ": "Ostatnie transakcje",
    "Today": "Dziś",
    "My Wallets": "Moje portfele",
    "Balance": "Saldo",
    "Wallet ID": "ID portfela",
    "Monthly Limit": "Miesięczny limit",
    "Top Up": "Doładuj",
    "Summary": "Podsumowanie",
    "Settings": "Ustawienia",
    "More": "Więcej",
    "Upgrade to Business Account": "Przejdź na konto biznesowe",
    "Conversion": "Konwersja",
    "Enter Amount": "Wprowadź kwotę",
    "Exchanged Amount": "Przeliczona kwota",
    "Convert": "Konwertuj",
    "You Are Converting": "Przeliczasz",
    "From": "Od",
    "To": "Na",
    "Exchange Rate": "Kurs wymiany",
    "Fees": "Opłaty",
    "You Will Receive": "Otrzymasz",
    "Slide To Confirm": "Przesuń, aby potwierdzić",
    "Verification Code Confirmed - You'll be redirected to the next step": "Kod weryfikacyjny potwierdzony - zostaniesz przekierowany do następnego kroku",
    "Moving to next step in": "Przechodzenie do następnego kroku za",
    "Thanks for using InstaPay": "Dziękujemy za korzystanie z InstaPay",
    "Your funds have been credited to your wallet.": "Twoje środki zostały zaksięgowane na twoim portfelu.",
    "Dashboard": "Panel sterowania",
    "Accounts": "Konta",
    "Transactions": "Transakcje",
    "Other Transactions": "Inne transakcje",
    "Payments": "Płatności",
    "Beneficiaries": "Beneficjenci",
    "Referrals": "Polecenia",
    "Quotations": "Oferty",
    "Pending": "Oczekujące",
    "My QR Code Sticker": "Mój naklejka kodu QR",
    "My Portfolio": "Moje portfolio",
    "My Payment Address": "Mój adres płatności",
    "Analytics": "Analizy",
    "Profile": "Profil",
    "Dark Mode": "Tryb ciemny",
    "Support": "Pomoc",
    "Logout": "Wyloguj się",
    "Powered By": "Napędzane przez",
    "Kemit Kingdom SA": "Kemit Kingdom SA",
    "A Swiss Company": "Szwajcarska firma fintech",
    "MAIN MENU": "GŁÓWNE MENU",
    "OTHERS": "INNE",
    "Wallet": "Wallet",
    "Default": "Domyślny",
    "Breakdown": "Rozkład",
    "Credit": "Kredyt",
    "Debit": "Debet",
    "Wallet Management": "Zarządzanie portfelem",
    "Top-up Your Wallet": "Doładuj swój portfel",
    "Download Statement": "Pobierz wyciąg",
    "Block the Wallet": "Zablokuj portfel",
    "Wallet Status": "Status portfela",
    "-Select-File-Type-": "-Wybierz-typ-pliku-",
    "Download Now": "Pobierz teraz",
    "Downloading": "Pobieranie",
    "All": "Wszystkie",
    "Sent": "Wysłane",
    "Received": "Otrzymane",
    "Requested": "Żądane",
    "Quoted": "Ofertowane",
    "Search Transactions": "Szukaj transakcji",
    "Date & Time": "Data i czas",
    "Type": "Typ",
    "Transaction ID": "ID transakcji",
    "Recipient": "Odbiorca",
    "Amount": "Kwota",
    "Status": "Status",
    "Payment Type": "Typ płatności",
    "Sent Payments": "Wysłane płatności",
    "Received Payments": "Otrzymane płatności",
    "Date": "Data",
    "Cycles/Time": "Cykle/Czas",
    "Payment As": "Płatność jako",
    "Name": "Nazwa",
    "Cancel": "Anuluj",
    "Subscriptions & Scheduled": "Subskrypcje i zaplanowane",
    "Select the option that matches your needs from the list below:": "Wybierz opcję, która odpowiada Twoim potrzebom z poniższej listy:",
    "Easily send money to friends, family, or businesses locally or internationally. \nChoose from multiple transfer options, including bank deposits, mobile wallets, \ncash pick-up, payment cards, and wallet-to-wallet transfers. Enjoy the convenience \nand security of InstaPay for seamless and instant money transfers.": "Łatwo wysyłaj pieniądze do przyjaciół, rodziny lub firm lokalnie lub międzynarodowo. Wybierz spośród wielu opcji transferu, w tym wpłat na konto \nbankowe, portfele mobilne,odbieranie gotówki, karty płatnicze oraz przelewy z portfela do portfela. Ciesz się wygodą i bezpieczeństwem korzystania \nz InstaPay dla płynnych i natychmiastowych przelewów pieniężnych.",
    "International Transfer": "Przelew Międzynarodowy\n",
    "Send money across borders with ease. Transfer funds internationally \nto friends, family, or businesses in just a few clicks. Choose from \na wide range of payment channels including bank account, \nmobile money/mobile wallet, cash pick-up location, or payment card \nfor convenient and secure transactions.": "Wyślij pieniądze za granicę z łatwością. Przelej środki międzynarodowo do przyjaciół, rodziny lub firm za pomocą kilku kliknięć. Wybierz spośród\nszerokiej gamy kanałów płatności, w tym konta bankowego, pieniędzy mobilnych/portfela mobilnego, punktu odbioru gotówki lub karty płatniczej\ndla wygodnych i bezpiecznych transakcji.",
    "Select the country where you want to send the money!": "Wybierz kraj, do którego chcesz wysłać pieniądze!",
    "Search Country": "Szukaj Kraju",
    "Trouble Sending Money?": "Problem z wysyłaniem pieniędzy?",
    "Confirm": "Potwierdź",
    "Select Payout Channel": "Wybierz Kanał Wypłaty",
    "Select your most convenient option to send the money.": "Wybierz najwygodniejszą opcję wysłania pieniędzy.",
    "Select The Bank": "Wybierz Bank",
    "Search Bank": "Szukaj Banku",
    "Send money directly to a recipient's bank account. Enjoy the convenience and security of transferring funds seamlessly to any bank around the world.": "Wyślij pieniądze bezpośrednio na konto bankowe odbiorcy. Ciesz się wygodą i bezpieczeństwem płynnego przesyłania środków do dowolnego banku \nna świecie.",
    "Bank Transfer": "Przelew Bankowy",
    "Enter the amount": "Wprowadź kwotę",
    "Tax": "Podatek",
    "Amount the recipient will receive": "Kwota, którą otrzyma odbiorca",
    "Select Remarks / Reason": "Wybierz Uwagi / Powód",
    "Comments": "Komentarze",
    "Attach Files": "Załącz Pliki",
    "Groups": "Grupy",
    "Manage Groups": "Zarządzaj Grupami",
    "Add New Group": "Dodaj Nową Grupę",
    "Invite": "Zaproś Osobę",
    "Manage Beneficiaries": "Zarządzaj Beneficjentami",
    "Recipients List": "Lista Odbiorców",
    "Search Contacts": "Szukaj Kontaktów",
    "Confirm Your Payment": "Potwierdź Płatność",
    "Select Country": "Wybierz Kraj",
    "Select the Recipient's Payment Channel": "Wybierz Kanał Płatności Odbiorcy",
    "Change Selection": "Zmień Wybór",
    "You Are Transferring ": "Przesyłasz",
    "Oops! Something Went Wrong.": "Ups! Coś poszło nie tak.",
    "We're Sorry About That. ": "Przepraszamy za to.",
    "Go back to Payments Page.": "Wróć do strony płatności.",
    "Wallet to Wallet Transfer": "Przelew z Portfela do Portfela",
    "Request a customized price quote from service providers or vendors.": "Zamów spersonalizowaną wycenę od dostawców usług lub sprzedawców.",
    "Enter the Wallet ID": "Wprowadź ID Portfela",
    "Enter the Wallet ID, Email, or Phone Number of the recipient.": "Wprowadź ID Portfela, Email lub Numer Telefonu odbiorcy.",
    "Recipient not on InstaPay?": "Czy odbiorca nie jest na InstaPay? Zaproś ich.",
    "Invite Them": "Zaproś Ich",
    "My Beneficiaries": "Moi Beneficjenci",
    "Enter the Amount You Wish to Transfer.": "Wprowadź kwotę, którą chcesz przelać.",
    "Sending to ": "Wysyłasz do",
    "You Have Selected Wallet to Wallet Transfer": "Wybrałeś Przelew z Portfela do Portfela",
    "Authenticate Your Payment.": "Uwierzytelnij swoją płatność.",
    "You are transferring": "Przesyłasz",
    "Total ": "Łącznie",
    "Where Is My Payment?": "Gdzie jest moja płatność?",
    "Share": "Udostępnij",
    "Your Payment Is Confirmed": "Twoja płatność została potwierdzona",
    "QR Code Payment": "Płatność Kodem QR",
    "Instantly transfer money to other InstaPay users with our secure wallet-to-wallet \nservice. Send and receive funds hassle-free within seconds.": "Natychmiastowo przelej pieniądze do innych użytkowników InstaPay za pomocą naszego bezpiecznego serwisu portfel-do-portfela. Wyślij i odbieraj \nśrodki bezproblemowo w ciągu kilku sekund.",
    "Scan QR with Your Camera or Upload from Your Device.": "Zeskanuj QR swoją kamerą lub załaduj z urządzenia.",
    "Upload from Your Device.": "Załaduj z urządzenia.",
    "Alternatively, Use the InstaPay Unique Payment Address (UPA)": "Alternatywnie, użyj Unikalnego Adresu Płatności InstaPay (UPA)",
    "Enter InstaPay UPA": "Wprowadź InstaPay UPA",
    "Recipient not on InstaPay? Invite Them": "Odbiorca nie jest na InstaPay? Zaproś ich",
    "Verify QR Code": "Zweryfikuj Kod QR",
    "Scan/Upload QR Code of the Recipient": "Zeskanuj/Załaduj Kod QR Odbiorcy",
    "Payment Confirmed": "Płatność Potwierdzona",
    "Request customized price quotes from service providers or vendors to receive accurate cost estimates for the services or products you need.": "Poproś o spersonalizowane wyceny od dostawców usług lub sprzedawców, aby otrzymać dokładne oszacowanie kosztów usług lub produktów, \nktórych potrzebujesz.",
    "Choose The Beneficiary or Group": "Wybierz Beneficjenta lub Grupę",
    "Enter the Amount You Wish to Request.": "Wprowadź kwotę, którą chcesz zażądać.",
    "You Are Requesting Money": "Żądasz Pieniędzy",
    "You are requesting": "Żądasz",
    "As": "Jako",
    "Conversion of Your Cryptocurrencies": "Konwersja Twoich Kryptowalut",
    "Experience our user-centric, secure, and efficient Cash-Out Crypto to Fiat Conversion.": "Doświadcz naszego użytkownika, bezpiecznego i efektywnego procesu Konwersji Crypto na Fiat.",
    "Choose the Cryptocurrency You Intend to Liquidate.": "Wybierz Kryptowalutę, którą zamierzasz zlikwidować.",
    "Next": "Dalej",
    "Enter the Crypto Wallet Address of the Recipient.": "Wprowadź Adres Portfela Krypto Odbiorcy.",
    "Minimum Transactional Value: $100.": "Minimalna Wartość Transakcji: 100$.",
    "Note: Crypto prices frequently change. Refer to the final quotation on the confirm order page.": "Uwaga: Ceny krypto często się zmieniają. Odniesienie do ostatecznej wyceny na stronie potwierdzenia zamówienia.",
    "Select Network": "Wybierz Sieć",
    "Send A Quote": "Wyślij Ofertę",
    "Send a detailed price quote to your clients or customers. Present your services, products, or offers professionally with a personalized quotation.": "Wyślij szczegółową ofertę cenową swoim klientom lub klientom. Przedstaw swoje usługi, produkty,",
    "Choose the Beneficiary": "lub oferty profesjonalnie za pomocą spersonalizowanej wyceny.",
    "Allow Receiver to Bargain": "Wybierz Beneficjenta",
    "Enter the Title for Your Quotation.": "Pozwól Odbiorcy Targować się",
    "Enter the Description for Your Quotation": "Wprowadź Tytuł dla Twojej Wyceny.",
    "You are Quoting": "Wprowadź Opis dla Twojej Wyceny",
    "Title": "Wyceniasz",
    "Description": "Opis",
    "Attachments": "Opis",
    "Confirm Your Quotation": "Załączniki",
    "Verify The Quotation": "Potwierdź swoją Wycenę",
    "Your quotation is confirmed": "Zweryfikuj Wycenę",
    "Request A Quote": "Poproś o wycenę",
    "Coming Soon": "Poproś o Wycenę",
    "Add Beneficiary": "Już wkrótce",
    "Search for Beneficiaries": "Dodaj Beneficjenta",
    "Connected Accounts": "Szukaj Beneficjentów",
    "Details": "Połączone Konta",
    "First Name": "Edytuj",
    "Last Name": "Imię",
    "Country": "Nazwisko",
    "City": "Kraj",
    "Address Line": "Miasto",
    "Mobile Number": "Numer Telefonu Komórkowego",
    "Relationship With Beneficiary": "Relacja z Beneficjentem",
    "Bank Account": "Konto Bankowe",
    "Mobile Money/Mobile Wallet": "Pieniądze Mobilne/Portfel Mobilny",
    "Payment Card": "Karta Płatnicza",
    "Crypto Wallet": "Portfel Krypto",
    "InstaPay Account": "Konto InstaPay",
    "Cash Pickup": "Odbiór Gotówki",
    "IBAN / SWIFT Code": "Kod IBAN lub Swift/BIC",
    "Swift Code": "Kod Swift",
    "Account Number": "Numer Konta",
    "Bank Name": "Nazwa Banku",
    "Branch Name": "Nazwa Oddziału",
    "Branch Street": "Ulica Oddziału",
    "Province": "Województwo",
    "Postal Code": "Kod Pocztowy",
    "Mobile Money Provider": "Dostawca Pieniędzy Mobilnych",
    "Wallet Name": "Nazwa Portfela",
    "Wallet Number": "Numer Portfela",
    "Card Holder Name": "Nazwa Właściciela Karty",
    "Card Number": "Numer Karty",
    "Expiry Date": "Data Ważności",
    "Crypto Currency": "Kryptowaluta",
    "Wallet Address": "Adres Portfela",
    "Wallet Holder Name": "Nazwa Właściciela Portfela",
    "Wallet Currency": "Waluta Portfela",
    "Select Document Type": "Wybierz Typ Dokumentu",
    "Enter Document Number": "Wprowadź Numer Dokumentu",
    "Add Individual Account": "Dodaj Indywidualne Konto",
    "Add Business Account": "Dodaj Konto Firmowe",
    "Company Address": "Adres Firmy",
    "Company Email": "Email Firmy",
    "Company Phone No": "Numer Telefonu Firmy",
    "Total Referrals": "Łączna Liczba Poleceń",
    "My Earnings": "Moje Zarobki",
    "Your Referral ID": "Twój ID Polecającego",
    "Share Your Unique Referral Link": "Udostępnij Swój Unikalny Link Polecający",
    "How do I Refer a Friend to InstaPay?": "Jak Polecić Przyjaciela do InstaPay?",
    "Step 01": "Krok 01",
    "Share your InstaPay unique referral link with your friends.": "Udostępnij swój unikalny link polecający InstaPay swoim znajomym.",
    "Step 02": "Krok 02",
    "Earn rewards when they transact $100 or more": "Zarabiaj nagrody, gdy dokonają transakcji na kwotę 100$ lub więcej",
    "Step 03": "Krok 03",
    "Earn up to $0.06 for every transaction your followers make.": "Zarabiaj do 0.06$ za każdą transakcję wykonaną przez Twoich obserwatorów.",
    "My Referrals": "Moje Polecenia",
    "Referral Complete": "Polecenie Zakończone",
    "Received Quotations": "Otrzymane Oferty",
    "Sent Quotations": "Wysłane Oferty",
    "Search Pending Items": "Szukaj Oczekujących Przedmiotów",
    "Actions": "Akcje",
    "Received Requests": "Otrzymane Prośby",
    "Sent Requests": "Wysłane Prośby",
    "No Actions": "Brak Akcji",
    "You Requested": "Poprosiłeś",
    "Via": "Przez",
    "How was your experience?": "Jakie było Twoje doświadczenie?",
    "Leave a Review": "Zostaw Recenzję",
    "Overall Ratings": "Ogólna Ocena",
    "Visit Profile": "Odwiedź Profil",
    "Ratings": "Oceny",
    "Reviews": "Recenzje",
    "No reviews available": "Brak dostępnych recenzji",
    "Select Your Currency": "Wybierz Swoją Walutę",
    "Proceed": "Kontynuuj",
    "Commision": "Prowizja",
    "Unlock Seamless Transactions with InstaPay QR Code Sticker": "Odblokuj Bezproblemowe Transakcje z Naklejką Kodu QR InstaPay",
    "Effortless and Swift:": "Bezproblemowe i Szybkie:",
    "Instantly download your unique InstaPay QR code sticker.": "Natychmiastowo pobierz swoją unikalną naklejkę kodu QR InstaPay.",
    "Experience hassle-free, cashless payments without needing complex infrastructure like \npayment terminals.": "Doświadcz bezproblemowych, bezgotówkowych płatności bez potrzeby korzystania ze skomplikowanej infrastruktury, jak terminale płatnicze.",
    "Swiftly collect payments – no more tedious entry of credit card or phone numbers.": "Szybko zbieraj płatności - bez uciążliwego wprowadzania numerów kart kredytowych lub telefonów.",
    "Each payment includes customer information for your convenience.": "Każda płatność zawiera informacje o kliencie dla Twojej wygody.",
    "Economically Smart:": "Ekonomicznie Mądre:",
    "Zero activation fees – start immediately without upfront costs.": "Brak opłat aktywacyjnych - zacznij od razu bez kosztów wstępnych.",
    "Forget about fixed monthly charges; pay as you transact.": "Zapomnij o stałych opłatach miesięcznych; płać, jak transakcjonujesz.",
    "Ditch the hardware; no terminal costs mean more savings for you.": "Odrzuć sprzęt; brak kosztów terminalu oznacza większe oszczędności dla Ciebie.",
    "Enjoy low transaction fees at just 0.75% per QR code payment.": "Ciesz się niskimi opłatami transakcyjnymi, wynoszącymi tylko 0,75% za płatność kodem QR.",
    "We don't impose minimum commissions; you save more with each transaction.": "Nie narzucamy minimalnych prowizji; oszczędzasz więcej przy każdej transakcji.",
    "Experience the joy of instant payments, enhancing your cash flow.": "Doświadcz radości z natychmiastowych płatności, poprawiających Twój przepływ gotówki.",
    "Make Every Transaction Count with InstaPay": "Spraw, by Każda Transakcja Liczyła się z InstaPay",
    "Wallet QR": "QR Portfela",
    "Download QR": "Pobierz QR",
    "QR Code Status": "Status Kodu QR",
    "Share your QR Code or payment address link to instantly receive payments in your InstaPay wallet.": "Udostępnij swój kod QR lub link do adresu płatności, aby natychmiastowo otrzymywać płatności na swoim koncie InstaPay.",
    "QR Code Title": "Tytuł Kodu QR",
    "View Transactions": "Zobacz Transakcje",
    "My QR Codes:": "Moje Kody QR:",
    "Load More": "Załaduj Więcej",
    "Benefits Of Portfolio": "Korzyści z Portfolio",
    "Benefits:": "Korzyści:",
    "Enhanced Visibility:": "Zwiększona Widoczność:",
    "Elevate your public profile. Your portfolio acts as a dynamic showcase, accessible directly from your Payment Address.": "Podnieś swój publiczny profil. Twoje portfolio działa jako dynamiczna wystawa, dostępna bezpośrednio z Twojego Adresu Płatności.",
    "Engage Your Audience:": "Angażuj Swoją Publiczność:\n",
    "Connect with visitors through your creative or professional journey. Let your work speak for you, creating a deeper engagement with potential clients or supporters.": "Łącz się z odwiedzającymi przez Twoją twórczą lub zawodową podróż. Pozwól, by Twoja praca\nmówiła za Ciebie, tworząc głębsze zaangażowanie potencjalnych klientów lub zwolenników.",
    "Integrated Social Proof:": "Zintegrowany Dowód Społeczny:\n",
    "Display your social media influence. Visitors can see your social media reach, adding credibility and context to your portfolio.": "Prezentuj swój wpływ w mediach społecznościowych. Odwiedzający mogą zobaczyć Twój zasięg w mediach społecznościowych, dodając wiarygodność \ni kontekst do Twojego portfolio.",
    "Seamless Transactions:": "Bezproblemowe Transakcje:\n",
    "Enable visitors to request or initiate payments right from your public page. Your portfolio not only showcases your work but also facilitates easy financial interactions.": "Umożliwiaj odwiedzającym żądanie lub inicjowanie płatności bezpośrednio ze strony publicznej. Twoje portfolio nie tylko prezentuje Twoją pracę, ale \ntakże ułatwia łatwe interakcje finansowe.",
    "Personalized Storytelling:": "Personalized Storytelling:",
    "Use the 'About Me' section to share your story, mission, or vision, creating a personal connection with your audience.": "Użyj sekcji 'O mnie', aby podzielić się swoją historią, misją lub wizją, tworząc osobistą więź z Twoją publicznością.\n",
    "My Portfolio' is more than just a gallery; it's a tool to build your brand, engage your audience, and streamline your financial interactions on InstaPay.": "Moje Portfolio' to coś więcej niż tylko galeria; to narzędzie do budowania Twojej marki, angażowania publiczności i usprawniania Twoich finansowych \ninterakcji na InstaPay.",
    "Showcase Your Work, Amplify Your Reach. 'My Portfolio' is your digital canvas on \nInstaPay. Upload and display a diverse range of content – from captivating images \nand videos to informative PDFs. Whether you're an artist, freelancer, or \nentrepreneur, this feature lets you present your work or projects in a visually \nengaging way.": "Wystaw swoje prace, zwiększ zasięg. 'Moje Portfolio' to Twoje cyfrowe płótno na InstaPay. Prześlij i pokaż różnorodne treści – od zachwycających \nobrazów i filmów po pouczające pliki PDF. Niezależnie od tego, czy jesteś artystą, freelancerem czy przedsiębiorcą, ta funkcja pozwala Ci prezentować \nswoje prace lub projekty w atrakcyjny wizualnie sposób.\n",
    "Add/Manage Portfolio": "Dodaj/Zarządzaj Portfolio",
    "Add New": "Dodaj Nowe",
    "Maximize Your Social Media Earnings with Your InstaPay Payment Address": "Maksymalizuj swoje zarobki z mediów społecznościowych z Adresem Płatności InstaPay",
    "Make It Effortless for Your Audience to Support You:": "Ułatw swojej publiczności wspieranie Ciebie:",
    "Easily integrate your InstaPay Payment Address into your social media posts.": "Łatwo zintegruj swój Adres Płatności InstaPay z postami w mediach społecznościowych.",
    "Easily copy and paste your payment address link into each of your posts, stories, or prominently on your profile.": "Łatwo skopiuj i wklej link swojego adresu płatności do każdego ze swoich postów, historii lub na widocznym miejscu w profilu.",
    "Ideal for influencers, artists, entrepreneurs, or anyone with a community eager to show \ntheir support.": "Idealne dla influencerów, artystów, przedsiębiorców lub każdego, kto ma społeczność chętną do okazania wsparcia.",
    "Instant Support, Endless Possibilities:": "Natychmiastowe wsparcie, nieograniczone możliwości:",
    "Transform the way you monetize your social media content.": "Zmieniaj sposób monetyzacji treści w mediach społecznościowych.",
    "Allow your followers to send you funds instantly with just a click, directly from their favorite social networks.": "Pozwól swoim obserwatorom wysyłać Ci fundusze natychmiast jednym kliknięciem, bezpośrednio z ich ulubionych sieci społecznościowych.",
    "Ideal for real-time support during live streams, as a tipping system for your creative content, or for funding your next big \nproject.": "Idealne do wsparcia w czasie rzeczywistym podczas transmisji na żywo, jako system napiwków dla Twoich kreatywnych treści lub do finansowania \nTwojego kolejnego dużego projektu.\n",
    "Where to Place Your InstaPay Payment Address for Maximum Impact:": "Gdzie umieścić swój Adres Płatności InstaPay, aby osiągnąć maksymalny wpływ:",
    "Social Media Profiles: Pin it to your bio or about section for constant visibility.": "Profile w mediach społecznościowych: Przypnij go do swojej biografii lub sekcji o sobie dla stałej widoczności",
    "Posts & Announcements: Incorporate it into your individual posts, particularly those highlighting your work or specific \ncalls for support.": "Posty i ogłoszenia: Włącz go do swoich indywidualnych postów, szczególnie tych podkreślających Twoją pracę lub konkretne wezwania do wsparcia.",
    "Stories & Reels: Integrate it into your interactive stories or captivating reels to capture instant contributions.": "Historie i Reelsy: Zintegruj go ze swoimi interaktywnymi historiami lub fascynującymi filmikami, aby natychmiastowo zbierać wkłady.",
    "Live Streams: Share it during your LIVE sessions, making it easy for viewers to contribute in real-time.": "Transmisje na żywo: Udostępniaj go podczas swoich sesji NA ŻY",
    "Simple, Fast, and Secure:": "Proste, szybkie i bezpieczne:",
    "No complex setup – get started in moments.": "Bez skomplikowanej konfiguracji – zacznij w kilka chwil.",
    "Enjoy the security and speed of InstaPay's trusted payment processing.": "Ciesz się bezpieczeństwem i szybkością zaufanego procesu płatności InstaPay.",
    "Receive payments from anyone, anywhere, without them needing an InstaPay account.": "Odbieraj płatności od każdego, wszędzie, bez konieczności posiadania konta w InstaPay.",
    "Elevate Your Social Media Game with InstaPay Payment Address": "Podnieś swoje możliwości w mediach społecznościowych dzięki Adresowi Płatności InstaPay.",
    "InstaPay VPA (Virtual Payment Address)": "InstaPay VPA (Wirtualny Adres Płatności)",
    "Payment Address Title": "Tytuł Adresu Płatności",
    "Currency": "Waluta",
    "Payment Address Description": "Opis Adresu Płatności",
    "Update": "Aktualizuj",
    "Updating": "Aktualizowanie",
    "Total Transaction": "Łączna transakcja",
    "Last 30 days": "Ostatnie 30 dni",
    "Last 1 month": "Ostatni 1 miesiąc",
    "Last 1 year": "Ostatni 1 rok",
    "Custom Date": "Niestandardowa data",
    "Payment Methods": "Metody płatności",
    "Payment Types": "Typy płatności",
    "Requested Amount": "Żądana kwota",
    "Quoted Amount": "Podana kwota",
    "About Me": "O mnie",
    "Followers": "Obserwujący",
    "InstaPay ID": "ID InstaPay",
    "Download QR Code": "Pobierz kod QR",
    "Initiate A Payment": "Zainicjuj płatność",
    "Report this person": "Zgłoś tę osobę",
    "Add now": "Dodaj teraz",
    "Recent Reviews": "Najnowsze recenzje",
    "Reviews As Seller": "Recenzje jako sprzedający",
    "Reviews As Buyer": "Recenzje jako kupujący",
    "Select Language": "Wybierz język",
    "Profile Completion": "Zakończenie profilu",
    "Profile Completed": "Profil ukończony",
    "Basic Info": "Podstawowe informacje",
    "Username": "Nazwa użytkownika",
    "Gender": "Płeć",
    "Male": "Mężczyzna",
    "Female": "Kobieta",
    "-Select-Gender-": "-Wybierz płeć-",
    "Date Of Birth": "Data urodzenia",
    "Address": "Adres",
    "Edit Profile": "Edytuj profil",
    "Update Profile": "Zaktualizuj profil",
    "Updating Profile": "Aktualizowanie profilu",
    "Complete Now": "Zakończ teraz",
    "Password": "Hasło",
    "Change Password": "Zmień hasło",
    "New Password": "Nowe hasło",
    "Re-enter New Password": "Wprowadź ponownie nowe hasło",
    "Please follow this guide for a strong password": "Proszę stosować się do tej instrukcji, aby stworzyć silne hasło",
    "Include at least one special character.": "Zawierać co najmniej jeden znak specjalny.",
    "Minimum of 8 characters.": "Minimum 8 znaków.",
    "Include at least one number": "Zawierać co najmniej jedną cyfrę",
    "Change it often for enhanced security.": "Często zmieniaj dla zwiększenia bezpieczeństwa.",
    "Security Questions": "Pytania bezpieczeństwa",
    "Security Question": "Pytanie zabezpieczające",
    "Select your question": "Wybierz swoje pytanie",
    "Answer To The Question": "Odpowiedź na pytanie",
    "Nominee": "Nominowany",
    "Relationship with Nominee": "Relacja z nominowanym",
    "Nominee Contact Number": "Numer kontaktowy nominowanego",
    "Nominee Address": "Adres nominowanego",
    "Delete Nominee": "Usuń nominowanego",
    "Update Nominee": "Zaktualizuj nominowanego",
    "Details Updated Successfully!": "Szczegóły zaktualizowane pomyślnie!",
    "Success": "Sukces",
    "You may elect a Nominee of your choice, provided they are not a minor, through the InstaPay platform itself. You acknowledge that no wet signature is required to \nappoint the nominee, and the online registration will be considered final and binding. In the event of the death of an InstaPay Wallet holder, the balance in the Wallet \nwill be transferred to the registered Nominee. KEMIT KINGDOM will be discharged from all its liabilities upon the handover of the amount to the Nominee. The Nominee \nwill provide any documents required by InstaPay, including those for identification and proof of death.": "Możesz wybrać osobę nominowaną według własnego uznania, pod warunkiem, że nie jest to osoba niepełnoletnia, za pośrednictwem platformy InstaPay. \nPotwierdzasz, że nie jest wymagany mokry podpis do mianowania osoby nominowanej, a rejestracja online będzie uważana za ostateczną i wiążącą. \nW przypadku śmierci posiadacza portfela InstaPay, saldo w portfelu zostanie przekazane zarejestrowanej osobie nominowanej. \nKEMIT KINGDOM zostanie zwolnione ze wszystkich swoich zobowiązań po przekazaniu kwoty osobie nominowanej. Nominowany dostarczy wszystkie \ndokumenty wymagane przez InstaPay, w tym te do identyfikacji i potwierdzenia śmierci.",
    "Add Nominee": "Dodaj Nominowanego",
    "Two Factor Authentication": "Uwierzytelnianie Dwuskładnikowe",
    "SMS Number": "Numer SMS",
    "Updated Successfully!": "Pomyślnie zaktualizowano!",
    "Social Network Accounts": "Konta w Sieciach Społecznościowych",
    "Here, you can set up and manage your integration settings.": "Tutaj możesz skonfigurować i zarządzać ustawieniami integracji.",
    "Social Network Account": "Konto w Sieci Społecznościowej",
    "Activate Account": "Aktywuj Konto",
    "Enter the code below in the InstaPay Chatbot.": "Wprowadź poniższy kod w Chatbocie InstaPay.",
    "The code will expire in ": "Kod wygaśnie za",
    "Notifications": "Powiadomienia",
    "Customize how you receive notifications. These settings apply to the activities you are monitoring.": "Dostosuj sposób otrzymywania powiadomień. Te ustawienia dotyczą monitorowanych przez Ciebie działań",
    "Activity": "Aktywność",
    "Email": "Email",
    "Push": "Powiadomienia Push",
    "SMS": "SMS",
    "Source": "Źródło",
    "Payment Requests": "Zapytania o płatność",
    "Bulk Payments": "Płatności masowe",
    "Identity Verification": "Weryfikacja Tożsamości",
    "Verify Your Identity": "Zweryfikuj swoją tożsamość",
    "Identity Verification Status:": "Status Weryfikacji Tożsamości:",
    "Briefly describe yourself and explain your reasons for using InstaPay.": "Krótko opisz siebie i wyjaśnij swoje powody korzystania z InstaPay.",
    "Enter Your Message": "Wprowadź swoją wiadomość",
    "Select Occupation": "Wybierz zawód",
    "It must be a government-issued identification document with a photo. All identification documents must be written in the Latin alphabet. If not, a certified \ntranslation is required.": "Musisz mieć dokument tożsamości wydany przez rząd z zdjęciem. Wszystkie dokumenty tożsamości muszą być napisane w alfabecie łacińskim. \nW przeciwnym razie wymagane jest uwierzytelnione tłumaczenie.",
    "Accepted Documents": "Akceptowane dokumenty",
    "A national identity card": "Krajowa karta tożsamości",
    "A valid passport": "Ważny paszport",
    "Valid residence permits (accepted only if issued by a European country or in Switzerland)": "Ważne pozwolenie na pobyt (akceptowane tylko, jeśli wydane przez kraj europejski lub w Szwajcarii)",
    "Biometric Facial Recognition: Comparing video with passport photograph": "Biometryczne rozpoznawanie twarzy: porównanie wideo z fotografią w paszporcie",
    "Address Verification:": "Weryfikacja Adresu:",
    "Provide a bank statement or utility bill (less than 3 months old) as proof of address": "Dostarcz wyciąg bankowy lub rachunek za media (młodszy niż 3 miesiące) jako dowód adresu",
    "Additional Documents:": "Dodatkowe dokumenty:",
    "Kemit Kingdom reserves the right to request any additional documents (Enhanced Due Diligence - EDD) or further information necessary for conducting thorough \nverifications, in accordance with its legal obligations.": "Kemit Kingdom zastrzega sobie prawo do żądania dodatkowych dokumentów (Zwiększona Staranność - EDD) lub dalszych informacji niezbędnych \ndo przeprowadzenia dokładnych weryfikacji, zgodnie z obowiązującymi przepisami prawnymi.",
    "Start Verification": "Rozpocznij weryfikację",
    "Sessions": "Sesje",
    "This is a list of devices that have logged into your account. Remove any that you do not recognize.": "To jest lista urządzeń, które zalogowały się na twoje konto. Usuń te, które nie rozpoznajesz.",
    "Your Withdrawal Options": "Twoje Opcje Wypłaty",
    "Choose your preferred method for withdrawing payments.": "Wybierz preferowaną metodę wypłaty płatności.",
    "No withdrawal options currently available in your country.": "Obecnie brak opcji wypłaty w twoim kraju.",
    "Download Your Data": "Pobierz swoje dane",
    "Request a Copy of Your Data": "Poproś o kopię swoich danych",
    "Submit a request to download a copy of your data": "Złóż wniosek o pobranie kopii swoich danych",
    "Which Data Should Be Included in the Download?": "Jakie dane powinny być zawarte w pobraniu?",
    "Select all applicable options.": "Wybierz wszystkie odpowiednie opcje.",
    "Personal Information": "Dane osobiste",
    "This information is used to secure your account, tailor your experience, and contact you as necessary.": "Te informacje służą do zabezpieczenia twojego konta, dostosowania doświadczenia i kontaktowania się z tobą w razie potrzeby.",
    "Name and date of birth": "Imię i nazwisko oraz data urodzenia",
    "Phone number": "Numer telefonu",
    "Financial Information": "Informacje finansowe",
    "We use this information to enable faster checkout and to send or receive money in just a few clicks.": "Korzystamy z tych informacji, aby umożliwić szybsze płatności i wysyłać lub odbierać pieniądze w kilka kliknięć.",
    "Bank accounts": "Konta bankowe",
    "Debit or credit cards": "Karty debetowe lub kredytowe",
    "Credit Information": "Informacje o kredycie",
    "Includes your credit application information": "Zawiera informacje o wniosku kredytowym",
    "Other Information (not included in file)": "Inne informacje (nie zawarte w pliku)",
    "Includes device info, technical usage data, geolocation information, marketing preferences, consent history, and data used for other services such as credit, \nidentity verification, communications with PayPal, and third-party processors.": "Zawiera informacje o urządzeniu, dane techniczne użytkowania, informacje geolokalizacyjne, preferencje marketingowe, historię zgód,\noraz dane używane do innych usług, takich jak kredyt, weryfikacja tożsamości, komunikacja z PayPal i procesory stron trzecich.",
    "Choose file type:": "Wybierz typ pliku:",
    "Select file type": "Wybierz typ pliku",
    "PDF file can be easily opened on any computer": "Plik PDF można łatwo otworzyć na każdym komputerze",
    "Submit Request": "Wyślij wniosek",
    "Delete Your Account": "Usuń swoje konto",
    "Ask us to delete your data and close this account": "Poproś nas o usunięcie twoich danych i zamknięcie tego konta",
    "We use your data to provide services, so if we delete your data, we also need to close your account.": "Korzystamy z twoich danych do świadczenia usług, więc jeśli usuniemy twoje dane, musimy również zamknąć twoje konto.",
    "If your account is limited, go to the Resolution Center, select 'Go to Account Limitations', then click 'Resolve'.": "Jeśli twoje konto jest ograniczone, przejdź do Centrum Rozwiązań, wybierz 'Przejdź do Ograniczeń Konta', a następnie kliknij 'Rozwiąż'.",
    "Make sure any outstanding payments have cleared. We won't be able to close your account until they do.": "Upewnij się, że wszystkie nierozliczone płatności zostały rozliczone. Nie będziemy mogli zamknąć twojego konta, dopóki to się nie stanie.",
    "If you have a negative balance, click the Resolve link next to your balance.": "Jeśli masz ujemne saldo, kliknij link Rozwiąż obok twojego salda.",
    "Once your account is clear, and if you're eligible, we'll close your account and delete your data. We need \nto retain some of your data for legal and regulatory reasons. To learn more, see our ": "Gdy twoje konto będzie czyste i jeśli będziesz spełniać warunki, zamkniemy twoje konto i usuniemy twoje dane. Musimy zachować niektóre twoje dane \nz powodów prawnych i regulacyjnych. Aby dowiedzieć się więcej, zobacz nasze ",
    "privacy statement": "oświadczenie o prywatności",
    "We'll email you to let you know the status, and if there are any other steps you need to take.": "Wyślemy ci email, aby poinformować cię o statusie i ewentualnych kolejnych krokach, które musisz podjąć.",
    "Have more questions about data deletion?": "Masz więcej pytań dotyczących usuwania danych?",
    "Contact us": "Skontaktuj się z nami",
    "I understand that account deletion is permanent and can't be reversed.": "Rozumiem, że usunięcie konta jest nieodwracalne.",
    "Are you sure you want to close your account?": "Czy na pewno chcesz zamknąć swoje konto?",
    "Yes, Continue": "Tak, kontynuuj",
    "Logging In": "Logowanie",
    "Complete your profile to streamline your transaction process.": "Uzupełnij swój profil, aby usprawnić proces transakcji.",
    "Skip": "Pomiń",
    "Complete Your Profile": "Uzupełnij swój profil",
    "API Key": "Klucz API",
    "Get API Key": "Uzyskaj Klucz API",
    "Generating New API Key": "Generowanie Nowego Klucza API",
    "Generate API Key": "Wygeneruj Klucz API",
    "Regenerate API Key": "Wygeneruj ponownie Klucz API",
    "To harness the full potential of InstaPay's seamless transactions, follow these quick steps to generate your API key. First, sign up for an account at \n[your registration URL] and access the Developer Dashboard. Navigate to the 'API Keys' section, generate a new key, and ensure its secure storage.": "Aby w pełni wykorzystać płynne transakcje InstaPay, wykonaj te szybkie kroki, aby wygenerować swój klucz API. Najpierw zarejestruj się na koncie pod \n[twoim adresem URL rejestracji] i uzyskaj dostęp do Panelu Dewelopera. Przejdź do sekcji 'Klucze API', wygeneruj nowy klucz i upewnij się, że jest \nbezpiecznie przechowywany.",
    "Delete API Key": "Usuń Klucz API",
    "Webhook URL": "URL Webhook",
    "KYB Verification": "Weryfikacja KYB",
    "Additional Files": "Dodatkowe Pliki",
    "Business Verification": "Weryfikacja Biznesu",
    "--Select-Your-Company-Type--": "--Wybierz-Typ-Twojej-Firmy--",
    "Documents Required:": "Wymagane Dokumenty:",
    "Official Company Registry Extract (Entity's Registration/Incorporation Document)": "Oficjalny Wyciąg z Rejestru Firm (Dokument Rejestracji/Inkorporacji Podmiotu)",
    "This document serves as the company's official 'identity card' and should be obtained from the local business registry. It reflects the current status of the business \n(active or closed).": "Ten dokument służy jako oficjalna 'karta tożsamości' firmy i powinien być uzyskany z lokalnego rejestru biznesowego. \nOdbija obecny status firmy (aktywna lub zamknięta).",
    "The document should be current, with an issuance date not exceeding 3 months.": "Dokument powinien być aktualny, z datą wydania nie przekraczającą 3 miesięcy.",
    "The document must include the following details:": "Dokument musi zawierać następujące szczegóły:\n",
    "Legal name of the company": "Prawna nazwa firmy",
    "Full registered address": "Pełny zarejestrowany adres\n",
    "Company registration number": "Numer rejestracji firmy",
    "Date of incorporation": "Data inkorporacji",
    "Description of the company's activities/purpose": "Opis działalności/purpose firmy",
    "Legal representative's information: full name, date and place of birth, residential address (minimum requirement: country of residence), and nationality": "Informacje o przedstawicielu prawnym: pełne imię i nazwisko, data i miejsce urodzenia, adres zamieszkania\n(minimalny wymóg: kraj zamieszkania) oraz narodowość",
    "If applicable, the document should also include:": "Jeśli to ma zastosowanie, dokument powinien również zawierać:\n",
    "Brand or trading names": "Nazwy handlowe lub marki",
    "Value Added Tax (VAT) number": "Numer podatku VAT",
    "List of Ultimate Beneficial Owners (UBOs) - this is mandatory in some countries, otherwise, it may be provided as a separate document.": "Lista Ostatecznych Beneficjentów (UBOs) - jest to obowiązkowe w niektórych krajach, w przeciwnym razie",
    "Proof of Legal Representative's Authority": "Dowód Upoważnienia Przedstawiciela Prawnego",
    "This document validates an individual's authority to represent the company": "Ten dokument potwierdza uprawnienia osoby do reprezentowania firmy",
    "Typically, the legal representative is named in the company's registry extract. However, if this is not the case, a document such as a Power of Attorney or Delegation \nof Powers must be secured.": "Zazwyczaj przedstawiciel prawny jest wymieniony w wyciągu z rejestru firmy. Jednak, jeśli tak nie jest,\nnależy zabezpieczyć dokument, taki jak Pełnomocnictwo lub Delegacja Upoważnień.",
    "Power of Attorney/Delegation of Powers:": "Pełnomocnictwo/Delegacja Upoważnień:\n",
    "The individual conferring the authority must be legitimately entitled to do so. This individual should be listed as a legal representative in the company's registry extract.": "Osoba udzielająca upoważnienia musi być do tego prawnie uprawniona. Ta osoba powinna być wymieniona jako przedstawiciel prawny\nw wyciągu z rejestru firmy.",
    "Ownership Structure and Ultimate Beneficial Owners (UBOs) Documentation": "Dokumentacja Struktury Własności i Ostatecznych Beneficjentów (UBOs)\n",
    "Evidence of the company's listing on the relevant stock exchange should be provided. This could be a page from the stock exchange website displaying \nthe company's details.": "Powinien zostać dostarczony dowód na notowanie firmy na odpowiedniej giełdzie papierów wartościowych. Może to być strona z witryny \ngiełdy wyświetlająca szczegóły firmy.",
    "If less than 75% of the company's shares are publicly owned, all shareholders who own or control more than 25% of the shares will be subject to \nUltimate Beneficial Owner (UBO) verification requirements.": "Jeśli mniej niż 75% akcji firmy jest publicznie posiadanych, wszyscy akcjonariusze posiadający lub kontrolujący więcej niż 25%\nakcji będą podlegać wymogom weryfikacji Ostatecznego Beneficjenta (UBO).",
    "Collection of Identification Documents": "Zbiór Dokumentów Tożsamości",
    "The identification document must be government-issued, bearing a photograph of the individual. All identification documents must be written in the Latin alphabet. If not, \na certified translation is required.": "Dokument tożsamości musi być wydany przez rząd, zawierający zdjęcie osoby. Wszystkie dokumenty tożsamości muszą być napisane \nw alfabecie łacińskim. W przeciwnym razie wymagane jest uwierzytelnione tłumaczenie.",
    "Accepted documents:": "Akceptowane dokumenty:",
    "Valid National Identity Card": "Ważna Karta Tożsamości Narodowej",
    "Valid Passport": "Ważny Paszport",
    "Valid Resident Permits - These will be accepted only if issued by a European country.": "Ważne Pozwolenie na Pobyt - Akceptowane tylko, jeśli wydane przez kraj europejski.",
    "Only legal representatives are subject to the identification document collection process.": "Tylko przedstawiciele prawni podlegają procesowi zbierania dokumentów tożsamości.",
    "Additional Requirement:": "Dodatkowe Wymagania:",
    "In cases involving a Power of Attorney or Delegation of Powers, both parties (the individual granting authority and the one receiving it) must provide a copy of their ID.": "W przypadkach związanych z Pełnomocnictwem lub Delegacją Upoważnień, obie strony\n(osoba udzielająca upoważnienia i odbierająca je) muszą dostarczyć kopię swoich dokumentów tożsamości.",
    "Additional Documents": "Dodatkowe Dokumenty\n",
    "Utility Bill (such as gas, electricity, telephone or mobile phone bill); or a document issued by a government department that shows the End-user’s address \nand name (e.g., Residential Certificate); or": "Rachunek za media (taki jak gaz, prąd, telefon stacjonarny lub komórkowy); lub dokument wydany przez departament rządowy\npokazujący adres i nazwisko użytkownika końcowego (np. Certyfikat Mieszkalny); lub",
    "Bank Statement; or": "Wyciąg Bankowy; lub\n",
    "a document issued by a government department that shows the End-user’s address and name (e.g., Residential Certificate).": "dokument wydany przez departament rządowy pokazujący adres i nazwisko użytkownika końcowego\n(np. Certyfikat Mieszkalny).",
    "Kemit Kingdom retains the right to request any additional documents or further information necessary for conducting thorough verifications, in line with \nits legal obligations.": "Kemit Kingdom zastrzega sobie prawo do żądania dodatkowych dokumentów lub dalszych informacji niezbędnych do przeprowadzenia\ndokładnych weryfikacji, zgodnie z obowiązującymi przepisami prawnymi.",
    "Note:": "Uwaga:",
    "Partners": "Partnerzy",
    "Add New Partner": "Dodaj Nowego Partnera",
    "Designation": "Nazwa",
    "Partner Name": "Nazwa Partnera",
    "Partner Email": "Email Partnera\n",
    "Partner Designation": "Oznaczenie Partnera",
    "Add Now": "Dodaj Teraz\n",
    "Adding": "Dodawanie",
    "Delete Partner": "Usuń Partnera\n",
    "Delete": "Usuń",
    "Deleting": "Usuwanie",
    "Are you sure, you want to remove sxz as partner?": "Czy na pewno chcesz usunąć sxz jako partnera?",
    "Entity's Registry Extract (Entity'sRegistration/Creation Document)": "Wyciąg z Rejestru Podmiotu (Dokument Rejestracji/Tworzenia Podmiotu)",
    "This document should be either:": "Ten dokument powinien być jednym z następujących:",
    "A proof of creation of the entity, providing the entity's details, creation date, and purpose.": "Dowód utworzenia podmiotu, zawierający szczegóły dotyczące podmiotu, datę utworzenia i cel.",
    "A local registry extract:": "Wyciąg z rejestru lokalnego:",
    "Required as per the country's regulations, serving as the current 'identity card' for the entity, indicating its status (active or closed).": "Wymaganym zgodnie z przepisami kraju, służącym jako aktualna 'karta tożsamości' podmiotu, wskazującym jego status\n(aktywny lub zamknięty).",
    "If a registry extract exists, it must not be older than 3 months.": "Jeśli istnieje wyciąg z rejestru, nie może być starszy niż 3 miesiące.\n",
    "Entity name": "Nazwa podmiotu\n",
    "Registration number (if applicable, based on the country's requirements)": "Numer rejestracji (jeśli dotyczy, w zależności od wymagań kraju)",
    "Date of creation": "Data utworzenia\n",
    "Description of the entity's activities or purpose": "Opis działalności lub celu podmiotu\n",
    "Trading names": "Nazwy handlowe\n",
    "Articles of Association": "Statut Spółki\n",
    "The Articles of Association serve as the constitutional document of the entity, outlining its operating rules.": "Statut Spółki pełni rolę dokumentu konstytucyjnego podmiotu, określając jego zasady działania.",
    "There is no time constraint on this document, meaning the most recent version is requested. Typically, these types of entities require at least one annual meeting \nthat may result in an updated version of the Articles of Association.": "Nie ma ograniczenia czasowego dotyczącego tego dokumentu, co oznacza, że wymagana jest najnowsza wersja. Zazwyczaj tego typu\npodmioty wymagają co najmniej jednego zebrania rocznego, które może skutkować zaktualizowaną wersją Statutu Spółki.",
    "Examples of changes that could occur include updates to the entity's name, address, purpose, management board, roles, responsibilities, etc.": "Przykłady zmian, które mogą nastąpić, obejmują aktualizacje nazwy podmiotu, adresu, celu, zarządu,\nról, obowiązków itd.",
    "The document should contain information about:": "Dokument powinien zawierać informacje o:",
    "The entity itself, including its legal name, registered address, and purpose": "Sam podmiot, w tym jego prawna nazwa, zarejestrowany adres i cel",
    "Its internal operating rules, such as the appointment process for management board members, their roles and powers, among other details. This list is not exhaustive.": "Wewnętrznych zasadach działania, takich jak proces powoływania członków zarządu, ich role i uprawnienia, wśród innych szczegółów. Ta lista nie jest \nwyczerpująca.",
    "How the entity is funded": "Jak jest finansowany podmiot",
    "Appointment/Removal/Changes on Board of Management Members:": "Powołanie/Usunięcie/Zmiany w Zarządzie:",
    "These updates usually occur based on the entity's needs or as stipulated in the Articles of Association. Changes are documented through board of management \nmeeting minutes, which are registered and certified to reflect the updated list of members, such as the president, vice-president, treasurer, and general secretary.": "Te aktualizacje zazwyczaj mają miejsce w oparciu o potrzeby podmiotu lub zgodnie ze Statutem Spółki. Zmiany są dokumentowane poprzez \nprotokoły z posiedzeń zarządu, które są rejestrowane i certyfikowane, aby odzwierciedlać zaktualizowaną listę członków, takich jak prezes, wiceprezes, \nskarbnik i sekretarz generalny.",
    "The latest board management meeting minutes, including the current list of members, will be required in addition to the Articles of Association, if available.": "Najnowsze protokoły z posiedzeń zarządu, w tym aktualna lista członków, będą wymagane wraz ze Statutem Spółki, jeśli jest dostępny.",
    "Note: If the current list of board management members is not included in the latest Articles of Association and there are no board meeting minutes available, \nthis list must be provided. It should be dated and signed by the most recently appointed legal representative, whose appointment can be verified through related \ndocumentation.": "Uwaga: Jeśli aktualna lista członków zarządu nie jest zawarta w najnowszym Statucie Spółki i nie ma dostępnych protokołów z posiedzeń zarządu, \nta lista musi zostać dostarczona. Powinna być datowana i podpisana przez najnowszego prawnie mianowanego przedstawiciela, którego powołanie \nmożna zweryfikować za pomocą odpowiedniej dokumentacji.",
    "This document demonstrates an individual's authority to represent the entity. This document could be:": "Ten dokument potwierdza uprawnienia osoby do reprezentowania podmiotu. Może to być:",
    "The latest Articles of Association": "Najnowszy Statut Spółki",
    "The current list of board management members (which may be included in the board management meeting minutes)": "Aktualna lista członków zarządu\n(która może być zawarta w protokołach z posiedzeń zarządu)",
    "The document should specify:": "Dokument powinien określać:",
    "Full name of the individual(s)": "Pełne imię i nazwisko osoby/osób",
    "Function of the individual(s). This information will be cross-verified with the Articles of Association to ensure that the individual's function permits them to bind the entity. \nThis is particularly important when the applicant is not the president, and other functions have limited representation authority as stated in the Articles of Association.": "Funkcja osoby/osób. Te informacje będą weryfikowane krzyżowo ze Statutem Spółki, aby zapewnić, że funkcja osoby pozwala jej zobowiązywać \npodmiot. Jest to szczególnie ważne, gdy wnioskodawca nie jest prezesem, a inne funkcje mają ograniczone uprawnienia reprezentacji, \njak stwierdzono w Statucie Spółki.",
    "Additional information about the legal representative that will be collected includes:": "Dodatkowe informacje o przedstawicielu prawnym, które będą zbierane, obejmują:",
    "Date and place of birth": "Datę i miejsce urodzenia",
    "Residential address (at least the country of residence should be stated)": "Adres zamieszkania (przynajmniej kraj zamieszkania powinien być podany)",
    "Nationality": "Obywatelstwo",
    "Ownership Structure & Ultimate Beneficial Owners (UBOs)": "Struktura Właśności & Ostateczni Beneficjenci (UBOs)",
    "Typically, these types of entities, given their structure and purpose, do not have beneficial owners. However, board members will be identified and treated as such for \nscreening purposes, ID collection and verification, etc.": "Zazwyczaj tego typu podmioty, biorąc pod uwagę ich strukturę i cel, nie mają beneficjentów. Jednakże, członkowie zarządu będą identyfikowani \ni traktowani jako tacy do celów przesiewowych, zbierania i weryfikacji tożsamości itp.",
    "A small number of associations might have beneficial owners, particularly those generating profits. In such instances, this detail will be mentioned \nin the Articles of Association. These individuals will be easily identified and treated as such, alongside management board members.": "Niewielka liczba stowarzyszeń może mieć beneficjentów, szczególnie te generujące zyski. W takich przypadkach ten szczegół zostanie wymieniony \nw Statucie Spółki. Ci ludzie będą łatwo identyfikowani i traktowani jako tacy, obok członków zarządu.",
    "Additional Requirement: In cases involving a Power of Attorney or Delegation of Powers, both parties \n(the individual granting authority and the one receiving it) must provide a copy of their identification documents.": "Dodatkowe Wymaganie: W przypadkach związanych z Pełnomocnictwem lub Delegacją Upoważnień, \nobie strony (osoba udzielająca upoważnienia i odbierająca je) muszą dostarczyć kopię swoich dokumentów tożsamości.",
    "Sole Trader's Registry Extract (Entity's Registration/Creation Document)": "Wyciąg z Rejestru Jednoosobowej Działalności Gospodarczej (Dokument Rejestracji/Tworzenia Podmiotu)\n",
    "This document serves as a local registry for sole traders, required in countries where such registration is mandatory. \nIt verifies that the individual is engaged in a professional activity as a sole trader. The name of this document varies from \ncountry to country.": "Ten dokument służy jako lokalny rejestr dla przedsiębiorców indywidualnych, wymagany w krajach, gdzie taka rejestracja jest obowiązkowa.\nPotwierdza, że osoba prowadzi działalność zawodową jako jedyny przedsiębiorca. Nazwa tego dokumentu różni się w zależności od kraju.",
    "Where applicable, the document should not be older than 3 months.": "Jeśli to możliwe, dokument nie powinien być starszy niż 3 miesiące.",
    "The document must contain:": "Dokument powinien zawierać:",
    "Individual's name": "Imię i nazwisko osoby",
    "Residential address": "Adres zamieszkania",
    "Registration number": "Numer rejestracyjny",
    "The sole trader is the only individual subject to the identification document collection process.": "Jedyny przedsiębiorca jest jedyną osobą podlegającą procesowi zbierania dokumentów tożsamości.",
    "No Transactions": "Brak Transakcji",
    "No Pending Items": "Brak Oczekujących Przedmiotów",
    "No Beneficiaries": "Brak Beneficjentów",
    "Send Money To": "Wyślij Pieniądze",
    "Confirmation": "Potwierdzenie",
    "You are setting": "Ustawiasz",
    "as default.": "jako domyślne.",
    "Previously": "Wcześniej",
    "was default QR.": "był domyślnym kodem QR.",
    "Category": "Kategoria",
    "-Select-From-The-List-": "-Wybierz-Z-Listy-",
    "Add a note": "Dodaj notatkę",
    "Categories Added Successfully!": "Kategorie Dodane Pomyślnie!",
    "Subscription starting date": "Data rozpoczęcia subskrypcji",
    "Until": "Do",
    "Next Transaction": "Następna Transakcja",
    "Transaction Method": "Metoda Transakcji",
    "Accept the quotation": "Zaakceptuj wycenę",
    "Choose Currency": "Wybierz Walutę",
    "Accept": "Zaakceptuj",
    "Bargain the quotation": "Targuj wycenę",
    "Enter Counter Amount:": "Wpisz Kwotę Przeciwną:",
    "Bargain": "Targuj",
    "Decline the quotation": "Anuluj wycenę",
    "Are you sure, you want to decline this quotation?": "Czy jesteś pewien, że chcesz odrzucić tę wycenę?",
    "Decline": "Odrzuć",
    "Personal Account": "Konto Osobiste",
    "Jan": "styć",
    "Feb": "lut",
    "Mar": "mar",
    "Apr": "kwie",
    "May": "maj",
    "Jun": "cze",
    "Jul": "lip",
    "Aug": "sierp",
    "Sep": "wrz",
    "Oct": "paź",
    "Nov": "lis",
    "Dec": "gru",
    "Today": "Dziś",
    "Spent In": "Spędzone w",
    "Revised Amount": "Skorygowana kwota",
    "You spent more comparing to last month": "Wydałeś więcej w porównaniu z poprzednim miesiącem",
    "Last": "Ostatni",
    "days": "Dni",
    "Year": "Lata",
    "Instant Mobile Top-up Across 150+ Countries": "Natychmiastowe doładowanie telefonu w ponad 150 krajach",
    "Delight your loved ones or recharge your own phone, no matter where you are! InstaPay’s Mobile Airtime service lets you instantly top up mobile phones in over 150 countries. Perfect for gifts or ensuring you're always connected, our service is designed for ease, speed, and security.": "Ciesz swoich bliskich lub doładuj własny telefon, niezależnie od tego, gdzie jesteś! Usługa doładowań komórkowych InstaPay pozwala na natychmiastowe doładowanie telefonów w ponad 150 krajach. Idealne na prezent lub aby zawsze być w kontakcie, nasza usługa jest zaprojektowana dla wygody, szybkości i bezpieczeństwa.",
    "How It Works - Step-by-Step Guide:": "Jak to działa - Przewodnik krok po kroku:",
    "1. Choose 'Mobile Top-up': Access this feature directly within the InstaPay app on platforms like WhatsApp, Twitter, Telegram, or Instagram.": "1. Wybierz 'Doładowanie telefonu': Uzyskaj dostęp do tej funkcji bezpośrednio w aplikacji InstaPay na platformach takich jak WhatsApp, Twitter, Telegram czy Instagram.",
    "2. Select Currency and Amount: Pick from multiple currencies (USD, GBP, EUR, etc.) and specify how much you want to top up.": "2. Wybierz walutę i kwotę: Wybierz spośród wielu walut (USD, GBP, EUR itp.) i określ, ile chcesz doładować.",
    "3. Enter the Mobile Number: Type in the number you wish to recharge—yours or that of a loved one. Our system ensures a secure and swift process.": "3. Wprowadź numer telefonu: Wpisz numer telefonu, który chcesz doładować—twój lub bliskiej osoby. Nasz system zapewnia bezpieczny i szybki proces.",
    "4. Confirm and Pay: Double-check the top-up details, confirm, and make the payment using your preferred method. The transaction processes instantly and securely.": "4. Potwierdź i zapłać: Sprawdź szczegóły doładowania, potwierdź i dokonaj płatności wybraną metodą. Transakcja przebiega natychmiastowo i bezpiecznie.",
    "5. Receive Instant Confirmation: Both you and the recipient (if applicable) will receive a confirmation notification once the top-up is successful.": "5. Otrzymaj natychmiastowe potwierdzenie: Zarówno ty, jak i odbiorca (jeśli dotyczy) otrzymacie powiadomienie o potwierdzeniu, gdy doładowanie się powiedzie.",
    "Benefits:": "Korzyści:",
    "Global Reach: Effortlessly send airtime to over 150 countries.": "Zasięg globalny: Łatwo wysyłaj doładowania do ponad 150 krajów.",
    "Instant Impact: See the benefits immediately—perfect for last-minute gifts or urgent communications.": "Natychmiastowy efekt: Zobacz korzyści natychmiast — idealne na prezenty na ostatnią chwilę lub pilne komunikacje.",
    "Complete Security: Enjoy peace of mind with our secure payment system.": "Pełne bezpieczeństwo: Ciesz się spokojem dzięki naszemu bezpiecznemu systemowi płatności.",
    "Versatile Payment Options: Choose from credit cards, PayPal, or your InstaPay wallet for payments.": "Wszechstronne opcje płatności: Wybierz spośród kart kredytowych, PayPal lub portfela InstaPay do płatności.",
    "Ready to spread joy or stay connected? Top-up now and make someone’s day—or your own!": "Gotowy, aby rozprowadzać radość lub pozostać w kontakcie? Doładuj teraz i uszczęśliw dnia kogoś — lub swój własny!",
    "Pick a language": "Wybierz język",
    "French": "Francuski",
    "Spanish": "Hiszpański",
    "Portuguese": "Portugalski",
    "German": "Niemiecki",
    "Ukrainian": "Ukraiński",
    "Italian": "Włoski",
    "Russian": "Rosyjski",
    "Arabic": "Arabski",
    "Polish": "Polski",
    "Dutch (Nederlands)": "Holenderski",
    "Yoruba": "Joruba",
    "Indonesian": "Indonezyjski",
    "Turkish": "Turecki",
    "Swahili": "Suahili",
    "Hausa": "Hausa",
    "Hindi": "Hindi",
    "Urdu": "Urdu",
    "Chinese": "Chiński",
    "Select Your Preferred Language": "Wybierz preferowany język",
    "Please select the language you would \nlike to use for the eKYC process.": "Wybierz język, którego chcesz używać do procesu eKYC.",
    "Select Your Nationality": "Wybierz swoją narodowość",
    "Choose your nationality to see the \nsupported ID documents for  your country.": "Wybierz swoją narodowość, aby zobaczyć obsługiwane dokumenty tożsamości dla twojego kraju.",
    "Grant permission to use the camera.": "Zezwól na użycie kamery.",
    "Tap or click \"Allow\" when prompted to \ngrant permission for camera access to \ncapture the document.": "Dotknij lub kliknij \"Zezwól\", gdy pojawi się monit o przyznanie dostępu do kamery w celu zrobienia zdjęcia dokumentu.",
    "Upload Your Documents from \nGallery/Photos": "Prześlij swoje dokumenty z galerii/zdjęć",
    "If you encounter issues with your camera \nor if your system doesn't have one, you can\n manually upload photos of the document \nfrom your local storage.": "Jeśli masz problemy z kamerą lub jeśli twój system jej nie ma, możesz ręcznie przesłać zdjęcia dokumentu z lokalnej pamięci.",
    "Upload Your ID.": "Prześlij swój dowód tożsamości.",
    "Make sure your ID is clear and not blurry to \nensure quick verification.": "Upewnij się, że twój dowód tożsamości jest wyraźny i nie rozmazany, aby zapewnić szybką weryfikację.",
    "Valid Proof of Address": "Ważny Dowód Adresu",
    "1. Ensure that your proof of address is \ncurrent and that the statement date is \nwithin the last three months from the \nstart date of your eKYC verification.\n\n2. Make sure your proof of address is clear \nand not blurry to ensure quick verification.": "1. Upewnij się, że twój dowód adresu jest aktualny i że data wyciągu jest w ciągu ostatnich trzech miesięcy od daty rozpoczęcia twojej weryfikacji eKYC.\n\n2. Upewnij się, że twój dowód adresu jest wyraźny i nie rozmazany, aby zapewnić szybką weryfikację.",
    "Important Notice\n\nPlease carefully review all the steps in this guideline before proceeding with your eKYC verification.\n\nAvoid Common Mistakes:\n\n* Ensure all your documents are clear, legible, and up-to-date.\n* Double-check that the information you provide matches the details on your official documents.\n* Follow the instructions precisely to avoid delays or rejections.\n\nNote: \nIf your eKYC verification fails and you need to resubmit, you will be charged again for the resubmission process.\n\nBy following these guidelines, you can help ensure a smooth and successful eKYC verification.\n\nFor any questions or assistance, please contact our support team at support@insta-pay.ch ": "Ważna informacja\n\nProszę dokładnie zapoznać się ze wszystkimi krokami tego przewodnika przed przystąpieniem do weryfikacji eKYC.\n\nUnikaj częstych błędów:\n\n* Upewnij się, że wszystkie twoje dokumenty są czytelne, wyraźne i aktualne.\n* Sprawdź, czy podane przez ciebie informacje są zgodne z danymi na twoich oficjalnych dokumentach.\n* Postępuj zgodnie z instrukcjami, aby uniknąć opóźnień lub odrzuceń.\n\nUwaga: \nJeśli twoja weryfikacja eKYC nie powiedzie się i będziesz musiał ją ponownie złożyć, zostaną naliczone dodatkowe opłaty za ponowne złożenie.\n\nPrzestrzegając tych wytycznych, możesz zapewnić płynne i udane przeprowadzenie weryfikacji eKYC.\n\nW przypadku pytań lub potrzebnej pomocy skontaktuj się z naszym zespołem wsparcia pod adresem support@insta-pay.ch",
    "Personal Transactions": "Transakcje osobiste",
    "Business Transactions": "Transakcje biznesowe",
    "Shopping & Purchases": "Zakupy i zakupy",
    "Bills & Utilities": "Rachunki i usługi",
    "Entertainment & Leisure": "Rozrywka i wypoczynek",
    "Investments & Savings": "Inwestycje i oszczędności",
    "Health & Wellness": "Zdrowie i dobre samopoczucie",
    "Transportation": "Transport",
    "Education": "Edukacja",
    "Miscellaneous": "Różne",
    "Family Support": "Wsparcie rodziny",
    "WALLET": "WALLET",
    "Add a currency": "Dodaj walutę",
    "When you add a currency, any payments you receive in that currency will be credited to your InstaPay balance. Your primary currency will be used for sending or requesting payments unless specified otherwise.": "Kiedy dodajesz walutę, wszystkie płatności, które otrzymasz w tej walucie, zostaną zaksięgowane na twoim saldzie InstaPay. Twoja główna waluta będzie używana do wysyłania lub żądania płatności, chyba że określono inaczej.",

    "balance amount ": "kwota salda",
    "referral ": "polecenie",
    "crypto wallet balance": "saldo portfela kryptowalut",
    "money in": "pieniądze przychodzące",
    "money out": "pieniądze wychodzące",
    "analytics": "analizy",
    "see more": "zobacz więcej",
    "manage my Wallets and see the transaction details": "zarządzać moimi Wallets i zobaczyć szczegóły moich transakcji",
    "set as default Wallet": "ustawić jako domyślny Wallet",
    "add currency": "dodaj walutę",
    "convert funds ": "konwertuj środki",
    "withdraw balance": "wypłać saldo",
    "chart names": "nazwy wykresów",
    "withdraw balance from PKR wallet": "wypłać saldo z portfela PKR",
    "select a payout channel": "wybierz kanał wypłaty",
    "you have entered an amount below the minimum payment range": "wpisałeś kwotę poniżej minimalnego zakresu płatności",
    "change": "zmienić",
    "edit": "edytować",
    "moving to next step in ...": "przejście do następnego kroku za ...",
    "you are withdrawing ...": "wypłacasz ...",
    "where is my payment": "gdzie jest moja płatność",
    "download": "pobierz",
    "IBAN / SWIFT Code": "IBAN lub kod SWIFT/BIC",
    "swift code": "kod SWIFT",
    "account number": "numer konta",
    "branch street": "ulica oddziału",
    "city": "miasto",
    "province": "województwo",
    "postal code": "kod pocztowy",
    "all payout channels": "wszystkie kanały wypłat",
    "bussiness beneficiary": "beneficjent biznesowy",
    "no quotation": "brak oferty",
    "no pending items": "brak oczekujących elementów",
    "QR status": "Status QR",
    "Portfolio": "Portfel",
    "edit whole portfolio page": "edytuj całą stronę portfela",
    "personal account": "konto osobiste",
    "Chart names": "Nazwy wykresów",
    "instagram": "instagram",
    "facebook": "facebook",
    "twitter": "twitter",
    "more": "więcej",
    "not connected": "nie połączony",
    "code": "kod",
    "ratings": "oceny",
    "current password": "obecne hasło",
    "include at least one uppercase and lowercase": "zawierać co najmniej jedną wielką i małą literę",
    "my social network accounts": "moje konta w sieciach społecznościowych",
    "SMS ": "SMS",
    "view identity verfication guide": "zobacz przewodnik weryfikacji tożsamości",
    "boimetric face recognition": "biometryczne rozpoznawanie twarzy",
    "please select a payer (withdrawl options)": "proszę wybrać płatnika (opcje wypłaty)",
    "IBAN number ": "numer IBAN",
    "account holder name": "nazwa posiadacza konta",
    "download data": "pobierz dane",
    "bank transfer": "przelew bankowy",
    "mobile wallet": "portfel mobilny",
    "all bank names": "wszystkie nazwy banków",
    "pay via card": "zapłać kartą",
    "you have entered an amount that is below the payer range": "wprowadziłeś kwotę poniżej zakresu płatnika",
    "trouble sending money": "problem z wysyłaniem pieniędzy",
    "please follow these example": "proszę postępować zgodnie z tymi przykładami",
    "wallet ID ": "ID Wallet",
    "username": "nazwa użytkownika",
    "email": "email",
    "phone number": "numer telefonu",
    "no user found": "nie znaleziono użytkownika",
    "search": "szukaj",
    "scan/upload QR code of the recipient": "zeskanuj/prześlij kod QR odbiorcy",
    "invite them": "zaprosić ich",
    "trouble quoting money": "problem z wyceną pieniędzy",
    "select a country": "wybierz kraj",
    "airtime details": "szczegóły doładowania",
    "enter the phone number": "wpisz numer telefonu",
    "service type": "typ usługi",
    "select a service": "wybierz usługę",
    "service list": "lista usług",
    "amount entered is less than the ....": "wprowadzona kwota jest mniejsza niż ....",
    "total": "całkowity",
    "total credit the receiver gets": "całkowity kredyt, który otrzymuje odbiorca",
    "take ": "weź",
    "OTP has been verified , You'll be redirected......": "OTP został zweryfikowany, zostaniesz przekierowany......",
    "Link Your Instagram Account to InstaPay": "Połącz swoje konto Instagram z InstaPay",
    "Step 01 – From Instagram App": "Krok 01 – Z aplikacji Instagram",
    "Open the Instagram app, search for the \"InstaPay\" profile page, select \"Message\" to open the chat box, type/say \"Hi\" and click send to initiate the conversation with the InstaPay chatbot.": "Otwórz aplikację Instagram, znajdź stronę profilu \"InstaPay\", wybierz \"Wiadomość\", aby otworzyć okno czatu, wpisz/powiedz \"Cześć\" i kliknij wyślij, aby rozpocząć rozmowę z chatbotem InstaPay.",
    "Step 02 – From InstaPay Instagram Profile Page ": "Krok 02 – Ze strony profilu Instagram InstaPay",
    "InstaPay chatbot will display two action buttons: \"Register\" and \"Connect.\" Select \"Connect,\" and the chatbot will prompt you to enter your InstaPay username.": "Chatbot InstaPay wyświetli dwa przyciski akcji: \"Zarejestruj się\" i \"Połącz\". Wybierz \"Połącz\" i chatbot poprosi Cię o wprowadzenie nazwy użytkownika InstaPay.",
    "Step 03 – From InstaPay Instagram Profile Page": "Krok 03 – Ze strony profilu Instagram InstaPay",
    "The InstaPay chatbot will then ask for the InstaPay verification code. Enter the code you obtained from the InstaPay settings under \"My Social Network Accounts\" and click send.": "Chatbot InstaPay poprosi o kod weryfikacyjny InstaPay. Wprowadź kod, który otrzymałeś w ustawieniach InstaPay w sekcji \"Moje konta w mediach społecznościowych\" i kliknij wyślij.",
    "Step 04 – From InstaPay Instagram Profile Page": "Krok 04 – Ze strony profilu Instagram InstaPay",
    "You will receive a congratulatory message confirming that your Instagram account is now synced with InstaPay. Click on \"Main Menu\" to start using InstaPay services.": "Otrzymasz wiadomość z gratulacjami potwierdzającą, że Twoje konto Instagram zostało teraz zsynchronizowane z InstaPay. Kliknij \"Menu główne\", aby rozpocząć korzystanie z usług InstaPay.",
    "Additional Tips": "Dodatkowe wskazówki",
    "1.\tSecure Your Account: Ensure your Instagram account is secure by enabling two-factor authentication (2FA) to protect your linked InstaPay services.": "1. Zabezpiecz swoje konto: Upewnij się, że Twoje konto Instagram jest zabezpieczone poprzez włączenie uwierzytelniania dwuskładnikowego (2FA), aby chronić powiązane usługi InstaPay.",
    "2.\tExplore More: Once linked, explore the wide range of services InstaPay offers, from seamless transactions to instant withdrawals.": "2. Odkryj więcej: Po połączeniu odkryj szeroką gamę usług oferowanych przez InstaPay, od bezproblemowych transakcji po natychmiastowe wypłaty.",
    "3.\tStay Updated: Keep an eye on our updates, as we will soon support linking other social media accounts to make your experience even more integrated.": "3. Bądź na bieżąco:Śledź nasze aktualizacje, ponieważ wkrótce będziemy wspierać łączenie innych kont w mediach społecznościowych, aby uczynić Twoje doświadczenie jeszcze bardziej zintegrowanym.",
    "4.\tHelp & Support: If you face any issues during the linking process, contact our support team for assistance.": "4. Pomoc i wsparcie:Jeśli napotkasz jakiekolwiek problemy podczas procesu łączenia, skontaktuj się z naszym zespołem wsparcia, aby uzyskać pomoc.",
    "Future Enhancements": "Przyszłe ulepszenia",
    "In the near future, InstaPay will allow you to link more social media accounts, providing you with even more flexibility and convenience. Stay tuned for exciting updates!": "W niedalekiej przyszłości InstaPay pozwoli na połączenie większej liczby kont w mediach społecznościowych, zapewniając jeszcze większą elastyczność i wygodę. Śledź ekscytujące aktualizacje!",
    "Personal Transactions": "Transakcje osobiste",
    "Business Transactions": "Transakcje biznesowe",
    "Shopping & Purchases": "Zakupy i zakupy",
    "Bills & Utilities": "Rachunki i usługi",
    "Entertainment & Leisure": "Rozrywka i wypoczynek",
    "Investments & Savings": "Inwestycje i oszczędności",
    "Health & Wellness": "Zdrowie i dobre samopoczucie",
    "Transportation": "Transport",
    "Education": "Edukacja",
    "Miscellaneous": "Różne",
    "Family Support": "Wsparcie rodziny",
    "Web - login with phone number": null,
    "Login": "Login",
    "Start Your InstaPay Journey Today!": "Rozpocznij swoją przygodę z InstaPay już dziś!",
    "Enter your phone number": "Wprowadź swój numer telefonu",
    "Login with email": "Zaloguj się za pomocą e-maila",
    "Don't have an account?": "Nie masz konta?",
    "Sign Up": "Zarejestruj się",
    "Continue": "Kontynuuj",
    "We recommend you download and install the Google Authenticator app": "Zalecamy pobranie i zainstalowanie aplikacji Google Authenticator",
    "Two-factor authentication (2FA) is the best way to protect yourself online.": "Uwierzytelnianie dwuskładnikowe (2FA) to najlepszy sposób na ochronę w Internecie.",
    "Strong security with Google Authenticator": "Silne zabezpieczenie z Google Authenticator",
    "Enter your password": "Wprowadź swoje hasło",
    "Web Mobile": null,
    "We recommend you download and install the Google Authenticator app to ensure you receive your verification codes instantly, avoiding any SMS delays or issues.": "Zalecamy pobranie i zainstalowanie aplikacji Google Authenticator, aby zapewnić natychmiastowe otrzymywanie kodów weryfikacyjnych, unikając opóźnień lub problemów z SMS.",
    "Web - Google Authenticator": null,
    "Google Authenticator": "Google Authenticator",
    "Two Factor Authentication Code": "Kod uwierzytelniania dwuskładnikowego",
    "Problem with the token?": "Problem z tokenem?",
    "Verify using SMS": "Użyj SMS do weryfikacji",
    "Verify": "Zweryfikować",
    "Web Mobile - Google Authenticator": null,
    "SMS - Web ": null,
    "SMS Verification": "Weryfikacja SMS",
    "Enter the SMS code sent to +41********4053": "Wprowadź kod SMS wysłany na +41********4053",
    "Verify using Authenticator App": "Użyj aplikacji Authenticator do weryfikacji",
    "Resend code in 120 seconds": "Wyślij kod ponownie za 120 sekund",
    "Verify ": "Zweryfikować",
    "SMS - Web Mobile": null,
    "My own account": "Moje własne konto",
    "Friends & Family": "Przyjaciele i Rodzina",
    "Aunt": "Ciotka",
    "Brother-in-law": "Szwagier",
    "Cousin": "Kuzyn",
    "Daughter": "Córka",
    "Father": "Ojciec",
    "Father-in-law": "Teść",
    "Friend": "Przyjaciel",
    "Grandfather": "Dziadek",
    "Grandmother": "Babcia",
    "Husband": "Mąż",
    "Mother": "Matka",
    "Mother-in-law": "Teściowa",
    "Nephew": "Siostrzeniec",
    "Niece": "Siostrzenica",
    "Self (i.e. the sender, himself)": "Ja sam (czyli nadawca)",
    "Sister": "Siostra",
    "Sister-in-law": "Szwagierka",
    "Son": "Syn",
    "Uncle": "Wujek",
    "Wife": "Żona",
    "Others not listed": "Inne niewymienione",
    "Merchant": "Sprzedawca",
    "No relationship": "Brak relacji",
    "Add Funds": "Dodaj środki",
    "Enter the Amount": "Wprowadź kwotę:",
    "Fees": "Opłaty:",
    "You Will Get": "Otrzymasz:",
    "⚠️ The transactional value should be between[Currency ISO code & Amount] - [Currency ISO code & Amount] ": "⚠️ Wartość transakcji powinna wynosić od [Currency ISO code & Amount] do [Currency ISO code & Amount]",
    "⚠️ Invalid Amount: Your current  identity verification level limits the  maximum amount you can add. Please upgrade your identity verification to benefit from higher limits.           ": "⚠️ Nieprawidłowa kwota: Twój obecny poziom weryfikacji tożsamości ogranicza maksymalną kwotę, jaką możesz dodać. Proszę zaktualizować weryfikację tożsamości, aby skorzystać z wyższych limitów.",
    "ℹ️ The price of currency changes frequently based on market conditions. Please refer to the price on the confirm order page as your final quotation": "ℹ️ Cena waluty często zmienia się w zależności od warunków rynkowych. Proszę odnieść się do ceny na stronie potwierdzenia zamówienia jako ostatecznej wyceny.",
    "Next": "Dalej",
    "Upgrade Identity Verification": "Zaktualizuj weryfikację tożsamości",
    "Request money from anyone, anywhere, including across social networking platforms. With InstaPay, your payment requests are instantly accessible once accepted and funds are sent to your Receivable Accounts. Simplify your transactions and manage payments with ease and security.": "Poproś o pieniądze od kogokolwiek, gdziekolwiek, w tym na platformach społecznościowych. Dzięki InstaPay twoje prośby o płatność są natychmiast dostępne po ich zaakceptowaniu, a środki są przesyłane na twoje konta odbiorcze. Uprość swoje transakcje i zarządzaj płatnościami z łatwością i bezpieczeństwem.",
    "You’ve been logged out.": "Zostałeś wylogowany.",
    "For your security, you’ve been logged out after 15 minutes of inactivity. Please log in again if you wish to use InstaPay.": "Dla twojego bezpieczeństwa zostałeś wylogowany po 15 minutach bezczynności. Zaloguj się ponownie, jeśli chcesz korzystać z InstaPay.",
    "LOGIN AGAIN": "ZALOGUJ SIĘ PONOWNIE",
    "Brother": "Brat",
    "Current Password": "Obecne hasło",
    "The new password cannot be the same as the old one": "Nowe hasło nie może być takie samo jak stare.",
    "Minimum password length: [X] characters ": "Minimalna długość hasła: [X] znaków",
    "Include at least one uppercase and one lowercase letter.": "Uwzględnij co najmniej jedną wielką i jedną małą literę.",
    "Nominee Information": "Informacje o nominowanym",
    "Verification Code Preferences": "Preferencje kodu weryfikacyjnego",
    "How Others Can Find You": "Jak inni mogą Cię znaleźć",
    "My Social Network Accounts": "Moje konta w sieciach społecznościowych",
    "Login Activity": "Aktywność logowania",
    "Receiving Accounts": "Konta odbiorcze",
    "Change Your Password To Continue": "Zmień hasło, aby kontynuować",
    "Continue with": "Kontynuuj z",
    "Back to Login": "Wróć do logowania",
    "Send OTP": "Wyślij OTP",
    "A Verification code has been sent to your email and number.": "Kod weryfikacyjny został wysłany na Twój email i numer.",
    "Resend available in": "Ponowne wysłanie dostępne za",
    "Create a strong Password": "Stwórz silne hasło",
    "Password must:": "Hasło musi:",
    "Be between 9 and 64 characters": "Mieć od 9 do 64 znaków",
    "Include at least two of the following:": "Zawierać co najmniej dwa z następujących:",
    "Lowercase Character": "Mała litera",
    "Special Character": "Znak specjalny",
    "Password Updated Successfully!": "Hasło zostało zaktualizowane pomyślnie!",
    "Click below to login": "Kliknij poniżej, aby się zalogować",

    "Send Money": "Wyślij pieniądze",
    "Enter the amount": null,
    "Recent Transfer": "Ostatnie przelewy",
    "See all": "Zobacz wszystko",
    "Send again": "Wyślij ponownie",
    "You Send": "Wysyłasz",
    "They Receive": "Oni otrzymują",
    "Receive Method": "Metoda odbioru",
    "Select method": "Wybierz metodę",
    "Next": "Dalej",
    "Trouble sending money?": "Problemy z wysyłaniem pieniędzy?",
    "Where do you want to send money ?": "Gdzie chcesz wysłać pieniądze?",
    "Search ": "Szukaj",
    "Confirm": "Potwierdź",
    "Bank Deposit": "Depozyt Bankowy",
    "Cash Pick-up": "Odbiór Gotówki",
    "Mobile Wallet": "Mobile Wallet",
    "Bank Card": "Karta Bankowa",
    "Crypto Wallet": "Wallet crypto",
    "What is the receiver’s Mobile Wallet?": "Jaki jest Wallet mobilny odbiorcy?",
    "Transfer fees": "Opłaty za przelew",
    "Total to pay": "Łączna kwota do zapłaty",
    "Choose the beneficiary!": "Wybierz beneficjenta!",
    "Country": "Kraj",
    "Receive method": "Metoda odbioru",
    "You send": "Wysyłasz",
    "change": "zmień",
    "edit": "dodaj nowy",
    "add new": "Beneficjenci",
    "Beneficiaries": "Zarządzaj beneficjentami",
    "Manage Beneficiaries": "Szukaj",
    "Search": "Dalej",
    "CASH PICK-UP": "ODBIÓR GOTÓWKI",
    "Where will your receiver collect the money": "Gdzie odbiorca odbierze pieniądze?",
    "Cash is available to collect within minutes from any branch of [name] ": "Gotówka jest dostępna do odbioru w ciągu kilku minut w dowolnym oddziale [nazwa]",
    "BANK ACCOUNT": "KONTO BANKOWE",
    "Transfer to recipient’s bank account": "Przelew na konto bankowe odbiorcy",
    "BANK CARD": "KARTA BANKOWA",
    "Fast transfer directly to recipient’s bank card": "Szybki przelew bezpośrednio na kartę bankową odbiorcy",
    "CRYPTO WALLET": "WALLET CRYPTO",
    "Fast transfer directly to a crypto wallet": "Szybki przelew bezpośrednio na Wallet crypto",
    "Review transfer": "Przegląd przelewu",
    "Receiver's Details": "Dane odbiorcy",
    "Edit": "Edytuj",
    "Name": "Imię",
    "Reason for sending": "Powód wysyłki",
    "Sending": "Wysyłka",
    "Receiver gets": "Odbiorca otrzymuje",
    "Exchange rate": "Kurs wymiany",
    "Estimated time": "Szacowany czas",
    "Select Payment Type": "Wybierz rodzaj płatności",
    "Credit cards": "Karty kredytowe",
    "Domestic cards": "Karty krajowe",
    "Mobile Wallets": "Wallet mobilne",
    "InstaPay Wallet": "Wallet InstaPay",
    "Crypto (USDT, ETH, BTC)": "Crypto (USDT, ETH, BTC)",
    "PayPal": "PayPal",
    "CREDIT CARD": "KARTA KREDYTOWA",
    "Credit Card": "Karta kredytowa",
    "Select card": "Wybierz kartę",
    "Card Number": "Numer karty",
    "Enter the 16-digit card number on the card": "Wprowadź 16-cyfrowy numer karty",
    "Expiry Date": "Data wygaśnięcia",
    "Enter the expiration date of the card": "Wprowadź datę wygaśnięcia karty",
    "CVV Number": "Numer CVV",
    "Enter the 3 or 4 digit number on the card": "Wprowadź 3- lub 4-cyfrowy numer na karcie",
    "INSTAPAY WALLET": "WALLET INSTAPAY",
    "Select Wallet": "Wybierz Wallet",
    "My Wallet": "Mój Wallet",
    "Conversion": "Konwersja",
    "Convert": "Konwertuj",
    "Your payment is confirmed": "Twoja płatność została potwierdzona",
    "Thanks for using InstaPay": "Dziękujemy za korzystanie z InstaPay",
    "Status": "Status",
    "Share": "Udostępnij",
    "Transfer created": "Przelew utworzony",
    "Payment received": "Płatność otrzymana",
    "Payment processed": "Płatność przetworzona",
    "Transfer successful": "Przelew udany",
    "It may take few hours for the funds to appear in [user name] account": "Może minąć kilka godzin, zanim środki pojawią się na koncie [nazwa użytkownika]",
    "Transfer failed": "Przelew nieudany",
    "Transaction Details": "Szczegóły transakcji",
    "Need help?": "Potrzebujesz pomocy?",
    "Service Details": "Szczegóły Usługi",
    "Funds Deposit Speed": "Dostępność Środków",
    "Cut-off Time": "Czas Zamknięcia",
    "Bank holiday list": "Lista Świąt Bankowych",
    "Threshold limits": "Limity Transakcyjne",
    "null": null,
    "Instant, Real Time": "Natychmiastowy, w czasie rzeczywistym",
    "Same day, T+1": "Tego samego dnia, T+1",
    "Non-instant, T+1": "Nienatychmiastowy, T+1",
    "Instant Payment, Real Time": "Natychmiastowa płatność, w czasie rzeczywistym",
    "Transaction processed before 3:00 PM Local time": "Transakcja przetworzona przed 15:00 czasu lokalnego",
    "Delivered the same day": "Dostarczone tego samego dnia",
    "Transactions processed post 3:00 PM": "Transakcje przetworzone po 15:00",
    "T+1 business days": "T+1 dni robocze",
    "Payments made on weekdays before 3:00PM will be credited to the receiving bank account by midnight of the\nsame day.": "Płatności dokonane w dni robocze przed 15:00 zostaną zaksięgowane na koncie bankowym odbiorcy do północy tego samego dnia.",
    "Payments made on weekdays after 15:00 will be credited by midnight the following day.": "Płatności dokonane w dni robocze po 15:00 zostaną zaksięgowane do północy następnego dnia.",
    "Payments made on a Saturday, Sunday or Public holiday will be credited to the account by midnight of the 1st following business day": "Płatności dokonane w sobotę, niedzielę lub święto zostaną zaksięgowane na koncie do północy następnego dnia roboczego.",
    "24*7": "24*7",
    "09hrs - 18 hrs Local Time": "09:00 - 18:00 czasu lokalnego",
    "https://www.officeholidays.com/countries ": "https://www.officeholidays.com/countries",
    "Min per transaction limit:": "Minimalny limit na transakcję:",
    "Maximum per transaction limit:": "Maksymalny limit na transakcję:",
    "Daily threshold limit:": "Dzienne limity:",
    "Weekly threshold limit:": "Tygodniowe limity:",
    "Monthly threshold limit:": "Miesięczne limity:",
    "Yearly threshold limit:": "Roczne limity:",
    "P2P": "P2P",
    "P2B": "P2B",
    "B2P": "B2P",
    "B2B": "B2B",
    "WALLET TO WALLET": "WALLET TO WALLET",
    "Basic Account (Unverified)": "Podstawowe konto (Niezweryfikowane)",
    "Identity Verified": "Tożsamość zweryfikowana"

}