export const es = {
    "How Others Can Find You": "Cómo otros pueden encontrarte",
    "Schedules": "Horarios",
    "Login": "Iniciando sesión",
    "Start Your InstaPay Journey Today!": "¡Inicia tu viaje con Instapay hoy!",
    "Phone Number or Email": "Número de teléfono/Correo electrónico",
    "Login Using Social Media": "Iniciar sesión con redes sociales",
    "Continue": "Continuar",
    "Don't Have an Account? Sign Up": "¿No tienes una cuenta?",
    "Sign Up": "Regístrate",
    "Edit": "Editar",
    "Enter Your Password": "Ingresa tu contraseña",
    "Forgot Password?": "¿Olvidaste la contraseña?",
    "Account Type": "Tipo de cuenta",
    "Individual": "Individual",
    "Business": "Negocio",
    "Already Have an Account? Sign In": "¿Ya tienes una cuenta?",
    "Sing In": "Iniciar sesión",
    "Enter Your Personal Details": "Ingresa tus datos personales",
    "Add Photo or Logo": "Añadir foto o logotipo",
    "Enter Your Name": "Ingresa tu nombre",
    "Back": "Regresar",
    "Phone Number": "Número de teléfono",
    "Email Address": "Dirección de Correo Electrónico",
    "Create a Strong Password": "Crea una contraseña fuerte",
    "Password Requirements": "Requisitos de Contraseña",
    "Must be between 9 and 64 characters": "Tener entre 9 y 64 caracteres",
    "Include at least two of the following:": "Incluir al menos dos de los siguientes:",
    "Uppercase character": "Carácter en mayúscula",
    "Lowercase character": "Carácter en minúscula",
    "Number": "Número",
    "Special character": "Carácter especial",
    "Confirm Password": "Confirmar contraseña",
    "Finalize Your Process": "Finaliza tu proceso",
    "Referral Code (Optional)": "Código de referencia (Opcional)",
    "It will autofill if you're signing up with an invitation link.": "Se autocompletará si te registras con un enlace de invitación.",
    "I agree with": "Estoy de acuerdo con",
    "Terms & Conditions": "Términos y condiciones",
    "Privacy Policy": "Política de privacidad",
    "Create Your Account": "Crea tu cuenta",
    "Enter Code": "Ingresa el código",
    "A one-time verification code has been sent to your email address  and phone number.": "Se ha enviado un código de verificación de un solo uso a su dirección de correo electrónico dirección y número de teléfono.",
    "Resend available in ": "Reenvío disponible en",
    "seconds": "segundos",
    "Verify": "Verificar",
    "Verification Code Verified": "Código de verificación verificado",
    "Please continue to the next step.": "Por favor, continúa al siguiente paso.",
    "Finish": "Finalizar",
    "Finishing": "Finalizando",
    "Company Name": "Nombre de la empresa",
    "Enter Your Business Details": "Ingresa los detalles de tu empresa",
    "Country ": "País",
    "You cannot change the country once your account is verified.": "No puedes cambiar el país una vez que tu cuenta esté verificada.",
    "Learn more": "Aprender más",
    "Optional": "Opcional",
    "Continuing": "Continuando",
    "Didn't receive the verification code?": "¿No has recibido el código de verificación?",
    "Resend Now": "Reenviar ahora",
    "Search Here": "Buscar aquí",
    "Search for users, services, and more on InstaPay": "Busca usuarios, servicios y más en InstaPay",
    "Search users here": "Buscar usuarios aquí",
    "Earn money with our affiliate program!": "Gane dinero con nuestro programa de afiliados",
    "Generate passive income by sharing your InstaPay affiliate code with your followers. For each transaction they make, \nyou earn a commission, thereby maximizing the profits from your online influence.": "Genera ingresos pasivos compartiendo tu código afiliado de InstaPay con tus seguidores. \nPor cada transacción que realicen, ganarás una comisión, maximizando así las ganancias de tu \ninfluencia en línea.",
    "Learn More": "Aprender más",
    "Balance Amount": "Cantidad de saldo",
    "You spent less compared to last month.": "Gastaste menos en comparación con el mes pasado.",
    "Referral Rewards": "Recompensas por Referencia",
    "Bitcoin": "Bitcoin",
    "USDT": "USDT",
    "Ethereum": "Ethereum",
    "Quick Transfer to Your Most Recent Contacts:": "Transferencia rápida a tus contactos más recientes:",
    "Number of Transactions": "Número de transacciones",
    "Total Transaction Amount": "Monto total de la transacción",
    "Total Sent": "Total enviado",
    "Total Received": "Total recibido",
    "Add Funds": "Añadir fondos",
    "Send Money": "Enviar dinero",
    "Request Money": "Solicitar Dinero",
    "Send a Quote": "Enviar una cotización",
    "Pay With Crypto": "Pagar con cripto",
    "Payment Insights": "Perspectivas de pago",
    "Recent Transactions ": "Transacciones recientes",
    "Today": "Hoy",
    "My Wallets": "Mis Wallets",
    "Balance": "Saldo",
    "Wallet ID": "ID de Wallet",
    "Monthly Limit": "Límite mensual",
    "Top Up": "Recargar",
    "Summary": "Resumen",
    "Settings": "Configuraciones",
    "More": "Más",
    "Upgrade to Business Account": "Actualiza a cuenta de negocio",
    "Conversion": "Conversión",
    "Enter Amount": "Introducir cantidad",
    "Exchanged Amount": "Monto de cambio",
    "Convert": "Convertir",
    "You Are Converting": "Estás convirtiendo",
    "From": "De",
    "To": "A",
    "Exchange Rate": "Tipo de cambio",
    "Fees": "Tarifas",
    "You Will Receive": "Recibirás",
    "Slide To Confirm": "Deslizar para confirmar",
    "Verification Code Confirmed - You'll be redirected to the next step": "Código de verificación confirmado - Serás redirigido al siguiente paso",
    "Moving to next step in": "Pasando al siguiente paso en",
    "Thanks for using InstaPay": "Gracias por usar InstaPay",
    "Your funds have been credited to your wallet.": "Tus fondos han sido acreditados a tu Wallet.",
    "Dashboard": "Panel de control",
    "Accounts": "Cuentas",
    "Transactions": "Transacciones",
    "Other Transactions": "Otras transacciones",
    "Payments": "Pagos",
    "Beneficiaries": "Beneficiarios",
    "Referrals": "Referencias",
    "Quotations": "Cotizaciones",
    "Pending": "Pendiente",
    "My QR Code Sticker": "Mi pegatina de código QR",
    "My Portfolio": "Mi portafolio",
    "My Payment Address": "Mi dirección de pago",
    "Analytics": "Análisis",
    "Profile": "Perfil",
    "Dark Mode": "Modo oscuro",
    "Support": "Soporte",
    "Logout": "Cerrar sesión",
    "Powered By": "Desarrollado por",
    "Kemit Kingdom SA": "Kemit Kingdom SA",
    "A Swiss Company": "Una compañía suiza de Fintech",
    "MAIN MENU": "MENÚ PRINCIPAL",
    "OTHERS": "OTROS",
    "Wallet": "Wallet",
    "Default": "Predeterminado",
    "Breakdown": "Desglose",
    "Credit": "Crédito",
    "Debit": "Débito",
    "Wallet Management": "Gestión de Wallet",
    "Top-up Your Wallet": "Recargar tu Wallet",
    "Download Statement": "Descargar estado de cuenta",
    "Block the Wallet": "Bloquear la Wallet",
    "Wallet Status": "Estado de la Wallet",
    "-Select-File-Type-": "-Seleccionar-Tipo-de-Archivo-",
    "Download Now": "Descargar ahora",
    "Downloading": "Descargando",
    "All": "Todo",
    "Sent": "Enviado",
    "Received": "Recibido",
    "Requested": "Solicitado",
    "Quoted": "Cotizado",
    "Search Transactions": "Buscar transacciones",
    "Date & Time": "Fecha y hora",
    "Type": "Tipo",
    "Transaction ID": "ID de transacción",
    "Recipient": "Destinatario",
    "Amount": "Monto",
    "Status": "Estado",
    "Payment Type": "Tipo de pago",
    "Sent Payments": "Pagos enviados",
    "Received Payments": "Pagos recibidos",
    "Date": "Fecha",
    "Cycles/Time": "Ciclos/Tiempo",
    "Payment As": "Pago como",
    "Name": "Nombre",
    "Cancel": "Cancelar",
    "Subscriptions & Scheduled": "Suscripciones y programados",
    "Select the option that matches your needs from the list below:": "Selecciona la opción que se ajuste a tus necesidades de la lista a continuación:",
    "Easily send money to friends, family, or businesses locally or internationally. \nChoose from multiple transfer options, including bank deposits, mobile wallets, \ncash pick-up, payment cards, and wallet-to-wallet transfers. Enjoy the convenience \nand security of InstaPay for seamless and instant money transfers.": "Envía dinero fácilmente a amigos, familiares o negocios local o internacionalmente. Elige entre \nmúltiples opciones de transferencia, incluyendo depósitos bancarios, mobile wallets, retiro en efectivo, \ntarjetas de pago y transferencias de wallet a wallet. Disfruta de la conveniencia y seguridad de \nInstaPay para transferencias de dinero instantáneas y sin problemas",
    "International Transfer": "Transferencia Internacional",
    "Send money across borders with ease. Transfer funds internationally \nto friends, family, or businesses in just a few clicks. Choose from \na wide range of payment channels including bank account, \nmobile money/mobile wallet, cash pick-up location, or payment card \nfor convenient and secure transactions.": "Envía dinero a través de fronteras con facilidad. Transfiere fondos internacionalmente\na amigos, familiares o negocios en solo unos clics. Elige entre una amplia gama de canales de pago \nincluyendo cuenta bancaria, dinero móvil/mobile wallet, ubicación de retiro en efectivo o tarjeta de pago\npara transacciones convenientes y seguras.",
    "Select the country where you want to send the money!": "¡Selecciona el país a donde quieres enviar el dinero!",
    "Search Country": "Buscar País",
    "Trouble Sending Money?": "¿Problemas enviando dinero?",
    "Confirm": "Confirmar",
    "Select Payout Channel": "Selecciona el Canal de Pago",
    "Select your most convenient option to send the money.": "Selecciona tu opción más conveniente para enviar el dinero.",
    "Select The Bank": "Selecciona el Banco",
    "Search Bank": "Buscar Banco",
    "Send money directly to a recipient's bank account. Enjoy the convenience \nand security of transferring funds seamlessly to any bank around the world.": "Envía dinero directamente a la cuenta bancaria del destinatario. Disfruta de la conveniencia\ny seguridad de transferir fondos sin problemas a cualquier banco alrededor del mundo.",
    "Bank Transfer": "Transferencia Bancaria",
    "Enter the amount": "Ingresa el monto",
    "Tax": "Impuesto",
    "Amount the recipient will receive": "Monto que recibirá el destinatario",
    "Select Remarks / Reason": "Selecciona Observaciones / Motivo",
    "Comments": "Comentarios",
    "Attach Files": "Adjuntar Archivos",
    "Groups": "Grupos",
    "Manage Groups": "Administrar Grupos",
    "Add New Group": "Añadir Nuevo Grupo",
    "Invite": "Invitar a una Persona",
    "Manage Beneficiaries": "Administrar Beneficiarios",
    "Recipients List": "Lista de Destinatarios",
    "Search Contacts": "Buscar Contactos",
    "Confirm Your Payment": "Confirmar tu Pago",
    "Select Country": "Seleccionar País",
    "Select the Recipient's Payment Channel": "Selecciona el Canal de Pago del Destinatario",
    "Change Selection": "Cambiar Selección",
    "You Are Transferring ": "Estás Transfiriendo",
    "Oops! Something Went Wrong.": "¡Ups! Algo salió mal.",
    "We're Sorry About That. ": "Lo sentimos por eso.",
    "Go back to Payments Page.": "Regresar a la Página de Pagos.",
    "Wallet to Wallet Transfer": "Transferencia de Wallet a Wallet",
    "Request a customized price quote from service providers or vendors.": "Solicita una cotización personalizada de proveedores o vendedores.",
    "Enter the Wallet ID": "Ingresa el ID de Wallet",
    "Enter the Wallet ID, Email, or Phone Number of the recipient.": "Ingresa el ID de Wallet, Email o Número de Teléfono del destinatario.",
    "Recipient not on InstaPay?": "¿El Destinatario no está en InstaPay? Invítalos.",
    "Invite Them": "Invitarlos",
    "My Beneficiaries": "Mis Beneficiarios",
    "Enter the Amount You Wish to Transfer.": "Ingresa el Monto que Deseas Transferir.",
    "Sending to ": "Enviando a",
    "You Have Selected Wallet to Wallet Transfer": "Has Seleccionado Transferencia de Wallet a Wallet",
    "Authenticate Your Payment.": "Autentica tu Pago.",
    "You are transferring": "Estás transfiriendo",
    "Total ": "Total",
    "Where Is My Payment?": "¿Dónde Está Mi Pago?",
    "Share": "Compartir",
    "Your Payment Is Confirmed": "Tu Pago Está Confirmado",
    "QR Code Payment": "Pago con Código QR",
    "Instantly transfer money to other InstaPay users with our secure wallet-to-wallet \nservice. Send and receive funds hassle-free within seconds.": "Transfiere dinero instantáneamente a otros usuarios de InstaPay con nuestro servicio seguro de wallet a wallet.\nEnvía y recibe fondos sin problemas en segundos.",
    "Scan QR with Your Camera or Upload from Your Device.": "Escanea el QR con tu cámara o sube desde tu dispositivo.",
    "Upload from Your Device.": "Subir desde tu Dispositivo.",
    "Alternatively, Use the InstaPay Unique Payment Address (UPA)": "Alternativamente, Usa la Dirección de Pago Única de InstaPay (UPA)",
    "Enter InstaPay UPA": "Ingresa InstaPay UPA",
    "Recipient not on InstaPay? Invite Them": "¿El Destinatario no está en InstaPay? Invítalos",
    "Verify QR Code": "Verificar Código QR",
    "Scan/Upload QR Code of the Recipient": "Escanea/Sube el Código QR del Destinatario",
    "Payment Confirmed": "Pago Confirmado",
    "Request customized price quotes from service providers or vendors to receive \naccurate cost estimates for the services or products you need.": "Solicita cotizaciones de precio personalizadas de proveedores o vendedores para recibir estimaciones de costos \nprecisas para los servicios o productos que necesitas.",
    "Choose The Beneficiary or Group": "Elige el Beneficiario o Grupo",
    "Enter the Amount You Wish to Request.": "Ingresa el Monto que Deseas Solicitar.",
    "You Are Requesting Money": "Estás Solicitando Dinero",
    "You are requesting": "Estás solicitando",
    "As": "Como",
    "Conversion of Your Cryptocurrencies": "Conversión de Tus Criptomonedas",
    "Experience our user-centric, secure, and efficient Cash-Out Crypto to Fiat Conversion.": "Experimenta nuestra conversión de cripto a fiat segura, eficiente y centrada en el usuario.",
    "Choose the Cryptocurrency You Intend to Liquidate.": "Elige la Criptomoneda que Piensas Liquidar.",
    "Next": "Siguiente",
    "Enter the Crypto Wallet Address of the Recipient.": "Ingresa la Dirección de Wallet de Cripto del Destinatario.",
    "Minimum Transactional Value: $100.": "Valor Mínimo de Transacción: $100.",
    "Note: Crypto prices frequently change. Refer to the final quotation on the confirm order page.": "Nota: Los precios de cripto cambian frecuentemente. Consulta la cotización final en la página de confirmar pedido.",
    "Select Network": "Selecciona la Red",
    "Send A Quote": "Enviar una Cotización",
    "Send a detailed price quote to your clients or customers. Present your services, products, \nor offers professionally with a personalized quotation.": "Envía una cotización de precio detallada a tus clientes o consumidores. Presenta tus servicios, productos,\no ofertas profesionalmente con una cotización personalizada.",
    "Choose the Beneficiary": "Elige al Beneficiario",
    "Allow Receiver to Bargain": "Permitir al Receptor Regatear",
    "Enter the Title for Your Quotation.": "Ingresa el Título para tu Cotización.",
    "Enter the Description for Your Quotation": "Ingresa la Descripción para tu Cotización",
    "You are Quoting": "Estás Cotizando",
    "Title": "Título",
    "Description": "Descripción",
    "Attachments": "Adjuntos",
    "Confirm Your Quotation": "Confirmar tu Cotización",
    "Verify The Quotation": "Verificar la Cotización",
    "Your quotation is confirmed": "Tu cotización está confirmada",
    "Request A Quote": "Solicitar una Cotización",
    "Coming Soon": "Próximamente",
    "Add Beneficiary": "Añadir Beneficiario",
    "Search for Beneficiaries": "Buscar Beneficiarios",
    "Connected Accounts": "Cuentas Conectadas",
    "Details": "Detalles",
    "First Name": "Nombre",
    "Last Name": "Apellido",
    "Country": "País",
    "City": "Ciudad",
    "Address Line": "Línea de Dirección",
    "Mobile Number": "Número Móvil",
    "Relationship With Beneficiary": "Relación con el Beneficiario",
    "Bank Account": "Cuenta Bancaria",
    "Mobile Money/Mobile Wallet": "Dinero Móvil/Mobile Wallet",
    "Payment Card": "Tarjeta de Pago",
    "Crypto Wallet": "Wallet de Cripto",
    "InstaPay Account": "Cuenta InstaPay",
    "Cash Pickup": "Retiro en Efectivo",
    "IBAN / SWIFT Code": "Código IBAN o Swift/BIC",
    "Swift Code": "Código Swift",
    "Account Number": "Número de Cuenta",
    "Bank Name": "Nombre del Banco",
    "Branch Name": "Nombre de la Sucursal",
    "Branch Street": "Calle de la Sucursal",
    "Province": "Provincia",
    "Postal Code": "Código Postal",
    "Mobile Money Provider": "Proveedor de Dinero Móvil",
    "Wallet Name": "Nombre del Wallet",
    "Wallet Number": "Número del Wallet",
    "Card Holder Name": "Nombre del Titular de la Tarjeta",
    "Card Number": "Número de Tarjeta",
    "Expiry Date": "Fecha de Expiración",
    "Crypto Currency": "Criptomoneda",
    "Wallet Address": "Dirección del Wallet",
    "Wallet Holder Name": "Nombre del Titular del Wallet",
    "Wallet Currency": "Moneda del Wallet",
    "Select Document Type": "Selecciona Tipo de Documento",
    "Enter Document Number": "Ingresa Número de Documento",
    "Add Individual Account": "Añadir Cuenta Individual",
    "Add Business Account": "Añadir Cuenta Empresarial",
    "Company Address": "Dirección de la Empresa",
    "Company Email": "Correo Electrónico de la Empresa",
    "Company Phone No": "Teléfono de la Empresa",
    "Total Referrals": "Total de Referencias",
    "My Earnings": "Mis Ganancias",
    "Your Referral ID": "Tu ID de Referencia",
    "Share Your Unique Referral Link": "Comparte Tu Enlace Único de Referencia de InstaPay",
    "How do I Refer a Friend to InstaPay?": "¿Cómo Refiero a un Amigo a InstaPay?",
    "Step 01": "Paso 01",
    "Share your InstaPay unique referral link with your friends.": "Comparte tu enlace único de referencia de InstaPay con tus amigos.",
    "Step 02": "Paso 02",
    "Earn rewards when they transact $100 or more": "Gana recompensas cuando realicen transacciones de $100 o más",
    "Step 03": "Paso 03",
    "Earn up to $0.06 for every transaction your followers make.": "Gana hasta $0.06 por cada transacción que realicen tus seguidores.",
    "My Referrals": "Mis Referencias",
    "Referral Complete": "Referencia Completa",
    "Received Quotations": "Cotizaciones Recibidas",
    "Sent Quotations": "Cotizaciones Enviadas",
    "Search Pending Items": "Buscar Elementos Pendientes",
    "Actions": "Acciones",
    "Received Requests": "Solicitudes Recibidas",
    "Sent Requests": "Solicitudes Enviadas",
    "No Actions": "Sin Acciones",
    "You Requested": "Solicitaste",
    "Via": "Vía",
    "How was your experience?": "¿Cómo fue tu experiencia?",
    "Leave a Review": "Deja una Reseña",
    "Overall Ratings": "Calificaciones Generales",
    "Visit Profile": "Visitar Perfil",
    "Ratings": "Calificaciones",
    "Reviews": "Comentarios",
    "No reviews available": "No hay reseñas disponibles",
    "Select Your Currency": "Selecciona tu Moneda",
    "Proceed": "Proceder",
    "Commision": "Comisión",
    "Unlock Seamless Transactions with InstaPay QR Code Sticker": "Desbloquea Transacciones Sin Esfuerzo con el Sticker de Código QR de InstaPay",
    "Effortless and Swift:": "Rápido y Sencillo:",
    "Instantly download your unique InstaPay QR code sticker.": "Descarga instantáneamente tu sticker único de código QR de InstaPay.",
    "Experience hassle-free, cashless payments without needing complex infrastructure like \npayment terminals.": "Experimenta pagos sin efectivo y sin problemas, sin necesidad de infraestructura compleja como\nterminales de pago.",
    "Swiftly collect payments – no more tedious entry of credit card or phone numbers.": "Cobra pagos rápidamente - se acabó la tediosa entrada de números de tarjeta de crédito o teléfono.",
    "Each payment includes customer information for your convenience.": "Cada pago incluye información del cliente para tu conveniencia.",
    "Economically Smart:": "Económicamente Inteligente:",
    "Zero activation fees – start immediately without upfront costs.": "Cero tarifas de activación - comienza de inmediato sin costos iniciales.",
    "Forget about fixed monthly charges; pay as you transact.": "Olvídate de cargos mensuales fijos; paga mientras transaccionas.",
    "Ditch the hardware; no terminal costs mean more savings for you.": "Deshazte del hardware; sin costos de terminal significa más ahorros para ti.",
    "Enjoy low transaction fees at just 0.75% per QR code payment.": "Disfruta de bajas tarifas de transacción de solo el 0.75% por pago con código QR.",
    "We don't impose minimum commissions; you save more with each transaction.": "No imponemos comisiones mínimas; ahorras más con cada transacción.",
    "Experience the joy of instant payments, enhancing your cash flow.": "Experimenta la alegría de pagos instantáneos, mejorando tu flujo de efectivo.",
    "Make Every Transaction Count with InstaPay": "Haz que Cada Transacción Cuente con InstaPay",
    "Wallet QR": "Wallet QR",
    "Download QR": "Descargar QR",
    "QR Code Status": "Estado del Código QR",
    "Share your QR Code or payment address link to instantly receive payments in your InstaPay wallet.": "Comparte tu código QR o enlace de dirección de pago para recibir pagos instantáneamente en tu Wallet de InstaPay.",
    "QR Code Title": "Título del Código QR",
    "View Transactions": "Ver Transacciones",
    "My QR Codes:": "Mis Códigos QR:",
    "Load More": "Cargar Más",
    "Benefits Of Portfolio": "Beneficios del Portafolio",
    "Benefits:": "Beneficios:",
    "Enhanced Visibility:": "Visibilidad Mejorada:",
    "Elevate your public profile. Your portfolio acts as a dynamic showcase, accessible \ndirectly from your Payment Address.": "Eleve su perfil público. Su portafolio actúa como una vitrina dinámica, accesible\ndirectamente desde su Dirección de Pago.",
    "Engage Your Audience:": "Involucra a Tu Audiencia:",
    "Connect with visitors through your creative or professional journey. Let your work \nspeak for you, creating a deeper engagement with potential clients or supporters.": "Conéctate con visitantes a través de tu viaje creativo o profesional. Deja que tu trabajo\nhable por ti, creando un compromiso más profundo con posibles clientes o seguidores.",
    "Integrated Social Proof:": "Prueba Social Integrada:",
    "Display your social media influence. Visitors can see your social media reach, \nadding credibility and context to your portfolio.": "Muestra tu influencia en redes sociales. Los visitantes pueden ver tu alcance en redes sociales,\nañadiendo credibilidad y contexto a tu portafolio.",
    "Seamless Transactions:": "Transacciones Sin Esfuerzo:",
    "Enable visitors to request or initiate payments right from your public page. Your \nportfolio not only showcases your work but also facilitates easy financial interactions.": "Permite a los visitantes solicitar o iniciar pagos directamente desde tu página pública. Tu\nportafolio no solo muestra tu trabajo, sino que también facilita interacciones financieras fáciles.",
    "Personalized Storytelling:": "Narración Personalizada:",
    "Use the 'About Me' section to share your story, mission, or vision, creating a\n personal connection with your audience.": "Usa la sección 'Sobre Mí' para compartir tu historia, misión o visión, creando una conexión personal con tu \naudiencia.",
    "My Portfolio' is more than just a gallery; it's a tool to build your brand, engage your \naudience, and streamline your financial interactions on InstaPay.": "Mi Portafolio' es más que solo una galería; es una herramienta para construir tu marca, involucrar a tu\naudiencia y agilizar tus interacciones financieras en InstaPay.",
    "Showcase Your Work, Amplify Your Reach. 'My Portfolio' is your digital canvas on \nInstaPay. Upload and display a diverse range of content – from captivating images \nand videos to informative PDFs. Whether you're an artist, freelancer, or \nentrepreneur, this feature lets you present your work or projects in a visually \nengaging way.": "Muestra Tu Trabajo, Amplifica Tu Alcance. 'Mi Portafolio' es tu lienzo digital en InstaPay. Sube y muestra una \namplia gama de contenido – desde imágenes cautivadoras y videos hasta PDFs informativos. Ya seas un artista, \nfreelancer o emprendedor, esta característica te permite presentar tu trabajo o proyectos de una manera\nvisualmente atractiva.",
    "Add/Manage Portfolio": "Añadir/Gestionar Portafolio",
    "Add New": "Añadir Nuevo",
    "Maximize Your Social Media Earnings with Your InstaPay Payment Address": "Maximiza Tus Ganancias en Redes Sociales con Tu Dirección de Pago de InstaPay",
    "Make It Effortless for Your Audience to Support You:": "Hazlo Fácil para Tu Audiencia Apoyarte:",
    "Easily integrate your InstaPay Payment Address into your social media posts.": "Integra fácilmente tu Dirección de Pago de InstaPay en tus publicaciones en redes sociales.",
    "Easily copy and paste your payment address link into each of your posts, stories, or prominently on your profile.": "Copia y pega fácilmente el enlace de tu dirección de pago en cada una de tus publicaciones, historias o \nprominentemente en tu perfil.",
    "Ideal for influencers, artists, entrepreneurs, or anyone with a community eager to show \ntheir support.": "Ideal para influencers, artistas, emprendedores o cualquiera con una comunidad ansiosa por mostrar\nsu apoyo.",
    "Instant Support, Endless Possibilities:": "Apoyo Instantáneo, Posibilidades Infinitas:",
    "Transform the way you monetize your social media content.": "Transforma la forma en que monetizas tu contenido en redes sociales.",
    "Allow your followers to send you funds instantly with just a click, directly from their favorite social networks.": "Permite a tus seguidores enviarte fondos instantáneamente con solo un clic, directamente desde sus redes sociales favoritas.",
    "Ideal for real-time support during live streams, as a tipping system for your creative content, or for funding your next big \nproject.": "Ideal para apoyo en tiempo real durante transmisiones en vivo, como un sistema de propinas para tu contenido \ncreativo, o para financiar tu próximo gran proyecto.",
    "Where to Place Your InstaPay Payment Address for Maximum Impact:": "Dónde Colocar Tu Dirección de Pago de InstaPay para Máximo Impacto:",
    "Social Media Profiles: Pin it to your bio or about section for constant visibility.": "Perfiles en Redes Sociales: Fíjalo en tu biografía o sección sobre ti para una visibilidad constante.",
    "Posts & Announcements: Incorporate it into your individual posts, particularly those highlighting your work or specific \ncalls for support.": "Publicaciones y Anuncios: Incorpóralo en tus publicaciones individuales, particularmente aquellas que destacan \ntu trabajo o llamados específicos para apoyo.",
    "Stories & Reels: Integrate it into your interactive stories or captivating reels to capture instant contributions.": "Historias y Reels: Intégralo en tus historias interactivas o reels cautivadores para captar contribuciones instantáneas.",
    "Live Streams: Share it during your LIVE sessions, making it easy for viewers to contribute in real-time.": "Transmisiones en Vivo: Compártelo durante tus sesiones EN VIVO, facilitando a los espectadores contribuir en tiempo real.",
    "Simple, Fast, and Secure:": "Simple, Rápido y Seguro:",
    "No complex setup – get started in moments.": "Sin configuración compleja – comienza en momentos.",
    "Enjoy the security and speed of InstaPay's trusted payment processing.": "Disfruta de la seguridad y velocidad del procesamiento de pagos confiable de InstaPay.",
    "Receive payments from anyone, anywhere, without them needing an InstaPay account.": "Recibe pagos de cualquiera, en cualquier lugar, sin necesidad de que tengan una cuenta de InstaPay.",
    "Elevate Your Social Media Game with InstaPay Payment Address": "Eleva Tu Juego en Redes Sociales con la Dirección de Pago de InstaPay",
    "InstaPay VPA (Virtual Payment Address)": "InstaPay VPA (Dirección de Pago Virtual)",
    "Payment Address Title": "Título de la Dirección de Pago",
    "Currency": "Moneda",
    "Payment Address Description": "Descripción de la Dirección de Pago",
    "Update": "Actualizar",
    "Updating": "Actualizando",
    "Total Transaction": "Total de Transacciones",
    "Last 30 days": "Últimos 30 días",
    "Last 1 month": "Último 1 mes",
    "Last 1 year": "Último 1 año",
    "Custom Date": "Fecha Personalizada",
    "Payment Methods": "Métodos de Pago",
    "Payment Types": "Tipos de Pago",
    "Requested Amount": "Monto Solicitado",
    "Quoted Amount": "Monto Cotizado",
    "About Me": "Sobre Mí",
    "Followers": "Seguidores",
    "InstaPay ID": "ID de InstaPay",
    "Download QR Code": "Descargar Código QR",
    "Initiate A Payment": "Iniciar un Pago",
    "Report this person": "Reportar a esta persona",
    "Add now": "Añadir ahora",
    "Recent Reviews": "Reseñas Recientes",
    "Reviews As Seller": "Reseñas Como Vendedor",
    "Reviews As Buyer": "Reseñas Como Comprador",
    "Select Language": "Seleccionar Idioma",
    "Profile Completion": "Completitud del Perfil",
    "Profile Completed": "Perfil Completado",
    "Basic Info": "Información Básica",
    "Username": "Nombre de Usuario",
    "Gender": "Género",
    "Male": "Masculino",
    "Female": "Femenino",
    "-Select-Gender-": "-Seleccionar-Género-",
    "Date Of Birth": "Fecha de Nacimiento",
    "Address": "Dirección",
    "Edit Profile": "Editar Perfil",
    "Update Profile": "Actualizar Perfil",
    "Updating Profile": "Actualizando Perfil",
    "Complete Now": "Completar Ahora",
    "Password": "Contraseña",
    "Change Password": "Cambiar Contraseña",
    "New Password": "Nueva Contraseña",
    "Re-enter New Password": "Reingresar Nueva Contraseña",
    "Please follow this guide for a strong password": "Por favor sigue esta guía para una contraseña segura",
    "Include at least one special character.": "Incluir al menos un carácter especial.",
    "Minimum of 8 characters.": "Mínimo de 8 caracteres.",
    "Include at least one number": "Incluir al menos un número.",
    "Change it often for enhanced security.": "Cámbiala frecuentemente para mayor seguridad.",
    "Security Questions": "Preguntas de Seguridad",
    "Security Question": "Pregunta de Seguridad",
    "Select your question": "Selecciona tu pregunta",
    "Answer To The Question": "Respuesta a la Pregunta",
    "Nominee": "Nombrado",
    "Relationship with Nominee": "Relación con el Nombrado",
    "Nominee Contact Number": "Número de Contacto del Nombrado",
    "Nominee Address": "Dirección del Nombrado",
    "Delete Nominee": "Eliminar Nombrado",
    "Update Nominee": "Actualizar Nombrado",
    "Details Updated Successfully!": "¡Detalles Actualizados Exitosamente!",
    "Success": "¡Éxito!",
    "You may elect a Nominee of your choice, provided they are not a minor, through the InstaPay \nplatform itself. You acknowledge that no wet signature is required to appoint the nominee, and \nthe online registration will be considered final and binding. In the event of the death of an \nInstaPay Wallet holder, the balance in the Wallet will be transferred to the registered Nominee. \nKEMIT KINGDOM will be discharged from all its liabilities upon the handover of the amount to \nthe Nominee. The Nominee will provide any documents required by InstaPay, including those \nfor identification and proof of death.": "Puedes elegir un Nombrado de tu elección, siempre que no sea menor de edad, a través de la plataforma de InstaPay. \nReconoces que no se requiere una firma física para nombrar al nombrado, y que el registro en línea se considerará final \ny vinculante. En caso de fallecimiento de un titular de Wallet de InstaPay, el saldo en el Wallet será transferido al Nombrado \nregistrado. KEMIT KINGDOM quedará liberada de todas sus obligaciones al entregar el monto al Nombrado. El Nombrado \ndeberá proporcionar cualquier documento requerido por InstaPay, incluyendo aquellos para identificación y prueba de \nfallecimiento.",
    "Add Nominee": "Añadir Nombrado",
    "Two Factor Authentication": "Autenticación de Dos Factores",
    "SMS Number": "Número SMS",
    "Updated Successfully!": "¡Actualizado con éxito!",
    "Social Network Accounts": "Cuentas de Redes Sociales",
    "Here, you can set up and manage your integration settings.": "Aquí, puedes configurar y administrar tus ajustes de integración.",
    "Social Network Account": "Cuenta de Red Social",
    "Activate Account": "Activar Cuenta",
    "Enter the code below in the InstaPay Chatbot.": "Ingresa el código a continuación en el Chatbot de InstaPay.",
    "The code will expire in ": "El código expirará en",
    "Notifications": "Notificaciones",
    "Customize how you receive notifications. These settings apply to the activities you are monitoring.": "Personaliza cómo recibes notificaciones. Estos ajustes se aplican a las actividades que estás monitoreando.",
    "Activity": "Actividad",
    "Email": "Email",
    "Push": "Notificación Push",
    "SMS": "SMS",
    "Source": "Fuente",
    "Payment Requests": "Solicitudes de pago",
    "Bulk Payments": "Pagos a granel",
    "Identity Verification": "Verificación de Identidad",
    "Verify Your Identity": "Verifica Tu Identidad",
    "Identity Verification Status:": "Estado de la Verificación de Identidad:",
    "Briefly describe yourself and explain your reasons for using InstaPay.": "Describe brevemente quién eres y explica tus razones para usar InstaPay.",
    "Enter Your Message": "Ingresa Tu Mensaje",
    "Select Occupation": "Seleccionar Ocupación",
    "It must be a government-issued identification document with a photo. All identification documents \nmust be written in the Latin alphabet. If not, a certified translation is required.": "Debe ser un documento de identificación emitido por el gobierno con foto. Todos los documentos de identificación\ndeben estar escritos en alfabeto latino. Si no es así, se requiere una traducción certificada.",
    "Accepted Documents": "Documentos Aceptados",
    "A national identity card": "Una tarjeta de identidad nacional",
    "A valid passport": "Un pasaporte válido",
    "Valid residence permits (accepted only if issued by a European country or in Switzerland)": "Permisos de residencia válidos (aceptados solo si son emitidos por un país europeo o en Suiza)",
    "Biometric Facial Recognition: Comparing video with passport photograph": "Reconocimiento Facial Biométrico: Comparando video con fotografía del pasaporte",
    "Address Verification:": "Verificación de Dirección:",
    "Provide a bank statement or utility bill (less than 3 months old) as proof of address": "Proporciona un estado de cuenta bancario o factura de servicios públicos (menos de 3 meses de antigüedad) como prueba de dirección",
    "Additional Documents:": "Documentos Adicionales:",
    "Kemit Kingdom reserves the right to request any additional documents (Enhanced Due Diligence - EDD) \nor further information necessary for conducting thorough verifications, in accordance with its legal obligations.": "Kemit Kingdom se reserva el derecho de solicitar cualquier documento adicional (Diligencia Debida Reforzada - EDD)\no información adicional necesaria para realizar verificaciones exhaustivas, de acuerdo con sus obligaciones legales.",
    "Start Verification": "Iniciar Verificación",
    "Sessions": "Sesiones",
    "This is a list of devices that have logged into your account. Remove any that you do not recognize.": "Esta es una lista de dispositivos que han iniciado sesión en tu cuenta. Elimina cualquiera que no reconozcas.",
    "Your Withdrawal Options": "Tus Opciones de Retiro",
    "Choose your preferred method for withdrawing payments.": "Elige tu método preferido para retirar pagos.",
    "No withdrawal options currently available in your country.": "No hay opciones de retiro disponibles actualmente en tu país.",
    "Download Your Data": "Descargar Tus Datos",
    "Request a Copy of Your Data": "Solicitar una Copia de Tus Datos",
    "Submit a request to download a copy of your data": "Envía una solicitud para descargar una copia de tus datos",
    "Which Data Should Be Included in the Download?": "¿Qué Datos Deberían Incluirse en la Descarga?",
    "Select all applicable options.": "Selecciona todas las opciones aplicables.",
    "Personal Information": "Información Personal",
    "This information is used to secure your account, tailor your experience, and contact you as necessary.": "Esta información se utiliza para asegurar tu cuenta, personalizar tu experiencia y contactarte según sea necesario.",
    "Name and date of birth": "Nombre y fecha de nacimiento",
    "Phone number": "Número de teléfono",
    "Financial Information": "Información Financiera",
    "We use this information to enable faster checkout and to send or receive money in just a few clicks.": "Utilizamos esta información para habilitar un pago más rápido y para enviar o recibir dinero en solo unos clics.",
    "Bank accounts": "Cuentas bancarias",
    "Debit or credit cards": "Tarjetas de débito o crédito",
    "Credit Information": "Información de Crédito",
    "Includes your credit application information": "Incluye tu información de solicitud de crédito",
    "Other Information (not included in file)": "Otra Información (no incluida en el archivo)",
    "Includes device info, technical usage data, geolocation information, marketing preferences, consent history, and data used for other services such as credit, \nidentity verification, communications with PayPal, and third-party processors.": "ncluye información del dispositivo, datos técnicos de uso, información de geolocalización, preferencias de marketing, \nhistorial de consentimiento, y datos utilizados para otros servicios como crédito, verificación de identidad, comunicaciones con \nPayPal y procesadores de terceros.",
    "Choose file type:": "Elige el tipo de archivo:",
    "Select file type": "Seleccionar tipo de archivo",
    "PDF file can be easily opened on any computer": "El archivo PDF se puede abrir fácilmente en cualquier computadora",
    "Submit Request": "Enviar Solicitud",
    "Delete Your Account": "Eliminar Tu Cuenta",
    "Ask us to delete your data and close this account": "Pídenos eliminar tus datos y cerrar esta cuenta",
    "We use your data to provide services, so if we delete your data, we also need to close your account.": "Usamos tus datos para proporcionar servicios, por lo que si eliminamos tus datos, también necesitamos cerrar tu cuenta.",
    "If your account is limited, go to the Resolution Center, select 'Go to Account Limitations', then click 'Resolve'.": "Si tu cuenta está limitada, ve al Centro de Resolución, selecciona 'Ir a Limitaciones de la Cuenta', luego haz clic en 'Resolver'.",
    "Make sure any outstanding payments have cleared. We won't be able to close your account until they do.": "Asegúrate de que todos los pagos pendientes se hayan completado. No podremos cerrar tu cuenta hasta que lo hagan.",
    "If you have a negative balance, click the Resolve link next to your balance.": "Si tienes un saldo negativo, haz clic en el enlace Resolver junto a tu saldo.",
    "Once your account is clear, and if you're eligible, we'll close your account and delete your data. We need \nto retain some of your data for legal and regulatory reasons. To learn more, see our ": "Una vez que tu cuenta esté clara y si eres elegible, cerraremos tu cuenta y eliminaremos tus datos. Necesitamos\nretener algunos de tus datos por razones legales y regulatorias. Para saber más, consulta nuestra",
    "privacy statement": "declaración de privacidad",
    "We'll email you to let you know the status, and if there are any other steps you need to take.": "Te enviaremos un email para informarte sobre el estado y si hay otros pasos que debes seguir.",
    "Have more questions about data deletion?": "¿Tienes más preguntas sobre la eliminación de datos?",
    "Contact us": "Contáctanos",
    "I understand that account deletion is permanent and can't be reversed.": "Entiendo que la eliminación de la cuenta es permanente y no se puede revertir.",
    "Are you sure you want to close your account?": "¿Estás seguro de que quieres cerrar tu cuenta?",
    "Yes, Continue": "Sí, Continuar",
    "Logging In": "Iniciando Sesión",
    "Complete your profile to streamline your transaction process.": "Completa tu perfil para agilizar tu proceso de transacción.",
    "Skip": "Omitir",
    "Complete Your Profile": "Completa Tu Perfil",
    "API Key": "Clave API",
    "Get API Key": "Obtener Clave API",
    "Generating New API Key": "Generando Nueva Clave API",
    "Generate API Key": "Generar Clave API",
    "Regenerate API Key": "Regenerar Clave API",
    "To harness the full potential of InstaPay's seamless transactions, follow these quick steps to generate your API key. \nFirst, sign up for an account at [your registration URL] and access the Developer Dashboard. Navigate to the \n'API Keys' section, generate a new key, and ensure its secure storage.": "Para aprovechar al máximo las transacciones sin problemas de InstaPay, sigue estos pasos rápidos para generar tu clave API.\nPrimero, regístrate en una cuenta en [tu URL de registro] y accede al Panel de Desarrolladores. Navega a la\nsección 'Claves API', genera una nueva clave y asegúrate de almacenarla de manera segura.",
    "Delete API Key": "Eliminar Clave API",
    "Webhook URL": "URL del Webhook",
    "KYB Verification": "Verificación KYB",
    "Additional Files": "Archivos Adicionales",
    "Business Verification": "Verificación Empresarial",
    "--Select-Your-Company-Type--": "--Selecciona-Tu-Tipo-de-Empresa--",
    "Documents Required:": "Documentos Requeridos:",
    "Official Company Registry Extract (Entity's Registration/Incorporation Document)": "Extracto Oficial del Registro de la Compañía (Documento de Registro/Incorporación de la Entidad)",
    "This document serves as the company's official 'identity card' and should be obtained from the local business registry. \nIt reflects the current status of the business (active or closed).": "Este documento sirve como la 'tarjeta de identidad' oficial de la compañía y debe obtenerse del registro comercial local.\nRefleja el estado actual del negocio (activo o cerrado).\n",
    "The document should be current, with an issuance date not exceeding 3 months.": "El documento debe estar actualizado, con una fecha de emisión que no exceda los 3 meses.",
    "The document must include the following details:": "El documento debe incluir los siguientes detalles:",
    "Legal name of the company": "Nombre legal de la empresa",
    "Full registered address": "Dirección registrada completa",
    "Company registration number": "Número de registro de la empresa",
    "Date of incorporation": "Fecha de incorporación",
    "Description of the company's activities/purpose": "Descripción de las actividades/propósito de la compañía",
    "Legal representative's information: full name, date and place of birth, residential address \n(minimum requirement: country of residence), and nationality": "Información del representante legal: nombre completo, fecha y lugar de nacimiento, dirección residencial\n(requisito mínimo: país de residencia) y nacionalidad",
    "If applicable, the document should also include:": "Si aplica, el documento también debe incluir:",
    "Brand or trading names": "Nombres comerciales o de marca",
    "Value Added Tax (VAT) number": "Número de Impuesto al Valor Agregado (IVA)",
    "List of Ultimate Beneficial Owners (UBOs) - this is mandatory in some countries, otherwise, \nit may be provided as a separate document.": "Lista de Propietarios Beneficiarios Finales (UBOs) - esto es obligatorio en algunos países, de lo contrario,\npuede proporcionarse como un documento separado.",
    "Proof of Legal Representative's Authority": "Prueba de la Autoridad del Representante Legal",
    "This document validates an individual's authority to represent the company": "Este documento valida la autoridad de un individuo para representar a la compañía",
    "Typically, the legal representative is named in the company's registry extract. However, if this is not the case, a \ndocument such as a Power of Attorney or Delegation of Powers must be secured.": "Por lo general, el representante legal se nombra en el extracto del registro de la compañía. Sin embargo, si este no es el caso, un\ndocumento como un Poder o Delegación de Poderes debe obtenerse.",
    "Power of Attorney/Delegation of Powers:": "Poder/Delegación de Poderes:",
    "The individual conferring the authority must be legitimately entitled to do so. This individual should be listed as a legal \nrepresentative in the company's registry extract.": "El individuo que confiere la autoridad debe estar legítimamente facultado para hacerlo. Este individuo debe figurar como representante legal\nen el extracto del registro de la compañía.",
    "Ownership Structure and Ultimate Beneficial Owners (UBOs) Documentation": "Documentación de la Estructura de Propiedad y Propietarios Beneficiarios Finales (UBOs)\n",
    "Evidence of the company's listing on the relevant stock exchange should be provided. This could be a page from the \nstock exchange website displaying the company's details.": "Se debe proporcionar evidencia de la cotización de la compañía en la bolsa de valores relevante. Esto podría ser una página del\nsitio web de la bolsa de valores que muestra los detalles de la compañía.",
    "If less than 75% of the company's shares are publicly owned, all shareholders who own or control more than 25% of \nthe shares will be subject to Ultimate Beneficial Owner (UBO) verification requirements.": "Si menos del 75% de las acciones de la compañía son de propiedad pública, todos los accionistas que posean o controlen más del 25% de\nlas acciones estarán sujetos a los requisitos de verificación de Propietario Beneficiario Final (UBO).",
    "Collection of Identification Documents": "Recolección de Documentos de Identificación",
    "The identification document must be government-issued, bearing a photograph of the individual. All identification \ndocuments must be written in the Latin alphabet. If not, a certified translation is required.": "El documento de identificación debe ser emitido por el gobierno, con una fotografía del individuo. Todos los documentos de identificación\ndeben estar escritos en alfabeto latino. Si no, se requiere una traducción certificada.",
    "Accepted documents:": "Documentos aceptados:",
    "Valid National Identity Card": "Tarjeta de Identidad Nacional válida",
    "Valid Passport": "Pasaporte válido",
    "Valid Resident Permits - These will be accepted only if issued by a European country.": "Permisos de Residencia válidos - Estos serán aceptados solo si son emitidos por un país europeo.",
    "Only legal representatives are subject to the identification document collection process.": "Solo los representantes legales están sujetos al proceso de recolección de documentos de identificación.",
    "Additional Requirement:": "Requisito Adicional:",
    "In cases involving a Power of Attorney or Delegation of Powers, both parties \n(the individual granting authority and the one receiving it) must provide a copy of their ID.": "En casos que involucren un Poder o Delegación de Poderes, ambas partes\n(la persona que otorga la autoridad y la que la recibe) deben proporcionar una copia de su ID.",
    "Additional Documents": "Documentos Adicionales",
    "Utility Bill (such as gas, electricity, telephone or mobile phone bill); or a document issued by a government department \nthat shows the End-user’s address and name (e.g., Residential Certificate); or": "Factura de Servicios (como gas, electricidad, teléfono o factura de móvil); o un documento emitido por un departamento gubernamental\nque muestre la dirección y nombre del Usuario Final (por ejemplo, Certificado Residencial); o",
    "Bank Statement; or": "Estado de Cuenta Bancario; o",
    "a document issued by a government department that shows the End-user’s address and name \n(e.g., Residential Certificate).": "un documento emitido por un departamento gubernamental que muestre la dirección y nombre del Usuario Final\n(por ejemplo, Certificado Residencial).",
    "Kemit Kingdom retains the right to request any additional documents or further information necessary for conducting \nthorough verifications, in line with its legal obligations.": "Kemit Kingdom se reserva el derecho de solicitar cualquier documento adicional o información adicional necesaria para realizar\nverificaciones exhaustivas, de acuerdo con sus obligaciones legales.",
    "Note:": "Nota:",
    "Partners": "Socios",
    "Add New Partner": "Añadir Nuevo Socio",
    "Designation": "Designación",
    "Partner Name": "Nombre del Socio",
    "Partner Email": "Correo Electrónico del Socio",
    "Partner Designation": "Designación del Socio",
    "Add Now": "Añadir Ahora",
    "Adding": "Añadiendo",
    "Delete Partner": "Eliminar Socio",
    "Delete": "Eliminar",
    "Deleting": "Eliminando",
    "Are you sure, you want to remove sxz as partner?": "¿Estás seguro de que quieres eliminar a sxz como socio?",
    "Entity's Registry Extract (Entity'sRegistration/Creation Document)": "Extracto del Registro de la Entidad (Documento de Registro/Creación de la Entidad)",
    "This document should be either:": "Este documento debería ser:",
    "A proof of creation of the entity, providing the entity's details, creation date, and purpose.": "Una prueba de creación de la entidad, proporcionando los detalles de la entidad, fecha de creación y propósito.",
    "A local registry extract:": "Un extracto del registro local:",
    "Required as per the country's regulations, serving as the current 'identity card' for the entity, indicating its status \n(active or closed).": "Requerido según las regulaciones del país, sirviendo como la actual 'tarjeta de identidad' de la entidad, indicando su estado\n(activo o cerrado).",
    "If a registry extract exists, it must not be older than 3 months.": "Si existe un extracto del registro, no debe tener más de 3 meses de antigüedad.",
    "Entity name": "Nombre de la entidad",
    "Registration number (if applicable, based on the country's requirements)": "Número de registro (si aplica, basado en los requisitos del país)",
    "Date of creation": "Fecha de creación",
    "Description of the entity's activities or purpose": "Descripción de las actividades o propósito de la entidad",
    "Trading names": "Nombres comerciales",
    "Articles of Association": "Estatutos de la Asociación",
    "The Articles of Association serve as the constitutional document of the entity, outlining its operating \nrules.": "Los Estatutos de la Asociación sirven como documento constitucional de la entidad, describiendo sus reglas de operación.\n",
    "There is no time constraint on this document, meaning the most recent version is requested. Typically, these types of \nentities require at least one annual meeting that may result in an updated version of the Articles of Association.": "No hay restricción de tiempo en este documento, lo que significa que se solicita la versión más reciente. Típicamente, este tipo de\nentidades requieren al menos una reunión anual que puede resultar en una versión actualizada de los Estatutos de la Asociación.",
    "Examples of changes that could occur include updates to the entity's name, address, purpose, management board, \nroles, responsibilities, etc.": "Ejemplos de cambios que podrían ocurrir incluyen actualizaciones al nombre de la entidad, dirección, propósito, junta directiva,\nroles, responsabilidades, etc.",
    "The document should contain information about:": "El documento debe contener información sobre:",
    "The entity itself, including its legal name, registered address, and purpose": "La propia entidad, incluyendo su nombre legal, dirección registrada y propósito",
    "Its internal operating rules, such as the appointment process for management board members, their \nroles and powers, among other details. This list is not exhaustive.": "Sus reglas operativas internas, como el proceso de nombramiento para los miembros de la junta directiva, sus roles y poderes, entre \notros detalles. Esta lista no es exhaustiva.",
    "How the entity is funded": "Cómo se financia la entidad",
    "Appointment/Removal/Changes on Board of Management Members:": "Nombramiento/Remoción/Cambios en los Miembros de la Junta Directiva:",
    "These updates usually occur based on the entity's needs or as stipulated in the Articles of Association. Changes are \ndocumented through board of management meeting minutes, which are registered and certified to reflect the updated \nlist of members, such as the president, vice-president, treasurer, and general secretary.": "Estas actualizaciones generalmente ocurren según las necesidades de la entidad o como estipulan los Estatutos de la Asociación. \nLos cambios sondocumentados a través de las actas de reuniones de la junta directiva, que están registradas y certificadas para reflejar \nla lista actualizada de miembros, como el presidente, vicepresidente, tesorero y secretario general.",
    "The latest board management meeting minutes, including the current list of members, will be required in addition to the \nArticles of Association, if available.": "Las últimas actas de reunión de la junta directiva, incluyendo la lista actual de miembros, serán requeridas además de los\nEstatutos de la Asociación, si están disponibles.",
    "Note: If the current list of board management members is not included in the latest Articles of Association and there \nare no board meeting minutes available, this list must be provided. It should be dated and signed by the most recently \nappointed legal representative, whose appointment can be verified through related documentation.": "Nota: Si la lista actual de miembros de la junta directiva no está incluida en los últimos Estatutos de la Asociación y no hay actas de reunión \nde la junta disponibles, esta lista debe ser proporcionada. Debe estar fechada y firmada por el representante legal más recientemente \nnombrado, cuyo nombramiento puede ser verificado a través de la documentación relacionada.",
    "This document demonstrates an individual's authority to represent the entity. This document could be:": "Este documento demuestra la autoridad de un individuo para representar a la entidad. Este documento podría ser:",
    "The latest Articles of Association": "Los últimos Estatutos de la Asociación",
    "The current list of board management members \n(which may be included in the board management meeting minutes)": "La lista actual de miembros de la junta directiva (que puede estar incluida en las actas de la reunión de la junta directiva)",
    "The document should specify:": "El documento debe especificar:",
    "Full name of the individual(s)": "Nombre completo del(los) individuo(s)",
    "Function of the individual(s). This information will be cross-verified with the Articles of Association to \nensure that the individual's function permits them to bind the entity. This is particularly important when the \napplicant is not the president, and other functions have limited representation authority as stated in \nthe Articles of Association.": "Función del(los) individuo(s). Esta información será verificada cruzadamente con los Estatutos de la Asociación para asegurarse de que la función \ndel individuo les permita vincular a la entidad. Esto es particularmente importante cuando el solicitante no es el presidente, y otras funciones tienen \nautoridad de representación limitada según se indica en los Estatutos de la Asociación.",
    "Additional information about the legal representative that will be collected includes:": "La información adicional sobre el representante legal que se recopilará incluye:",
    "Date and place of birth": "Fecha y lugar de nacimiento",
    "Residential address (at least the country of residence should be stated)": "Dirección residencial (al menos se debe indicar el país de residencia)",
    "Nationality": "Nacionalidad",
    "Ownership Structure & Ultimate Beneficial Owners (UBOs)": "Estructura de Propiedad y Propietarios Beneficiarios Finales (UBOs)",
    "Typically, these types of entities, given their structure and purpose, do not have beneficial owners. However, board \nmembers will be identified and treated as such for screening purposes, ID collection and verification, etc.": "Por lo general, este tipo de entidades, dada su estructura y propósito, no tienen propietarios beneficiarios. Sin embargo, los\nmiembros de la junta serán identificados y tratados como tales para propósitos de evaluación, recolección y verificación de ID, etc.",
    "A small number of associations might have beneficial owners, particularly those generating profits. In such instances, \nthis detail will be mentioned in the Articles of Association. These individuals will be easily identified and treated as such, \nalongside management board members.": "Un pequeño número de asociaciones podría tener propietarios beneficiarios, particularmente aquellas que generan ganancias. En tales casos,\neste detalle se mencionará en los Estatutos de la Asociación. Estos individuos serán fácilmente identificados y tratados como tales,\njunto con los miembros de la junta directiva.",
    "Additional Requirement: In cases involving a Power of Attorney or Delegation of Powers, both parties \n(the individual granting authority and the one receiving it) must provide a copy of their identification documents.": "Requisito Adicional: En casos que involucren un Poder o Delegación de Poderes, ambas partes\n(la persona que otorga la autoridad y la que la recibe) deben proporcionar una copia de sus documentos de identificación.",
    "Sole Trader's Registry Extract (Entity's Registration/Creation Document)": "Extracto del Registro de Comerciante Individual (Documento de Registro/Creación de la Entidad)",
    "This document serves as a local registry for sole traders, required in countries where such registration is mandatory. \nIt verifies that the individual is engaged in a professional activity as a sole trader. The name of this document varies from \ncountry to country.": "Este documento sirve como un registro local para comerciantes individuales, requerido en países donde tal registro es obligatorio.\nVerifica que el individuo esté involucrado en una actividad profesional como comerciante individual. El nombre de este documento varía de\npaís a país.",
    "Where applicable, the document should not be older than 3 months.": "Donde corresponda, el documento no debe tener más de 3 meses de antigüedad.",
    "The document must contain:": "El documento debe contener:",
    "Individual's name": "Nombre del individuo",
    "Residential address": "Dirección residencial",
    "Registration number": "Número de registro",
    "The sole trader is the only individual subject to the identification document collection process.": "El comerciante individual es el único sujeto al proceso de recolección de documentos de identificación.",
    "No Transactions": "Sin Transacciones",
    "No Pending Items": "Sin Elementos Pendientes",
    "No Beneficiaries": "Sin Beneficiarios",
    "Send Money To": "Enviar Dinero A",
    "Confirmation": "Confirmación",
    "You are setting": "Estás estableciendo",
    "as default.": "como predeterminado.",
    "Previously": "Previamente",
    "was default QR.": "era el QR predeterminado.",
    "Category": "Categoría",
    "-Select-From-The-List-": "-Seleccionar-De-La-Lista-",
    "Add a note": "Añadir una nota",
    "Categories Added Successfully!": "¡Categorías Añadidas con Éxito!",
    "Subscription starting date": "Fecha de inicio de la suscripción",
    "Until": "Hasta",
    "Next Transaction": "Próxima Transacción",
    "Transaction Method": "Método de Transacción",
    "Accept the quotation": "Aceptar la cotización",
    "Choose Currency": "Elegir Moneda",
    "Accept": "Aceptar",
    "Bargain the quotation": "Negociar la cotización",
    "Enter Counter Amount:": "Ingresa el Monto Contrario:",
    "Bargain": "Negociar",
    "Decline the quotation": "Cancelar la cotización",
    "Are you sure, you want to decline this quotation?": "¿Estás seguro de que quieres rechazar esta cotización?",
    "Decline": "Rechazar",
    "Personal Account": "Cuenta Personal",
    "Jan": "Ene",
    "Feb": "Feb",
    "Mar": "Mar",
    "Apr": "Abr",
    "May": "May",
    "Jun": "Jun",
    "Jul": "Jul",
    "Aug": "Ago",
    "Sep": "Sep",
    "Oct": "Oct",
    "Nov": "Nov",
    "Dec": "Dic",
    "Today": "Hoy",
    "Spent In": "Gastado en",
    "Revised Amount": "Cantidad revisada",
    "You spent more comparing to last month": "Gastaste más en comparación con el mes pasado",
    "Last": "Pasado",
    "days": "Días",
    "Year": "Años",
    "Instant Mobile Top-up Across 150+ Countries": "Recarga Móvil Instantánea en Más de 150 Países",
    "Delight your loved ones or recharge your own phone, no matter where you are! InstaPay’s Mobile Airtime service lets you instantly top up mobile phones in over 150 countries. Perfect for gifts or ensuring you're always connected, our service is designed for ease, speed, and security.": "¡Alegra a tus seres queridos o recarga tu propio teléfono, no importa dónde estés! El servicio de Recarga Móvil de InstaPay te permite recargar teléfonos móviles instantáneamente en más de 150 países. Perfecto para regalos o para asegurarte de estar siempre conectado, nuestro servicio está diseñado para ser fácil, rápido y seguro. ",
    "How It Works - Step-by-Step Guide:": "Cómo Funciona - Guía Paso a Paso:",
    "1. Choose 'Mobile Top-up': Access this feature directly within the InstaPay app on platforms like WhatsApp, Twitter, Telegram, or Instagram.": "1. Elige 'Recarga Móvil': Accede a esta función directamente dentro de la app de InstaPay en plataformas como WhatsApp, Twitter, Telegram o Instagram.",
    "2. Select Currency and Amount: Pick from multiple currencies (USD, GBP, EUR, etc.) and specify how much you want to top up.": "2. Selecciona Moneda y Cantidad: Elige entre varias monedas (USD, GBP, EUR, etc.) y especifica cuánto quieres recargar.",
    "3. Enter the Mobile Number: Type in the number you wish to recharge—yours or that of a loved one. Our system ensures a secure and swift process.": "3. Introduce el Número Móvil: Ingresa el número que deseas recargar—el tuyo o el de un ser querido. Nuestro sistema asegura un proceso seguro y rápido.",
    "4. Confirm and Pay: Double-check the top-up details, confirm, and make the payment using your preferred method. The transaction processes instantly and securely.": "4. Confirma y Paga: Revisa los detalles de la recarga, confirma y realiza el pago con tu método preferido. La transacción se procesa de manera instantánea y segura.",
    "5. Receive Instant Confirmation: Both you and the recipient (if applicable) will receive a confirmation notification once the top-up is successful.": "5. Recibe Confirmación Instantánea: Tanto tú como el destinatario (si aplica) recibiréis una notificación de confirmación una vez que la recarga sea exitosa.",
    "Benefits:": "Beneficios:",
    "Global Reach: Effortlessly send airtime to over 150 countries.": "Alcance Global: Envía saldo de manera fácil a más de 150 países.",
    "Instant Impact: See the benefits immediately—perfect for last-minute gifts or urgent communications.": "Impacto Instantáneo: Ve los beneficios de inmediato—perfecto para regalos de último momento o comunicaciones urgentes.",
    "Complete Security: Enjoy peace of mind with our secure payment system.": "Seguridad Completa: Disfruta de la tranquilidad que ofrece nuestro sistema de pago seguro.",
    "Versatile Payment Options: Choose from credit cards, PayPal, or your InstaPay wallet for payments.": "Opciones de Pago Versátiles: Elige entre tarjetas de crédito, PayPal o tu billetera InstaPay para los pagos.",
    "Ready to spread joy or stay connected? Top-up now and make someone’s day—or your own!": "¿Listo para esparcir alegría o mantenerte conectado? ¡Recarga ahora y alegra el día de alguien—o el tuyo!",
    "Pick a language": "Elige un idioma",
    "French": "Francés",
    "Spanish": "Español",
    "Portuguese": "Portugués",
    "German": "Alemán",
    "Ukrainian": "Ucraniano",
    "Italian": "Italiano",
    "Russian": "Ruso",
    "Arabic": "Árabe",
    "Polish": "Polaco",
    "Dutch (Nederlands)": "Neerlandés",
    "Yoruba": "Yoruba",
    "Indonesian": "Indonesio",
    "Turkish": "Turco",
    "Swahili": "Swahili",
    "Hausa": "Hausa",
    "Hindi": "Hindi",
    "Urdu": "Urdu",
    "Chinese": "Chino",
    "Select Your Preferred Language": "Seleccione su idioma preferido",
    "Please select the language you would \nlike to use for the eKYC process.": "Por favor seleccione el idioma que le gustaría usar para el proceso de eKYC.",
    "Select Your Nationality": "Seleccione su nacionalidad",
    "Choose your nationality to see the \nsupported ID documents for  your country.": "Elija su nacionalidad para ver los documentos de identificación compatibles para su país.",
    "Grant permission to use the camera.": "Conceda permiso para usar la cámara.",
    "Tap or click \"Allow\" when prompted to \ngrant permission for camera access to \ncapture the document.": "Toque o haga clic en \"Permitir\" cuando se le solicite para otorgar permiso de acceso a la cámara para capturar el documento.",
    "Upload Your Documents from \nGallery/Photos": "Suba sus documentos desde la galería/fotos",
    "If you encounter issues with your camera \nor if your system doesn't have one, you can\n manually upload photos of the document \nfrom your local storage.": "Si tiene problemas con su cámara o si su sistema no tiene una, puede subir manualmente fotos del documento desde su almacenamiento local.",
    "Upload Your ID.": "Sube tu identificación.",
    "Make sure your ID is clear and not blurry to \nensure quick verification.": "Asegúrate de que tu identificación esté clara y no borrosa para asegurar una verificación rápida.",
    "Valid Proof of Address": "Prueba de Dirección Válida",
    "1. Ensure that your proof of address is \ncurrent and that the statement date is \nwithin the last three months from the \nstart date of your eKYC verification.\n\n2. Make sure your proof of address is clear \nand not blurry to ensure quick verification.": "1. Asegúrate de que tu prueba de dirección esté actual y que la fecha del estado de cuenta sea dentro de los últimos tres meses desde la fecha de inicio de tu verificación eKYC.\n\n2. Asegúrate de que tu prueba de dirección esté clara y no borrosa para asegurar una verificación rápida.",
    "Important Notice\n\nPlease carefully review all the steps in this guideline before proceeding with your eKYC verification.\n\nAvoid Common Mistakes:\n\n* Ensure all your documents are clear, legible, and up-to-date.\n* Double-check that the information you provide matches the details on your official documents.\n* Follow the instructions precisely to avoid delays or rejections.\n\nNote: \nIf your eKYC verification fails and you need to resubmit, you will be charged again for the resubmission process.\n\nBy following these guidelines, you can help ensure a smooth and successful eKYC verification.\n\nFor any questions or assistance, please contact our support team at support@insta-pay.ch ": "Aviso Importante\n\nPor favor, revise todos los pasos de esta guía antes de proceder con su verificación eKYC.\n\nEvite errores comunes:\n\n* Asegúrese de que todos sus documentos sean claros, legibles y estén actualizados.\n* Verifique que la información proporcionada coincida con los detalles de sus documentos oficiales.\n* Siga las instrucciones con precisión para evitar retrasos o rechazos.\n\nNota: \nSi su verificación eKYC falla y necesita volver a enviarla, se le cobrará nuevamente por el proceso de reenvío.\n\nSiguiendo estas pautas, puede ayudar a asegurar una verificación eKYC exitosa y sin problemas.\n\nPara cualquier pregunta o asistencia, por favor contacte a nuestro equipo de soporte en support@insta-pay.ch",
    "Personal Transactions": "Transacciones personales",
    "Business Transactions": "Transacciones comerciales",
    "Shopping & Purchases": "Compras y compras",
    "Bills & Utilities": "Facturas y servicios públicos",
    "Entertainment & Leisure": "Entretenimiento y ocio",
    "Investments & Savings": "Inversiones y ahorros",
    "Health & Wellness": "Salud y bienestar",
    "Transportation": "Transporte",
    "Education": "Educación",
    "Miscellaneous": "Diversos",
    "Family Support": "Apoyo familiar",
    "WALLET": "WALLET",
    "Add a currency": "Añadir una moneda",
    "When you add a currency, any payments you receive in that currency will be credited to your InstaPay balance. Your primary currency will be used for sending or requesting payments unless specified otherwise.": "Cuando añades una moneda, cualquier pago que recibas en esa moneda se acreditará a tu saldo de InstaPay. Tu moneda principal se utilizará para enviar o solicitar pagos, a menos que se especifique lo contrario.",

    "balance amount ": "cantidad de saldo",
    "referral ": "referencia",
    "crypto wallet balance": "saldo del monedero crypto",
    "money in": "dinero entrante",
    "money out": "dinero saliente",
    "analytics": "analítica",
    "see more": "ver más",
    "manage my Wallets and see the transaction details": "gestionar mis Wallets y ver el detalle de mis transacciones",
    "set as default Wallet": "establecer como Wallet predeterminado",
    "add currency": "añadir moneda",
    "convert funds ": "convertir fondos",
    "withdraw balance": "retirar saldo",
    "chart names": "nombres de gráficos",
    "withdraw balance from PKR wallet": "retirar saldo de la billetera PKR",
    "select a payout channel": "seleccionar un canal de pago",
    "you have entered an amount below the minimum payment range": "has ingresado una cantidad por debajo del rango mínimo de pago",
    "change": "cambiar",
    "edit": "editar",
    "moving to next step in ...": "pasando al siguiente paso en ...",
    "you are withdrawing ...": "estás retirando ...",
    "where is my payment": "dónde está mi pago",
    "download": "descargar",
    "IBAN / SWIFT Code": "IBAN o código SWIFT/BIC",
    "swift code": "código SWIFT",
    "account number": "número de cuenta",
    "branch street": "calle de la sucursal",
    "city": "ciudad",
    "province": "provincia",
    "postal code": "código postal",
    "all payout channels": "todos los canales de pago",
    "bussiness beneficiary": "beneficiario comercial",
    "no quotation": "sin cotización",
    "no pending items": "sin elementos pendientes",
    "QR status": "Estado del QR",
    "Portfolio": "Portafolio",
    "edit whole portfolio page": "editar toda la página del portafolio",
    "personal account": "cuenta personal",
    "Chart names": "Nombres de gráficos",
    "instagram": "instagram",
    "facebook": "facebook",
    "twitter": "twitter",
    "more": "más",
    "not connected": "no conectado",
    "code": "código",
    "ratings": "calificaciones",
    "current password": "contraseña actual",
    "include at least one uppercase and lowercase": "incluya al menos una mayúscula y una minúscula",
    "my social network accounts": "mis cuentas de redes sociales",
    "SMS ": "SMS",
    "view identity verfication guide": "ver la guía de verificación de identidad",
    "boimetric face recognition": "reconocimiento facial biométrico",
    "please select a payer (withdrawl options)": "por favor seleccione un pagador (opciones de retiro)",
    "IBAN number ": "número IBAN",
    "account holder name": "nombre del titular de la cuenta",
    "download data": "descargar datos",
    "bank transfer": "transferencia bancaria",
    "mobile wallet": "billetera móvil",
    "all bank names": "todos los nombres de bancos",
    "pay via card": "pagar con tarjeta",
    "you have entered an amount that is below the payer range": "ha ingresado una cantidad que está por debajo del rango del pagador",
    "trouble sending money": "problema para enviar dinero",
    "please follow these example": "por favor siga estos ejemplos",
    "wallet ID ": "ID de Wallet",
    "username": "nombre de usuario",
    "email": "correo electrónico",
    "phone number": "número de teléfono",
    "no user found": "no se encontró ningún usuario",
    "search": "buscar",
    "scan/upload QR code of the recipient": "escanee/cargue el código QR del destinatario",
    "invite them": "invítalos",
    "trouble quoting money": "problema al cotizar dinero",
    "select a country": "seleccionar un país",
    "airtime details": "detalles de recarga",
    "enter the phone number": "ingrese el número de teléfono",
    "service type": "tipo de servicio",
    "select a service": "seleccionar un servicio",
    "service list": "lista de servicios",
    "amount entered is less than the ....": "el monto ingresado es menor que ....",
    "total": "total",
    "total credit the receiver gets": "crédito total que recibe el destinatario",
    "take ": "tomar",
    "OTP has been verified , You'll be redirected......": "OTP ha sido verificado, serás redirigido......",
    "Link Your Instagram Account to InstaPay": "Vincula tu cuenta de Instagram a InstaPay",
    "Step 01 – From Instagram App": "Paso 01 – Desde la aplicación de Instagram",
    "Open the Instagram app, search for the \"InstaPay\" profile page, select \"Message\" to open the chat box, type/say \"Hi\" and click send to initiate the conversation with the InstaPay chatbot.": "Abre la aplicación de Instagram, busca la página de perfil \"InstaPay\", selecciona \"Mensaje\" para abrir la caja de chat, escribe/diga \"Hola\" y haz clic en enviar para iniciar la conversación con el chatbot de InstaPay.",
    "Step 02 – From InstaPay Instagram Profile Page ": "Paso 02 – Desde la página de perfil de Instagram de InstaPay",
    "InstaPay chatbot will display two action buttons: \"Register\" and \"Connect.\" Select \"Connect,\" and the chatbot will prompt you to enter your InstaPay username.": "El chatbot de InstaPay mostrará dos botones de acción: \"Registrar\" y \"Conectar\". Selecciona \"Conectar\" y el chatbot te pedirá que ingreses tu nombre de usuario de InstaPay.",
    "Step 03 – From InstaPay Instagram Profile Page": "Paso 03 – Desde la página de perfil de Instagram de InstaPay",
    "The InstaPay chatbot will then ask for the InstaPay verification code. Enter the code you obtained from the InstaPay settings under \"My Social Network Accounts\" and click send.": "El chatbot de InstaPay luego te pedirá el código de verificación de InstaPay. Ingresa el código que obtuviste de la configuración de InstaPay bajo \"Mis cuentas de redes sociales\" y haz clic en enviar.",
    "Step 04 – From InstaPay Instagram Profile Page": "Paso 04 – Desde la página de perfil de Instagram de InstaPay",
    "You will receive a congratulatory message confirming that your Instagram account is now synced with InstaPay. Click on \"Main Menu\" to start using InstaPay services.": "Recibirás un mensaje de felicitación confirmando que tu cuenta de Instagram ahora está sincronizada con InstaPay. Haz clic en \"Menú principal\" para comenzar a usar los servicios de InstaPay.",
    "Additional Tips": "Consejos adicionales",
    "1.\tSecure Your Account: Ensure your Instagram account is secure by enabling two-factor authentication (2FA) to protect your linked InstaPay services.": "1. Asegura tu cuenta: Asegúrate de que tu cuenta de Instagram esté segura habilitando la autenticación de dos factores (2FA) para proteger tus servicios InstaPay vinculados.",
    "2.\tExplore More: Once linked, explore the wide range of services InstaPay offers, from seamless transactions to instant withdrawals.": "2.  Explora más: Una vez vinculado, explora la amplia gama de servicios que ofrece InstaPay, desde transacciones sin problemas hasta retiros instantáneos.",
    "3.\tStay Updated: Keep an eye on our updates, as we will soon support linking other social media accounts to make your experience even more integrated.": "3. Mantente actualizado: Mantente atento a nuestras actualizaciones, ya que pronto admitiremos la vinculación de otras cuentas de redes sociales para que tu experiencia sea aún más integrada.",
    "4.\tHelp & Support: If you face any issues during the linking process, contact our support team for assistance.": "4. Ayuda y soporte: Si enfrentas algún problema durante el proceso de vinculación, contacta a nuestro equipo de soporte para obtener ayuda.",
    "Future Enhancements": "Mejoras futuras",
    "In the near future, InstaPay will allow you to link more social media accounts, providing you with even more flexibility and convenience. Stay tuned for exciting updates!": "En un futuro cercano, InstaPay te permitirá vincular más cuentas de redes sociales, brindándote aún más flexibilidad y conveniencia. ¡Mantente atento a emocionantes actualizaciones!",
    "Personal Transactions": "Transacciones personales",
    "Business Transactions": "Transacciones comerciales",
    "Shopping & Purchases": "Compras y compras",
    "Bills & Utilities": "Facturas y servicios públicos",
    "Entertainment & Leisure": "Entretenimiento y ocio",
    "Investments & Savings": "Inversiones y ahorros",
    "Health & Wellness": "Salud y bienestar",
    "Transportation": "Transporte",
    "Education": "Educación",
    "Miscellaneous": "Diversos",
    "Family Support": "Apoyo familiar",
    "Web - login with phone number": null,
    "Login": "Login",
    "Start Your InstaPay Journey Today!": "¡Empieza tu aventura en InstaPay hoy!",
    "Enter your phone number": "Ingrese su número de teléfono",
    "Login with email": "Iniciar sesión con email",
    "Don't have an account?": "¿No tienes una cuenta?",
    "Sign Up": "Regístrate",
    "Continue": "Continuar",
    "We recommend you download and install the Google Authenticator app": "Recomendamos que descargue e instale la aplicación Google Authenticator",
    "Two-factor authentication (2FA) is the best way to protect yourself online.": "La autenticación de dos factores (2FA) es la mejor manera de protegerse en línea.",
    "Strong security with Google Authenticator": "Seguridad sólida con Google Authenticator",
    "Enter your password": "Ingrese su contraseña",
    "Web Mobile": null,
    "We recommend you download and install the Google Authenticator app to ensure you receive your verification codes instantly, avoiding any SMS delays or issues.": "Recomendamos que descargue e instale la aplicación Google Authenticator para asegurarse de recibir sus códigos de verificación instantáneamente, evitando cualquier retraso o problema con los SMS.",
    "Web - Google Authenticator": null,
    "Google Authenticator": "Google Authenticator",
    "Two Factor Authentication Code": "Código de Autenticación de Dos Factores",
    "Problem with the token?": "¿Problema con el token?",
    "Verify using SMS": "Verificar usando SMS",
    "Verify": "Verificar",
    "Web Mobile - Google Authenticator": null,
    "SMS - Web ": null,
    "SMS Verification": "Verificación por SMS",
    "Enter the SMS code sent to +41********4053": "Ingrese el código SMS enviado a +41********4053",
    "Verify using Authenticator App": "Verificar usando la aplicación Authenticator",
    "Resend code in 120 seconds": "Reenviar código en 120 segundos",
    "Verify ": "Verificar",
    "SMS - Web Mobile": null,
    "My own account": "Mi propia cuenta",
    "Friends & Family": "Amigos y Familia",
    "Aunt": "Tía",
    "Brother-in-law": "Cuñado",
    "Cousin": "Primo",
    "Daughter": "Hija",
    "Father": "Padre",
    "Father-in-law": "Suegro",
    "Friend": "Amigo",
    "Grandfather": "Abuelo",
    "Grandmother": "Abuela",
    "Husband": "Esposo",
    "Mother": "Madre",
    "Mother-in-law": "Suegra",
    "Nephew": "Sobrino",
    "Niece": "Sobrina",
    "Self (i.e. the sender, himself)": "Yo mismo (es decir, el remitente)",
    "Sister": "Hermana",
    "Sister-in-law": "Cuñada",
    "Son": "Hijo",
    "Uncle": "Tío",
    "Wife": "Esposa",
    "Others not listed": "Otros no listados",
    "Merchant": "Comerciante",
    "No relationship": "Sin relación",
    "Add Funds": "Agregar fondos",
    "Enter the Amount": "Ingrese el monto:",
    "Fees": "Tarifas:",
    "You Will Get": "Usted recibirá:",
    "⚠️ The transactional value should be between[Currency ISO code & Amount] - [Currency ISO code & Amount] ": "⚠️ El valor de la transacción debe estar entre [Currency ISO code & Amount] - [Currency ISO code & Amount]",
    "⚠️ Invalid Amount: Your current  identity verification level limits the  maximum amount you can add. Please upgrade your identity verification to benefit from higher limits.           ": "⚠️ Monto inválido: Su nivel actual de verificación de identidad limita la cantidad máxima que puede agregar. Por favor, actualice su verificación de identidad para beneficiarse de límites más altos.",
    "ℹ️ The price of currency changes frequently based on market conditions. Please refer to the price on the confirm order page as your final quotation": "ℹ️ El precio de la moneda cambia frecuentemente según las condiciones del mercado. Consulte el precio en la página de confirmación del pedido como su cotización final.",
    "Next": "Siguiente",
    "Upgrade Identity Verification": "Actualizar verificación de identidad",
    "Request money from anyone, anywhere, including across social networking platforms. With InstaPay, your payment requests are instantly accessible once accepted and funds are sent to your Receivable Accounts. Simplify your transactions and manage payments with ease and security.": "Solicite dinero a cualquier persona, en cualquier lugar, incluidas las plataformas de redes sociales. Con InstaPay, sus solicitudes de pago son accesibles al instante una vez aceptadas y los fondos se envían a sus cuentas de recepción. Simplifique sus transacciones y gestione los pagos con facilidad y seguridad.",
    "You’ve been logged out.": "Has sido desconectado.",
    "For your security, you’ve been logged out after 15 minutes of inactivity. Please log in again if you wish to use InstaPay.": "Por tu seguridad, has sido desconectado después de 15 minutos de inactividad. Por favor, vuelve a iniciar sesión si deseas usar InstaPay.",
    "LOGIN AGAIN": "INICIAR SESIÓN DE NUEVO",
    "Brother": "Hermano",
    "Current Password": "Contraseña actual",
    "The new password cannot be the same as the old one": "La nueva contraseña no puede ser la misma que la anterior.",
    "Minimum password length: [X] characters ": "Longitud mínima de la contraseña: [X] caracteres",
    "Include at least one uppercase and one lowercase letter.": "Incluye al menos una letra mayúscula y una minúscula.",
    "Nominee Information": "Información del nominado",
    "Verification Code Preferences": "Preferencias del código de verificación",
    "How Others Can Find You": "Cómo pueden encontrarte otros",
    "My Social Network Accounts": "Mis cuentas de redes sociales",
    "Login Activity": "Actividad de inicio de sesión",
    "Receiving Accounts": "Cuentas de recepción",
    "Change Your Password To Continue": "Cambie su contraseña para continuar",
    "Continue with": "Continuar con",
    "Back to Login": "Volver al inicio de sesión",
    "Send OTP": "Enviar OTP",
    "A Verification code has been sent to your email and number.": "Se ha enviado un código de verificación a su correo electrónico y número.",
    "Resend available in": "Reenviar disponible en",
    "Create a strong Password": "Cree una contraseña segura",
    "Password must:": "La contraseña debe:",
    "Be between 9 and 64 characters": "Tener entre 9 y 64 caracteres",
    "Include at least two of the following:": "Incluir al menos dos de los siguientes:",
    "Lowercase Character": "Carácter en minúscula",
    "Special Character": "Carácter especial",
    "Password Updated Successfully!": "¡Contraseña actualizada con éxito!",
    "Click below to login": "Haga clic abajo para iniciar sesión",

    "Send Money": "Enviar dinero",
    "Enter the amount": null,
    "Recent Transfer": "Transferencia reciente",
    "See all": "Ver todo",
    "Send again": "Enviar de nuevo",
    "You Send": "Tú envías",
    "They Receive": "Ellos reciben",
    "Receive Method": "Método de recepción",
    "Select method": "Seleccionar método",
    "Next": "Siguiente",
    "Trouble sending money?": "¿Problemas para enviar dinero?",
    "Where do you want to send money ?": "¿Dónde quieres enviar dinero?",
    "Search ": "Buscar",
    "Confirm": "Confirmar",
    "Bank Deposit": "Depósito Bancario",
    "Cash Pick-up": "Retiro de Efectivo",
    "Mobile Wallet": "Mobile Wallet",
    "Bank Card": "Tarjeta Bancaria",
    "Crypto Wallet": "Billetera crypto",
    "What is the receiver’s Mobile Wallet?": "¿Cuál es el Wallet móvil del destinatario?",
    "Transfer fees": "Tarifas de transferencia",
    "Total to pay": "Total a pagar",
    "Choose the beneficiary!": "¡Elige el beneficiario!",
    "Country": "País",
    "Receive method": "Método de recepción",
    "You send": "Tú envías",
    "change": "editar",
    "edit": "añadir nuevo",
    "add new": "Beneficiarios",
    "Beneficiaries": "Gestionar beneficiarios",
    "Manage Beneficiaries": "Buscar",
    "Search": "Siguiente",
    "CASH PICK-UP": "RETIRO EN EFECTIVO",
    "Where will your receiver collect the money": "¿Dónde recogerá el dinero tu receptor?",
    "Cash is available to collect within minutes from any branch of [name] ": "El dinero está disponible para recoger en minutos en cualquier sucursal de [nombre]",
    "BANK ACCOUNT": "CUENTA BANCARIA",
    "Transfer to recipient’s bank account": "Transferir a la cuenta bancaria del receptor",
    "BANK CARD": "TARJETA BANCARIA",
    "Fast transfer directly to recipient’s bank card": "Transferencia rápida directamente a la tarjeta bancaria del receptor",
    "CRYPTO WALLET": "WALLET CRYPTO",
    "Fast transfer directly to a crypto wallet": "Transferencia rápida directamente a un Wallet crypto",
    "Review transfer": "Revisar transferencia",
    "Receiver's Details": "Detalles del receptor",
    "Edit": "Editar",
    "Name": "Nombre",
    "Reason for sending": "Razón para enviar",
    "Sending": "Enviando",
    "Receiver gets": "El receptor recibe",
    "Exchange rate": "Tasa de cambio",
    "Estimated time": "Tiempo estimado",
    "Select Payment Type": "Seleccionar tipo de pago",
    "Credit cards": "Tarjetas de crédito",
    "Domestic cards": "Tarjetas nacionales",
    "Mobile Wallets": "Wallets móviles",
    "InstaPay Wallet": "Wallet InstaPay",
    "Crypto (USDT, ETH, BTC)": "Crypto (USDT, ETH, BTC)",
    "PayPal": "PayPal",
    "CREDIT CARD": "TARJETA DE CRÉDITO",
    "Credit Card": "Tarjeta de crédito",
    "Select card": "Seleccionar tarjeta",
    "Card Number": "Número de tarjeta",
    "Enter the 16-digit card number on the card": "Ingrese el número de tarjeta de 16 dígitos ",
    "Expiry Date": "Fecha de vencimiento",
    "Enter the expiration date of the card": "Ingrese la fecha de vencimiento de la tarjeta",
    "CVV Number": "Número CVV",
    "Enter the 3 or 4 digit number on the card": "Ingrese el número de 3 o 4 dígitos de la tarjeta",
    "INSTAPAY WALLET": "WALLET INSTAPAY",
    "Select Wallet": "Seleccionar billetera",
    "My Wallet": "Mi Wallet",
    "Conversion": "Conversión",
    "Convert": "Convertir",
    "Your payment is confirmed": "Su pago está confirmado",
    "Thanks for using InstaPay": "Gracias por usar InstaPay",
    "Status": "Estado",
    "Share": "Compartir",
    "Transfer created": "Transferencia creada",
    "Payment received": "Pago recibido",
    "Payment processed": "Pago procesado",
    "Transfer successful": "Transferencia exitosa",
    "It may take few hours for the funds to appear in [user name] account": "Puede tomar unas pocas horas para que los fondos aparezcan en la cuenta de [nombre del usuario]",
    "Transfer failed": "Transferencia fallida",
    "Transaction Details": "Detalles de la transacción",
    "Need help?": "¿Necesitas ayuda?",
    "Service Details": "Detalles del Servicio",
    "Funds Deposit Speed": "Disponibilidad de Fondos",
    "Cut-off Time": "Hora Límite",
    "Bank holiday list": "Lista de Días Feriados Bancarios",
    "Threshold limits": "Límites Transaccionales",
    "null": null,
    "Instant, Real Time": "Instantáneo, en tiempo real",
    "Same day, T+1": "El mismo día, T+1",
    "Non-instant, T+1": "No instantáneo, T+1",
    "Instant Payment, Real Time": "Pago instantáneo, en tiempo real",
    "Transaction processed before 3:00 PM Local time": "Transacción procesada antes de las 3:00 PM hora local",
    "Delivered the same day": "Entregado el mismo día",
    "Transactions processed post 3:00 PM": "Transacciones procesadas después de las 3:00 PM",
    "T+1 business days": "T+1 días hábiles",
    "Payments made on weekdays before 3:00PM will be credited to the receiving bank account by midnight of the\nsame day.": "Los pagos realizados en días laborables antes de las 15:00 serán acreditados en la cuenta bancaria receptora antes de la medianoche del mismo día.",
    "Payments made on weekdays after 15:00 will be credited by midnight the following day.": "Los pagos realizados en días laborables después de las 15:00 serán acreditados antes de la medianoche del día siguiente.",
    "Payments made on a Saturday, Sunday or Public holiday will be credited to the account by midnight of the 1st following business day": "Los pagos realizados un sábado, domingo o festivo serán acreditados en la cuenta antes de la medianoche del siguiente día hábil.",
    "24*7": "24*7",
    "09hrs - 18 hrs Local Time": "09hrs - 18 hrs hora local",
    "https://www.officeholidays.com/countries ": "https://www.officeholidays.com/countries ",
    "Min per transaction limit:": "Límite mínimo por transacción:",
    "Maximum per transaction limit:": "Límite máximo por transacción:",
    "Daily threshold limit:": "Límite diario:",
    "Weekly threshold limit:": "Límite semanal:",
    "Monthly threshold limit:": "Límite mensual:",
    "Yearly threshold limit:": "Límite anual:",
    "P2P": "P2P",
    "P2B": "P2B",
    "B2P": "B2P",
    "B2B": "B2B",
    "WALLET TO WALLET": "WALLET TO WALLET",
    "Basic Account (Unverified)": "Cuenta básica (No verificada)",
    "Identity Verified": "Identidad verificada"

}