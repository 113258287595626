export class ConfigDB {
	static data = {
		settings: {
			layout_type: 'ltr',
			layout_mode: '',
			sidebar: {
				type: 'compact-wrapper',
			},
			sidebar_setting: 'default-sidebar',
		},
		color: {
			primary_color: '#5926f0',
			secondary_color: '#e64067',
			mix_background_layout: 'light-only',
		},
	}
}
export default ConfigDB;




