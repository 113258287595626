export const de = {
    "How Others Can Find You": "Wie andere dich finden können",
    "Schedules": "Zeitpläne",
    "Login": "Login",
    "Start Your InstaPay Journey Today!": "Beginnen Sie heute Ihre InstaPay-Reise!",
    "Phone Number or Email": "Telefonnummer oder E-Mail",
    "Login Using Social Media": "Login mit sozialen Medien",
    "Continue": "Fortsetzen",
    "Don't Have an Account? Sign Up": "Noch kein Konto? Registrieren",
    "Sign Up": "Registrieren",
    "Edit": "Bearbeiten",
    "Enter Your Password": "Geben Sie Ihr Passwort ein",
    "Forgot Password?": "Passwort vergessen?",
    "Account Type": "Kontotyp",
    "Individual": "Privatperson",
    "Business": "Geschäft",
    "Already Have an Account? Sign In": "Sie haben bereits ein Konto? Anmelden",
    "Sing In": "Anmelden",
    "Enter Your Personal Details": "Geben Sie Ihre persönlichen Daten ein",
    "Add Photo or Logo": "Foto oder Logo hinzufügen",
    "Enter Your Name": "Geben Sie Ihren Namen ein",
    "Back": "Zurück",
    "Phone Number": "Telefonnummer",
    "Email Address": "E-Mail-Adresse",
    "Create a Strong Password": "Erstellen Sie ein starkes Passwort",
    "Password Requirements": "Passwortanforderungen",
    "Must be between 9 and 64 characters": "Muss zwischen 9 und 64 Zeichen lang sein",
    "Include at least two of the following:": "Enthalten Sie mindestens zwei der Folgenden:",
    "Uppercase character": "Großbuchstabe",
    "Lowercase character": "Kleinbuchstabe",
    "Number": "Zahl",
    "Special character": "Sonderzeichen",
    "Confirm Password": "Passwort bestätigen",
    "Finalize Your Process": "Vervollständigen Sie Ihren Prozess",
    "Referral Code (Optional)": "Empfehlungscode (Optional)",
    "It will autofill if you're signing up with an invitation link.": "Wird automatisch ausgefüllt, wenn Sie sich mit einem Einladungslink anmelden.",
    "I agree with": "Ich stimme zu",
    "Terms & Conditions": "Geschäftsbedingungen",
    "Privacy Policy": "Datenschutzrichtlinie",
    "Create Your Account": "Erstellen Sie Ihr Konto",
    "Enter Code": "Code eingeben",
    "A one-time verification code has been sent to your email address  and phone number.": "Ein einmaliger Verifizierungscode wurde an Ihre E-Mail-Adresse gesendet und Telefonnummer geschickt.",
    "Resend available in ": "Erneuter Versand verfügbar in",
    "seconds": "Sekunden",
    "Verify": "Verifizieren",
    "Verification Code Verified": "Verifizierungscode bestätigt",
    "Please continue to the next step.": "Bitte fahren Sie mit dem nächsten Schritt fort.",
    "Finish": "Fertigstellen",
    "Finishing": "Fertigstellung",
    "Company Name": "Firmenname",
    "Enter Your Business Details": "Geben Sie Ihre Geschäftsdetails ein",
    "Country ": "Land",
    "You cannot change the country once your account is verified.": "Sie können das Land nicht ändern, sobald Ihr Konto verifiziert ist.",
    "Learn more": "Mehr erfahren",
    "Optional": "Optional",
    "Continuing": "Fortfahren",
    "Didn't receive the verification code?": "Sie haben den Bestätigungscode nicht erhalten?",
    "Resend Now": "Jetzt erneut senden",
    "Search Here": "Hier suchen",
    "Search for users, services, and more on InstaPay": "Suchen Sie nach Benutzern, Dienstleistungen und mehr auf InstaPay",
    "Search users here": "Benutzer hier suchen",
    "Earn money with our affiliate program!": "Verdienen Sie Geld mit unserem Partnerprogramm!",
    "Generate passive income by sharing your InstaPay affiliate code with your followers. For each transaction they make, \nyou earn a commission, thereby maximizing the profits from your online influence.": "Generieren Sie passives Einkommen, indem Sie Ihren InstaPay-Partnercode mit Ihren Followern teilen. Für jede Transaktion, die sie tätigen,\nverdienen Sie eine Provision und maximieren so Ihre Gewinne aus Ihrem Online-Einfluss.",
    "Learn More": "Mehr erfahren",
    "Balance Amount": "Saldo",
    "You spent less compared to last month.": "Sie haben weniger ausgegeben als im letzten Monat.",
    "Referral Rewards": "Empfehlungsbelohnungen",
    "Bitcoin": "Bitcoin",
    "USDT": "USDT",
    "Ethereum": "Ethereum",
    "Quick Transfer to Your Most Recent Contacts:": "Schnelle Überweisung an Ihre neuesten Kontakte:",
    "Number of Transactions": "Anzahl der Transaktionen",
    "Total Transaction Amount": "Gesamtbetrag der Transaktion",
    "Total Sent": "Gesamt gesendet",
    "Total Received": "Gesamt empfangen",
    "Add Funds": "Gelder hinzufügen",
    "Send Money": "Geld senden\n",
    "Request Money": "Geld anfordern",
    "Send a Quote": "Ein Angebot senden",
    "Pay With Crypto": "Mit Krypto bezahlen",
    "Payment Insights": "Zahlungseinblicke",
    "Recent Transactions ": "Neueste Transaktionen",
    "Today": "Heute",
    "My Wallets": "Meine Wallets",
    "Balance": "Saldo",
    "Wallet ID": "Wallet-ID",
    "Monthly Limit": "Monatslimit",
    "Top Up": "Aufladen",
    "Summary": "Zusammenfassung",
    "Settings": "Einstellungen",
    "More": "Mehr",
    "Upgrade to Business Account": "Upgrade auf Geschäftskonto",
    "Conversion": "Konvertierung",
    "Enter Amount": "Betrag eingeben",
    "Exchanged Amount": "Umgetauschter Betrag",
    "Convert": "Konvertieren",
    "You Are Converting": "Sie konvertieren",
    "From": "Von",
    "To": "Nach",
    "Exchange Rate": "Wechselkurs",
    "Fees": "-Aus-der-Liste-auswählen-",
    "You Will Receive": "Sie werden erhalten",
    "Slide To Confirm": "Zum Bestätigen wischen",
    "Verification Code Confirmed - You'll be redirected to the next step": "Verifizierungscode bestätigt - Sie werden zum nächsten Schritt weitergeleitet",
    "Moving to next step in": "Weiter zum nächsten Schritt in",
    "Thanks for using InstaPay": "Danke für die Nutzung von InstaPay",
    "Your funds have been credited to your wallet.": "Ihre Gelder wurden Ihrer Wallet gutgeschrieben.",
    "Dashboard": "Dashboard",
    "Accounts": "Konten",
    "Transactions": "Transaktionen",
    "Other Transactions": "Andere Transaktionen",
    "Payments": "Zahlungen",
    "Beneficiaries": "Begünstigte",
    "Referrals": "Empfehlungen",
    "Quotations": "Angebote",
    "Pending": "Ausstehend",
    "My QR Code Sticker": "Mein QR-Code-Aufkleber",
    "My Portfolio": "Mein Portfolio",
    "My Payment Address": "Meine Zahlungsadresse",
    "Analytics": "Analytik",
    "Profile": "Profil",
    "Dark Mode": "Dunkelmodus",
    "Support": "Support",
    "Logout": "Abmelden",
    "Powered By": "Unterstützt von",
    "Kemit Kingdom SA": "Kemit Kingdom SA",
    "A Swiss Company": "Ein Schweizer Fintech-Unternehmen",
    "MAIN MENU": "HAUPTMENÜ",
    "OTHERS": "ANDERE",
    "Wallet": "Wallet",
    "Default": "Standard",
    "Breakdown": "Aufschlüsselung",
    "Credit": "Guthaben",
    "Debit": "Belastung",
    "Wallet Management": "Wallet-Verwaltung",
    "Top-up Your Wallet": "Ihre Wallet aufladen",
    "Download Statement": "Kontoauszug herunterladen",
    "Block the Wallet": "Die Wallet blockieren",
    "Wallet Status": "Wallet-Status",
    "-Select-File-Type-": "-Dateityp auswählen-",
    "Download Now": "Jetzt herunterladen",
    "Downloading": "Herunterladen",
    "All": "Alle",
    "Sent": "Gesendet",
    "Received": "Empfangen",
    "Requested": "Angefordert",
    "Quoted": "Angeboten",
    "Search Transactions": "Transaktionen suchen",
    "Date & Time": "Datum & Uhrzeit",
    "Type": "Typ",
    "Transaction ID": "Transaktions-ID",
    "Recipient": "Empfänger",
    "Amount": "Betrag",
    "Status": "Status",
    "Payment Type": "Zahlungsart",
    "Sent Payments": "Gesendete Zahlungen",
    "Received Payments": "Empfangene Zahlungen",
    "Date": "Datum",
    "Cycles/Time": "Zyklen/Zeit",
    "Payment As": "Zahlung als",
    "Name": "Name",
    "Cancel": "Abbrechen",
    "Subscriptions & Scheduled": "Abonnements & Geplantes",
    "Select the option that matches your needs from the list below:": "Wählen Sie die Option, die Ihren Bedürfnissen aus der Liste unten entspricht:",
    "Easily send money to friends, family, or businesses locally or internationally. \nChoose from multiple transfer options, including bank deposits, mobile wallets, \ncash pick-up, payment cards, and wallet-to-wallet transfers. Enjoy the convenience \nand security of InstaPay for seamless and instant money transfers.": "Senden Sie ganz einfach Geld an Freunde, Familie oder Geschäfte, lokal oder international.\nWählen Sie aus mehreren Überweisungsoptionen, einschließlich Banküberweisungen, mobilen Wallets,\nBargeldabholung, Zahlungskarten und Wallet-zu-Wallet-Überweisungen. Genießen Sie den Komfort\nund die Sicherheit von InstaPay für nahtlose und sofortige Geldtransfers.",
    "International Transfer": "Internationale Überweisung\n",
    "Send money across borders with ease. Transfer funds internationally \nto friends, family, or businesses in just a few clicks. Choose from \na wide range of payment channels including bank account, \nmobile money/mobile wallet, cash pick-up location, or payment card \nfor convenient and secure transactions.": "Senden Sie mühelos Geld über Grenzen hinweg. Überweisen Sie international\nGeld an Freunde, Familie oder Geschäfte mit nur wenigen Klicks. Wählen Sie aus\neiner breiten Palette von Zahlungskanälen, einschließlich Bankkonten,\nMobile Money/Mobile Wallet, Bargeldabholungsorten oder Zahlungskarten\nfür bequeme und sichere Transaktionen.\"",
    "Select the country where you want to send the money!": "Wählen Sie das Land, in das Sie Geld senden möchten!\n",
    "Search Country": "Land suchen",
    "Trouble Sending Money?": "Probleme beim Senden von Geld?",
    "Confirm": "Bestätigen",
    "Select Payout Channel": "Auszahlungskanal wählen",
    "Select your most convenient option to send the money.": "Wählen Sie Ihre bequemste Option, um Geld zu senden.",
    "Select The Bank": "Bank auswählen",
    "Search Bank": "Bank suchen",
    "Send money directly to a recipient's bank account. Enjoy the convenience \nand security of transferring funds seamlessly to any bank around the world.": "Senden Sie Geld direkt auf das Bankkonto des Empfängers. Genießen Sie den Komfort\nund die Sicherheit, Geld nahtlos an jede Bank weltweit zu überweisen.",
    "Bank Transfer": "Banküberweisung",
    "Enter the amount": "Betrag eingeben",
    "Tax": "Steuer",
    "Amount the recipient will receive": "Betrag, den der Empfänger erhalten wird",
    "Select Remarks / Reason": "Bemerkungen/Grund auswählen",
    "Comments": "Kommentare",
    "Attach Files": "Dateien anhängen",
    "Groups": "Gruppen",
    "Manage Groups": "Gruppen verwalten",
    "Add New Group": "Neue Gruppe hinzufügen",
    "Invite": "Person einladen",
    "Manage Beneficiaries": "Begünstigte verwalten",
    "Recipients List": "Empfängerliste",
    "Search Contacts": "Kontakte suchen",
    "Confirm Your Payment": "Zahlung bestätigen",
    "Select Country": "Land auswählen",
    "Select the Recipient's Payment Channel": "Zahlungskanal des Empfängers auswählen",
    "Change Selection": "Auswahl ändern",
    "You Are Transferring ": "Sie überweisen",
    "Oops! Something Went Wrong.": "Oops! Etwas ist schiefgelaufen.",
    "We're Sorry About That. ": "Es tut uns leid.",
    "Go back to Payments Page.": "Zurück zur Zahlungsseite.",
    "Wallet to Wallet Transfer": "Wallet zu Wallet Überweisung",
    "Request a customized price quote from service providers or vendors.": "Fordern Sie ein individuelles Preisangebot von Dienstleistern oder Lieferanten an.",
    "Enter the Wallet ID": "Geben Sie die Wallet-ID ein",
    "Enter the Wallet ID, Email, or Phone Number of the recipient.": "Geben Sie die Wallet-ID, E-Mail oder Telefonnummer des Empfängers ein.",
    "Recipient not on InstaPay?": "Ist der Empfänger nicht bei InstaPay? Laden Sie ihn ein.",
    "Invite Them": "Einladen",
    "My Beneficiaries": "Meine Begünstigten",
    "Enter the Amount You Wish to Transfer.": "Geben Sie den Betrag ein, den Sie überweisen möchten.",
    "Sending to ": "Senden an",
    "You Have Selected Wallet to Wallet Transfer": "Sie haben Wallet zu Wallet Überweisung ausgewählt",
    "Authenticate Your Payment.": "Zahlung authentifizieren.",
    "You are transferring": "Sie überweisen",
    "Total ": "Gesamt",
    "Where Is My Payment?": "Wo ist meine Zahlung?",
    "Share": "Teilen",
    "Your Payment Is Confirmed": "Ihre Zahlung ist bestätigt",
    "QR Code Payment": "QR-Code-Zahlung",
    "Instantly transfer money to other InstaPay users with our secure wallet-to-wallet \nservice. Send and receive funds hassle-free within seconds.": "Überweisen Sie sofort Geld an andere InstaPay-Nutzer mit unserem sicheren Wallet-zu-Wallet Service. Senden und \nempfangen Sie Geld innerhalb von Sekunden problemlos.",
    "Scan QR with Your Camera or Upload from Your Device.": "Scannen Sie den QR-Code mit Ihrer Kamera oder laden Sie ihn von Ihrem Gerät hoch.",
    "Upload from Your Device.": "Von Ihrem Gerät hochladen.",
    "Alternatively, Use the InstaPay Unique Payment Address (UPA)": "Alternativ verwenden Sie die einzigartige InstaPay Zahlungsadresse (UPA)",
    "Enter InstaPay UPA": "Geben Sie die InstaPay UPA ein",
    "Recipient not on InstaPay? Invite Them": "Empfänger nicht bei InstaPay? Laden Sie ihn ein",
    "Verify QR Code": "QR-Code überprüfen",
    "Scan/Upload QR Code of the Recipient": "QR-Code des Empfängers scannen/hochladen",
    "Payment Confirmed": "Zahlung bestätigt",
    "Request customized price quotes from service providers or vendors to receive \naccurate cost estimates for the services or products you need.": "Fordern Sie individuelle Preisangebote von Dienstleistern oder Lieferanten an, um genaue Kostenschätzungen für die \nbenötigten Dienstleistungen oder Produkte zu erhalten.",
    "Choose The Beneficiary or Group": "Wählen Sie den Begünstigten oder die Gruppe aus",
    "Enter the Amount You Wish to Request.": "Geben Sie den Betrag ein, den Sie anfordern möchten.",
    "You Are Requesting Money": "Sie fordern Geld an",
    "You are requesting": "Sie fordern an",
    "As": "Als",
    "Conversion of Your Cryptocurrencies": "Umwandlung Ihrer Kryptowährungen",
    "Experience our user-centric, secure, and efficient Cash-Out Crypto to Fiat Conversion.": "Erleben Sie unsere nutzerzentrierte, sichere und effiziente Umwandlung von Krypto in Fiat.",
    "Choose the Cryptocurrency You Intend to Liquidate.": "Wählen Sie die Kryptowährung aus, die Sie liquidieren möchten.",
    "Next": "Weiter",
    "Enter the Crypto Wallet Address of the Recipient.": "Geben Sie die Krypto-Wallet-Adresse des Empfängers ein.",
    "Minimum Transactional Value: $100.": "Minimaler Transaktionswert: $100.",
    "Note: Crypto prices frequently change. Refer to the final quotation on the confirm order page.": "Hinweis: Kryptopreise ändern sich häufig. Beziehen Sie sich auf das endgültige Angebot auf der Bestellbestätigungsseite.",
    "Select Network": "Netzwerk auswählen",
    "Send A Quote": "Angebot senden",
    "Send a detailed price quote to your clients or customers. Present your services, products, \nor offers professionally with a personalized quotation.": "Senden Sie ein detailliertes Preisangebot an Ihre Kunden oder Klienten. Präsentieren Sie Ihre Dienstleistungen, Produkte\noder Angebote professionell mit einem personalisierten Angebot.",
    "Choose the Beneficiary": "Wählen Sie den Begünstigten aus",
    "Allow Receiver to Bargain": "Empfänger zum Verhandeln zulassen",
    "Enter the Title for Your Quotation.": "Geben Sie den Titel für Ihr Angebot ein.",
    "Enter the Description for Your Quotation": "Geben Sie die Beschreibung für Ihr Angebot ein",
    "You are Quoting": "Sie geben ein Angebot ab",
    "Title": "Titel",
    "Description": "Beschreibung",
    "Attachments": "Anhänge",
    "Confirm Your Quotation": "Ihr Angebot bestätigen",
    "Verify The Quotation": "Das Angebot überprüfen",
    "Your quotation is confirmed": "Ihr Angebot ist bestätigt",
    "Request A Quote": "Angebot anfordern",
    "Coming Soon": "Demnächst verfügbar",
    "Add Beneficiary": "Begünstigten hinzufügen",
    "Search for Beneficiaries": "Nach Begünstigten suchen",
    "Connected Accounts": "Verbundene Konten",
    "Details": "Details",
    "First Name": "Vorname",
    "Last Name": "Nachname",
    "Country": "Land",
    "City": "Stadt",
    "Address Line": "Adresszeile",
    "Mobile Number": "Handynummer",
    "Relationship With Beneficiary": "Beziehung zum Begünstigten",
    "Bank Account": "Bankkonto",
    "Mobile Money/Mobile Wallet": "Mobile Money/Mobile Wallet",
    "Payment Card": "Zahlungskarte",
    "Crypto Wallet": "Krypto-Wallet",
    "InstaPay Account": "InstaPay-Konto",
    "Cash Pickup": "Bargeldabholung",
    "IBAN / SWIFT Code": "IBAN oder Swift/BIC-Code",
    "Swift Code": "Swift-Code",
    "Account Number": "Kontonummer",
    "Bank Name": "Bankname",
    "Branch Name": "Bankfilialenname",
    "Branch Street": "Straße der Bankfiliale",
    "Province": "Provinz",
    "Postal Code": "Postleitzahl",
    "Mobile Money Provider": "Anbieter von Mobile Money",
    "Wallet Name": "Wallet-Name",
    "Wallet Number": "Wallet-Nummer",
    "Card Holder Name": "Name des Karteninhabers",
    "Card Number": "Kartennummer",
    "Expiry Date": "Ablaufdatum",
    "Crypto Currency": "Krypto-Währung",
    "Wallet Address": "Wallet-Adresse",
    "Wallet Holder Name": "Name des Wallet-Inhabers",
    "Wallet Currency": "Wallet-Währung",
    "Select Document Type": "Dokumenttyp auswählen",
    "Enter Document Number": "Dokumentnummer eingeben",
    "Add Individual Account": "Einzelkonto hinzufügen",
    "Add Business Account": "Geschäftskonto hinzufügen",
    "Company Address": "Firmenadresse",
    "Company Email": "Firmen-E-Mail",
    "Company Phone No": "Firmen-Telefonnummer",
    "Total Referrals": "Gesamtzahl der Empfehlungen",
    "My Earnings": "Meine Einnahmen",
    "Your Referral ID": "Ihre Empfehlungs-ID",
    "Share Your Unique Referral Link": "Teilen Sie Ihren einzigartigen Empfehlungslink",
    "How do I Refer a Friend to InstaPay?": "Wie lade ich einen Freund zu InstaPay ein?",
    "Step 01": "Schritt 01",
    "Share your InstaPay unique referral link with your friends.": "Teilen Sie Ihren einzigartigen InstaPay-Empfehlungslink mit Ihren Freunden.",
    "Step 02": "Schritt 02",
    "Earn rewards when they transact $100 or more": "Verdienen Sie Belohnungen, wenn sie Transaktionen über $100 tätigen",
    "Step 03": "Schritt 03",
    "Earn up to $0.06 for every transaction your followers make.": "Verdienen Sie bis zu $0,06 für jede Transaktion, die Ihre Follower tätigen.",
    "My Referrals": "Meine Empfehlungen",
    "Referral Complete": "Empfehlung abgeschlossen",
    "Received Quotations": "Erhaltene Angebote",
    "Sent Quotations": "Gesendete Angebote",
    "Search Pending Items": "Ausstehende Elemente suchen",
    "Actions": "Aktionen",
    "Received Requests": "Erhaltene Anfragen",
    "Sent Requests": "Gesendete Anfragen",
    "No Actions": "Keine Aktionen",
    "You Requested": "Sie haben angefordert",
    "Via": "Über",
    "How was your experience?": "Wie war Ihre Erfahrung?",
    "Leave a Review": "Eine Bewertung hinterlassen",
    "Overall Ratings": "Gesamtbewertungen",
    "Visit Profile": "Profil besuchen",
    "Ratings": "Bewertungen",
    "Reviews": "Rezensionen",
    "No reviews available": "Keine Rezensionen verfügbar",
    "Select Your Currency": "Wählen Sie Ihre Währung aus",
    "Proceed": "Fortfahren",
    "Commision": "Provision",
    "Unlock Seamless Transactions with InstaPay QR Code Sticker": "Nahtlose Transaktionen mit InstaPay QR-Code-Aufkleber freischalten",
    "Effortless and Swift:": "Mühelos und schnell:",
    "Instantly download your unique InstaPay QR code sticker.": "Laden Sie sofort Ihren einzigartigen InstaPay QR-Code-Aufkleber herunter.",
    "Experience hassle-free, cashless payments without needing complex infrastructure like \npayment terminals.": "Erleben Sie problemlose, bargeldlose Zahlungen ohne komplizierte Infrastruktur wie Zahlungsterminals.",
    "Swiftly collect payments – no more tedious entry of credit card or phone numbers.": "Zahlungen schnell erfassen – keine mühsame Eingabe von Kreditkarten- oder Telefonnummern mehr.",
    "Each payment includes customer information for your convenience.": "Jede Zahlung enthält Kundeninformationen für Ihre Bequemlichkeit.",
    "Economically Smart:": "Wirtschaftlich klug:",
    "Zero activation fees – start immediately without upfront costs.": "Keine Aktivierungsgebühren – beginnen Sie sofort ohne Anfangskosten.",
    "Forget about fixed monthly charges; pay as you transact.": "Vergessen Sie feste monatliche Gebühren; zahlen Sie, wie Sie handeln.",
    "Ditch the hardware; no terminal costs mean more savings for you.": "Verzichten Sie auf die Hardware; keine Terminalkosten bedeuten mehr Einsparungen für Sie.",
    "Enjoy low transaction fees at just 0.75% per QR code payment.": "Genießen Sie niedrige Transaktionsgebühren von nur 0,75 % pro QR-Code-Zahlung.",
    "We don't impose minimum commissions; you save more with each transaction.": "Wir erheben keine Mindestprovisionen; Sie sparen mehr bei jeder Transaktion.",
    "Experience the joy of instant payments, enhancing your cash flow.": "Erleben Sie die Freude an sofortigen Zahlungen und verbessern Sie Ihren Cashflow.",
    "Make Every Transaction Count with InstaPay": "Machen Sie jede Transaktion mit InstaPay zählen",
    "Wallet QR": "Wallet QR",
    "Download QR": "QR herunterladen",
    "QR Code Status": "QR-Code-Status",
    "Share your QR Code or payment address link to instantly receive payments in your InstaPay wallet.": "Teilen Sie Ihren QR-Code oder Zahlungsadresslink, um sofort Zahlungen in Ihrem InstaPay Wallet zu erhalten.",
    "QR Code Title": "QR-Code-Titel",
    "View Transactions": "Transaktionen anzeigen",
    "My QR Codes:": "Meine QR-Codes:",
    "Load More": "Mehr laden",
    "Benefits Of Portfolio": "Vorteile des Portfolios",
    "Benefits:": "Vorteile:",
    "Enhanced Visibility:": "Verbesserte Sichtbarkeit:",
    "Elevate your public profile. Your portfolio acts as a dynamic showcase, accessible \ndirectly from your Payment Address.": "eben Sie Ihr öffentliches Profil hervor. Ihr Portfolio dient als dynamische Präsentationsplattform, direkt erreichbar \nüber Ihre Zahlungsadresse.",
    "Engage Your Audience:": "Binden Sie Ihr Publikum ein:",
    "Connect with visitors through your creative or professional journey. Let your work \nspeak for you, creating a deeper engagement with potential clients or supporters.": "Vernetzen Sie sich mit Besuchern durch Ihre kreative oder berufliche Reise. Lassen Sie Ihre Arbeit für sich sprechen und \nschaffen Sie so eine tiefere Bindung zu potenziellen Kunden oder Unterstützern.",
    "Integrated Social Proof:": "Integrierter Social Proof:",
    "Display your social media influence. Visitors can see your social media reach, \nadding credibility and context to your portfolio.": "Zeigen Sie Ihren Einfluss in den sozialen Medien. Besucher können Ihre Reichweite in sozialen Medien sehen,\nwas Glaubwürdigkeit und Kontext zu Ihrem Portfolio hinzufügt.",
    "Seamless Transactions:": "Nahtlose Transaktionen:",
    "Enable visitors to request or initiate payments right from your public page. Your \nportfolio not only showcases your work but also facilitates easy financial interactions.": "Ermöglichen Sie Besuchern, direkt von Ihrer öffentlichen Seite aus Zahlungen anzufordern oder zu initiieren. Ihr\nPortfolio präsentiert nicht nur Ihre Arbeit, sondern erleichtert auch finanzielle Interaktionen.",
    "Personalized Storytelling:": "Personalisiertes Storytelling:\n",
    "Use the 'About Me' section to share your story, mission, or vision, creating a\n personal connection with your audience.": "Nutzen Sie den Abschnitt 'Über Mich', um Ihre Geschichte, Mission oder Vision zu teilen und eine\npersönliche Verbindung zu Ihrem Publikum herzustellen.",
    "My Portfolio' is more than just a gallery; it's a tool to build your brand, engage your \naudience, and streamline your financial interactions on InstaPay.": "Mein Portfolio' ist mehr als nur eine Galerie; es ist ein Werkzeug, um Ihre Marke aufzubauen, Ihr Publikum zu binden \nund Ihre finanziellen Interaktionen auf InstaPay zu vereinfachen.",
    "Showcase Your Work, Amplify Your Reach. 'My Portfolio' is your digital canvas on \nInstaPay. Upload and display a diverse range of content – from captivating images \nand videos to informative PDFs. Whether you're an artist, freelancer, or \nentrepreneur, this feature lets you present your work or projects in a visually \nengaging way.": "Präsentieren Sie Ihre Arbeit, Vergrößern Sie Ihre Reichweite. 'Mein Portfolio' ist Ihre digitale Leinwand auf InstaPay. \nLaden Sie eine vielfältige Auswahl an Inhalten hoch – von fesselnden Bildern und Videos bis hin zu informativen PDFs. \nOb Sie Künstler, Freiberufler oder Unternehmer sind, dieses Feature ermöglicht es Ihnen, Ihre Arbeit oder Projekte auf eine \nvisuell ansprechende Weise zu präsentieren.",
    "Add/Manage Portfolio": "Portfolio hinzufügen/verwalten",
    "Add New": "Neu hinzufügen",
    "Maximize Your Social Media Earnings with Your InstaPay Payment Address": "Maximieren Sie Ihre Einnahmen in sozialen Medien mit Ihrer InstaPay Zahlungsadresse",
    "Make It Effortless for Your Audience to Support You:": "Machen Sie es Ihrem Publikum leicht, Sie zu unterstützen:",
    "Easily integrate your InstaPay Payment Address into your social media posts.": "Integrieren Sie Ihre InstaPay Zahlungsadresse einfach in Ihre Beiträge in sozialen Medien.",
    "Easily copy and paste your payment address link into each of your posts, stories, or prominently on your profile.": "Kopieren und fügen Sie den Link Ihrer Zahlungsadresse einfach in jeden Ihrer Beiträge, Stories oder auffällig in Ihrem Profil ein.",
    "Ideal for influencers, artists, entrepreneurs, or anyone with a community eager to show \ntheir support.": "Ideal für Influencer, Künstler, Unternehmer oder jeden, der eine Gemeinschaft hat, die ihre Unterstützung zeigen möchte.",
    "Instant Support, Endless Possibilities:": "Sofortige Unterstützung, endlose Möglichkeiten:",
    "Transform the way you monetize your social media content.": "Verändern Sie die Art und Weise, wie Sie Ihre Inhalte in sozialen Medien monetarisieren.",
    "Allow your followers to send you funds instantly with just a click, directly from their favorite social networks.": "Ermöglichen Sie Ihren Followern, Ihnen sofort Geld mit nur einem Klick direkt aus ihren bevorzugten sozialen Netzwerken zu senden.",
    "Ideal for real-time support during live streams, as a tipping system for your creative content, or for funding your next big \nproject.": "Ideal für Echtzeit-Unterstützung während Live-Streams, als Trinkgeldsystem für Ihre kreativen Inhalte oder zur Finanzierung Ihres \nnächsten großen Projekts.",
    "Where to Place Your InstaPay Payment Address for Maximum Impact:": "Wo Sie Ihre InstaPay Zahlungsadresse für maximale Wirkung platzieren sollten:",
    "Social Media Profiles: Pin it to your bio or about section for constant visibility.": "Soziale-Medien-Profile: Befestigen Sie es in Ihrem Bio- oder Info-Bereich für ständige Sichtbarkeit.",
    "Posts & Announcements: Incorporate it into your individual posts, particularly those highlighting your work or specific \ncalls for support.": "Beiträge & Ankündigungen: Integrieren Sie es in Ihre individuellen Beiträge, insbesondere solche, die Ihre Arbeit oder spezifische \nAufrufe zur Unterstützung hervorheben.",
    "Stories & Reels: Integrate it into your interactive stories or captivating reels to capture instant contributions.": "Stories & Reels: Integrieren Sie es in Ihre interaktiven Stories oder fesselnden Reels, um sofortige Beiträge zu erfassen.",
    "Live Streams: Share it during your LIVE sessions, making it easy for viewers to contribute in real-time.": "Live-Streams: Teilen Sie es während Ihrer LIVE-Sitzungen, um es Zuschauern leicht zu machen, in Echtzeit beizutragen.",
    "Simple, Fast, and Secure:": "Einfach, schnell und sicher:",
    "No complex setup – get started in moments.": "Keine komplexe Einrichtung – starten Sie in wenigen Momenten.",
    "Enjoy the security and speed of InstaPay's trusted payment processing.": "Genießen Sie die Sicherheit und Geschwindigkeit der vertrauenswürdigen Zahlungsabwicklung von InstaPay.",
    "Receive payments from anyone, anywhere, without them needing an InstaPay account.": "Empfangen Sie Zahlungen von jedem, überall, ohne dass diese ein InstaPay-Konto benötigen.",
    "Elevate Your Social Media Game with InstaPay Payment Address": "Steigern Sie Ihr Spiel in sozialen Medien mit der InstaPay Zahlungsadresse",
    "InstaPay VPA (Virtual Payment Address)": "InstaPay VPA (Virtuelle Zahlungsadresse)",
    "Payment Address Title": "Titel der Zahlungsadresse",
    "Currency": "Währung",
    "Payment Address Description": "Beschreibung der Zahlungsadresse",
    "Update": "Aktualisieren",
    "Updating": "Aktualisierung",
    "Total Transaction": "Gesamttransaktion",
    "Last 30 days": "Letzte 30 Tage",
    "Last 1 month": "Letzter 1 Monat",
    "Last 1 year": "Letztes 1 Jahr",
    "Custom Date": "Benutzerdefiniertes Datum",
    "Payment Methods": "Zahlungsmethoden",
    "Payment Types": "Zahlungsarten",
    "Requested Amount": "Angeforderter Betrag",
    "Quoted Amount": "Angebotener Betrag",
    "About Me": "Über Mich",
    "Followers": "Follower",
    "InstaPay ID": "InstaPay ID",
    "Download QR Code": "QR-Code herunterladen",
    "Initiate A Payment": "Zahlung initiieren",
    "Report this person": "Diese Person melden",
    "Add now": "Jetzt hinzufügen",
    "Recent Reviews": "Neueste Bewertungen",
    "Reviews As Seller": "Bewertungen als Verkäufer",
    "Reviews As Buyer": "Bewertungen als Käufer",
    "Select Language": "Sprache auswählen",
    "Profile Completion": "Profilvervollständigung",
    "Profile Completed": "Profil abgeschlossen",
    "Basic Info": "Basisinformationen",
    "Username": "Benutzername",
    "Gender": "Geschlecht",
    "Male": "Männlich",
    "Female": "Weiblich",
    "-Select-Gender-": "-Geschlecht auswählen-",
    "Date Of Birth": "Geburtsdatum",
    "Address": "Adresse",
    "Edit Profile": "Profil bearbeiten",
    "Update Profile": "Profil aktualisieren",
    "Updating Profile": "Profilaktualisierung",
    "Complete Now": "Jetzt vervollständigen",
    "Password": "Passwort",
    "Change Password": "Passwort ändern",
    "New Password": "Neues Passwort",
    "Re-enter New Password": "Neues Passwort erneut eingeben",
    "Please follow this guide for a strong password": "Bitte folgen Sie dieser Anleitung für ein starkes Passwort",
    "Include at least one special character.": "Mindestens ein Sonderzeichen einschließen.",
    "Minimum of 8 characters.": "Mindestens 8 Zeichen.",
    "Include at least one number": "Mindestens eine Zahl einschließen",
    "Change it often for enhanced security.": "Ändern Sie es oft für erhöhte Sicherheit.",
    "Security Questions": "Sicherheitsfragen",
    "Security Question": "Sicherheitsfrage",
    "Select your question": "Wählen Sie Ihre Frage aus",
    "Answer To The Question": "Antwort auf die Frage",
    "Nominee": "Nominierte",
    "Relationship with Nominee": "Beziehung zum Nominierten",
    "Nominee Contact Number": "Kontaktnummer des Nominierten",
    "Nominee Address": "Adresse des Nominierten",
    "Delete Nominee": "Nominierten löschen",
    "Update Nominee": "Nominierten aktualisieren",
    "Details Updated Successfully!": "Details erfolgreich aktualisiert!",
    "Success": "Erfolg",
    "You may elect a Nominee of your choice, provided they are not a minor, through the InstaPay \nplatform itself. You acknowledge that no wet signature is required to appoint the nominee, and \nthe online registration will be considered final and binding. In the event of the death of an \nInstaPay Wallet holder, the balance in the Wallet will be transferred to the registered Nominee. \nKEMIT KINGDOM will be discharged from all its liabilities upon the handover of the amount to \nthe Nominee. The Nominee will provide any documents required by InstaPay, including those \nfor identification and proof of death.": "Sie können einen Nominierten Ihrer Wahl benennen, vorausgesetzt, er ist nicht minderjährig, über die InstaPay-Plattform selbst. \nSie erkennen an, dass keine nasse Unterschrift erforderlich ist, um den Nominierten zu ernennen, und die Online-Registrierung wird \nals endgültig und bindend betrachtet. Im Falle des Todes eines InstaPay Wallet-Inhabers wird das Guthaben in der Wallet auf den \nregistrierten Nominierten übertragen. KEMIT KINGDOM wird von allen seinen Verbindlichkeiten entlastet, sobald der Betrag an den \nNominierten übergeben wird. Der Nominierte wird alle von InstaPay benötigten Dokumente bereitstellen, einschließlich derer zur \nIdentifikation und zum Nachweis des Todes.",
    "Add Nominee": "Nominierten hinzufügen",
    "Two Factor Authentication": "Zwei-Faktor-Authentifizierung",
    "SMS Number": "SMS-Nummer",
    "Updated Successfully!": "Erfolgreich aktualisiert!",
    "Social Network Accounts": "Soziale Netzwerkkonten",
    "Here, you can set up and manage your integration settings.": "Hier können Sie Ihre Integrationseinstellungen einrichten und verwalten.",
    "Social Network Account": "Soziales Netzwerkkonto",
    "Activate Account": "Konto aktivieren",
    "Enter the code below in the InstaPay Chatbot.": "Geben Sie den untenstehenden Code im InstaPay Chatbot ein.",
    "The code will expire in ": "Der Code läuft ab in",
    "Notifications": "Benachrichtigungen",
    "Customize how you receive notifications. These settings apply to the activities you are monitoring.": "Passen Sie an, wie Sie Benachrichtigungen erhalten. Diese Einstellungen gelten für die Aktivitäten, die Sie überwachen.",
    "Activity": "Aktivität",
    "Email": "E-Mail",
    "Push": "Push",
    "SMS": "SMS",
    "Source": "Quelle",
    "Payment Requests": "Zahlungsanfragen",
    "Bulk Payments": "Massenzahlungen",
    "Identity Verification": "Identitätsüberprüfung",
    "Verify Your Identity": "Überprüfen Sie Ihre Identität",
    "Identity Verification Status:": "Status der Identitätsüberprüfung:",
    "Briefly describe yourself and explain your reasons for using InstaPay.": "Beschreiben Sie sich kurz und erklären Sie Ihre Gründe für die Nutzung von InstaPay.",
    "Enter Your Message": "Geben Sie Ihre Nachricht ein",
    "Select Occupation": "Beruf auswählen",
    "It must be a government-issued identification document with a photo. All identification documents \nmust be written in the Latin alphabet. If not, a certified translation is required.": "Es muss ein von der Regierung ausgestelltes Identifikationsdokument mit Foto sein. Alle Identifikationsdokumente müssen in \nlateinischen Buchstaben geschrieben sein. Wenn nicht, ist eine beglaubigte Übersetzung erforderlich.",
    "Accepted Documents": "Akzeptierte Dokumente",
    "A national identity card": "Ein gültiger Personalausweis",
    "A valid passport": "Ein gültiger Reisepass",
    "Valid residence permits (accepted only if issued by a European country or in Switzerland)": "Gültige Aufenthaltsgenehmigungen (nur akzeptiert, wenn von einem europäischen Land oder der Schweiz ausgestellt)",
    "Biometric Facial Recognition: Comparing video with passport photograph": "Biometrische Gesichtserkennung: Vergleich von Video mit Passfoto",
    "Address Verification:": "Adressüberprüfung:",
    "Provide a bank statement or utility bill (less than 3 months old) as proof of address": "Stellen Sie einen Kontoauszug oder eine Nebenkostenabrechnung (weniger als 3 Monate alt) als Adressnachweis bereit",
    "Additional Documents:": "Zusätzliche Dokumente:",
    "Kemit Kingdom reserves the right to request any additional documents (Enhanced Due Diligence - EDD) \nor further information necessary for conducting thorough verifications, in accordance with its legal obligations.": "Kemit Kingdom behält sich das Recht vor, zusätzliche Dokumente (Enhanced Due Diligence - EDD) oder weitere Informationen \nanzufordern, die für eine gründliche Überprüfung erforderlich sind, gemäß seinen rechtlichen Verpflichtungen.\"",
    "Start Verification": "Überprüfung starten",
    "Sessions": "Sitzungen",
    "This is a list of devices that have logged into your account. Remove any that you do not recognize.": "Dies ist eine Liste der Geräte, die sich in Ihr Konto eingeloggt haben. Entfernen Sie alle, die Sie nicht erkennen.",
    "Your Withdrawal Options": "Ihre Auszahlungsoptionen",
    "Choose your preferred method for withdrawing payments.": "Wählen Sie Ihre bevorzugte Methode zur Auszahlung von Zahlungen.",
    "No withdrawal options currently available in your country.": "Derzeit sind keine Auszahlungsoptionen in Ihrem Land verfügbar.",
    "Download Your Data": "Laden Sie Ihre Daten herunter",
    "Request a Copy of Your Data": "Fordern Sie eine Kopie Ihrer Daten an",
    "Submit a request to download a copy of your data": "Reichen Sie eine Anfrage ein, um eine Kopie Ihrer Daten herunterzuladen",
    "Which Data Should Be Included in the Download?": "Welche Daten sollten im Download enthalten sein?",
    "Select all applicable options.": "Wählen Sie alle zutreffenden Optionen aus.",
    "Personal Information": "Persönliche Informationen",
    "This information is used to secure your account, tailor your experience, and contact you as necessary.": "Diese Informationen werden verwendet, um Ihr Konto zu sichern, Ihr Erlebnis anzupassen und Sie bei Bedarf zu kontaktieren.",
    "Name and date of birth": "Name und Geburtsdatum",
    "Phone number": "Telefonnummer",
    "Financial Information": "Finanzinformationen",
    "We use this information to enable faster checkout and to send or receive money in just a few clicks.": "Wir verwenden diese Informationen, um einen schnelleren Checkout zu ermöglichen und um in nur wenigen Klicks Geld zu senden \noder zu empfangen.",
    "Bank accounts": "Bankkonten",
    "Debit or credit cards": "Debit- oder Kreditkarten",
    "Credit Information": "Kreditinformationen",
    "Includes your credit application information": "Beinhaltet Ihre Kreditantragsinformationen",
    "Other Information (not included in file)": "Andere Informationen (nicht in der Datei enthalten)",
    "Includes device info, technical usage data, geolocation information, marketing preferences, consent history, and data used for other services such as credit, \nidentity verification, communications with PayPal, and third-party processors.": "Beinhaltet Geräteinformationen, technische Nutzungsdaten, Geolokalisierungsinformationen, Marketingpräferenzen, \nEinwilligungshistorie und Daten, die für andere Dienste wie Kredit, Identitätsüberprüfung, Kommunikation mit PayPal und \nDrittanbietern verwendet werden.",
    "Choose file type:": "Wählen Sie den Dateityp:",
    "Select file type": "Dateityp auswählen",
    "PDF file can be easily opened on any computer": "PDF-Datei kann auf jedem Computer einfach geöffnet werden",
    "Submit Request": "Anfrage einreichen",
    "Delete Your Account": "Löschen Sie Ihr Konto",
    "Ask us to delete your data and close this account": "Bitten Sie uns, Ihre Daten zu löschen und dieses Konto zu schließen",
    "We use your data to provide services, so if we delete your data, we also need to close your account.": "Wir verwenden Ihre Daten, um Dienstleistungen zu erbringen, also wenn wir Ihre Daten löschen, müssen wir auch Ihr Konto schließen.",
    "If your account is limited, go to the Resolution Center, select 'Go to Account Limitations', then click 'Resolve'.": "Wenn Ihr Konto eingeschränkt ist, gehen Sie zum Resolution Center, wählen Sie 'Go to Account Limitations' und dann klicken Sie auf 'Resolve'.",
    "Make sure any outstanding payments have cleared. We won't be able to close your account until they do.": "Stellen Sie sicher, dass alle ausstehenden Zahlungen geklärt sind. Wir können Ihr Konto erst schließen, wenn dies der Fall ist.",
    "If you have a negative balance, click the Resolve link next to your balance.": "Wenn Sie ein negatives Guthaben haben, klicken Sie auf den Link 'Resolve' neben Ihrem Guthaben.",
    "Once your account is clear, and if you're eligible, we'll close your account and delete your data. We need \nto retain some of your data for legal and regulatory reasons. To learn more, see our ": "Sobald Ihr Konto klar ist und Sie berechtigt sind, werden wir Ihr Konto schließen und Ihre Daten löschen. Wir müssen einige Ihrer Daten \naus rechtlichen und regulatorischen Gründen behalten. Um mehr zu erfahren, sehen Sie unsere ",
    "privacy statement": "Datenschutzerklärung",
    "We'll email you to let you know the status, and if there are any other steps you need to take.": "Wir werden Ihnen eine E-Mail senden, um Sie über den Status zu informieren und ob es weitere Schritte gibt, die Sie unternehmen müssen.",
    "Have more questions about data deletion?": "Haben Sie weitere Fragen zur Datenlöschung?",
    "Contact us": "Kontaktieren Sie uns",
    "I understand that account deletion is permanent and can't be reversed.": "Ich verstehe, dass die Kontolöschung dauerhaft ist und nicht rückgängig gemacht werden kann.",
    "Are you sure you want to close your account?": "Sind Sie sicher, dass Sie Ihr Konto schließen möchten?",
    "Yes, Continue": "Ja, Fortfahren",
    "Logging In": "Einloggen",
    "Complete your profile to streamline your transaction process.": "Vervollständigen Sie Ihr Profil, um Ihren Transaktionsprozess zu vereinfachen.",
    "Skip": "Überspringen",
    "Complete Your Profile": "Vervollständigen Sie Ihr Profil",
    "API Key": "API-Schlüssel",
    "Get API Key": "API-Schlüssel erhalten",
    "Generating New API Key": "Neuen API-Schlüssel generieren",
    "Generate API Key": "API-Schlüssel generieren",
    "Regenerate API Key": "API-Schlüssel regenerieren",
    "To harness the full potential of InstaPay's seamless transactions, follow these quick steps to generate your API key. \nFirst, sign up for an account at [your registration URL] and access the Developer Dashboard. Navigate to the \n'API Keys' section, generate a new key, and ensure its secure storage.": "Um das volle Potenzial der nahtlosen Transaktionen von InstaPay auszuschöpfen, folgen Sie diesen schnellen Schritten, um Ihren API-Schlüssel \nzu generieren. Melden Sie sich zuerst für ein Konto unter [Ihrem Registrierungs-URL] an und greifen Sie auf das Entwickler-Dashboard zu. \nNavigieren Sie zum Abschnitt 'API-Schlüssel', generieren Sie einen neuen Schlüssel und stellen Sie sicher, dass er sicher aufbewahrt wird.",
    "Delete API Key": "API-Schlüssel löschen",
    "Webhook URL": "Webhook-URL",
    "KYB Verification": "KYB-Überprüfung",
    "Additional Files": "Zusätzliche Dateien",
    "Business Verification": "Geschäftsüberprüfung",
    "--Select-Your-Company-Type--": "--Wählen Sie Ihren Unternehmenstyp aus--",
    "Documents Required:": "Erforderliche Dokumente:",
    "Official Company Registry Extract (Entity's Registration/Incorporation Document)": "Offizieller Firmenregisterauszug (Registrierungs-/Inkorporationsdokument der Entität)",
    "This document serves as the company's official 'identity card' and should be obtained from the local business registry. \nIt reflects the current status of the business (active or closed).": "Dieses Dokument dient als offizielle 'Identitätskarte' des Unternehmens und sollte aus dem lokalen Firmenregister bezogen werden.\nEs spiegelt den aktuellen Status des Unternehmens wider (aktiv oder geschlossen).",
    "The document should be current, with an issuance date not exceeding 3 months.": "Das Dokument sollte aktuell sein, mit einem Ausstellungsdatum, das 3 Monate nicht überschreitet.",
    "The document must include the following details:": "Das Dokument muss folgende Angaben enthalten:",
    "Legal name of the company": "Rechtlicher Name des Unternehmens",
    "Full registered address": "Vollständige registrierte Adresse",
    "Company registration number": "Unternehmensregistrierungsnummer",
    "Date of incorporation": "Gründungsdatum",
    "Description of the company's activities/purpose": "Beschreibung der Aktivitäten/Zwecke des Unternehmens",
    "Legal representative's information: full name, date and place of birth, residential address \n(minimum requirement: country of residence), and nationality": "Informationen des gesetzlichen Vertreters: vollständiger Name, Geburtsdatum und -ort, Wohnadresse\n(minimale Anforderung: Land des Wohnsitzes) und Staatsangehörigkeit",
    "If applicable, the document should also include:": "Falls zutreffend, sollte das Dokument auch enthalten:",
    "Brand or trading names": "Marken- oder Handelsnamen",
    "Value Added Tax (VAT) number": "Umsatzsteuer-Identifikationsnummer (USt-IdNr.)",
    "List of Ultimate Beneficial Owners (UBOs) - this is mandatory in some countries, otherwise, \nit may be provided as a separate document.": "Liste der letztendlichen wirtschaftlichen Eigentümer (UBOs) - dies ist in einigen Ländern obligatorisch, ansonsten kann es als separates Dokument \nbereitgestellt werden.",
    "Proof of Legal Representative's Authority": "Nachweis der Befugnis des gesetzlichen Vertreters\n",
    "This document validates an individual's authority to represent the company": "Dieses Dokument validiert die Befugnis einer Person, das Unternehmen zu vertreten\n",
    "Typically, the legal representative is named in the company's registry extract. However, if this is not the case, a \ndocument such as a Power of Attorney or Delegation of Powers must be secured.": "Typischerweise wird der rechtliche Vertreter im Firmenregisterauszug des Unternehmens genannt. Wenn dies jedoch nicht der Fall ist, muss\nein Dokument wie eine Vollmacht oder Delegation von Befugnissen gesichert werden.",
    "Power of Attorney/Delegation of Powers:": "Vollmacht/Delegation von Befugnissen:\n",
    "The individual conferring the authority must be legitimately entitled to do so. This individual should be listed as a legal \nrepresentative in the company's registry extract.": "Die Person, die die Vollmacht erteilt, muss dazu legitimiert sein. Diese Person sollte als gesetzlicher Vertreter im Firmenregisterauszug aufgeführt \nsein.",
    "Ownership Structure and Ultimate Beneficial Owners (UBOs) Documentation": "Dokumentation der Eigentumsstruktur und der Letztbegünstigten Eigentümer (UBOs)",
    "Evidence of the company's listing on the relevant stock exchange should be provided. This could be a page from the \nstock exchange website displaying the company's details.": "Es muss ein Nachweis erbracht werden, dass das Unternehmen an der relevanten Börse gelistet ist. Dies könnte eine Seite von der Börsenwebsite \nsein, die die Details des Unternehmens anzeigt.",
    "If less than 75% of the company's shares are publicly owned, all shareholders who own or control more than 25% of \nthe shares will be subject to Ultimate Beneficial Owner (UBO) verification requirements.": "Wenn weniger als 75% der Aktien des Unternehmens öffentlich gehandelt werden, unterliegen alle Aktionäre, die mehr als 25% der Aktien besitzen \noder kontrollieren, den Überprüfungsanforderungen für den Letztbegünstigten Eigentümer (UBO).",
    "Collection of Identification Documents": "Sammeln von Identifikationsdokumenten\n",
    "The identification document must be government-issued, bearing a photograph of the individual. All identification \ndocuments must be written in the Latin alphabet. If not, a certified translation is required.": "Das Identifikationsdokument muss von der Regierung ausgestellt sein und ein Foto der Person enthalten. Alle Identifikationsdokumente\nmüssen in lateinischer Schrift verfasst sein. Wenn nicht, ist eine beglaubigte Übersetzung erforderlich.",
    "Accepted documents:": "Akzeptierte Dokumente:",
    "Valid National Identity Card": "Gültiger Nationaler Personalausweis",
    "Valid Passport": "Gültiger Reisepass",
    "Valid Resident Permits - These will be accepted only if issued by a European country.": "Gültige Aufenthaltsgenehmigungen - Diese werden nur akzeptiert, wenn sie von einem europäischen Land ausgestellt wurden.",
    "Only legal representatives are subject to the identification document collection process.": "Ausschließlich gesetzliche Vertreter unterliegen dem Prozess der Sammlung von Identifikationsdokumenten.",
    "Additional Requirement:": "Zusätzliche Anforderung:",
    "In cases involving a Power of Attorney or Delegation of Powers, both parties \n(the individual granting authority and the one receiving it) must provide a copy of their ID.": "In Fällen, die eine Vollmacht oder eine Delegation von Befugnissen beinhalten, müssen beide Parteien\n(die Person, die die Autorität erteilt und die sie erhält) eine Kopie ihres Ausweises vorlegen.",
    "Additional Documents": "Weitere Dokumente\n",
    "Utility Bill (such as gas, electricity, telephone or mobile phone bill); or a document issued by a government department \nthat shows the End-user’s address and name (e.g., Residential Certificate); or": "Nebenkostenabrechnung (wie Gas-, Strom-, Telefon- oder Mobiltelefonrechnung); oder ein von einer Behörde ausgestelltes Dokument,\ndas die Adresse und den Namen des Endbenutzers anzeigt (z.B. Wohnsitzbescheinigung); oder",
    "Bank Statement; or": "Kontoauszug; oder\n",
    "a document issued by a government department that shows the End-user’s address and name \n(e.g., Residential Certificate).": "ein von einer Behörde ausgestelltes Dokument, das die Adresse und den Namen des Endbenutzers anzeigt (z.B. Wohnsitzbescheinigung).",
    "Kemit Kingdom retains the right to request any additional documents or further information necessary for conducting \nthorough verifications, in line with its legal obligations.": "Kemit Kingdom behält sich das Recht vor, zusätzliche Dokumente oder weitere Informationen anzufordern, die für eine gründliche Überprüfung gemäß\nseinen gesetzlichen Verpflichtungen erforderlich sind.",
    "Note:": "Hinweis:",
    "Partners": "Partner",
    "Add New Partner": "Neuen Partner hinzufügen",
    "Designation": "Bezeichnung",
    "Partner Name": "Partnername",
    "Partner Email": "Partner-E-Mail",
    "Partner Designation": "Bezeichnung des Partners",
    "Add Now": "Jetzt hinzufügen",
    "Adding": "Hinzufügen",
    "Delete Partner": "Partner löschen",
    "Delete": "Löschen",
    "Deleting": "Löschvorgang",
    "Are you sure, you want to remove sxz as partner?": "Sind Sie sicher, dass Sie sxz als Partner entfernen möchten?",
    "Entity's Registry Extract (Entity'sRegistration/Creation Document)": "Auszug aus dem Unternehmensregister (Dokument zur Registrierung/Erstellung der Entität)",
    "This document should be either:": "Dieses Dokument sollte entweder sein:",
    "A proof of creation of the entity, providing the entity's details, creation date, and purpose.": "Ein Nachweis über die Gründung der Entität, der die Details, das Gründungsdatum und den Zweck der Entität enthält.",
    "A local registry extract:": "Ein lokaler Registrierungsauszug:",
    "Required as per the country's regulations, serving as the current 'identity card' for the entity, indicating its status \n(active or closed).": "Erforderlich gemäß den Vorschriften des Landes, dient als aktuelle 'Identitätskarte' der Entität und gibt ihren Status an (aktiv oder geschlossen).",
    "If a registry extract exists, it must not be older than 3 months.": "Wenn ein Registrierungsauszug vorhanden ist, darf dieser nicht älter als 3 Monate sein.",
    "Entity name": "Name der Entität",
    "Registration number (if applicable, based on the country's requirements)": "Registrierungsnummer (falls zutreffend, basierend auf den Anforderungen des Landes)",
    "Date of creation": "Gründungsdatum",
    "Description of the entity's activities or purpose": "Beschreibung der Aktivitäten oder des Zwecks der Entität",
    "Trading names": "Handelsnamen",
    "Articles of Association": "Satzung",
    "The Articles of Association serve as the constitutional document of the entity, outlining its operating \nrules.": "Die Satzung dient als konstitutionelles Dokument der Entität und legt ihre Betriebsregeln fest.",
    "There is no time constraint on this document, meaning the most recent version is requested. Typically, these types of \nentities require at least one annual meeting that may result in an updated version of the Articles of Association.": "Es gibt keine zeitliche Einschränkung für dieses Dokument, was bedeutet, dass die neueste Version angefordert wird. Typischerweise erfordern \ndiese  Arten von Entitäten mindestens eine jährliche Versammlung, die zu einer aktualisierten Version der Satzung führen kann.",
    "Examples of changes that could occur include updates to the entity's name, address, purpose, management board, \nroles, responsibilities, etc.": "Beispiele für mögliche Änderungen umfassen Aktualisierungen des Namens der Entität, der Adresse, des Zwecks, des Verwaltungsrats, der Rollen,\nVerantwortlichkeiten usw.",
    "The document should contain information about:": "Das Dokument sollte Informationen über folgendes enthalten:\n",
    "The entity itself, including its legal name, registered address, and purpose": "Die Entität selbst, einschließlich ihres rechtlichen Namens, der registrierten Adresse und des Zwecks",
    "Its internal operating rules, such as the appointment process for management board members, their \nroles and powers, among other details. This list is not exhaustive.": "Ihre internen Betriebsregeln, wie der Ernennungsprozess für Mitglieder des Verwaltungsrats, ihre Rollen und Befugnisse,\nunter anderen Details. Diese Liste ist nicht erschöpfend.",
    "How the entity is funded": "Wie die Entität finanziert wird\n",
    "Appointment/Removal/Changes on Board of Management Members:": "Ernennung/Entfernung/Änderungen bei den Mitgliedern des Verwaltungsrats:\n",
    "These updates usually occur based on the entity's needs or as stipulated in the Articles of Association. Changes are \ndocumented through board of management meeting minutes, which are registered and certified to reflect the updated \nlist of members, such as the president, vice-president, treasurer, and general secretary.": "Diese Aktualisierungen erfolgen in der Regel aufgrund der Bedürfnisse der Entität oder wie in der Satzung festgelegt. Änderungen werden\nin den Protokollen der Verwaltungsratssitzungen dokumentiert, die registriert und zertifiziert werden, um die aktualisierte Liste der Mitglieder \nwiderzuspiegeln, wie z.B. den Präsidenten, Vizepräsidenten, Schatzmeister und Generalsekretär.",
    "The latest board management meeting minutes, including the current list of members, will be required in addition to the \nArticles of Association, if available.": "Die neuesten Protokolle der Verwaltungsratssitzungen, einschließlich der aktuellen Liste der Mitglieder, werden zusätzlich zu den\nSatzungen benötigt, wenn verfügbar.",
    "Note: If the current list of board management members is not included in the latest Articles of Association and there \nare no board meeting minutes available, this list must be provided. It should be dated and signed by the most recently \nappointed legal representative, whose appointment can be verified through related documentation.": "Hinweis: Wenn die aktuelle Liste der Mitglieder des Verwaltungsrats nicht in den neuesten Satzungen enthalten ist und keine Protokolle der \nVerwaltungsratssitzungen vorhanden sind, muss diese Liste bereitgestellt werden. Sie sollte datiert und von dem zuletzt ernannten gesetzlichen \nVertreter unterschrieben sein, dessen Ernennung durch entsprechende Unterlagen überprüft werden kann.",
    "This document demonstrates an individual's authority to represent the entity. This document could be:": "Dieses Dokument weist die Befugnis einer Person nach, die Entität zu vertreten. Dieses Dokument kann sein:\n",
    "The latest Articles of Association": "Die neuesten Satzungen\n",
    "The current list of board management members \n(which may be included in the board management meeting minutes)": "Die aktuelle Liste der Mitglieder des Verwaltungsrats (die in den Protokollen der Verwaltungsratssitzungen enthalten sein kann)",
    "The document should specify:": "Das Dokument sollte spezifizieren:\n",
    "Full name of the individual(s)": "Vollständiger Name der Person(en)\n",
    "Function of the individual(s). This information will be cross-verified with the Articles of Association to \nensure that the individual's function permits them to bind the entity. This is particularly important when the \napplicant is not the president, and other functions have limited representation authority as stated in \nthe Articles of Association.": "Funktion der Person(en). Diese Informationen werden mit den Satzungen abgeglichen, um sicherzustellen, dass die Funktion der Person sie dazu \nberechtigt, die Entität zu binden. Dies ist besonders wichtig, wenn der Antragsteller nicht der Präsident ist und andere Funktionen gemäß den \nSatzungen eine begrenzte Vertretungsbefugnis haben.",
    "Additional information about the legal representative that will be collected includes:": "Weitere Informationen über den gesetzlichen Vertreter, die gesammelt werden, umfassen:",
    "Date and place of birth": "Geburtsdatum und -ort",
    "Residential address (at least the country of residence should be stated)": "Wohnadresse (mindestens das Land des Wohnsitzes sollte angegeben werden)",
    "Nationality": "Nationalität",
    "Ownership Structure & Ultimate Beneficial Owners (UBOs)": "Eigentumsstruktur und Letztbegünstigte Eigentümer (UBOs)",
    "Typically, these types of entities, given their structure and purpose, do not have beneficial owners. However, board \nmembers will be identified and treated as such for screening purposes, ID collection and verification, etc.": "Typischerweise haben diese Arten von Entitäten aufgrund ihrer Struktur und ihres Zwecks keine wirtschaftlich Berechtigten. Mitglieder des \nVerwaltungsrats werden jedoch identifiziert und als solche für Überprüfungszwecke, ID-Sammlung und -Verifizierung usw. behandelt.",
    "A small number of associations might have beneficial owners, particularly those generating profits. In such instances, \nthis detail will be mentioned in the Articles of Association. These individuals will be easily identified and treated as such, \nalongside management board members.": "Eine kleine Anzahl von Vereinigungen könnte wirtschaftlich Berechtigte haben, insbesondere solche, die Gewinne erzielen. In solchen Fällen \nwird dieses  Detail in den Satzungen erwähnt. Diese Personen werden leicht identifiziert und behandelt, zusammen mit den Mitgliedern des \nVerwaltungsrats.",
    "Additional Requirement: In cases involving a Power of Attorney or Delegation of Powers, both parties \n(the individual granting authority and the one receiving it) must provide a copy of their identification documents.": "Zusätzliche Anforderung: In Fällen, die eine Vollmacht oder eine Delegation von Befugnissen beinhalten, müssen beide Parteien\n(die Person, die die Vollmacht erteilt und die sie erhält) eine Kopie ihres Ausweises vorlegen.",
    "Sole Trader's Registry Extract (Entity's Registration/Creation Document)": "Auszug aus dem Register des Einzelunternehmers (Dokument zur Eintragung/Gründung des Unternehmens)",
    "This document serves as a local registry for sole traders, required in countries where such registration is mandatory. \nIt verifies that the individual is engaged in a professional activity as a sole trader. The name of this document varies from \ncountry to country.": "Dieses Dokument dient als lokales Register für Einzelunternehmer, erforderlich in Ländern, in denen eine solche Registrierung verpflichtend ist.\nEs bestätigt, dass die Person als Einzelunternehmer beruflich tätig ist. Der Name dieses Dokuments variiert von\nLand zu Land.",
    "Where applicable, the document should not be older than 3 months.": "Wo zutreffend, sollte das Dokument nicht älter als 3 Monate sein.",
    "The document must contain:": "Das Dokument muss enthalten:",
    "Individual's name": "Name des Einzelunternehmers",
    "Residential address": "Wohnadresse",
    "Registration number": "Registrierungsnummer",
    "The sole trader is the only individual subject to the identification document collection process.": "Der Einzelunternehmer ist die einzige Person, die dem Prozess der Sammlung von Identifikationsdokumenten unterliegt.",
    "No Transactions": "Keine Transaktionen",
    "No Pending Items": "Keine offenen Posten",
    "No Beneficiaries": "Keine Begünstigten",
    "Send Money To": "Geld an",
    "Confirmation": "senden",
    "You are setting": "Bestätigung",
    "as default.": "Sie stellen",
    "Previously": "als Standard ein.",
    "was default QR.": "Zuvor",
    "Category": "war Standard-QR.",
    "-Select-From-The-List-": "Kategorie",
    "Add a note": "Gebühren",
    "Categories Added Successfully!": "Eine Notiz hinzufügen",
    "Subscription starting date": "Kategorien erfolgreich hinzugefügt!",
    "Until": "Beginn des Abonnements",
    "Next Transaction": "Bis",
    "Transaction Method": "Nächste Transaktion",
    "Accept the quotation": "Transaktionsmethode",
    "Choose Currency": "Das Angebot akzeptieren",
    "Accept": "Währung wählen",
    "Bargain the quotation": "Akzeptieren",
    "Enter Counter Amount:": "Das Angebot verhandeln",
    "Bargain": "Gegenbetrag eingeben:",
    "Decline the quotation": "Verhandeln",
    "Are you sure, you want to decline this quotation?": "Das Angebot ablehnen",
    "Decline": "Sind Sie sicher, dass Sie dieses Angebot ablehnen möchten?",
    "Personal Account": "Ablehnen",
    "undefined": "Persönliches Konto",
    "Jan": "Jan",
    "Feb": "Feb",
    "Mar": "Mär",
    "Apr": "Apr",
    "May": "Mai",
    "Jun": "Jun",
    "Jul": "Jul",
    "Aug": "Aug",
    "Sep": "Sep",
    "Oct": "Okt",
    "Nov": "Nov",
    "Dec": "Dez",
    "Today": "Heute",
    "Spent In": "Verbracht in",
    "Revised Amount": "Überarbeiteter Betrag",
    "You spent more comparing to last month": "Du hast im Vergleich zum letzten Monat mehr ausgegeben",
    "Last": "Letzter",
    "days": "Tage",
    "Year": "Jahre",
    "Instant Mobile Top-up Across 150+ Countries": "Sofortiges Handy-Aufladen in Über 150 Ländern",
    "Delight your loved ones or recharge your own phone, no matter where you are! InstaPay’s Mobile Airtime service lets you instantly top up mobile phones in over 150 countries. Perfect for gifts or ensuring you're always connected, our service is designed for ease, speed, and security.": "Freuen Sie sich darauf, Ihre Lieben zu erfreuen oder Ihr eigenes Telefon aufzuladen, egal wo Sie sich befinden! Mit dem Mobilfunk-Aufladeservice von InstaPay können Sie Handys in über 150 Ländern sofort aufladen. Perfekt für Geschenke oder um immer verbunden zu bleiben, unser Service ist für Einfachheit, Geschwindigkeit und Sicherheit konzipiert.",
    "How It Works - Step-by-Step Guide:": "Wie es funktioniert - Schritt-für-Schritt-Anleitung:",
    "1. Choose 'Mobile Top-up': Access this feature directly within the InstaPay app on platforms like WhatsApp, Twitter, Telegram, or Instagram.": "1. Wählen Sie 'Handy-Aufladen': Greifen Sie direkt in der InstaPay-App auf Plattformen wie WhatsApp, Twitter, Telegram oder Instagram auf diese Funktion zu.",
    "2. Select Currency and Amount: Pick from multiple currencies (USD, GBP, EUR, etc.) and specify how much you want to top up.": "2. Währung und Betrag wählen: Wählen Sie aus mehreren Währungen (USD, GBP, EUR usw.) und geben Sie an, wie viel Sie aufladen möchten.",
    "3. Enter the Mobile Number: Type in the number you wish to recharge—yours or that of a loved one. Our system ensures a secure and swift process.": "3. Handynummer eingeben: Geben Sie die Nummer ein, die Sie aufladen möchten – Ihre eigene oder die eines geliebten Menschen. Unser System gewährleistet einen sicheren und schnellen Prozess.",
    "4. Confirm and Pay: Double-check the top-up details, confirm, and make the payment using your preferred method. The transaction processes instantly and securely.": "4. Bestätigen und Bezahlen: Überprüfen Sie die Aufladedetails, bestätigen Sie diese und führen Sie die Zahlung mit Ihrer bevorzugten Methode durch. Die Transaktion wird sofort und sicher verarbeitet.",
    "5. Receive Instant Confirmation: Both you and the recipient (if applicable) will receive a confirmation notification once the top-up is successful.": "5. Sofortige Bestätigung erhalten: Sowohl Sie als auch der Empfänger (falls zutreffend) erhalten eine Bestätigungsnachricht, sobald die Aufladung erfolgreich war.",
    "Benefits:": "Vorteile:",
    "Global Reach: Effortlessly send airtime to over 150 countries.": "Globale Reichweite: Senden Sie mühelos Guthaben in über 150 Länder.",
    "Instant Impact: See the benefits immediately—perfect for last-minute gifts or urgent communications.": "Sofortige Wirkung: Sehen Sie sofort die Vorteile – perfekt für Last-Minute-Geschenke oder dringende Kommunikation.",
    "Complete Security: Enjoy peace of mind with our secure payment system.": "Volle Sicherheit: Genießen Sie Sicherheit dank unseres sicheren Zahlungssystems.",
    "Versatile Payment Options: Choose from credit cards, PayPal, or your InstaPay wallet for payments.": "Vielseitige Zahlungsoptionen: Wählen Sie zwischen Kreditkarten, PayPal oder Ihrem InstaPay-Wallet für Zahlungen.",
    "Ready to spread joy or stay connected? Top-up now and make someone’s day—or your own!": "Bereit, Freude zu verbreiten oder verbunden zu bleiben? Laden Sie jetzt auf und machen Sie jemandem den Tag – oder Ihren eigenen!",
    "Instant Mobile Top-up Across 150+ Countries": "Direct mobiel opwaarderen in meer dan 150 landen",
    "Delight your loved ones or recharge your own phone, no matter where you are! InstaPay’s Mobile Airtime service lets you instantly top up mobile phones in over 150 countries. Perfect for gifts or ensuring you're always connected, our service is designed for ease, speed, and security.": "Verras uw geliefden of laad uw eigen telefoon op, waar u ook bent! Met de mobiele luchttijdservice van InstaPay kunt u direct mobiele telefoons opwaarderen in meer dan 150 landen. Perfect voor cadeaus of om altijd verbonden te blijven, onze service is ontworpen voor gemak, snelheid en veiligheid.",
    "How It Works - Step-by-Step Guide:": "Hoe het werkt - Stap-voor-stap gids:",
    "1. Choose 'Mobile Top-up': Access this feature directly within the InstaPay app on platforms like WhatsApp, Twitter, Telegram, or Instagram.": "1. Kies 'Mobiel opwaarderen': Toegang tot deze functie direct binnen de InstaPay-app op platforms zoals WhatsApp, Twitter, Telegram of Instagram.",
    "2. Select Currency and Amount: Pick from multiple currencies (USD, GBP, EUR, etc.) and specify how much you want to top up.": "2. Selecteer Valuta en Bedrag: Kies uit meerdere valuta's (USD, GBP, EUR, enz.) en specificeer hoeveel u wilt opwaarderen.",
    "3. Enter the Mobile Number: Type in the number you wish to recharge—yours or that of a loved one. Our system ensures a secure and swift process.": "3. Voer het mobiele nummer in: Typ het nummer in dat u wilt opladen—van uzelf of van een dierbare. Ons systeem zorgt voor een veilig en snel proces.",
    "4. Confirm and Pay: Double-check the top-up details, confirm, and make the payment using your preferred method. The transaction processes instantly and securely.": "4. Bevestigen en Betalen: Controleer de oplaadgegevens, bevestig en maak de betaling met uw voorkeursmethode. De transactie wordt onmiddellijk en veilig verwerkt.",
    "5. Receive Instant Confirmation: Both you and the recipient (if applicable) will receive a confirmation notification once the top-up is successful.": "5. Ontvang directe bevestiging: Zowel u als de ontvanger (indien van toepassing) ontvangen een bevestigingsmelding zodra de opwaardering succesvol is.",
    "Benefits:": "Voordelen:",
    "Global Reach: Effortlessly send airtime to over 150 countries.": "Wereldwijd bereik: Stuur moeiteloos luchttijd naar meer dan 150 landen.",
    "Instant Impact: See the benefits immediately—perfect for last-minute gifts or urgent communications.": "Direct effect: Zie de voordelen meteen—perfect voor last-minute cadeaus of dringende communicatie.",
    "Complete Security: Enjoy peace of mind with our secure payment system.": "Volledige Veiligheid: Geniet van gemoedsrust met ons veilige betalingssysteem.",
    "Versatile Payment Options: Choose from credit cards, PayPal, or your InstaPay wallet for payments.": "Veelzijdige Betaalopties: Kies uit creditcards, PayPal, of uw InstaPay-portemonnee voor betalingen.",
    "Ready to spread joy or stay connected? Top-up now and make someone’s day—or your own!": "Klaar om vreugde te verspreiden of verbonden te blijven? Waardeer nu op en maak iemands dag—of de uwe!",
    "Pick a language": "Wählen Sie eine Sprache",
    "French": "Französisch",
    "Spanish": "Spanisch",
    "Portuguese": "Portugiesisch",
    "German": "Deutsch",
    "Ukrainian": "Ukrainisch",
    "Italian": "Italienisch",
    "Russian": "Russisch",
    "Arabic": "Arabisch",
    "Polish": "Polnisch",
    "Dutch (Nederlands)": "Niederländisch",
    "Yoruba": "Yoruba",
    "Indonesian": "Indonesisch",
    "Turkish": "Türkisch",
    "Swahili": "Swahili",
    "Hausa": "Hausa",
    "Hindi": "Hindi",
    "Urdu": "Urdu",
    "Chinese": "Chinesisch",
    "Select Your Preferred Language": "Wählen Sie Ihre bevorzugte Sprache",
    "Please select the language you would \nlike to use for the eKYC process.": "Bitte wählen Sie die Sprache aus, die Sie für den eKYC-Prozess verwenden möchten.",
    "Select Your Nationality": "Wählen Sie Ihre Nationalität",
    "Choose your nationality to see the \nsupported ID documents for  your country.": "Wählen Sie Ihre Nationalität, um die unterstützten Ausweisdokumente für Ihr Land anzuzeigen.",
    "Grant permission to use the camera.": "Erlauben Sie die Nutzung der Kamera.",
    "Tap or click \"Allow\" when prompted to \ngrant permission for camera access to \ncapture the document.": "Tippen oder klicken Sie auf \"Zulassen\", wenn Sie aufgefordert werden, die Erlaubnis zur Kameranutzung zu erteilen, um das Dokument zu erfassen.",
    "Upload Your Documents from \nGallery/Photos": "Laden Sie Ihre Dokumente aus der Galerie/Fotos hoch",
    "If you encounter issues with your camera \nor if your system doesn't have one, you can\n manually upload photos of the document \nfrom your local storage.": "Wenn Sie Probleme mit Ihrer Kamera haben oder Ihr System keine hat, können Sie Fotos des Dokuments manuell aus Ihrem lokalen Speicher hochladen.",
    "Upload Your ID.": "Laden Sie Ihren Ausweis hoch.",
    "Make sure your ID is clear and not blurry to \nensure quick verification.": "Stellen Sie sicher, dass Ihr Ausweis klar und nicht verschwommen ist, um eine schnelle Überprüfung zu gewährleisten.",
    "Valid Proof of Address": "Gültiger Adressnachweis",
    "1. Ensure that your proof of address is \ncurrent and that the statement date is \nwithin the last three months from the \nstart date of your eKYC verification.\n\n2. Make sure your proof of address is clear \nand not blurry to ensure quick verification.": "1. Stellen Sie sicher, dass Ihr Adressnachweis aktuell ist und das Datum des Kontoauszugs innerhalb der letzten drei Monate ab dem Startdatum Ihrer eKYC-Überprüfung liegt.\n\n2. Stellen Sie sicher, dass Ihr Adressnachweis klar und nicht verschwommen ist, um eine schnelle Überprüfung zu gewährleisten.",
    "Important Notice\n\nPlease carefully review all the steps in this guideline before proceeding with your eKYC verification.\n\nAvoid Common Mistakes:\n\n* Ensure all your documents are clear, legible, and up-to-date.\n* Double-check that the information you provide matches the details on your official documents.\n* Follow the instructions precisely to avoid delays or rejections.\n\nNote: \nIf your eKYC verification fails and you need to resubmit, you will be charged again for the resubmission process.\n\nBy following these guidelines, you can help ensure a smooth and successful eKYC verification.\n\nFor any questions or assistance, please contact our support team at support@insta-pay.ch ": "Wichtiger Hinweis\n\nBitte lesen Sie alle Schritte in dieser Anleitung sorgfältig durch, bevor Sie mit Ihrer eKYC-Verifizierung fortfahren.\n\nVermeiden Sie häufige Fehler:\n\n* Stellen Sie sicher, dass alle Ihre Dokumente klar, lesbar und aktuell sind.\n* Überprüfen Sie, ob die von Ihnen angegebenen Informationen mit den Angaben in Ihren offiziellen Dokumenten übereinstimmen.\n* Befolgen Sie die Anweisungen genau, um Verzögerungen oder Ablehnungen zu vermeiden.\n\nHinweis: \nWenn Ihre eKYC-Verifizierung fehlschlägt und Sie sie erneut einreichen müssen, werden Ihnen für den erneuten Einreichungsprozess zusätzliche Gebühren berechnet.\n\nIndem Sie diese Richtlinien befolgen, können Sie eine reibungslose und erfolgreiche eKYC-Verifizierung sicherstellen.\n\nBei Fragen oder für Unterstützung wenden Sie sich bitte an unser Support-Team unter support@insta-pay.ch",
    "Personal Transactions": "Persönliche Transaktionen",
    "Business Transactions": "Geschäftstransaktionen",
    "Shopping & Purchases": "Einkaufen und Einkäufe",
    "Bills & Utilities": "Rechnungen und Versorgungsleistungen",
    "Entertainment & Leisure": "Unterhaltung und Freizeit",
    "Investments & Savings": "Investitionen und Einsparungen",
    "Health & Wellness": "Gesundheit und Wellness",
    "Transportation": "Transport",
    "Education": "Bildung",
    "Miscellaneous": "Verschiedenes",
    "Family Support": "Familienunterstützung",
    "WALLET": "WALLET",
    "Add a currency": "Währung hinzufügen",
    "When you add a currency, any payments you receive in that currency will be credited to your InstaPay balance. Your primary currency will be used for sending or requesting payments unless specified otherwise.": "Wenn Sie eine Währung hinzufügen, werden alle Zahlungen, die Sie in dieser Währung erhalten, Ihrem InstaPay-Guthaben gutgeschrieben. Ihre Hauptwährung wird für das Senden oder Anfordern von Zahlungen verwendet, es sei denn, anders angegeben.",

    "balance amount ": "Saldo Betrag",
    "referral ": "Empfehlung",
    "crypto wallet balance": "Krypto-Wallet-Saldo",
    "money in": "Geld rein",
    "money out": "Geld raus",
    "analytics": "Analytik",
    "see more": "mehr sehen",
    "manage my Wallets and see the transaction details": "meine Wallets verwalten und Transaktionsdetails einsehen",
    "set as default Wallet": "als Standard-Wallet festlegen",
    "add currency": "Währung hinzufügen",
    "convert funds ": "Gelder umwandeln",
    "withdraw balance": "Saldo abheben",
    "chart names": "Diagrammnamen",
    "withdraw balance from PKR wallet": "Saldo aus der PKR-Wallet abheben",
    "select a payout channel": "einen Auszahlungskanal auswählen",
    "you have entered an amount below the minimum payment range": "Sie haben einen Betrag unterhalb des erforderlichen Mindestbetrags eingegeben",
    "change": "ändern",
    "edit": "bearbeiten",
    "moving to next step in ...": "nächster Schritt in ...",
    "you are withdrawing ...": "Sie heben ab ...",
    "where is my payment": "wo ist meine Zahlung",
    "download": "herunterladen",
    "IBAN / SWIFT Code": "IBAN oder SWIFT/BIC-Code",
    "swift code": "SWIFT-Code",
    "account number": "Kontonummer",
    "branch street": "Filialstraße",
    "city": "Stadt",
    "province": "Provinz",
    "postal code": "Postleitzahl",
    "all payout channels": "alle Auszahlungskanäle",
    "bussiness beneficiary": "geschäftlicher Begünstigter",
    "no quotation": "kein Angebot",
    "no pending items": "keine ausstehenden Elemente",
    "QR status": "QR-Status",
    "Portfolio": "Portfolio",
    "edit whole portfolio page": "ganze Portfolio-Seite bearbeiten",
    "personal account": "persönliches Konto",
    "Chart names": "Diagrammnamen",
    "instagram": "instagram",
    "facebook": "facebook",
    "twitter": "twitter",
    "more": "mehr",
    "not connected": "nicht verbunden",
    "code": "Code",
    "ratings": "Bewertungen",
    "current password": "aktuelles Passwort",
    "include at least one uppercase and lowercase": "mindestens einen Groß- und Kleinbuchstaben enthalten",
    "my social network accounts": "meine sozialen Netzwerkkonten",
    "SMS ": "SMS",
    "view identity verfication guide": "Identitätsüberprüfungsanleitung anzeigen",
    "boimetric face recognition": "biometrische Gesichtserkennung",
    "please select a payer (withdrawl options)": "bitte wählen Sie einen Zahler (Abhebungsoptionen)",
    "IBAN number ": "IBAN-Nummer",
    "account holder name": "Name des Kontoinhabers",
    "download data": "Daten herunterladen",
    "bank transfer": "Banküberweisung",
    "mobile wallet": "mobile Geldbörse",
    "all bank names": "alle Banknamen",
    "pay via card": "mit Karte bezahlen",
    "you have entered an amount that is below the payer range": "Sie haben einen Betrag eingegeben, der unter dem Zahlerbereich liegt",
    "trouble sending money": "Probleme beim Geld senden",
    "please follow these example": "bitte folgen Sie diesen Beispielen",
    "wallet ID ": "Wallet-ID",
    "username": "Benutzername",
    "email": "E-Mail",
    "phone number": "Telefonnummer",
    "no user found": "kein Benutzer gefunden",
    "search": "Suche",
    "scan/upload QR code of the recipient": "QR-Code des Empfängers scannen/hochladen",
    "invite them": "sie einladen",
    "trouble quoting money": "Problem beim Angebot",
    "select a country": "Land auswählen",
    "airtime details": "Guthabensdetails",
    "enter the phone number": "Telefonnummer eingeben",
    "service type": "Diensttyp",
    "select a service": "Dienst auswählen",
    "service list": "Dienstliste",
    "amount entered is less than the ....": "der eingegebene Betrag ist geringer als ....",
    "total": "Gesamt",
    "total credit the receiver gets": "gesamtguthaben, das der Empfänger erhält",
    "take ": "nehmen",
    "OTP has been verified , You'll be redirected......": "OTP wurde verifiziert, Sie werden weitergeleitet......",
    "Link Your Instagram Account to InstaPay": "Verknüpfen Sie Ihr Instagram-Konto mit InstaPay",
    "Step 01 – From Instagram App": "Schritt 01 – Von der Instagram-App",
    "Open the Instagram app, search for the \"InstaPay\" profile page, select \"Message\" to open the chat box, type/say \"Hi\" and click send to initiate the conversation with the InstaPay chatbot.": "Öffnen Sie die Instagram-App, suchen Sie die \"InstaPay\"-Profilseite, wählen Sie \"Nachricht\", um das Chat-Fenster zu öffnen, tippen/sagen Sie \"Hallo\" und klicken Sie auf Senden, um das Gespräch mit dem InstaPay-Chatbot zu beginnen.",
    "Step 02 – From InstaPay Instagram Profile Page ": "Schritt 02 – Von der InstaPay-Instagram-Profilseite",
    "InstaPay chatbot will display two action buttons: \"Register\" and \"Connect.\" Select \"Connect,\" and the chatbot will prompt you to enter your InstaPay username.": "Der InstaPay-Chatbot zeigt zwei Aktionsbuttons an: \"Registrieren\" und \"Verbinden\". Wählen Sie \"Verbinden\" und der Chatbot fordert Sie auf, Ihren InstaPay-Benutzernamen einzugeben.",
    "Step 03 – From InstaPay Instagram Profile Page": "Schritt 03 – Von der InstaPay-Instagram-Profilseite",
    "The InstaPay chatbot will then ask for the InstaPay verification code. Enter the code you obtained from the InstaPay settings under \"My Social Network Accounts\" and click send.": "Der InstaPay-Chatbot wird dann nach dem InstaPay-Verifizierungscode fragen. Geben Sie den Code ein, den Sie in den InstaPay-Einstellungen unter \"Meine sozialen Netzwerkkonten\" erhalten haben, und klicken Sie auf Senden.",
    "Step 04 – From InstaPay Instagram Profile Page": "Schritt 04 – Von der InstaPay-Instagram-Profilseite",
    "You will receive a congratulatory message confirming that your Instagram account is now synced with InstaPay. Click on \"Main Menu\" to start using InstaPay services.": "Sie erhalten eine Glückwunschnachricht, die bestätigt, dass Ihr Instagram-Konto nun mit InstaPay synchronisiert ist. Klicken Sie auf \"Hauptmenü\", um die InstaPay-Dienste zu nutzen.",
    "Additional Tips": "Zusätzliche Tipps",
    "1.\tSecure Your Account: Ensure your Instagram account is secure by enabling two-factor authentication (2FA) to protect your linked InstaPay services.": "1. Sichern Sie Ihr Konto: Stellen Sie sicher, dass Ihr Instagram-Konto durch Aktivieren der Zwei-Faktor-Authentifizierung (2FA) gesichert ist, um Ihre verbundenen InstaPay-Dienste zu schützen.",
    "2.\tExplore More: Once linked, explore the wide range of services InstaPay offers, from seamless transactions to instant withdrawals.": "2. Mehr entdecken: Sobald verknüpft, erkunden Sie die breite Palette von Diensten, die InstaPay anbietet, von nahtlosen Transaktionen bis hin zu sofortigen Auszahlungen.",
    "3.\tStay Updated: Keep an eye on our updates, as we will soon support linking other social media accounts to make your experience even more integrated.": "3. Bleiben Sie auf dem Laufenden: Behalten Sie unsere Updates im Auge, da wir bald die Verknüpfung anderer sozialer Netzwerkkonten unterstützen werden, um Ihr Erlebnis noch integrierter zu gestalten.",
    "4.\tHelp & Support: If you face any issues during the linking process, contact our support team for assistance.": "4. Hilfe & Support: Wenn Sie während des Verknüpfungsprozesses auf Probleme stoßen, kontaktieren Sie unser Support-Team für Unterstützung.",
    "Future Enhancements": "Zukünftige Verbesserungen",
    "In the near future, InstaPay will allow you to link more social media accounts, providing you with even more flexibility and convenience. Stay tuned for exciting updates!": "In naher Zukunft wird InstaPay Ihnen ermöglichen, mehr soziale Netzwerkkonten zu verknüpfen, was Ihnen noch mehr Flexibilität und Komfort bietet. Bleiben Sie gespannt auf aufregende Updates!",
    "Personal Transactions": "Persönliche Transaktionen",
    "Business Transactions": "Geschäftstransaktionen",
    "Shopping & Purchases": "Einkaufen und Einkäufe",
    "Bills & Utilities": "Rechnungen und Versorgungsleistungen",
    "Entertainment & Leisure": "Unterhaltung und Freizeit",
    "Investments & Savings": "Investitionen und Einsparungen",
    "Health & Wellness": "Gesundheit und Wellness",
    "Transportation": "Transport",
    "Education": "Bildung",
    "Miscellaneous": "Verschiedenes",
    "Family Support": "Familienunterstützung",
    "Web - login with phone number": null,
    "Login": "Login",
    "Start Your InstaPay Journey Today!": "Starten Sie Ihre InstaPay-Reise noch heute!",
    "Enter your phone number": "Geben Sie Ihre Telefonnummer ein",
    "Login with email": "Mit E-Mail anmelden",
    "Don't have an account?": "Haben Sie kein Konto?",
    "Sign Up": "Registrieren",
    "Continue": "Fortsetzen",
    "We recommend you download and install the Google Authenticator app": "Wir empfehlen Ihnen, die Google Authenticator-App herunterzuladen und zu installieren",
    "Two-factor authentication (2FA) is the best way to protect yourself online.": "Zwei-Faktor-Authentifizierung (2FA) ist der beste Weg, um sich online zu schützen.",
    "Strong security with Google Authenticator": "Starke Sicherheit mit Google Authenticator",
    "Enter your password": "Geben Sie Ihr Passwort ein",
    "Web Mobile": null,
    "We recommend you download and install the Google Authenticator app to ensure you receive your verification codes instantly, avoiding any SMS delays or issues.": "Wir empfehlen Ihnen, die Google Authenticator-App herunterzuladen und zu installieren, um sicherzustellen, dass Sie Ihre Verifizierungscodes sofort erhalten, ohne Verzögerungen oder Probleme mit SMS.",
    "Web - Google Authenticator": null,
    "Google Authenticator": "Google Authenticator",
    "Two Factor Authentication Code": "Zwei-Faktor-Authentifizierungscode",
    "Problem with the token?": "Problem mit dem Token?",
    "Verify using SMS": "Über SMS verifizieren",
    "Verify": "Verifizieren",
    "Web Mobile - Google Authenticator": null,
    "SMS - Web ": null,
    "SMS Verification": "SMS-Verifizierung",
    "Enter the SMS code sent to +41********4053": "Geben Sie den SMS-Code ein, der an +41********4053 gesendet wurde",
    "Verify using Authenticator App": "Verifizieren Sie sich mit der Authenticator-App",
    "Resend code in 120 seconds": "Code in 120 Sekunden erneut senden",
    "Verify ": "Verifizieren",
    "SMS - Web Mobile": null,
    "My own account": "Mein eigenes Konto",
    "Friends & Family": "Freunde & Familie",
    "Aunt": "Tante",
    "Brother-in-law": "Schwager",
    "Cousin": "Cousin",
    "Daughter": "Tochter",
    "Father": "Vater",
    "Father-in-law": "Schwiegervater",
    "Friend": "Freund",
    "Grandfather": "Großvater",
    "Grandmother": "Großmutter",
    "Husband": "Ehemann",
    "Mother": "Mutter",
    "Mother-in-law": "Schwiegermutter",
    "Nephew": "Neffe",
    "Niece": "Nichte",
    "Self (i.e. the sender, himself)": "Selbst (d.h. der Absender selbst)",
    "Sister": "Schwester",
    "Sister-in-law": "Schwägerin",
    "Son": "Sohn",
    "Uncle": "Onkel",
    "Wife": "Ehefrau",
    "Others not listed": "Andere nicht aufgeführt",
    "Merchant": "Händler",
    "No relationship": "Keine Beziehung",
    "Add Funds": "Geld hinzufügen",
    "Enter the Amount": "Geben Sie den Betrag ein:",
    "Fees": "Gebühren:",
    "You Will Get": "Sie erhalten:",
    "⚠️ The transactional value should be between[Currency ISO code & Amount] - [Currency ISO code & Amount] ": "⚠️ Der Transaktionswert sollte zwischen [Currency ISO code & Amount] - [Currency ISO code & Amount] liegen",
    "⚠️ Invalid Amount: Your current  identity verification level limits the  maximum amount you can add. Please upgrade your identity verification to benefit from higher limits.           ": "⚠️ Ungültiger Betrag: Ihr aktuelles Identitätsüberprüfungsniveau begrenzt den maximalen Betrag, den Sie hinzufügen können. Bitte aktualisieren Sie Ihre Identitätsüberprüfung, um von höheren Limits zu profitieren.",
    "ℹ️ The price of currency changes frequently based on market conditions. Please refer to the price on the confirm order page as your final quotation": "ℹ️ Der Währungspreis ändert sich häufig basierend auf den Marktbedingungen. Bitte beziehen Sie sich auf den Preis auf der Bestellbestätigungsseite als Ihr endgültiges Angebot.",
    "Next": "Weiter",
    "Upgrade Identity Verification": "Identitätsüberprüfung aktualisieren",
    "Request money from anyone, anywhere, including across social networking platforms. With InstaPay, your payment requests are instantly accessible once accepted and funds are sent to your Receivable Accounts. Simplify your transactions and manage payments with ease and security.": "Fordern Sie Geld von jedem, überall an, einschließlich über soziale Netzwerke. Mit InstaPay sind Ihre Zahlungsanforderungen sofort zugänglich, sobald sie akzeptiert werden, und die Gelder werden auf Ihre Empfangskonten überwiesen. Vereinfachen Sie Ihre Transaktionen und verwalten Sie Zahlungen mit Leichtigkeit und Sicherheit.",
    "You’ve been logged out.": "Sie wurden ausgeloggt.",
    "For your security, you’ve been logged out after 15 minutes of inactivity. Please log in again if you wish to use InstaPay.": "Zu Ihrer Sicherheit wurden Sie nach 15 Minuten Inaktivität ausgeloggt. Bitte loggen Sie sich erneut ein, wenn Sie InstaPay weiter nutzen möchten.",
    "LOGIN AGAIN": "ERNEUT ANMELDEN",
    "Brother": "Bruder",
    "Current Password": "Aktuelles Passwort",
    "The new password cannot be the same as the old one": "Das neue Passwort darf nicht mit dem alten übereinstimmen.",
    "Minimum password length: [X] characters ": "Minimale Passwortlänge: [X] Zeichen",
    "Include at least one uppercase and one lowercase letter.": "Mindestens einen Großbuchstaben und einen Kleinbuchstaben enthalten.",
    "Nominee Information": "Informationen zu nominierten Personen",
    "Verification Code Preferences": "Präferenzen für den Bestätigungscode",
    "How Others Can Find You": "Wie andere Sie finden können",
    "My Social Network Accounts": "Meine sozialen Netzwerkkonten",
    "Login Activity": "Anmeldeaktivität",
    "Receiving Accounts": "Empfangskonten",
    "Change Your Password To Continue": "Ändern Sie Ihr Passwort, um fortzufahren",
    "Continue with": "Weiter mit",
    "Back to Login": "Zurück zur Anmeldung",
    "Send OTP": "OTP senden",
    "A Verification code has been sent to your email and number.": "Ein Bestätigungscode wurde an Ihre E-Mail und Nummer gesendet.",
    "Resend available in": "Erneut senden verfügbar in",
    "Create a strong Password": "Erstellen Sie ein starkes Passwort",
    "Password must:": "Das Passwort muss:",
    "Be between 9 and 64 characters": "Zwischen 9 und 64 Zeichen lang sein",
    "Include at least two of the following:": "Mindestens zwei der folgenden enthalten:",
    "Lowercase Character": "Kleinbuchstaben",
    "Special Character": "Sonderzeichen",
    "Password Updated Successfully!": "Passwort erfolgreich aktualisiert!",
    "Click below to login": "Klicken Sie unten, um sich anzumelden",

    "Send Money": "Geld senden",
    "Enter the amount": null,
    "Recent Transfer": "Letzte Überweisung",
    "See all": "Alles sehen",
    "Send again": "Erneut senden",
    "You Send": "Du sendest",
    "They Receive": "Sie erhalten",
    "Receive Method": "Empfangsmethode",
    "Select method": "Methode auswählen",
    "Next": "Weiter",
    "Trouble sending money?": "Probleme beim Senden von Geld?",
    "Where do you want to send money ?": "Wohin möchten Sie Geld senden?",
    "Search ": "Suche",
    "Confirm": "Bestätigen",
    "Bank Deposit": "Bankeinzahlung",
    "Cash Pick-up": "Bargeldabholung",
    "Mobile Wallet": "Mobile Wallet",
    "Bank Card": "Bankkarte",
    "Crypto Wallet": "Wallet crypto",
    "What is the receiver’s Mobile Wallet?": "Was ist das Wallet mobil des Empfängers?",
    "Transfer fees": "Überweisungsgebühren",
    "Total to pay": "Gesamtbetrag",
    "Choose the beneficiary!": "Wählen Sie den Begünstigten!",
    "Country": "Land",
    "Receive method": "Empfangsmethode",
    "You send": "Du sendest",
    "change": "ändern",
    "edit": "neu hinzufügen",
    "add new": "Begünstigte",
    "Beneficiaries": "Begünstigte verwalten",
    "Manage Beneficiaries": "Suche",
    "Search": "Weiter",
    "CASH PICK-UP": "BARGELDABHOLUNG",
    "Where will your receiver collect the money": "Wo wird Ihr Empfänger das Geld abholen?",
    "Cash is available to collect within minutes from any branch of [name] ": "Bargeld ist innerhalb von Minuten in jeder Filiale von [Name] verfügbar",
    "BANK ACCOUNT": "BANKKONTO",
    "Transfer to recipient’s bank account": "Überweisung auf das Bankkonto des Empfängers",
    "BANK CARD": "BANKKARTE",
    "Fast transfer directly to recipient’s bank card": "Schnelle Überweisung direkt auf die Bankkarte des Empfängers",
    "CRYPTO WALLET": "WALLET CRYPTO",
    "Fast transfer directly to a crypto wallet": "Schnelle Überweisung direkt auf ein Wallet crypto",
    "Review transfer": "Überweisung überprüfen",
    "Receiver's Details": "Empfängerdetails",
    "Edit": "Bearbeiten",
    "Name": "Name",
    "Reason for sending": "Grund für das Senden",
    "Sending": "Senden",
    "Receiver gets": "Empfänger erhält",
    "Exchange rate": "Wechselkurs",
    "Estimated time": "Geschätzte Zeit",
    "Select Payment Type": "Zahlungsmethode auswählen",
    "Credit cards": "Kreditkarten",
    "Domestic cards": "Inländische Karten",
    "Mobile Wallets": "Wallets mobil",
    "InstaPay Wallet": "Wallet InstaPay",
    "Crypto (USDT, ETH, BTC)": "Crypto (USDT, ETH, BTC)",
    "PayPal": "PayPal",
    "CREDIT CARD": "KREDITKARTE",
    "Credit Card": "Kreditkarte",
    "Select card": "Karte auswählen",
    "Card Number": "Kartennummer",
    "Enter the 16-digit card number on the card": "Geben Sie die 16-stellige Kartennummer ein",
    "Expiry Date": "Ablaufdatum",
    "Enter the expiration date of the card": "Geben Sie das Ablaufdatum der Karte ein",
    "CVV Number": "CVV-Nummer",
    "Enter the 3 or 4 digit number on the card": "Geben Sie die 3- oder 4-stellige Nummer der Karte ein",
    "INSTAPAY WALLET": "WALLET INSTAPAY",
    "Select Wallet": "Wallet auswählen",
    "My Wallet": "Mein Wallet",
    "Conversion": "Konvertierung",
    "Convert": "Konvertieren",
    "Your payment is confirmed": "Ihre Zahlung ist bestätigt",
    "Thanks for using InstaPay": "Danke, dass Sie InstaPay verwenden",
    "Status": "Status",
    "Share": "Teilen",
    "Transfer created": "Überweisung erstellt",
    "Payment received": "Zahlung erhalten",
    "Payment processed": "Zahlung verarbeitet",
    "Transfer successful": "Überweisung erfolgreich",
    "It may take few hours for the funds to appear in [user name] account": "Es kann einige Stunden dauern, bis die Gelder auf dem Konto von [Benutzername] erscheinen",
    "Transfer failed": "Überweisung fehlgeschlagen",
    "Transaction Details": "Transaktionsdetails",
    "Need help?": "Brauchen Sie Hilfe?",
    "Service Details": "Servicedetails",
    "Funds Deposit Speed": "Verfügbarkeit der Gelder",
    "Cut-off Time": "Annahmeschlusszeit",
    "Bank holiday list": "Liste der Bankfeiertage",
    "Threshold limits": "Transaktionslimits",
    "null": null,
    "Instant, Real Time": "Sofort, in Echtzeit",
    "Same day, T+1": "Gleicher Tag, T+1",
    "Non-instant, T+1": "Nicht sofort, T+1",
    "Instant Payment, Real Time": "Sofortige Zahlung, in Echtzeit",
    "Transaction processed before 3:00 PM Local time": "Transaktion vor 15:00 Uhr Ortszeit bearbeitet",
    "Delivered the same day": "Am selben Tag geliefert",
    "Transactions processed post 3:00 PM": "Transaktionen nach 15:00 Uhr bearbeitet",
    "T+1 business days": "T+1 Geschäftstage",
    "Payments made on weekdays before 3:00PM will be credited to the receiving bank account by midnight of the\nsame day.": "Zahlungen, die an Werktagen vor 15:00 Uhr getätigt werden, werden bis Mitternacht desselben Tages dem Empfängerkonto gutgeschrieben.",
    "Payments made on weekdays after 15:00 will be credited by midnight the following day.": "Zahlungen, die an Werktagen nach 15:00 Uhr getätigt werden, werden bis Mitternacht des folgenden Tages gutgeschrieben.",
    "Payments made on a Saturday, Sunday or Public holiday will be credited to the account by midnight of the 1st following business day": "Zahlungen, die an einem Samstag, Sonntag oder Feiertag getätigt werden, werden dem Konto bis Mitternacht des nächsten Geschäftstages gutgeschrieben.",
    "24*7": "24*7",
    "09hrs - 18 hrs Local Time": "09:00 - 18:00 Ortszeit",
    "https://www.officeholidays.com/countries ": "https://www.officeholidays.com/countries",
    "Min per transaction limit:": "Mindestbetrag pro Transaktion:",
    "Maximum per transaction limit:": "Maximalbetrag pro Transaktion:",
    "Daily threshold limit:": "Tägliches Limit:",
    "Weekly threshold limit:": "Wöchentliches Limit:",
    "Monthly threshold limit:": "Monatliches Limit:",
    "Yearly threshold limit:": "Jährliches Limit:",
    "P2P": "P2P",
    "P2B": "P2B",
    "B2P": "B2P",
    "B2B": "B2B",
    "WALLET TO WALLET": "WALLET TO WALLET",
    "Basic Account (Unverified)": "Basis Konto (Unverifiziert)",
    "Identity Verified": "Identität verifiziert"

}