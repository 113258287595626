export const nl = {
    "How Others Can Find You": "Hoe anderen jou kunnen vinden",
    "Schedules": "Schema's",
    "Login": "Inloggen",
    "Start Your InstaPay Journey Today!": "Begin Vandaag met Uw InstaPay Reis!",
    "Phone Number or Email": "Telefoonnummer of Email",
    "Login Using Social Media": "Inloggen Met Sociale Media",
    "Continue": "Doorgaan",
    "Don't Have an Account? Sign Up": "Heeft U Nog Geen Account? Meld U Aan",
    "Sign Up": "Aanmelden",
    "Edit": "Bewerken",
    "Enter Your Password": "Voer Uw Wachtwoord In",
    "Forgot Password?": "Wachtwoord Vergeten?",
    "Account Type": "Accounttype",
    "Individual": "Individueel",
    "Business": "Zakelijk",
    "Already Have an Account? Sign In": "Heeft U Al Een Account? Meld U Aan",
    "Sing In": "Aanmelden",
    "Enter Your Personal Details": "Voer Uw Persoonlijke Gegevens In",
    "Add Photo or Logo": "Voeg Foto of Logo Toe",
    "Enter Your Name": "Voer Uw Naam In",
    "Back": "Terug",
    "Phone Number": "Telefoonnummer",
    "Email Address": "E-mailadres",
    "Create a Strong Password": "Maak Een Sterk Wachtwoord",
    "Password Requirements": "Wachtwoordvereisten",
    "Must be between 9 and 64 characters": "Moet tussen de 9 en 64 tekens zijn",
    "Include at least two of the following:": "Bevat ten minste twee van de volgende:",
    "Uppercase character": "Hoofdletter",
    "Lowercase character": "Kleine letter",
    "Number": "Nummer",
    "Special character": "Speciaal teken",
    "Confirm Password": "Bevestig Wachtwoord",
    "Finalize Your Process": "Rond Uw Proces Af",
    "Referral Code (Optional)": "Verwijzingscode (Optioneel)",
    "It will autofill if you're signing up with an invitation link.": "Dit wordt automatisch ingevuld als u zich aanmeldt met een uitnodigingslink.",
    "I agree with": "Ik ga akkoord met",
    "Terms & Conditions": "Algemene Voorwaarden",
    "Privacy Policy": "Privacybeleid",
    "Create Your Account": "Maak Uw Account Aan",
    "Enter Code": "Voer Code In",
    "A one-time verification code has been sent to your email address  and phone number.": "Een eenmalige verificatiecode is verzonden naar uw emailadres en telefoonnummer.",
    "Resend available in ": "Opnieuw verzenden beschikbaar in",
    "seconds": "seconden",
    "Verify": "Verifiëren",
    "Verification Code Verified": "Verificatiecode Geverifieerd",
    "Please continue to the next step.": "Ga door naar de volgende stap.",
    "Finish": "Voltooien",
    "Finishing": "Afronden",
    "Company Name": "Bedrijfsnaam",
    "Enter Your Business Details": "Voer Uw Bedrijfsgegevens In",
    "Country ": "Land",
    "You cannot change the country once your account is verified.": "U kunt het land niet veranderen zodra uw account is geverifieerd.",
    "Learn more": "Meer leren",
    "Optional": "Optioneel",
    "Continuing": "Doorgaan",
    "Didn't receive the verification code?": "Heeft u de verificatiecode niet ontvangen?",
    "Resend Now": "Nu Opnieuw Verzenden",
    "Search Here": "Zoek Hier",
    "Search for users, services, and more on InstaPay": "Zoek naar gebruikers, diensten en meer op InstaPay",
    "Search users here": "Zoek gebruikers hier",
    "Earn money with our affiliate program!": "Verdien geld met ons affiliate programma!",
    "Generate passive income by sharing your InstaPay affiliate code with your followers. For each transaction they make, \nyou earn a commission, thereby maximizing the profits from your online influence.": "Genereer passief inkomen door uw InstaPay affiliate code te delen met uw volgers. Voor elke transactie die zij maken,\nverdient u een commissie, waardoor u de winsten van uw online invloed maximaliseert.",
    "Learn More": "Meer leren",
    "Balance Amount": "Balansbedrag",
    "You spent less compared to last month.": "U heeft minder uitgegeven vergeleken met vorige maand.",
    "Referral Rewards": "Verwijzingsbeloningen",
    "Bitcoin": "Bitcoin",
    "USDT": "USDT",
    "Ethereum": "Ethereum",
    "Quick Transfer to Your Most Recent Contacts:": "Snelle Overdracht naar Uw Meest Recente Contacten:",
    "Number of Transactions": "Aantal Transacties",
    "Total Transaction Amount": "Totaal Transactiebedrag",
    "Total Sent": "Totaal Verzonden",
    "Total Received": "Totaal Ontvangen",
    "Add Funds": "Fondsen Toevoegen",
    "Send Money": "Geld Verzenden",
    "Request Money": "Geld Aanvragen",
    "Send a Quote": "Een Offerte Verzenden",
    "Pay With Crypto": "Betalen Met Crypto",
    "Payment Insights": "Inzichten in Betalingen",
    "Recent Transactions ": "Recente Transacties",
    "Today": "Vandaag",
    "My Wallets": "Mijn Wallets",
    "Balance": "Saldo",
    "Wallet ID": "Wallet ID",
    "Monthly Limit": "Maandelijkse Limiet",
    "Top Up": "Opwaarderen",
    "Summary": "Overzicht",
    "Settings": "Instellingen",
    "More": "Meer",
    "Upgrade to Business Account": "Upgrade naar Zakelijk Account",
    "Conversion": "Conversie",
    "Enter Amount": "Voer Bedrag In",
    "Exchanged Amount": "Omgeruild Bedrag",
    "Convert": "Converteren",
    "You Are Converting": "U Converteert",
    "From": "Van",
    "To": "Naar",
    "Exchange Rate": "Wisselkoers",
    "Fees": "Tarieven",
    "You Will Receive": "U Ontvangt",
    "Slide To Confirm": "Schuiven Om Te Bevestigen",
    "Verification Code Confirmed - You'll be redirected to the next step": "Verificatiecode Bevestigd - U wordt doorverwezen naar de volgende stap",
    "Moving to next step in": "Naar de volgende stap in",
    "Thanks for using InstaPay": "Bedankt voor het gebruiken van InstaPay",
    "Your funds have been credited to your wallet.": "Uw fondsen zijn bijgeschreven op uw wallet.",
    "Dashboard": "Dashboard",
    "Accounts": "Accounts",
    "Transactions": "Transacties",
    "Other Transactions": "Andere Transacties",
    "Payments": "Betalingen",
    "Beneficiaries": "Begunstigden",
    "Referrals": "Verwijzingen",
    "Quotations": "Offertes",
    "Pending": "In Behandeling",
    "My QR Code Sticker": "Mijn QR-Code Sticker",
    "My Portfolio": "Mijn Portfolio",
    "My Payment Address": "Mijn Betaaladres",
    "Analytics": "Analytics",
    "Profile": "Profiel",
    "Dark Mode": "Donkere Modus",
    "Support": "Ondersteuning",
    "Logout": "Uitloggen",
    "Powered By": "Aangedreven door",
    "Kemit Kingdom SA": "Kemit Kingdom SA",
    "A Swiss Company": "Een Zwitsers Fintech Bedrijf",
    "MAIN MENU": "HOOFDMENU",
    "OTHERS": "ANDEREN",
    "Wallet": "Wallet",
    "Default": "Standaard",
    "Breakdown": "Uitsplitsing",
    "Credit": "Credit",
    "Debit": "Debet",
    "Wallet Management": "Walletbeheer",
    "Top-up Your Wallet": "Uw Wallet Opwaarderen",
    "Download Statement": "Rekeningoverzicht Downloaden",
    "Block the Wallet": "De Wallet Blokkeren",
    "Wallet Status": "Wallet Status",
    "-Select-File-Type-": "-Selecteer-Bestandstype-",
    "Download Now": "Nu Downloaden",
    "Downloading": "Downloaden",
    "All": "Alles",
    "Sent": "Verzonden",
    "Received": "Ontvangen",
    "Requested": "Aangevraagd",
    "Quoted": "Geoffreerd",
    "Search Transactions": "Zoek Transacties",
    "Date & Time": "Datum & Tijd",
    "Type": "Type",
    "Transaction ID": "Transactie-ID",
    "Recipient": "Ontvanger",
    "Amount": "Bedrag",
    "Status": "Status",
    "Payment Type": "Betaaltype",
    "Sent Payments": "Verzonden Betalingen",
    "Received Payments": "Ontvangen Betalingen",
    "Date": "Datum",
    "Cycles/Time": "Cycli/Tijd",
    "Payment As": "Betalen Als",
    "Name": "Naam",
    "Cancel": "Annuleren",
    "Subscriptions & Scheduled": "Abonnementen & Gepland",
    "Select the option that matches your needs from the list below:": "Selecteer de optie die past bij uw behoeften uit de lijst hieronder:",
    "Easily send money to friends, family, or businesses locally or internationally. \nChoose from multiple transfer options, including bank deposits, mobile wallets, \ncash pick-up, payment cards, and wallet-to-wallet transfers. Enjoy the convenience \nand security of InstaPay for seamless and instant money transfers.": "Eenvoudig geld verzenden naar vrienden, familie of bedrijven, zowel lokaal als internationaal. Kies uit meerdere overdrachtsopties, \nwaaronder bankstortingen, mobiele wallets, contant geld ophalen, betaalkaarten en wallet-to-wallet overdrachten. Geniet van het gemak\nen de veiligheid van InstaPay voor naadloze en onmiddellijke geldoverdrachten.",
    "International Transfer": "Internationale Overboeking\n",
    "Send money across borders with ease. Transfer funds internationally \nto friends, family, or businesses in just a few clicks. Choose from \na wide range of payment channels including bank account, \nmobile money/mobile wallet, cash pick-up location, or payment card \nfor convenient and secure transactions.": "Verzend geld moeiteloos over de grenzen. Transfer fondsen internationaal naar vrienden, familie of bedrijven met slechts een paar klikken. \nKies uit een breed scala aan betaalkanalen, waaronder bankrekening, mobiel geld/mobiele wallet, contante ophaallocatie of betaalkaart\nvoor handige en veilige transacties.",
    "Select the country where you want to send the money!": "Selecteer het land waar je het geld naartoe wilt sturen!",
    "Search Country": "Zoek Land",
    "Trouble Sending Money?": "Problemen met geld verzenden?",
    "Confirm": "Bevestigen",
    "Select Payout Channel": "Selecteer Uitbetalingskanaal",
    "Select your most convenient option to send the money.": "Kies uw meest handige optie om het geld te verzenden.",
    "Select The Bank": "Selecteer De Bank",
    "Search Bank": "Zoek Bank",
    "Send money directly to a recipient's bank account. Enjoy the convenience and security of transferring funds seamlessly to any bank around the world.": "Stuur geld direct naar de bankrekening van een ontvanger. Geniet van het gemak en de veiligheid van het naadloos overmaken van fondsen \nnaar elke bank wereldwijd.\"",
    "Bank Transfer": "Bankoverschrijving",
    "Enter the amount": "Voer het bedrag in",
    "Tax": "Belasting",
    "Amount the recipient will receive": "Bedrag dat de ontvanger zal ontvangen",
    "Select Remarks / Reason": "Selecteer Opmerkingen / Reden",
    "Comments": "Opmerkingen",
    "Attach Files": "Bestanden Toevoegen",
    "Groups": "Groepen",
    "Manage Groups": "Beheer Groepen",
    "Add New Group": "Nieuwe Groep Toevoegen",
    "Invite": "Nodig Iemand Uit",
    "Manage Beneficiaries": "Beheer Begunstigden",
    "Recipients List": "Ontvangerslijst",
    "Search Contacts": "Zoek Contacten",
    "Confirm Your Payment": "Bevestig Uw Betaling",
    "Select Country": "Selecteer Land",
    "Select the Recipient's Payment Channel": "Selecteer Betaalkanaal van de Ontvanger",
    "Change Selection": "Selectie Wijzigen",
    "You Are Transferring ": "U Maakt Over",
    "Oops! Something Went Wrong.": "Oeps! Er Ging Iets Mis.",
    "We're Sorry About That. ": "Het spijt ons.",
    "Go back to Payments Page.": "Ga terug naar de Betalingspagina.",
    "Wallet to Wallet Transfer": "Wallet naar Wallet Overboeking",
    "Request a customized price quote from service providers or vendors.": "Vraag een aangepaste prijsofferte aan bij dienstverleners of leveranciers.",
    "Enter the Wallet ID": "Voer de Wallet-ID in",
    "Enter the Wallet ID, Email, or Phone Number of the recipient.": "Voer de Wallet-ID, e-mail of telefoonnummer van de ontvanger in.",
    "Recipient not on InstaPay?": "Is de Ontvanger Niet op InstaPay? Nodig Ze Uit.",
    "Invite Them": "Nodig Ze Uit",
    "My Beneficiaries": "Mijn Begunstigden",
    "Enter the Amount You Wish to Transfer.": "Voer het bedrag in dat u wilt overmaken.",
    "Sending to ": "Verzenden naar",
    "You Have Selected Wallet to Wallet Transfer": "U Heeft Gekozen voor Wallet naar Wallet Overboeking",
    "Authenticate Your Payment.": "Authenticeer Uw Betaling.",
    "You are transferring": "U maakt over",
    "Total ": "Totaal",
    "Where Is My Payment?": "Waar Is Mijn Betaling?",
    "Share": "Delen",
    "Your Payment Is Confirmed": "Uw Betaling Is Bevestigd",
    "QR Code Payment": "QR-Code Betaling",
    "Instantly transfer money to other InstaPay users with our secure wallet-to-wallet \nservice. Send and receive funds hassle-free within seconds.": "Zet direct geld over naar andere InstaPay-gebruikers met onze veilige wallet-naar-wallet service. Verzend en ontvang fondsen probleemloos \nbinnen enkele seconden.",
    "Scan QR with Your Camera or Upload from Your Device.": "Scan QR met uw camera of upload van uw apparaat.",
    "Upload from Your Device.": "Upload van uw apparaat.",
    "Alternatively, Use the InstaPay Unique Payment Address (UPA)": "Gebruik anders het unieke betalingsadres van InstaPay (UPA)",
    "Enter InstaPay UPA": "Voer InstaPay UPA in",
    "Recipient not on InstaPay? Invite Them": "Ontvanger niet op InstaPay? Nodig Ze Uit",
    "Verify QR Code": "Controleer QR-Code",
    "Scan/Upload QR Code of the Recipient": "Scan/Upload",
    "Payment Confirmed": "Geld Verzenden\n",
    "Request customized price quotes from service providers or vendors to receive accurate cost estimates for the services or products you need.": "Vraag aangepaste prijsoffertes aan bij dienstverleners of leveranciers om nauwkeurige kostenramingen te ontvangen voor de diensten of \nproducten die u nodig heeft.",
    "Choose The Beneficiary or Group": "Kies De Begunstigde of Groep",
    "Enter the Amount You Wish to Request.": "Voer Het Bedrag In Dat U Wilt Aanvragen.",
    "You Are Requesting Money": "U Vraagt Geld Aan",
    "You are requesting": "U vraagt aan",
    "As": "Als",
    "Conversion of Your Cryptocurrencies": "Conversie Van Uw Cryptocurrencies",
    "Experience our user-centric, secure, and efficient Cash-Out Crypto to Fiat Conversion.": "Ervaar onze gebruikersgerichte, veilige en efficiënte Cash-Out Crypto naar Fiat Conversie.",
    "Choose the Cryptocurrency You Intend to Liquidate.": "Kies De Cryptocurrency Die U Wilt Liquideren.",
    "Next": "Volgende",
    "Enter the Crypto Wallet Address of the Recipient.": "Voer Het Crypto Wallet Adres van de Ontvanger In.",
    "Minimum Transactional Value: $100.": "Minimale Transactiewaarde: $100.",
    "Note: Crypto prices frequently change. Refer to the final quotation on the confirm order page.": "Let op: Crypto prijzen veranderen vaak. Raadpleeg de definitieve offerte op de bevestigingspagina.",
    "Select Network": "Selecteer Netwerk",
    "Send A Quote": "Stuur Een Offerte",
    "Send a detailed price quote to your clients or customers. Present your services, products, or offers professionally with a personalized quotation.": "Stuur een gedetailleerde prijsofferte naar uw klanten of opdrachtgevers. Presenteer uw diensten, producten,\nof aanbiedingen professioneel met een gepersonaliseerde offerte.",
    "Choose the Beneficiary": "Kies De Begunstigde",
    "Allow Receiver to Bargain": "Sta Ontvanger Toe Om Te Onderhandelen",
    "Enter the Title for Your Quotation.": "Voer De Titel Voor Uw Offerte In.",
    "Enter the Description for Your Quotation": "Voer De Beschrijving Voor Uw Offerte In",
    "You are Quoting": "U Offreert",
    "Title": "Titel",
    "Description": "Beschrijving",
    "Attachments": "Bijlagen",
    "Confirm Your Quotation": "Bevestig Uw Offerte",
    "Verify The Quotation": "Verifieer De Offerte",
    "Your quotation is confirmed": "Uw offerte is bevestigd",
    "Request A Quote": "Offerte Aanvragen",
    "Coming Soon": "Binnenkort Beschikbaar",
    "Add Beneficiary": "Voeg Begunstigde Toe",
    "Search for Beneficiaries": "Zoek naar Begunstigden",
    "Connected Accounts": "Verbonden Accounts",
    "Details": "Details",
    "First Name": "Voornaam",
    "Last Name": "Achternaam",
    "Country": "Land",
    "City": "Stad",
    "Address Line": "Adresregel",
    "Mobile Number": "Mobiel Nummer",
    "Relationship With Beneficiary": "Relatie Met Begunstigde",
    "Bank Account": "Bankrekening",
    "Mobile Money/Mobile Wallet": "Mobiel Geld/Mobiele Wallet",
    "Payment Card": "Betaalkaart",
    "Crypto Wallet": "Crypto Wallet",
    "InstaPay Account": "InstaPay Account",
    "Cash Pickup": "Contant Ophalen",
    "IBAN / SWIFT Code": "IBAN Of Swift/BIC-code",
    "Swift Code": "Swift-Code",
    "Account Number": "Rekeningnummer",
    "Bank Name": "Banknaam",
    "Branch Name": "Naam Van De Vestiging",
    "Branch Street": "Straat Van De Vestiging",
    "Province": "Provincie",
    "Postal Code": "Postcode",
    "Mobile Money Provider": "Mobiel Geld Aanbieder",
    "Wallet Name": "Wallet Naam",
    "Wallet Number": "Wallet Nummer",
    "Card Holder Name": "Naam Kaarthouder",
    "Card Number": "Kaartnummer",
    "Expiry Date": "Vervaldatum",
    "Crypto Currency": "Cryptovaluta",
    "Wallet Address": "Wallet Adres",
    "Wallet Holder Name": "Naam Wallet Houder",
    "Wallet Currency": "Wallet Valuta",
    "Select Document Type": "Selecteer Documenttype",
    "Enter Document Number": "Voer Documentnummer In",
    "Add Individual Account": "Voeg Individuele Rekening Toe",
    "Add Business Account": "Voeg Zakelijke Rekening Toe",
    "Company Address": "Bedrijfsadres",
    "Company Email": "Bedrijfsemail",
    "Company Phone No": "Bedrijfstelefoonnummer",
    "Total Referrals": "Totaal Verwijzingen",
    "My Earnings": "Mijn Verdiensten",
    "Your Referral ID": "Uw Referral ID",
    "Share Your Unique Referral Link": "Deel Uw Unieke Referral Link",
    "How do I Refer a Friend to InstaPay?": "Hoe verwijs ik een vriend naar InstaPay?",
    "Step 01": "Stap 01",
    "Share your InstaPay unique referral link with your friends.": "Deel uw unieke InstaPay referral link met uw vrienden.",
    "Step 02": "Stap 02",
    "Earn rewards when they transact $100 or more": "Verdien beloningen wanneer zij transacties doen van $100 of meer",
    "Step 03": "Stap 03",
    "Earn up to $0.06 for every transaction your followers make.": "Verdien tot $0.06 voor elke transactie die uw volgers maken.",
    "My Referrals": "Mijn Verwijzingen",
    "Referral Complete": "Referral Voltooid",
    "Received Quotations": "Ontvangen Offertes",
    "Sent Quotations": "Verzonden Offertes",
    "Search Pending Items": "Zoek naar Openstaande Items",
    "Actions": "Acties",
    "Received Requests": "Ontvangen Verzoeken",
    "Sent Requests": "Verzonden Verzoeken",
    "No Actions": "Geen Acties",
    "You Requested": "U Heeft Aangevraagd",
    "Via": "Via",
    "How was your experience?": "Hoe was uw ervaring?",
    "Leave a Review": "Laat een Beoordeling Achter",
    "Overall Ratings": "Algemene Beoordelingen",
    "Visit Profile": "Bezoek Profiel",
    "Ratings": "Beoordelingen",
    "Reviews": "Recensies",
    "No reviews available": "Geen recensies beschikbaar",
    "Select Your Currency": "Selecteer Uw Valuta",
    "Proceed": "Ga Verder",
    "Commision": "Commissie",
    "Unlock Seamless Transactions with InstaPay QR Code Sticker": "Ontgrendel Naadloze Transacties met InstaPay QR Code Sticker",
    "Effortless and Swift:": "Moeiteloos en Snel:",
    "Instantly download your unique InstaPay QR code sticker.": "Download direct uw unieke InstaPay QR code sticker.",
    "Experience hassle-free, cashless payments without needing complex infrastructure like \npayment terminals.": "Ervaar probleemloze, cashloze betalingen zonder complexe infrastructuur zoals betaalterminals.",
    "Swiftly collect payments – no more tedious entry of credit card or phone numbers.": "Verzamel betalingen snel – geen vervelend invoeren van creditcard- of telefoonnummers meer.",
    "Each payment includes customer information for your convenience.": "Elke betaling bevat klantinformatie voor uw gemak.",
    "Economically Smart:": "Economisch Slim:",
    "Zero activation fees – start immediately without upfront costs.": "Geen activeringskosten – start onmiddellijk zonder voorafgaande kosten.",
    "Forget about fixed monthly charges; pay as you transact.": "Vergeet vaste maandelijkse kosten; betaal als u transacties doet.",
    "Ditch the hardware; no terminal costs mean more savings for you.": "Laat de hardware los; geen terminalkosten betekenen meer besparingen voor u.",
    "Enjoy low transaction fees at just 0.75% per QR code payment.": "Geniet van lage transactiekosten van slechts 0,75% per QR-code betaling.",
    "We don't impose minimum commissions; you save more with each transaction.": "We leggen geen minimale commissies op; u bespaart meer bij elke transactie.",
    "Experience the joy of instant payments, enhancing your cash flow.": "Ervaar de vreugde van onmiddellijke betalingen, en verhoog uw cashflow.",
    "Make Every Transaction Count with InstaPay": "Maak Elke Transactie Belangrijk met InstaPay",
    "Wallet QR": "Wallet QR",
    "Download QR": "Download QR",
    "QR Code Status": "QR Code Status",
    "Share your QR Code or payment address link to instantly receive payments in your InstaPay wallet.": "Deel uw QR-code of betalingsadreslink om direct betalingen in uw InstaPay wallet te ontvangen.",
    "QR Code Title": "QR Code Titel",
    "View Transactions": "Bekijk Transacties",
    "My QR Codes:": "Mijn QR Codes:",
    "Load More": "Meer Laden",
    "Benefits Of Portfolio": "Voordelen van Portfolio",
    "Benefits:": "Voordelen:",
    "Enhanced Visibility:": "Verbeterde Zichtbaarheid:",
    "Elevate your public profile. Your portfolio acts as a dynamic showcase, accessible directly from your Payment Address.": "Verhoog uw openbare profiel. Uw portfolio fungeert als een dynamische showcase, direct toegankelijk vanaf uw betalingsadres.",
    "Engage Your Audience:": "Betrek Uw Publiek:\n",
    "Connect with visitors through your creative or professional journey. Let your work speak for you, creating a deeper engagement with potential clients or supporters.": "Verbind met bezoekers via uw creatieve of professionele reis. Laat uw werk\nvoor u spreken, en creëer een diepere betrokkenheid met potentiële klanten of ondersteuners.",
    "Integrated Social Proof:": "Geïntegreerd Sociaal Bewijs:\n",
    "Display your social media influence. Visitors can see your social media reach, adding credibility and context to your portfolio.": "Toon uw sociale media-invloed. Bezoekers kunnen uw sociale media-bereik zien, waardoor uw portfolio geloofwaardigheid en context krijgt.",
    "Seamless Transactions:": "Naadloze Transacties:\n",
    "Enable visitors to request or initiate payments right from your public page. Your portfolio not only showcases your work but also facilitates easy financial interactions.": "Sta bezoekers toe om betalingen rechtstreeks vanaf uw openbare pagina aan te vragen of te initiëren. Uw portfolio toont niet alleen uw werk, \nmaar faciliteert ook gemakkelijke financiële interacties.",
    "Personalized Storytelling:": "Gepersonaliseerd Verhaal Vertellen:\n",
    "Use the 'About Me' section to share your story, mission, or vision, creating a personal connection with your audience.": "Gebruik de 'Over Mij'-sectie om uw verhaal, missie of visie te delen, en creëer een\npersoonlijke connectie met uw publiek.",
    "My Portfolio' is more than just a gallery; it's a tool to build your brand, engage your audience, and streamline your financial interactions on InstaPay.": "Mijn Portfolio' is meer dan alleen een galerij; het is een hulpmiddel om uw merk te bouwen, uw publiek te betrekken en uw financiële interacties \nop InstaPay te stroomlijnen.",
    "Showcase Your Work, Amplify Your Reach. 'My Portfolio' is your digital canvas on \nInstaPay. Upload and display a diverse range of content – from captivating images \nand videos to informative PDFs. Whether you're an artist, freelancer, or \nentrepreneur, this feature lets you present your work or projects in a visually \nengaging way.": "Toon Uw Werk, Vergroot Uw Bereik. 'Mijn Portfolio' is uw digitale canvas op InstaPay. Upload en toon een divers scala aan inhoud - van \nboeiende afbeeldingen en video's tot informatieve PDF's. Of u nu een artiest, freelancer of ondernemer bent, deze functie stelt u in staat om \nuw werk of projecten op een visueel boeiende manier te presenteren.",
    "Add/Manage Portfolio": "Portfolio Toevoegen/Beheren",
    "Add New": "Nieuw Toevoegen",
    "Maximize Your Social Media Earnings with Your InstaPay Payment Address": "Maximaliseer Uw Sociale Media-Inkomsten met Uw InstaPay Betaaladres",
    "Make It Effortless for Your Audience to Support You:": "Maak het moeiteloos voor uw publiek om u te ondersteunen:",
    "Easily integrate your InstaPay Payment Address into your social media posts.": "Integreer gemakkelijk uw InstaPay Betaaladres in uw sociale media-posts.",
    "Easily copy and paste your payment address link into each of your posts, stories, or prominently on your profile.": "Kopieer en plak eenvoudig uw betaaladreslink in elk van uw posts, verhalen of prominent op uw profiel.",
    "Ideal for influencers, artists, entrepreneurs, or anyone with a community eager to show \ntheir support.": "Ideaal voor influencers, artiesten, ondernemers of iedereen met een gemeenschap die graag hun steun wil tonen.",
    "Instant Support, Endless Possibilities:": "Onmiddellijke Ondersteuning, Eindeloze Mogelijkheden:",
    "Transform the way you monetize your social media content.": "Transformeer de manier waarop u uw sociale media-inhoud monetariseert.",
    "Allow your followers to send you funds instantly with just a click, directly from their favorite social networks.": "Sta uw volgers toe u direct geld te sturen met slechts één klik, rechtstreeks vanaf hun favoriete sociale netwerken.",
    "Ideal for real-time support during live streams, as a tipping system for your creative content, or for funding your next big \nproject.": "Ideaal voor realtime ondersteuning tijdens live streams, als een fooiensysteem voor uw creatieve inhoud, of voor de financiering \nvan uw volgende grote project.",
    "Where to Place Your InstaPay Payment Address for Maximum Impact:": "Waar u uw InstaPay betaaladres voor maximale impact plaatst:",
    "Social Media Profiles: Pin it to your bio or about section for constant visibility.": "Sociale Media Profielen: Zet het vast in uw bio of over-sectie voor constante zichtbaarheid.",
    "Posts & Announcements: Incorporate it into your individual posts, particularly those highlighting your work or specific \ncalls for support.": "Berichten & Aankondigingen: Integreer het in uw individuele berichten, vooral diegene die uw werk of specifieke oproepen voor \nondersteuning benadrukken.",
    "Stories & Reels: Integrate it into your interactive stories or captivating reels to capture instant contributions.": "Verhalen & Reels: Integreer het in uw interactieve verhalen of boeiende reels om directe bijdragen vast te leggen.",
    "Live Streams: Share it during your LIVE sessions, making it easy for viewers to contribute in real-time.": "Live Streams: Deel het tijdens uw LIVE-sessies, waardoor het gemakkelijk is voor kijkers om in realtime bij te dragen.",
    "Simple, Fast, and Secure:": "Eenvoudig, Snel en Veilig:",
    "No complex setup – get started in moments.": "Geen complexe installatie - begin in een oogwenk.",
    "Enjoy the security and speed of InstaPay's trusted payment processing.": "Geniet van de veiligheid en snelheid van InstaPay's vertrouwde betaalverwerking.",
    "Receive payments from anyone, anywhere, without them needing an InstaPay account.": "Ontvang betalingen van iedereen, overal, zonder dat ze een InstaPay-account nodig hebben.",
    "Elevate Your Social Media Game with InstaPay Payment Address": "Verhoog uw sociale media-spel met InstaPay Betaaladres",
    "InstaPay VPA (Virtual Payment Address)": "InstaPay VPA (Virtueel Betaaladres)",
    "Payment Address Title": "Titel Betaaladres",
    "Currency": "Valuta",
    "Payment Address Description": "Beschrijving Betaaladres",
    "Update": "Update",
    "Updating": "Bijwerken",
    "Total Transaction": "Totaal Transactie",
    "Last 30 days": "Laatste 30 dagen",
    "Last 1 month": "Laatste 1 maand",
    "Last 1 year": "Laatste 1 jaar",
    "Custom Date": "Aangepaste Datum",
    "Payment Methods": "Betaalmethoden",
    "Payment Types": "Betaaltypes",
    "Requested Amount": "Gevraagd Bedrag",
    "Quoted Amount": "Geoffreerd Bedrag",
    "About Me": "Over Mij",
    "Followers": "Volgers",
    "InstaPay ID": "InstaPay ID",
    "Download QR Code": "QR Code Downloaden",
    "Initiate A Payment": "Een Betaling Initiëren",
    "Report this person": "Deze persoon Rapporteren",
    "Add now": "Nu Toevoegen",
    "Recent Reviews": "Recente Beoordelingen",
    "Reviews As Seller": "Beoordelingen Als Verkoper",
    "Reviews As Buyer": "Beoordelingen Als Koper",
    "Select Language": "Taal Selecteren",
    "Profile Completion": "Profiel Afronden",
    "Profile Completed": "Profiel Voltooid",
    "Basic Info": "Basisinformatie",
    "Username": "Gebruikersnaam",
    "Gender": "Geslacht",
    "Male": "Man",
    "Female": "Vrouw",
    "-Select-Gender-": "-Geslacht Selecteren-",
    "Date Of Birth": "Geboortedatum",
    "Address": "Adres",
    "Edit Profile": "Profiel Bewerken",
    "Update Profile": "Profiel Bijwerken",
    "Updating Profile": "Profiel wordt Bijgewerkt",
    "Complete Now": "Nu Voltooien",
    "Password": "Wachtwoord",
    "Change Password": "Wachtwoord Wijzigen",
    "New Password": "Nieuw Wachtwoord",
    "Re-enter New Password": "Nieuw Wachtwoord Herhalen",
    "Please follow this guide for a strong password": "Volg deze gids voor een sterk wachtwoord",
    "Include at least one special character.": "Minstens één speciaal teken bevatten.",
    "Minimum of 8 characters.": "Minimaal 8 tekens.",
    "Include at least one number": "Minstens één nummer bevatten",
    "Change it often for enhanced security.": "Verander het regelmatig voor verhoogde veiligheid.",
    "Security Questions": "Beveiligingsvragen",
    "Security Question": "Beveiligingsvraag",
    "Select your question": "Selecteer uw vraag",
    "Answer To The Question": "Antwoord op de Vraag",
    "Nominee": "Genomineerde",
    "Relationship with Nominee": "Relatie met de Genomineerde",
    "Nominee Contact Number": "Contactnummer Genomineerde",
    "Nominee Address": "Adres Genomineerde",
    "Delete Nominee": "Genomineerde Verwijderen",
    "Update Nominee": "Genomineerde Bijwerken",
    "Details Updated Successfully!": "Details Succesvol Bijgewerkt!",
    "Success": "Succes",
    "You may elect a Nominee of your choice, provided they are not a minor, through the InstaPay platform itself. You acknowledge that no wet signature is required to \nappoint the nominee, and the online registration will be considered final and binding. In the event of the death of an InstaPay Wallet holder, the balance in the Wallet \nwill be transferred to the registered Nominee. KEMIT KINGDOM will be discharged from all its liabilities upon the handover of the amount to the Nominee. The Nominee \nwill provide any documents required by InstaPay, including those for identification and proof of death.": "U kunt een begunstigde van uw keuze aanwijzen, mits deze geen minderjarige is, via het InstaPay platform zelf. U erkent dat geen natte \nhandtekening vereist is om de begunstigde aan te wijzen, en de online registratie wordt beschouwd als definitief en bindend. In het geval \nvan overlijden van een InstaPay Wallet houder, zal het saldo in de Wallet worden overgedragen aan de geregistreerde begunstigde. \nKEMIT KINGDOM zal worden ontslagen van alle verplichtingen bij de overhandiging van het bedrag aan de begunstigde. De begunstigde \nzal alle door InstaPay vereiste documenten verstrekken, inclusief die voor identificatie en bewijs van overlijden.",
    "Add Nominee": "Voeg Genomineerde Toe",
    "Two Factor Authentication": "Tweefactorauthenticatie",
    "SMS Number": "SMS Nummer",
    "Updated Successfully!": "Succesvol Bijgewerkt!",
    "Social Network Accounts": "Sociale Netwerk Accounts",
    "Here, you can set up and manage your integration settings.": "Hier kunt u uw integratie-instellingen opzetten en beheren.",
    "Social Network Account": "Sociaal Netwerk Account",
    "Activate Account": "Activeer Account",
    "Enter the code below in the InstaPay Chatbot.": "Voer de onderstaande code in in de InstaPay Chatbot.",
    "The code will expire in ": "De code verloopt over",
    "Notifications": "Meldingen",
    "Customize how you receive notifications. These settings apply to the activities you are monitoring.": "Pas aan hoe u meldingen ontvangt. Deze instellingen zijn van toepassing op de activiteiten die u monitort.\n",
    "Activity": "Activiteit",
    "Email": "E-mail",
    "Push": "Push",
    "SMS": "SMS",
    "Source": "Bron",
    "Payment Requests": "Betalingsverzoeken",
    "Bulk Payments": "Bulkbetalingen",
    "Identity Verification": "Identiteitsverificatie",
    "Verify Your Identity": "Verifieer Uw Identiteit",
    "Identity Verification Status:": "Status Identiteitsverificatie:",
    "Briefly describe yourself and explain your reasons for using InstaPay.": "Beschrijf uzelf kort en leg uit waarom u InstaPay gebruikt.",
    "Enter Your Message": "Voer Uw Bericht in",
    "Select Occupation": "Selecteer Beroep",
    "It must be a government-issued identification document with a photo. All identification documents must be written in the Latin alphabet. If not, a certified \ntranslation is required.": "Het moet een door de overheid uitgegeven identificatiedocument met foto zijn. Alle identificatiedocumenten moeten in het Latijnse \nalfabet geschreven zijn. Zo niet, dan is een gecertificeerde vertaling vereist.",
    "Accepted Documents": "Geaccepteerde Documenten",
    "A national identity card": "Een nationale identiteitskaart",
    "A valid passport": "Een geldig paspoort",
    "Valid residence permits (accepted only if issued by a European country or in Switzerland)": "Geldige verblijfsvergunningen (alleen geaccepteerd indien uitgegeven door een Europees land of in Zwitserland)",
    "Biometric Facial Recognition: Comparing video with passport photograph": "Biometrische Gezichtsherkenning: Vergelijken van video met paspoortfoto",
    "Address Verification:": "Adresverificatie:",
    "Provide a bank statement or utility bill (less than 3 months old) as proof of address": "Geef een bankafschrift of nutsrekening (niet ouder dan 3 maanden) als bewijs van adres\n",
    "Additional Documents:": "Aanvullende Documenten:",
    "Kemit Kingdom reserves the right to request any additional documents (Enhanced Due Diligence - EDD) or further information necessary for conducting thorough \nverifications, in accordance with its legal obligations.": "Kemit Kingdom behoudt zich het recht voor om eventuele aanvullende documenten (Enhanced Due Diligence - EDD) of verdere informatie \ndie nodig is voor grondige verificaties op te vragen, in overeenstemming met de wettelijke verplichtingen.",
    "Start Verification": "Start Verificatie",
    "Sessions": "Sessies",
    "This is a list of devices that have logged into your account. Remove any that you do not recognize.": "Dit is een lijst van apparaten die op uw account hebben ingelogd. Verwijder alle apparaten die u niet herkent.",
    "Your Withdrawal Options": "Uw Opnameopties",
    "Choose your preferred method for withdrawing payments.": "Kies uw voorkeursmethode om betalingen op te nemen.",
    "No withdrawal options currently available in your country.": "Er zijn momenteel geen opnameopties beschikbaar in uw land.",
    "Download Your Data": "Download Uw Gegevens",
    "Request a Copy of Your Data": "Vraag een Kopie van Uw Gegevens aan",
    "Submit a request to download a copy of your data": "Dien een verzoek in om een kopie van uw gegevens te downloaden",
    "Which Data Should Be Included in the Download?": "Welke Gegevens Moeten Worden Opgenomen in de Download?",
    "Select all applicable options.": "Selecteer alle toepasselijke opties.",
    "Personal Information": "Persoonlijke Informatie",
    "This information is used to secure your account, tailor your experience, and contact you as necessary.": "Deze informatie wordt gebruikt om uw account te beveiligen, uw ervaring aan te passen en contact met u op te nemen indien nodig.",
    "Name and date of birth": "Naam en geboortedatum",
    "Phone number": "Telefoonnummer",
    "Financial Information": "Financiële Informatie",
    "We use this information to enable faster checkout and to send or receive money in just a few clicks.": "We gebruiken deze informatie om sneller af te rekenen en om in slechts een paar klikken geld te verzenden of te ontvangen.",
    "Bank accounts": "Bankrekeningen",
    "Debit or credit cards": "Debet- of creditcards",
    "Credit Information": "Kredietinformatie",
    "Includes your credit application information": "Bevat uw kredietaanvraaginformatie",
    "Other Information (not included in file)": "Overige Informatie (niet opgenomen in bestand)",
    "Includes device info, technical usage data, geolocation information, marketing preferences, consent history, and data used for other services such as credit, \nidentity verification, communications with PayPal, and third-party processors.": "Bevat apparaatinformatie, technische gebruiksgegevens, geolocatie-informatie, marketingvoorkeuren, toestemmingsgeschiedenis en gegevens \ndie worden gebruikt voor andere diensten zoals krediet, identiteitsverificatie, communicatie met PayPal en derde partij verwerkers.",
    "Choose file type:": "Kies bestandstype:",
    "Select file type": "Selecteer bestandstype",
    "PDF file can be easily opened on any computer": "Een PDF-bestand kan eenvoudig worden geopend op elke computer",
    "Submit Request": "Dien Verzoek In",
    "Delete Your Account": "Verwijder Uw Account",
    "Ask us to delete your data and close this account": "Vraag ons om uw gegevens te verwijderen en dit account te sluiten",
    "We use your data to provide services, so if we delete your data, we also need to close your account.": "We gebruiken uw gegevens om diensten te leveren, dus als we uw gegevens verwijderen, moeten we ook uw account sluiten.",
    "If your account is limited, go to the Resolution Center, select 'Go to Account Limitations', then click 'Resolve'.": "Als uw account beperkt is, ga dan naar het Resolution Center, selecteer 'Ga naar Accountbeperkingen' en klik op 'Oplossen'.",
    "Make sure any outstanding payments have cleared. We won't be able to close your account until they do.": "Zorg ervoor dat eventuele uitstaande betalingen zijn verrekend. We kunnen uw account niet sluiten totdat dit gebeurd is.",
    "If you have a negative balance, click the Resolve link next to your balance.": "Als u een negatief saldo heeft, klik dan op de oploslink naast uw saldo.",
    "Once your account is clear, and if you're eligible, we'll close your account and delete your data. We need \nto retain some of your data for legal and regulatory reasons. To learn more, see our ": "Zodra uw account in orde is, en als u in aanmerking komt, sluiten we uw account en verwijderen we uw gegevens. We moeten sommige van \nuw gegevens om juridische en regelgevende redenen bewaren. Om meer te weten te komen, zie onze ",
    "privacy statement": "Privacyverklaring",
    "We'll email you to let you know the status, and if there are any other steps you need to take.": "We sturen u een e-mail om u op de hoogte te stellen van de status en of er nog andere stappen zijn die u moet nemen.",
    "Have more questions about data deletion?": "Heeft u meer vragen over gegevensverwijdering?",
    "Contact us": "Neem contact met ons op",
    "I understand that account deletion is permanent and can't be reversed.": "Ik begrijp dat accountverwijdering permanent is en niet kan worden teruggedraaid.",
    "Are you sure you want to close your account?": "Weet u zeker dat u uw account wilt sluiten?",
    "Yes, Continue": "Ja, Doorgaan",
    "Logging In": "Inloggen",
    "Complete your profile to streamline your transaction process.": "Voltooi uw profiel om uw transactieproces te stroomlijnen.",
    "Skip": "Overslaan",
    "Complete Your Profile": "Voltooi Uw Profiel",
    "API Key": "API-sleutel",
    "Get API Key": "Verkrijg API-sleutel",
    "Generating New API Key": "Nieuwe API-sleutel genereren",
    "Generate API Key": "Genereer API-sleutel",
    "Regenerate API Key": "Regenereer API-sleutel",
    "To harness the full potential of InstaPay's seamless transactions, follow these quick steps to generate your API key. First, sign up for an account at \n[your registration URL] and access the Developer Dashboard. Navigate to the 'API Keys' section, generate a new key, and ensure its secure storage.": "Om het volledige potentieel van de naadloze transacties van InstaPay te benutten, volg deze snelle stappen om uw API-sleutel te genereren. \nMeld u eerst aan voor een account op [uw registratie-URL] en ga naar het Ontwikkelaarsdashboard. Navigeer naar de sectie 'API-sleutels', \ngenereer een nieuwe sleutel en zorg voor een veilige opslag.",
    "Delete API Key": "Verwijder API-sleutel",
    "Webhook URL": "Webhook-URL",
    "KYB Verification": "KYB-verificatie",
    "Additional Files": "Aanvullende Bestanden",
    "Business Verification": "Bedrijfsverificatie",
    "--Select-Your-Company-Type--": "--Selecteer-Uw-Bedrijfstype--",
    "Documents Required:": "Vereiste Documenten:",
    "Official Company Registry Extract (Entity's Registration/Incorporation Document)": "Officieel Uittreksel van het Bedrijfsregister (Registratie-/Oprichtingsdocument van de Entiteit)",
    "This document serves as the company's official 'identity card' and should be obtained from the local business registry. It reflects the current status of the business \n(active or closed).": "Dit document dient als het officiële 'identiteitsbewijs' van het bedrijf en moet worden verkregen uit het lokale bedrijfsregister.\nHet weerspiegelt de huidige status van het bedrijf (actief of gesloten).",
    "The document should be current, with an issuance date not exceeding 3 months.": "Het document moet actueel zijn, met een uitgiftedatum die niet ouder is dan 3 maanden.",
    "The document must include the following details:": "Het document moet de volgende details bevatten:",
    "Legal name of the company": "Juridische naam van het bedrijf",
    "Full registered address": "Volledig geregistreerd adres",
    "Company registration number": "Bedrijfsregistratienummer",
    "Date of incorporation": "Datum van oprichting",
    "Description of the company's activities/purpose": "Beschrijving van de activiteiten/doel van het bedrijf",
    "Legal representative's information: full name, date and place of birth, residential address (minimum requirement: country of residence), and nationality": "Informatie over de wettelijke vertegenwoordiger: volledige naam, geboortedatum en plaats, woonadres (minimale vereiste: land van verblijf), \nen nationaliteit",
    "If applicable, the document should also include:": "Indien van toepassing, moet het document ook bevatten:",
    "Brand or trading names": "Merk of handelsnamen",
    "Value Added Tax (VAT) number": "BTW-nummer (Belasting Toegevoegde Waarde)",
    "List of Ultimate Beneficial Owners (UBOs) - this is mandatory in some countries, otherwise, it may be provided as a separate document.": "Lijst van Uiteindelijke Begunstigden (UBO's) - dit is verplicht in sommige landen, anders kan het als een apart document worden verstrekt.",
    "Proof of Legal Representative's Authority": "Proof of Legal Representative's Authority",
    "This document validates an individual's authority to represent the company": "Dit document valideert de bevoegdheid van een individu om het bedrijf te vertegenwoordigen",
    "Typically, the legal representative is named in the company's registry extract. However, if this is not the case, a document such as a Power of Attorney or Delegation \nof Powers must be secured.": "Typisch wordt de wettelijke vertegenwoordiger genoemd in het uittreksel uit het bedrijfsregister. Als dit niet het geval is, moet een document \nzoals een Volmacht of Delegatie van Bevoegdheden worden verkregen.",
    "Power of Attorney/Delegation of Powers:": "Volmacht/Delegatie van Bevoegdheden:\n",
    "The individual conferring the authority must be legitimately entitled to do so. This individual should be listed as a legal representative in the company's registry extract.": "De persoon die de bevoegdheid verleent, moet hiertoe legitiem gerechtigd zijn. Deze persoon moet worden vermeld als een wettelijke \nvertegenwoordiger in het uittreksel uit het bedrijfsregister.",
    "Ownership Structure and Ultimate Beneficial Owners (UBOs) Documentation": "Documentatie van Eigendomsstructuur en Uiteindelijke Begunstigden (UBO's)",
    "Evidence of the company's listing on the relevant stock exchange should be provided. This could be a page from the stock exchange website displaying \nthe company's details.": "Bewijs van notering van het bedrijf op de relevante effectenbeurs moet worden verstrekt. Dit kan een pagina van de website van de \neffectenbeurs zijn waarop de gegevens van het bedrijf worden weergegeven.",
    "If less than 75% of the company's shares are publicly owned, all shareholders who own or control more than 25% of the shares will be subject to \nUltimate Beneficial Owner (UBO) verification requirements.": "Als minder dan 75% van de aandelen van het bedrijf openbaar in bezit is, zullen alle aandeelhouders die meer dan 25% van de aandelen \nbezitten of beheersen, onderworpen zijn aan verificatie-eisen van de Uiteindelijke Begunstigde Eigenaar (UBO).",
    "Collection of Identification Documents": "Collection of Identification Documents\n",
    "The identification document must be government-issued, bearing a photograph of the individual. All identification documents must be written in the Latin alphabet. If not, \na certified translation is required.": "The identification document must be government-issued, bearing a photograph of the individual. All identification documents must be written \nin the Latin alphabet. If not, a certified translation is required.",
    "Accepted documents:": "Geaccepteerde documenten:",
    "Valid National Identity Card": "Geldige nationale identiteitskaart",
    "Valid Passport": "Geldig paspoort",
    "Valid Resident Permits - These will be accepted only if issued by a European country.": "Geldige verblijfsvergunningen - Deze worden alleen geaccepteerd indien uitgegeven door een Europees land.",
    "Only legal representatives are subject to the identification document collection process.": "Only legal representatives are subject to the identification document collection process.\n",
    "Additional Requirement:": "Aanvullende Vereiste:\n",
    "In cases involving a Power of Attorney or Delegation of Powers, both parties (the individual granting authority and the one receiving it) must provide a copy of their ID.": "In gevallen met een volmacht of delegatie van bevoegdheden, moeten beide partijen\n(de persoon die de autoriteit verleent en degene die deze ontvangt) een kopie van hun ID verstrekken.",
    "Additional Documents": "Aanvullende Documenten\n",
    "Utility Bill (such as gas, electricity, telephone or mobile phone bill); or a document issued by a government department that shows the End-user’s address \nand name (e.g., Residential Certificate); or": "Nutsrekening (zoals gas, elektriciteit, telefoon of mobiele telefoon rekening); of een document uitgegeven door een overheidsinstantie \ndat het adres en de naam van de eindgebruiker toont (bijvoorbeeld een woonplaatscertificaat); of",
    "Bank Statement; or": "Bankafschrift; of\n",
    "a document issued by a government department that shows the End-user’s address and name (e.g., Residential Certificate).": "een document uitgegeven door een overheidsinstantie dat het adres en de naam van de eindgebruiker toont \n(bijvoorbeeld een woonplaatscertificaat).",
    "Kemit Kingdom retains the right to request any additional documents or further information necessary for conducting thorough verifications, in line with \nits legal obligations.": "Kemit Kingdom behoudt zich het recht voor om eventuele aanvullende documenten of verdere informatie die nodig is voor het uitvoeren \nvan grondige verificaties aan te vragen, in overeenstemming met zijn wettelijke verplichtingen.",
    "Note:": "Opmerking:",
    "Partners": "Partners",
    "Add New Partner": "Nieuwe Partner Toevoegen",
    "Designation": "Functie",
    "Partner Name": "Partner Naam",
    "Partner Email": "Partner E-mail",
    "Partner Designation": "Partner Functie",
    "Add Now": "Nu Toevoegen",
    "Adding": "Toevoegen",
    "Delete Partner": "Partner Verwijderen",
    "Delete": "Verwijderen",
    "Deleting": "Verwijdering",
    "Are you sure, you want to remove sxz as partner?": "Bent u zeker dat u sxz als partner wilt verwijderen?",
    "Entity's Registry Extract (Entity'sRegistration/Creation Document)": "Uittreksel uit het Handelsregister (Registratie-/Oprichtingsdocument van de Entiteit)",
    "This document should be either:": "Dit document kan een van de volgende zijn:",
    "A proof of creation of the entity, providing the entity's details, creation date, and purpose.": "Een bewijs van oprichting van de entiteit, met details, oprichtingsdatum en doel van de entiteit.",
    "A local registry extract:": "Een uittreksel uit het lokale register:",
    "Required as per the country's regulations, serving as the current 'identity card' for the entity, indicating its status (active or closed).": "Vereist volgens de regelgeving van het land, fungeert als de huidige 'identiteitskaart' van de entiteit, waarin de status (actief of gesloten) \nwordt aangegeven.",
    "If a registry extract exists, it must not be older than 3 months.": "Indien er een uittreksel uit het register bestaat, mag dit niet ouder zijn dan 3 maanden.",
    "Entity name": "Naam van de entiteit",
    "Registration number (if applicable, based on the country's requirements)": "Registratienummer (indien van toepassing, gebaseerd op de eisen van het land)",
    "Date of creation": "Datum van oprichting",
    "Description of the entity's activities or purpose": "Beschrijving van de activiteiten of het doel van de entiteit",
    "Trading names": "Handelsnamen",
    "Articles of Association": "Statuten",
    "The Articles of Association serve as the constitutional document of the entity, outlining its operating rules.": "De Statuten dienen als het grondwettelijk document van de entiteit, waarin de operationele regels worden uiteengezet.",
    "There is no time constraint on this document, meaning the most recent version is requested. Typically, these types of entities require at least one annual meeting \nthat may result in an updated version of the Articles of Association.": "There is no time constraint on this document, meaning the most recent version is requested. Typically, these types of entities require at \nleast one annual meeting that may result in an updated version of the Articles of Association.",
    "Examples of changes that could occur include updates to the entity's name, address, purpose, management board, roles, responsibilities, etc.": "Examples of changes that could occur include updates to the entity's name, address, purpose, management board, roles, responsibilities, etc.",
    "The document should contain information about:": "The document should contain information about:\n",
    "The entity itself, including its legal name, registered address, and purpose": "The entity itself, including its legal name, registered address, and purpose\n",
    "Its internal operating rules, such as the appointment process for management board members, their roles and powers, among other details. This list is not exhaustive.": "Its internal operating rules, such as the appointment process for management board members, their roles and powers, among other details. \nThis list is not exhaustive.",
    "How the entity is funded": "How the entity is funded\n",
    "Appointment/Removal/Changes on Board of Management Members:": "Appointment/Removal/Changes on Board of Management Members:\n",
    "These updates usually occur based on the entity's needs or as stipulated in the Articles of Association. Changes are documented through board of management \nmeeting minutes, which are registered and certified to reflect the updated list of members, such as the president, vice-president, treasurer, and general secretary.": "These updates usually occur based on the entity's needs or as stipulated in the Articles of Association. Changes are documented through \nboard of management meeting minutes, which are registered and certified to reflect the updated list of members, such as the president, \nvice-president, treasurer, and general secretary.",
    "The latest board management meeting minutes, including the current list of members, will be required in addition to the Articles of Association, if available.": "The latest board management meeting minutes, including the current list of members, will be required in addition to the Articles of Association, \nif available.",
    "Note: If the current list of board management members is not included in the latest Articles of Association and there are no board meeting minutes available, \nthis list must be provided. It should be dated and signed by the most recently appointed legal representative, whose appointment can be verified through related \ndocumentation.": "Note: If the current list of board management members is not included in the latest Articles of Association and there are no board meeting \nminutes available, this list must be provided. It should be dated and signed by the most recently appointed legal representative, \nwhose appointment can be verified through related documentation.",
    "This document demonstrates an individual's authority to represent the entity. This document could be:": "This document demonstrates an individual's authority to represent the entity. This document could be:",
    "The latest Articles of Association": "The latest Articles of Association",
    "The current list of board management members (which may be included in the board management meeting minutes)": "The current list of board management members (which may be included in the board management meeting minutes)",
    "The document should specify:": "The document should specify:",
    "Full name of the individual(s)": "Full name of the individual(s)",
    "Function of the individual(s). This information will be cross-verified with the Articles of Association to ensure that the individual's function permits them to bind the entity. \nThis is particularly important when the applicant is not the president, and other functions have limited representation authority as stated in the Articles of Association.": "Function of the individual(s). This information will be cross-verified with the Articles of Association to ensure that the individual's function permits \nthem to bind the entity. This is particularly important when the applicant is not the president, and other functions have limited representation \nauthority as stated in the Articles of Association.",
    "Additional information about the legal representative that will be collected includes:": "Additional information about the legal representative that will be collected includes:",
    "Date and place of birth": "Geboortedatum en -plaats",
    "Residential address (at least the country of residence should be stated)": "Residential address (at least the country of residence should be stated)",
    "Nationality": "Nationaliteit",
    "Ownership Structure & Ultimate Beneficial Owners (UBOs)": "Ownership Structure & Ultimate Beneficial Owners (UBOs)",
    "Typically, these types of entities, given their structure and purpose, do not have beneficial owners. However, board members will be identified and treated as such for \nscreening purposes, ID collection and verification, etc.": "Typically, these types of entities, given their structure and purpose, do not have beneficial owners. However, board members will be identified \nand treated as such for screening purposes, ID collection and verification, etc.",
    "A small number of associations might have beneficial owners, particularly those generating profits. In such instances, this detail will be mentioned \nin the Articles of Association. These individuals will be easily identified and treated as such, alongside management board members.": "A small number of associations might have beneficial owners, particularly those generating profits. In such instances, this detail will be \nmentioned in the Articles of Association. These individuals will be easily identified and treated as such, alongside management board members.",
    "Additional Requirement: In cases involving a Power of Attorney or Delegation of Powers, both parties \n(the individual granting authority and the one receiving it) must provide a copy of their identification documents.": "Additional Requirement: In cases involving a Power of Attorney or Delegation of Powers, both parties \n(the individual granting authority and the one receiving it) must provide a copy of their identification documents.",
    "Sole Trader's Registry Extract (Entity's Registration/Creation Document)": "Sole Trader's Registry Extract (Entity's Registration/Creation Document)\n",
    "This document serves as a local registry for sole traders, required in countries where such registration is mandatory. \nIt verifies that the individual is engaged in a professional activity as a sole trader. The name of this document varies from \ncountry to country.": "This document serves as a local registry for sole traders, required in countries where such registration is mandatory. It verifies that the individual \nis engaged in a professional activity as a sole trader. The name of this document varies from country to country.",
    "Where applicable, the document should not be older than 3 months.": "Waar van toepassing, mag het document niet ouder zijn dan 3 maanden.",
    "The document must contain:": "Het document moet bevatten:",
    "Individual's name": "Naam van de persoon",
    "Residential address": "Woonadres",
    "Registration number": "Registratienummer",
    "The sole trader is the only individual subject to the identification document collection process.": "De eenmanszaak is de enige persoon onderworpen aan het verzamelproces van identiteitsdocumenten.",
    "No Transactions": "Geen Transacties",
    "No Pending Items": "Geen Lopende Zaken",
    "No Beneficiaries": "Geen Begunstigden",
    "Send Money To": "Geld Versturen Naar",
    "Confirmation": "Bevestiging",
    "You are setting": "U stelt in",
    "as default.": "als standaard.",
    "Previously": "Eerder",
    "was default QR.": "was standaard QR.",
    "Category": "Categorie",
    "-Select-From-The-List-": "-Selecteer-Van-De-Lijst-",
    "Add a note": "Voeg een notitie toe",
    "Categories Added Successfully!": "Categorieën Succesvol Toegevoegd!",
    "Subscription starting date": "Startdatum abonnement",
    "Until": "Tot",
    "Next Transaction": "Volgende Transactie",
    "Transaction Method": "Transactiemethode",
    "Accept the quotation": "Accepteer de offerte",
    "Choose Currency": "Kies Valuta",
    "Accept": "Accepteren",
    "Bargain the quotation": "Onderhandel over de offerte",
    "Enter Counter Amount:": "Voer Tegenbedrag in:",
    "Bargain": "Onderhandelen",
    "Decline the quotation": "Annuleer de offerte",
    "Are you sure, you want to decline this quotation?": "Weet u zeker dat u deze offerte wilt afwijzen?",
    "Decline": "Afwijzen",
    "Personal Account": "Persoonlijke Rekening",
    "Jan": "jan",
    "Feb": "feb",
    "Mar": "mrt",
    "Apr": "apr",
    "May": "mei",
    "Jun": "jun",
    "Jul": "jul",
    "Aug": "aug",
    "Sep": "sep",
    "Oct": "okt",
    "Nov": "nov",
    "Dec": "dec",
    "Today": "Vandaag",
    "Spent In": "Besteed in",
    "Revised Amount": "Herzien bedrag",
    "You spent more comparing to last month": "Je hebt meer uitgegeven in vergelijking met vorige maand",
    "Last": "Laatste",
    "days": "Dagen",
    "Year": "Jaren",
    "Pick a language": "Kies een taal",
    "French": "Frans",
    "Spanish": "Spaans",
    "Portuguese": "Portugees",
    "German": "Duits",
    "Ukrainian": "Oekraïens",
    "Italian": "Italiaans",
    "Russian": "Russisch",
    "Arabic": "Arabisch",
    "Polish": "Pools",
    "Dutch (Nederlands)": "Nederlands",
    "Yoruba": "Yoruba",
    "Indonesian": "Indonesisch",
    "Turkish": "Turks",
    "Swahili": "Swahili",
    "Hausa": "Hausa",
    "Hindi": "Hindi",
    "Urdu": "Urdu",
    "Chinese": "Chinees",
    "Select Your Preferred Language": "Selecteer uw voorkeurstaal",
    "Please select the language you would \nlike to use for the eKYC process.": "Selecteer de taal die u wilt gebruiken voor het eKYC-proces.",
    "Select Your Nationality": "Selecteer uw nationaliteit",
    "Choose your nationality to see the \nsupported ID documents for  your country.": "Kies uw nationaliteit om de ondersteunde ID-documenten voor uw land te zien.",
    "Grant permission to use the camera.": "Geef toestemming om de camera te gebruiken.",
    "Tap or click \"Allow\" when prompted to \ngrant permission for camera access to \ncapture the document.": "Tik of klik op \"Toestaan\" wanneer daarom wordt gevraagd om toestemming voor cameratoegang om het document vast te leggen.",
    "Upload Your Documents from \nGallery/Photos": "Upload uw documenten vanuit de galerij/foto's",
    "If you encounter issues with your camera \nor if your system doesn't have one, you can\n manually upload photos of the document \nfrom your local storage.": "Als u problemen ondervindt met uw camera of als uw systeem er geen heeft, kunt u handmatig foto's van het document uploaden vanuit uw lokale opslag.",
    "Upload Your ID.": "Upload uw ID.",
    "Make sure your ID is clear and not blurry to \nensure quick verification.": "Zorg ervoor dat uw ID duidelijk en niet wazig is om een snelle verificatie te garanderen.",
    "Valid Proof of Address": "Geldig Bewijs van Adres",
    "1. Ensure that your proof of address is \ncurrent and that the statement date is \nwithin the last three months from the \nstart date of your eKYC verification.\n\n2. Make sure your proof of address is clear \nand not blurry to ensure quick verification.": "1. Zorg ervoor dat uw bewijs van adres actueel is en dat de datum van het afschrift binnen de laatste drie maanden vanaf de startdatum van uw eKYC-verificatie ligt.\n\n2. Zorg ervoor dat uw bewijs van adres duidelijk en niet wazig is om een snelle verificatie te garanderen.\n",
    "Important Notice\n\nPlease carefully review all the steps in this guideline before proceeding with your eKYC verification.\n\nAvoid Common Mistakes:\n\n* Ensure all your documents are clear, legible, and up-to-date.\n* Double-check that the information you provide matches the details on your official documents.\n* Follow the instructions precisely to avoid delays or rejections.\n\nNote: \nIf your eKYC verification fails and you need to resubmit, you will be charged again for the resubmission process.\n\nBy following these guidelines, you can help ensure a smooth and successful eKYC verification.\n\nFor any questions or assistance, please contact our support team at support@insta-pay.ch ": "Belangrijke mededeling\n\nLees alle stappen in deze handleiding zorgvuldig door voordat u doorgaat met uw eKYC-verificatie.\n\nVermijd veelvoorkomende fouten:\n\n* Zorg ervoor dat al uw documenten duidelijk, leesbaar en actueel zijn.\n* Controleer of de door u verstrekte informatie overeenkomt met de gegevens op uw officiële documenten.\n* Volg de instructies nauwkeurig op om vertragingen of afwijzingen te voorkomen.\n\nOpmerking: \nAls uw eKYC-verificatie mislukt en u deze opnieuw moet indienen, worden er extra kosten in rekening gebracht voor het herindienen.\n\nDoor deze richtlijnen te volgen, kunt u zorgen voor een soepele en succesvolle eKYC-verificatie.\n\nVoor vragen of ondersteuning kunt u contact opnemen met ons ondersteuningsteam via support@insta-pay.ch",
    "Personal Transactions": "Persoonlijke transacties",
    "Business Transactions": "Zakelijke transacties",
    "Shopping & Purchases": "Winkelen en aankopen",
    "Bills & Utilities": "Rekeningen en nutsvoorzieningen",
    "Entertainment & Leisure": "Vermaak en Vrije tijd",
    "Investments & Savings": "Investeringen en Besparingen",
    "Health & Wellness": "Gezondheid en Welzijn",
    "Transportation": "Vervoer",
    "Education": "Onderwijs",
    "Miscellaneous": "Diversen",
    "Family Support": "Gezinsondersteuning",
    "WALLET": "WALLET",
    "Add a currency": "Voeg een valuta toe",
    "When you add a currency, any payments you receive in that currency will be credited to your InstaPay balance. Your primary currency will be used for sending or requesting payments unless specified otherwise.": "Wanneer je een valuta toevoegt, worden alle betalingen die je in die valuta ontvangt bijgeschreven op je InstaPay-saldo. Je primaire valuta wordt gebruikt voor het verzenden of aanvragen van betalingen, tenzij anders aangegeven.",

    "balance amount ": "saldo bedrag",
    "referral ": "verwijzing",
    "crypto wallet balance": "saldo crypto-portemonnee",
    "money in": "geld in",
    "money out": "geld uit",
    "analytics": "analyse",
    "see more": "meer zien",
    "manage my Wallets and see the transaction details": "beheer mijn Wallets en bekijk de details van mijn transacties",
    "set as default Wallet": "als standaard Wallet instellen",
    "add currency": "valuta toevoegen",
    "convert funds ": "fondsen omzetten",
    "withdraw balance": "saldo opnemen",
    "chart names": "grafieknamen",
    "withdraw balance from PKR wallet": "saldo opnemen van PKR-portemonnee",
    "select a payout channel": "kies een uitbetalingskanaal",
    "you have entered an amount below the minimum payment range": "je hebt een bedrag ingevoerd dat lager is dan het minimale betalingsbedrag",
    "change": "veranderen",
    "edit": "bewerken",
    "moving to next step in ...": "naar de volgende stap in ...",
    "you are withdrawing ...": "je neemt op ...",
    "where is my payment": "waar is mijn betaling",
    "download": "downloaden",
    "IBAN / SWIFT Code": "IBAN lub kod SWIFT/BIC",
    "swift code": "kod SWIFT",
    "account number": "numer konta",
    "branch street": "straat van het filiaal",
    "city": "miasto",
    "province": "województwo",
    "postal code": "kod pocztowy",
    "all payout channels": "wszystkie kanały wypłat",
    "bussiness beneficiary": "beneficjent biznesowy",
    "no quotation": "geen offerte",
    "no pending items": "geen openstaande items",
    "QR status": "QR-status",
    "Portfolio": "Portfolio",
    "edit whole portfolio page": "bewerk de hele portfoliopagina",
    "personal account": "persoonlijk account",
    "Chart names": "Grafieknamen",
    "instagram": "instagram",
    "facebook": "facebook",
    "twitter": "twitter",
    "more": "meer",
    "not connected": "niet verbonden",
    "code": "code",
    "ratings": "beoordelingen",
    "current password": "huidig wachtwoord",
    "include at least one uppercase and lowercase": "bevat ten minste één hoofdletter en kleine letter",
    "my social network accounts": "mijn sociale netwerkaccounts",
    "SMS ": "SMS",
    "view identity verfication guide": "bekijk de identiteitsverificatiegids",
    "boimetric face recognition": "biometrische gezichtsherkenning",
    "please select a payer (withdrawl options)": "selecteer een betaler (opnamemogelijkheden)",
    "IBAN number ": "IBAN-nummer",
    "account holder name": "naam rekeninghouder",
    "download data": "gegevens downloaden",
    "bank transfer": "bankoverschrijving",
    "mobile wallet": "mobiele portemonnee",
    "all bank names": "alle banknamen",
    "pay via card": "betaal met kaart",
    "you have entered an amount that is below the payer range": "u heeft een bedrag ingevoerd dat lager is dan het betalerbereik",
    "trouble sending money": "probleem met geld versturen",
    "please follow these example": "volg deze voorbeelden",
    "wallet ID ": "Wallet-ID",
    "username": "gebruikersnaam",
    "email": "e-mail",
    "phone number": "telefoonnummer",
    "no user found": "geen gebruiker gevonden",
    "search": "zoeken",
    "scan/upload QR code of the recipient": "scan/upload de QR-code van de ontvanger",
    "invite them": "nodig hen uit",
    "trouble quoting money": "probleem met het quoteren van geld",
    "select a country": "selecteer een land",
    "airtime details": "details van het beltegoed",
    "enter the phone number": "voer het telefoonnummer in",
    "service type": "soort dienst",
    "select a service": "selecteer een dienst",
    "service list": "servicelijst",
    "amount entered is less than the ....": "het ingevoerde bedrag is minder dan ....",
    "total": "totaal",
    "total credit the receiver gets": "Totaal krediet dat de ontvanger krijgt",
    "take ": "nemen",
    "OTP has been verified , You'll be redirected......": "OTP is geverifieerd, je wordt doorgestuurd......",
    "Link Your Instagram Account to InstaPay": "Koppel je Instagram-account aan InstaPa",
    "Step 01 – From Instagram App": "Stap 01 – Vanuit de Instagram-app",
    "Open the Instagram app, search for the \"InstaPay\" profile page, select \"Message\" to open the chat box, type/say \"Hi\" and click send to initiate the conversation with the InstaPay chatbot.": "Open de Instagram-app, zoek naar de \"InstaPay\" profielpagina, selecteer \"Bericht\" om het chatvenster te openen, typ/zeg \"Hallo\" en klik op verzenden om het gesprek met de InstaPay-chatbot te starten.",
    "Step 02 – From InstaPay Instagram Profile Page ": "Stap 02 – Vanuit de InstaPay Instagram-profielpagina",
    "InstaPay chatbot will display two action buttons: \"Register\" and \"Connect.\" Select \"Connect,\" and the chatbot will prompt you to enter your InstaPay username.": "De InstaPay-chatbot toont twee actieborden: \"Registreren\" en \"Verbinden\". Selecteer \"Verbinden\" en de chatbot vraagt je om je InstaPay-gebruikersnaam in te voeren.",
    "Step 03 – From InstaPay Instagram Profile Page": "Stap 03 – Vanuit de InstaPay Instagram-profielpagina",
    "The InstaPay chatbot will then ask for the InstaPay verification code. Enter the code you obtained from the InstaPay settings under \"My Social Network Accounts\" and click send.": "De InstaPay-chatbot zal dan om de InstaPay-verificatiecode vragen. Voer de code in die je hebt verkregen in de InstaPay-instellingen onder \"Mijn sociale netwerken\" en klik op verzenden.",
    "Step 04 – From InstaPay Instagram Profile Page": "Stap 04 – Vanuit de InstaPay Instagram-profielpagina",
    "You will receive a congratulatory message confirming that your Instagram account is now synced with InstaPay. Click on \"Main Menu\" to start using InstaPay services.": "Je ontvangt een felicitatiebericht waarin wordt bevestigd dat je Instagram-account nu is gesynchroniseerd met InstaPay. Klik op \"Hoofdmenu\" om de InstaPay-diensten te gebruiken.",
    "Additional Tips": "Aanvullende tips",
    "1.\tSecure Your Account: Ensure your Instagram account is secure by enabling two-factor authentication (2FA) to protect your linked InstaPay services.": "1. Beveilig uw account: Zorg ervoor dat uw Instagram-account beveiligd is door tweefactorauthenticatie (2FA) in te schakelen om uw gekoppelde InstaPay-diensten te beschermen.",
    "2.\tExplore More: Once linked, explore the wide range of services InstaPay offers, from seamless transactions to instant withdrawals.": "2. Verken meer: Verken, zodra u bent verbonden, het brede scala aan diensten dat InstaPay biedt, van naadloze transacties tot directe opnames.",
    "3.\tStay Updated: Keep an eye on our updates, as we will soon support linking other social media accounts to make your experience even more integrated.": "3. Blijf op de hoogte: Houd onze updates in de gaten, want binnenkort ondersteunen we het koppelen van andere sociale netwerkaccounts om uw ervaring nog meer geïntegreerd te maken.",
    "4.\tHelp & Support: If you face any issues during the linking process, contact our support team for assistance.": "4. Hulp en ondersteuning: Als u problemen ondervindt tijdens het koppelingsproces, neem dan contact op met ons ondersteuningsteam voor hulp.",
    "Future Enhancements": "Toekomstige verbeteringen",
    "In the near future, InstaPay will allow you to link more social media accounts, providing you with even more flexibility and convenience. Stay tuned for exciting updates!": "In de nabije toekomst stelt InstaPay u in staat om meer sociale netwerkaccounts te koppelen, waardoor u nog meer flexibiliteit en gemak krijgt. Blijf op de hoogte van spannende updates!",
    "Personal Transactions": "Persoonlijke transacties",
    "Business Transactions": "Zakelijke transacties",
    "Shopping & Purchases": "Winkelen en aankopen",
    "Bills & Utilities": "Rekeningen en nutsvoorzieningen",
    "Entertainment & Leisure": "Vermaak en Vrije tijd",
    "Investments & Savings": "Investeringen en Besparingen",
    "Health & Wellness": "Gezondheid en Welzijn",
    "Transportation": "Vervoer",
    "Education": "Onderwijs",
    "Miscellaneous": "Diversen",
    "Family Support": "Gezinsondersteuning",
    "Web - login with phone number": null,
    "Login": "Login",
    "Start Your InstaPay Journey Today!": "Begin vandaag nog aan je InstaPay-reis!",
    "Enter your phone number": "Voer je telefoonnummer in",
    "Login with email": "Inloggen met e-mail",
    "Don't have an account?": "Geen account?",
    "Sign Up": "Meld je aan",
    "Continue": "Doorgaan",
    "We recommend you download and install the Google Authenticator app": "We raden je aan de Google Authenticator-app te downloaden en te installeren",
    "Two-factor authentication (2FA) is the best way to protect yourself online.": "Twee-factor-authenticatie (2FA) is de beste manier om jezelf online te beschermen.",
    "Strong security with Google Authenticator": "Sterke beveiliging met Google Authenticator",
    "Enter your password": "Voer je wachtwoord in",
    "Web Mobile": null,
    "We recommend you download and install the Google Authenticator app to ensure you receive your verification codes instantly, avoiding any SMS delays or issues.": "We raden je aan de Google Authenticator-app te downloaden en te installeren om ervoor te zorgen dat je verificatiecodes direct ontvangt, zonder vertragingen of problemen met SMS.",
    "Web - Google Authenticator": null,
    "Google Authenticator": "Google Authenticator",
    "Two Factor Authentication Code": "Twee-factor-authenticatiecode",
    "Problem with the token?": "Probleem met het token?",
    "Verify using SMS": "Verifieer via SMS",
    "Verify": "Verifiëren",
    "Web Mobile - Google Authenticator": null,
    "SMS - Web ": null,
    "SMS Verification": "SMS-verificatie",
    "Enter the SMS code sent to +41********4053": "Voer de SMS-code in die is verzonden naar +41********4053",
    "Verify using Authenticator App": "Verifieer met de Authenticator-app",
    "Resend code in 120 seconds": "Stuur de code opnieuw in 120 seconden",
    "Verify ": "Verifiëren",
    "SMS - Web Mobile": null,
    "My own account": "Mijn eigen account",
    "Friends & Family": "Vrienden & Familie",
    "Aunt": "Tante",
    "Brother-in-law": "Zwager",
    "Cousin": "Neef",
    "Daughter": "Dochter",
    "Father": "Vader",
    "Father-in-law": "Schoonvader",
    "Friend": "Vriend",
    "Grandfather": "Grootvader",
    "Grandmother": "Grootmoeder",
    "Husband": "Echtgenoot",
    "Mother": "Moeder",
    "Mother-in-law": "Schoonmoeder",
    "Nephew": "Neefje",
    "Niece": "Nichtje",
    "Self (i.e. the sender, himself)": "Zelf (d.w.z. de afzender zelf)",
    "Sister": "Zus",
    "Sister-in-law": "Schoonzus",
    "Son": "Zoon",
    "Uncle": "Oom",
    "Wife": "Echtgenote",
    "Others not listed": "Anderen niet vermeld",
    "Merchant": "Handelaar",
    "No relationship": "Geen relatie",
    "Add Funds": "Geld toevoegen",
    "Enter the Amount": "Voer het bedrag in:",
    "Fees": "Kosten:",
    "You Will Get": "Je ontvangt:",
    "⚠️ The transactional value should be between[Currency ISO code & Amount] - [Currency ISO code & Amount] ": "⚠️ De transactie waarde moet tussen [Currency ISO code & Amount] - [Currency ISO code & Amount] liggen",
    "⚠️ Invalid Amount: Your current  identity verification level limits the  maximum amount you can add. Please upgrade your identity verification to benefit from higher limits.           ": "⚠️ Ongeldig bedrag: Uw huidige identiteitsverificatieniveau beperkt het maximale bedrag dat u kunt toevoegen. Gelieve uw identiteitsverificatie te upgraden om te profiteren van hogere limieten.",
    "ℹ️ The price of currency changes frequently based on market conditions. Please refer to the price on the confirm order page as your final quotation": "ℹ️ De prijs van de valuta verandert vaak op basis van marktomstandigheden. Raadpleeg de prijs op de bevestigingspagina van de bestelling als uw definitieve offerte.",
    "Next": "Volgende",
    "Upgrade Identity Verification": "Identiteitsverificatie upgraden",
    "Request money from anyone, anywhere, including across social networking platforms. With InstaPay, your payment requests are instantly accessible once accepted and funds are sent to your Receivable Accounts. Simplify your transactions and manage payments with ease and security.": "Vraag geld aan van iedereen, overal, inclusief via sociale netwerken. Met InstaPay zijn uw betalingsverzoeken direct toegankelijk zodra ze zijn geaccepteerd en worden de fondsen naar uw ontvangende accounts gestuurd. Vereenvoudig uw transacties en beheer betalingen met gemak en veiligheid.",
    "You’ve been logged out.": "Je bent uitgelogd.",
    "For your security, you’ve been logged out after 15 minutes of inactivity. Please log in again if you wish to use InstaPay.": "Voor je veiligheid ben je uitgelogd na 15 minuten inactiviteit. Log opnieuw in als je InstaPay wilt gebruiken.",
    "LOGIN AGAIN": "OPNIEUW INLOGGEN",
    "Brother": "Broer",
    "Current Password": "Huidig wachtwoord",
    "The new password cannot be the same as the old one": "Het nieuwe wachtwoord mag niet hetzelfde zijn als het oude.",
    "Minimum password length: [X] characters ": "Minimale wachtwoordlengte: [X] tekens",
    "Include at least one uppercase and one lowercase letter.": "Inclusief minstens één hoofdletter en één kleine letter.",
    "Nominee Information": "Informatie over genomineerde",
    "Verification Code Preferences": "Voorkeuren voor verificatiecode",
    "How Others Can Find You": "Hoe anderen je kunnen vinden",
    "My Social Network Accounts": "Mijn sociale netwerkaccounts",
    "Login Activity": "Inlogactiviteit",
    "Receiving Accounts": "Ontvangstrekeningen",
    "Change Your Password To Continue": "Wijzig uw wachtwoord om door te gaan",
    "Continue with": "Doorgaan met",
    "Back to Login": "Terug naar inloggen",
    "Send OTP": "OTP verzenden",
    "A Verification code has been sent to your email and number.": "Een verificatiecode is naar uw e-mail en nummer verzonden.",
    "Resend available in": "Opnieuw verzenden beschikbaar over",
    "Create a strong Password": "Maak een sterk wachtwoord aan",
    "Password must:": "Wachtwoord moet:",
    "Be between 9 and 64 characters": "Tussen 9 en 64 tekens lang zijn",
    "Include at least two of the following:": "Minstens twee van de volgende bevatten:",
    "Lowercase Character": "Kleine letter",
    "Special Character": "Speciaal teken",
    "Password Updated Successfully!": "Wachtwoord succesvol bijgewerkt!",
    "Click below to login": "Klik hieronder om in te loggen",

    "Send Money": "Geld verzenden",
    "Enter the amount": null,
    "Recent Transfer": "Recente overboeking",
    "See all": "Alles zien",
    "Send again": "Opnieuw verzenden",
    "You Send": "U verzendt",
    "They Receive": "Zij ontvangen",
    "Receive Method": "Ontvangstmethode",
    "Select method": "Selecteer methode",
    "Next": "Volgende",
    "Trouble sending money?": "Problemen met het verzenden van geld?",
    "Where do you want to send money ?": "Waar wilt u geld naar toe verzenden?",
    "Search ": "Zoeken",
    "Confirm": "Bevestigen",
    "Bank Deposit": "Bankstorting",
    "Cash Pick-up": "Contant Ophalen",
    "Mobile Wallet": "Mobile Wallet",
    "Bank Card": "Bankpas",
    "Crypto Wallet": "Wallet crypto",
    "What is the receiver’s Mobile Wallet?": "Wat is het Wallet mobiel van de ontvanger?",
    "Transfer fees": "Overboekingskosten",
    "Total to pay": "Totaal te betalen",
    "Choose the beneficiary!": "Kies de begunstigde!",
    "Country": "Land",
    "Receive method": "Ontvangstmethode",
    "You send": "U verzendt",
    "change": "wijzigen",
    "edit": "nieuwe toevoegen",
    "add new": "Begunstigden",
    "Beneficiaries": "Beheer begunstigden",
    "Manage Beneficiaries": "Zoeken",
    "Search": "Volgende",
    "CASH PICK-UP": "CONTANT AFHALEN",
    "Where will your receiver collect the money": "Waar zal uw ontvanger het geld afhalen?",
    "Cash is available to collect within minutes from any branch of [name] ": "Contant geld is binnen enkele minuten beschikbaar bij elk filiaal van [naam]",
    "BANK ACCOUNT": "BANKREKENING",
    "Transfer to recipient’s bank account": "Overboeking naar de bankrekening van de ontvanger",
    "BANK CARD": "BANKKAART",
    "Fast transfer directly to recipient’s bank card": "Snelle overboeking direct naar de bankkaart van de ontvanger",
    "CRYPTO WALLET": "WALLET CRYPTO",
    "Fast transfer directly to a crypto wallet": "Snelle overboeking direct naar een Wallet crypto",
    "Review transfer": "Overboeking controleren",
    "Receiver's Details": "Gegevens van de ontvanger",
    "Edit": "Bewerken",
    "Name": "Naam",
    "Reason for sending": "Reden voor verzending",
    "Sending": "Verzenden",
    "Receiver gets": "Ontvanger krijgt",
    "Exchange rate": "Wisselkoers",
    "Estimated time": "Geschatte tijd",
    "Select Payment Type": "Selecteer betaalmethode",
    "Credit cards": "Creditcards",
    "Domestic cards": "Binnenlandse kaarten",
    "Mobile Wallets": "Wallets mobiel",
    "InstaPay Wallet": "Wallet InstaPay",
    "Crypto (USDT, ETH, BTC)": "Crypto (USDT, ETH, BTC)",
    "PayPal": "PayPal",
    "CREDIT CARD": "CREDITCARD",
    "Credit Card": "Creditcard",
    "Select card": "Selecteer kaart",
    "Card Number": "Kaartnummer",
    "Enter the 16-digit card number on the card": "Voer het 16-cijferige kaartnummer in",
    "Expiry Date": "Vervaldatum",
    "Enter the expiration date of the card": "Voer de vervaldatum van de kaart in",
    "CVV Number": "CVV-nummer",
    "Enter the 3 or 4 digit number on the card": "Voer het 3- of 4-cijferige nummer van de kaart in",
    "INSTAPAY WALLET": "WALLET INSTAPAY",
    "Select Wallet": "Wallet selecteren",
    "My Wallet": "Mijn Wallet",
    "Conversion": "Conversie",
    "Convert": "Converteren",
    "Your payment is confirmed": "Uw betaling is bevestigd",
    "Thanks for using InstaPay": "Bedankt voor het gebruik van InstaPay",
    "Status": "Status",
    "Share": "Delen",
    "Transfer created": "Overboeking aangemaakt",
    "Payment received": "Betaling ontvangen",
    "Payment processed": "Betaling verwerkt",
    "Transfer successful": "Overboeking succesvol",
    "It may take few hours for the funds to appear in [user name] account": "Het kan enkele uren duren voordat de fondsen op het account van [gebruikersnaam] verschijnen",
    "Transfer failed": "Overboeking mislukt",
    "Transaction Details": "Transactiedetails",
    "Need help?": "Hulp nodig?",
    "Service Details": "Service Details",
    "Funds Deposit Speed": "Beschikbaarheid van Fondsen",
    "Cut-off Time": "Sluitingstijd",
    "Bank holiday list": "Lijst van Bankfeestdagen",
    "Threshold limits": "Transactielimieten",
    "null": null,
    "Instant, Real Time": "Instant, real-time",
    "Same day, T+1": "Zelfde dag, T+1",
    "Non-instant, T+1": "Niet instant, T+1",
    "Instant Payment, Real Time": "Directe betaling, real-time",
    "Transaction processed before 3:00 PM Local time": "Transactie verwerkt vóór 15:00 lokale tijd",
    "Delivered the same day": "Geleverd dezelfde dag",
    "Transactions processed post 3:00 PM": "Transacties verwerkt na 15:00",
    "T+1 business days": "T+1 werkdagen",
    "Payments made on weekdays before 3:00PM will be credited to the receiving bank account by midnight of the\nsame day.": "Betalingen die op werkdagen vóór 15:00 uur worden gedaan, worden voor middernacht van dezelfde dag bijgeschreven op de ontvangende bankrekening.",
    "Payments made on weekdays after 15:00 will be credited by midnight the following day.": "Betalingen die op werkdagen na 15:00 uur worden gedaan, worden voor middernacht de volgende dag bijgeschreven.",
    "Payments made on a Saturday, Sunday or Public holiday will be credited to the account by midnight of the 1st following business day": "Betalingen die op zaterdag, zondag of feestdagen worden gedaan, worden voor middernacht van de eerstvolgende werkdag bijgeschreven.",
    "24*7": "24*7",
    "09hrs - 18 hrs Local Time": "09:00 - 18:00 lokale tijd",
    "https://www.officeholidays.com/countries ": "https://www.officeholidays.com/countries",
    "Min per transaction limit:": "Minimale limiet per transactie:",
    "Maximum per transaction limit:": "Maximale limiet per transactie:",
    "Daily threshold limit:": "Dagelijkse limiet:",
    "Weekly threshold limit:": "Wekelijkse limiet:",
    "Monthly threshold limit:": "Maandelijkse limiet:",
    "Yearly threshold limit:": "Jaarlijkse limiet:",
    "P2P": "P2P",
    "P2B": "P2B",
    "B2P": "B2P",
    "B2B": "B2B",
    "WALLET TO WALLET": "WALLET TO WALLET",
    "Basic Account (Unverified)": "Basisaccount (Niet geverifieerd)",
    "Identity Verified": "Identiteit geverifieerd"

}