import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router'
import InstaPayLoader from '../Loader'
import { decryptData } from '../../Helper/EssentilaMethods/Decryption'
import { toast } from 'react-toastify'
import axios from 'axios'
import { encryptData } from '../../Helper/EssentilaMethods/Encryption'
import { CustomDatePickerContainer, SuccessImage } from './schedule-calendar'

const PaypalTransactions = () => {
    const { status, id } = useParams()
    const [completed, setCompleted] = useState(false)
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    const flow = queryParams.get("flow")

    useEffect(() => {
        const hitNotification = async () => {
            try {
                const data = {
                    "referenceId": id,
                    "flow": flow,
                    "method": "paypal"
                }

                const res = await axios.post(`${process.env.REACT_APP_IP_DEV_API}/api/paypal/send-bot-notification`, {
                    data: encryptData(data)
                })

                const dec = decryptData(res.data.data)
                console.log(dec)
                setCompleted(true)
            } catch (error) {
                const err = decryptData(error?.response?.data?.data)
                console.log(err)
                toast.error(err?.message)
            }
        }
        if (status && id) {
            hitNotification()
        }
    }, [status, id])

    return (
        completed
            ?
            <CustomDatePickerContainer>
                <SuccessImage
                    src="https://cdn.iconscout.com/icon/premium/png-256-thumb/success-8634476-7012213.png"
                    alt="Logo"
                />
                <h5 style={{ padding: "1rem", textAlign: "center", fontSize: ".9rem", fontWeight: 600 }}>You can now close this webpage and go back to Instagram.</h5>
            </CustomDatePickerContainer>
            :
            <div style={{ height: "100vh", width: "100vw" }}>
                <InstaPayLoader />
            </div>
    )
}

export default PaypalTransactions